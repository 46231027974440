import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text, MultiAutocomplete } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';

const RoleForm = ({ form, handleSubmit, submitting, pristine, invalid, onSubmit, submitLabel }) => {
    const permissionsReducerName = 'permissions';
    const permissionsActionName = 'PERMISSIONS';

    const dispatch = useDispatch();
    const formValues = useSelector(state => getFormValues(form)(state));
    const permissions = useSelector(state => getListingResponse(state, permissionsReducerName));
    const permissionsFetching = useSelector(state =>
        getListingFetching(state, permissionsReducerName)
    );

    const fetchPermissions = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: permissionsActionName,
                name: permissionsReducerName,
                endpoint: 'api/permissions'
            });
        }).catch(error => {
            return error;
        });
    };

    const getPermissionData = (permissionIri = null) => {
        if (!permissionIri || !permissions?.data?.length) return null;

        return permissions.data.find(permission => permission.iri === permissionIri);
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    id="name"
                    fieldProps={{
                        label: 'Role name',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="permissions"
                    id="permissions"
                    fieldProps={{
                        label: 'Permissions',
                        options: {
                            endpoint: '/api/permissions?filters[active][equals]=true',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={MultiAutocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {!!formValues?.permissions?.length && (
                <>
                    <p style={{ margin: '0 0 8px' }}>With this set of permissions User can:</p>
                    <ul>
                        {permissionsFetching ? (
                            <li>
                                <ElementLoader />
                            </li>
                        ) : (
                            formValues.permissions.map(permissionIri => {
                                const permissionLabel = getPermissionData(permissionIri)?.name;
                                const permissionDescription = getPermissionData(permissionIri)
                                    ?.description;
                                return (
                                    <li style={{ marginBottom: '4px' }}>
                                        {permissionLabel ? <strong>{permissionLabel}</strong> : ''}
                                        {permissionLabel && permissionDescription ? ' - ' : ''}
                                        {permissionDescription || ''}
                                    </li>
                                );
                            })
                        )}
                    </ul>
                </>
            )}
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

RoleForm.defaultProps = {
    form: 'RoleForm',
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

RoleForm.propTypes = {
    form: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    enableReinitialize: true
})(RoleForm);
