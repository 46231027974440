import React, { useState } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import BlogsEditCreateForm from 'erpcore/screens/Settings/Blue/screens/Blog/components/BlogEditCreateForm';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import BlogsDelete from 'erpcore/screens/Settings/Blue/screens/Blog/components/BlogDelete';
import BlogsEdit from 'erpcore/screens/Settings/Blue/screens/Blog/components/BlogEdit';
import { actions as blogsActions } from 'erpcore/screens/Settings/Blue/screens/Blog/Blog.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import { reset } from 'redux-form';
import BlueTabs from 'erpcore/screens/Settings/Blue/components/BlueTabs';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import moment from 'moment-timezone';

const Blogs = () => {
    const title = 'MLA Blue App - FAQ';
    const reducerName = 'blogs';
    const actionName = 'BLOGS';
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const dispatch = useDispatch();
    const [createModal, setCreateModal] = useState(false);

    const fetchBlogs = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params: { ...params, include: 'image,category' },
                entity: actionName,
                name: reducerName,
                endpoint: 'api/blogs'
            });
        }).catch(error => {
            return error;
        });
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: blogsActions.START_CREATE_BLOG,
                promise: { resolve, reject },
                formData
            });
        })
            .then(() => {
                dispatch(reset('BlogsEditCreateForm'));
            })
            .finally(() => setCreateModal(false));
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Filters
        table.filters = [
            {
                name: 'category',
                label: 'Category',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Category',
                            options: {
                                endpoint: `/api/blog-categories`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        // Table Schema
        table.schema = [
            {
                title: 'image',
                field: 'image',
                sortable: false,
                mobile: 'image'
            },
            {
                title: 'Title',
                field: 'title',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Publish Date',
                field: 'publish_date',
                mobile: 'publish_date'
            },
            {
                title: 'Category',
                field: 'category',
                mobile: 'category'
            },
            {
                title: 'Excerpt',
                field: 'excerpt',
                sortable: false,
                mobile: 'excerpt'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'publish_date'
        };

        if (listing && listing.data) {
            listing.data.map(row => {
                const { title: blogTitle, excerpt, publish_date: publishDate, image, category } = {
                    ...row
                };
                const { content_url: imageUrl } = { ...image };

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    publish_date: publishDate ? moment(publishDate).format('MMM Do, YYYY') : '-',
                    image: (
                        <div
                            style={{
                                width: '100px',
                                height: '100px',
                                border: '1px solid lightGray',
                                backgroundImage: `url(${imageUrl})`,
                                backgroundColor: '#f7f7f7',
                                backgroundSize: 'cover'
                            }}
                        />
                    ),
                    title: blogTitle || '',
                    excerpt: excerpt || '',
                    category: category?.name || '',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <BlogsEdit data={row} />
                            </TableActions.Action>
                            <TableActions.Action>
                                <BlogsDelete iri={row?.iri} form={`BlogsDelete-${row?.id}`} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button
                    size="small"
                    onClick={() => setCreateModal(true)}
                    label="Create New FAQ Post"
                />
            </PageHeader>
            <BlueTabs />
            <PageContent>
                <Modal
                    title="Create FAQ Post"
                    opened={createModal}
                    onClose={() => setCreateModal(false)}
                >
                    <BlogsEditCreateForm onSubmit={onSubmit} />
                </Modal>
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchBlogs(params)}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default Blogs;
