import { createSelector } from 'reselect';

export const getFetchingMe = state => state.authManager?.fetchingMe;

export const getSignedIn = state => state.authManager.isSignedIn;

export const getMeData = state => state?.authManager?.user || {};

export const getUserPermissions = state => {
    if (
        state &&
        state.authManager &&
        state.authManager.user &&
        state.authManager.user.meta &&
        state.authManager.user.meta.permissions &&
        state.authManager.user.meta.permissions.constructor === Object
    ) {
        return state.authManager.user.meta.permissions;
    }
    return {
        events: false,
        vendors: false,
        eventsPerPermission: {}
    };
};

export const getUserCurrentOrganization = state =>
    state.authManager.userOrganization && state.authManager.userOrganization;

export const getUserRole = state =>
    state.authManager.isSignedIn === true
        ? state.authManager.user && state.authManager.user.role
        : '';

export default createSelector([getSignedIn, getMeData, getUserRole, getUserCurrentOrganization]);
