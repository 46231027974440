import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as sessionActions } from 'erpcore/screens/Sessions/Sessions.reducer';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { useDispatch, useSelector } from 'react-redux';
import SessionCreateForm from 'erpcore/screens/Sessions/components/SessionCreateForm';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { useHistory } from 'react-router-dom';
import { getIdFromIri } from 'erpcore/utils/dto';

const SessionCreate = () => {
    const dispatch = useDispatch();
    const meData = useSelector(getMeData) || {};
    const history = useHistory();

    const initialValues = {
        sales_agent: meData?.iri,
        recap_days_to_expiration: 7,
        recap_days_to_expiration_toggle: true,
        chat_enabled: true,
        raising_hands_enabled: true,
        timezone: '/api/timezones/497'
    };

    const title = 'Create new session';

    const createSessionProspect = (sessionIri, prospectIri) => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_CREATE_SESSION_PROSPECT,
                prospectIri,
                sessionIri
            });
        }).finally(() => {
            history.push(`/sessions/${getIdFromIri(sessionIri)}/edit`);
        });
    };

    const onSubmit = formData => {
        if (!formData.recap_days_to_expiration_toggle) {
            formData.recap_days_to_expiration = null;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_CREATE_SESSION,
                formData
            });
        })
            .then(({ sessionIri, prospects = [] }) => {
                if (prospects?.length) {
                    prospects.map(prospectIri => {
                        return createSessionProspect(sessionIri, prospectIri);
                    });
                } else {
                    history.push(`/sessions/${getIdFromIri(sessionIri)}/edit`);
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <SessionCreateForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    form="SessionCreateForm"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default SessionCreate;
