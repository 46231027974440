import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import './ElementLoader.scss';

/**
 *
 * @param {*} props
 */
const ElementLoader = props => {
    const { className, overlay } = props;

    return (
        <div
            className={`element-loader ${className || ''} ${
                overlay ? 'element-loader--overlay' : ''
            }`}
        >
            <Svg icon="elementLoader" />
        </div>
    );
};

ElementLoader.defaultProps = {
    className: '',
    overlay: false
};

ElementLoader.propTypes = {
    className: PropTypes.string,
    overlay: PropTypes.bool
};

export default ElementLoader;
