import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Form, { TextEditor } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';

const OIAccessGeneralForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="pbi_message"
                        id="pbi_message"
                        fieldProps={{
                            label: 'MLA OI dashboard text',
                            clearable: true
                        }}
                        component={TextEditor}
                    />
                </Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form>
        </>
    );
};

OIAccessGeneralForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    pristine: false,
    invalid: false
};

OIAccessGeneralForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'OIAccessGeneralForm',
    enableReinitialize: true
})(OIAccessGeneralForm);
