import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'erpcore/components/Tooltip';
import StatusCircle from 'erpcore/components/StatusCircle';
import Svg from 'erpcore/components/Svg';
import './AppStatuses.scss';

const AppStatuses = ({ mlaLive, homestore, mlaBlue, portal }) => {
    return (
        <div className="app-statuses">
            <Tooltip className="app-statuses-icon" content="Field populates">
                <Svg icon="info" />
            </Tooltip>
            {mlaLive && (
                <div className="app-statuses-status">
                    <StatusCircle type="apple" />
                    <span>MLA Live</span>
                </div>
            )}
            {homestore && (
                <div className="app-statuses-status">
                    <StatusCircle type="black" />
                    <span>HomeStore</span>
                </div>
            )}
            {mlaBlue && (
                <div className="app-statuses-status">
                    <StatusCircle type="sky" />
                    <span>MLA Blue/Oi</span>
                </div>
            )}
            {portal && (
                <div className="app-statuses-status">
                    <StatusCircle type="mid-grey" />
                    <span>Homeowner Portal</span>
                </div>
            )}
        </div>
    );
};

AppStatuses.defaultProps = {
    mlaLive: false,
    homestore: false,
    mlaBlue: false,
    portal: false
};

AppStatuses.propTypes = {
    mlaLive: PropTypes.bool,
    homestore: PropTypes.bool,
    mlaBlue: PropTypes.bool,
    portal: PropTypes.bool
};

export default AppStatuses;
