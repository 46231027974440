import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import Form, {
    Text,
    Select,
    Media,
    TextEditor,
    DateTime,
    Switch,
    Repeater
} from 'erpcore/components/Form';
import { projectIsActive } from 'erpcore/screens/Projects/data/projectsData';
import AppStatuses from 'erpcore/components/AppStatuses';
import restClient from 'erpcore/api/restClient';
// import PurchaseCalculatorsRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/PurchaseCalculatorsRepeaterItem';

const ConstructionStatusRepeatableItem = member => {
    const selectedStage = member?.data?.stage;
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member?.member}.stage`}
                    fieldProps={{
                        label: 'Stage',
                        clearable: true,
                        options: [
                            {
                                value: 'demolition_and_foundation',
                                label: 'Demolition & Foundation'
                            },
                            { value: 'framing_and_roofing', label: 'Framing & Roofing' },
                            { value: 'rough-ins_and_drywall', label: 'Rough-ins & Drywall' },
                            { value: 'interior_finishes', label: 'Interior Finishes' },
                            { value: 'landscaping', label: 'Landscaping' },
                            { value: 'final_detailing', label: 'Final Detailing' },
                            { value: 'custom_stage', label: 'Custom Stage' }
                        ]
                    }}
                    fieldAttr={{ id: `${member?.member}.stage`, required: true }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                {selectedStage === 'custom_stage' && (
                    <Field
                        name={`${member?.member}.custom_stage`}
                        fieldProps={{
                            label: 'Custom Stage',
                            clearable: true
                        }}
                        fieldAttr={{ id: `${member?.member}.custom_stage`, required: true }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                )}
                <Field
                    name={`${member?.member}.status`}
                    fieldProps={{
                        label: 'Status',
                        clearable: true,
                        options: [
                            { value: 'completed', label: 'Completed' },
                            { value: 'in_progress', label: 'In Progress' },
                            { value: 'not_started', label: 'Not Started' }
                        ]
                    }}
                    fieldAttr={{ id: `${member?.member}.status`, required: true }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member?.member}.date`}
                    fieldProps={{
                        label: 'Date',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member?.member}.date` }}
                    component={DateTime}
                />
            </Form.Row>
        </div>
    );
};

ConstructionStatusRepeatableItem.defaultProps = {};

ConstructionStatusRepeatableItem.propTypes = {};

const KeyContactsRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{
                        label: 'Contact Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.url`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.email`}
                    fieldProps={{
                        label: 'Contact Email',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.email`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Column>
                <Field
                    name={`${member}.content`}
                    fieldProps={{
                        label: 'Contact Content',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.content`, required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <AppStatuses mlaBlue mlaLive />
            </Form.Column>
        </div>
    );
};

KeyContactsRepeatableItem.defaultProps = {
    member: null
};

KeyContactsRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const ProjectHeroRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Form.SectionTitleSmall>Hero Image *</Form.SectionTitleSmall>
                <FieldArray
                    name={`${member}.image`}
                    id={`${member}.image`}
                    component={Media}
                    useLightbox
                    maxImageCount={1}
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Section>
                <Form.SectionTitle>Key Contacts</Form.SectionTitle>
                <FieldArray
                    name="key_contacts"
                    component={Repeater}
                    RepeatableItem={KeyContactsRepeatableItem}
                    saveButtonIntent="all"
                    canSave={false}
                    canSort
                    canSortNewItems
                    uniqueIdentifier="iri"
                    addNewLabel="Add New Key Contact"
                    canAddMultiple
                />
            </Form.Section>
        </div>
    );
};

ProjectHeroRepeatableItem.defaultProps = {
    member: null
};

ProjectHeroRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const ProjectHomeownerPortalForm = ({
    touch,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    isSlugRequired,
    projectData,
    form
}) => {
    const dispatch = useDispatch();
    const [auditGenerating, setAuditGenerating] = useState(false);
    // const formData = useSelector(state => getFormValues(form)(state));

    useEffect(() => {
        if (!isSlugRequired) {
            touch('slug');
        }
    }, []);

    const handleChangePortalToggles = (isEnabled, fieldName) => {
        if (fieldName === 'test_portal_enabled' && isEnabled) {
            dispatch(change(form, 'portal_enabled', false));
            return;
        }
        if (fieldName === 'portal_enabled' && isEnabled) {
            dispatch(change(form, 'test_portal_enabled', false));
        }
    };

    const audit = () => {
        setAuditGenerating(true);
        restClient
            .get(`${projectData.iri}/portal-list`, {
                responseType: 'blob'
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${projectData?.name}-portal-audit-${Date.now()}.csv`
                );
                document.body.appendChild(link);
                link.click();
            })
            .finally(() => {
                setAuditGenerating(false);
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="test_portal_enabled"
                    fieldProps={{
                        label: 'Test MLA Portal for this project',
                        clearable: true,
                        options: projectIsActive,
                        off: {
                            value: false
                        },
                        on: {
                            value: true
                        }
                    }}
                    fieldAttr={{ id: 'test_portal_enabled' }}
                    component={Switch}
                    onChange={isEnabled =>
                        handleChangePortalToggles(isEnabled, 'test_portal_enabled')
                    }
                />
                <Button
                    variation="secondary"
                    iconName="presentation"
                    label="Portal Audit Export"
                    onClick={audit}
                    loading={auditGenerating}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="portal_enabled"
                    fieldProps={{
                        label: 'Enable MLA Portal for this project',
                        clearable: true,
                        options: projectIsActive,
                        off: {
                            value: false
                        },
                        on: {
                            value: true
                        }
                    }}
                    fieldAttr={{ id: 'portal_enabled' }}
                    component={Switch}
                    onChange={isEnabled => handleChangePortalToggles(isEnabled, 'portal_enabled')}
                />
            </Form.Row>
            <Form.Section>
                <Form.SectionTitle>Key Contacts</Form.SectionTitle>
                <FieldArray
                    name="key_contacts"
                    component={Repeater}
                    RepeatableItem={KeyContactsRepeatableItem}
                    saveButtonIntent="all"
                    canSave={false}
                    canSort
                    canSortNewItems
                    uniqueIdentifier="iri"
                    addNewLabel="Add New Key Contact"
                    canAddMultiple
                />
            </Form.Section>
            <br />
            <Form.Section>
                <Form.SectionTitle>Construction Status</Form.SectionTitle>
                <Form.Row>
                    <FieldArray
                        name="construction_status"
                        component={Repeater}
                        RepeatableItem={ConstructionStatusRepeatableItem}
                        saveButtonIntent="all"
                        canSave={false}
                        canSort
                        canSortNewItems
                        uniqueIdentifier="iri"
                        addNewLabel="Add New Construction Status"
                        canAddMultiple
                        rerenderOnEveryChange
                    />
                </Form.Row>
            </Form.Section>
            <Form.Section>
                <Form.SectionTitle>Create Realtor credentials</Form.SectionTitle>
                <Form.Row>
                    <Field
                        name="create_realtor_credentials_and_send_email"
                        fieldProps={{
                            label: 'Create Realtor credentials and send email',
                            off: {
                                value: false
                            },
                            on: {
                                value: true
                            }
                        }}
                        fieldAttr={{ id: 'create_realtor_credentials_and_send_email' }}
                        component={Switch}
                    />
                </Form.Row>
            </Form.Section>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

ProjectHomeownerPortalForm.defaultProps = {
    onSubmit: () => {},
    // change: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    isSlugRequired: false,
    form: null,
    projectData: ''
};

ProjectHomeownerPortalForm.propTypes = {
    touch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    // change: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isSlugRequired: PropTypes.bool,
    form: PropTypes.string,
    projectData: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'ProjectHomeownerPortalForm',
    enableReinitialize: true
})(ProjectHomeownerPortalForm);
