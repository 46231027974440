import React, { useEffect } from 'react';
import Widget from 'erpcore/components/Widget';
import { Link } from 'react-router-dom';
import './DemoSessisonsWidget.scss';
import Svg from 'erpcore/components/Svg';
import { useDispatch, useSelector } from 'react-redux';
import { actions as dashboardActions } from 'erpcore/screens/Dashboard/Dashboard.reducer';
import {
    getDashboardData,
    getDashboardFetching
} from 'erpcore/screens/Dashboard/Dashboard.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import moment from 'moment-timezone';
import DemoSessionSchedule from 'erpcore/screens/Dashboard/components/DemoSessionSchedule';

const DemoSessionsWidget = () => {
    const dispatch = useDispatch();
    const dashboardSessionData = useSelector(getDashboardData);
    const dashboardFetching = useSelector(getDashboardFetching);
    const { meta } = { ...dashboardSessionData };

    const today = moment().format('DD/MM/YYYY');
    const tomorrow = moment()
        .add(1, 'days')
        .format('DD/MM/YYYY');

    const dayAfterTomorrow = moment()
        .add(2, 'days')
        .format('DD/MM/YYYY');

    const todaysSessions =
        dashboardSessionData?.data?.filter(item => {
            const scheduledDate = moment(item?.date).format('DD/MM/YYYY');

            return today === scheduledDate;
        }) || [];

    const tomorrowsSessions =
        dashboardSessionData?.data?.filter(item => {
            const scheduledDate = moment(item?.date).format('DD/MM/YYYY');

            return tomorrow === scheduledDate;
        }) || [];

    const dayAfterTomorrowSessions =
        dashboardSessionData?.data?.filter(item => {
            const scheduledDate = moment(item?.date).format('DD/MM/YYYY');

            return dayAfterTomorrow === scheduledDate;
        }) || [];

    const fetchSessions = () => {
        const params = {
            include: 'sessionProspects,salesAgent,projects,sessionProspects.prospect',
            'filters[date][larger_than]': moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: dashboardActions.START_FETCHING_SESSIONS_DASHBOARD,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    return (
        <Widget size={6} className="demo-sessions-widget">
            {dashboardFetching && <ElementLoader overlay />}
            <div className="demo-sessions-widget__header">
                <h3>Demo sessions</h3>
                <Link to="/sessions">View All Sessions</Link>
            </div>

            <div className="demo-sessions-widget__totals">
                <div className="demo-sessions-widget__totals-block">
                    <div className="demo-sessions-widget__totals-icon">
                        <Svg icon="sessions" iconColor="denim" />
                    </div>
                    <div className="demo-sessions-widget__totals-stats">
                        <p>Upcomming Sessions</p>
                        <h3>{meta?.totalItems}</h3>
                    </div>
                </div>
                <div className="demo-sessions-widget__totals-block">
                    <div className="demo-sessions-widget__totals-icon">
                        <Svg icon="postDemo" iconColor="denim" />
                    </div>
                    <div className="demo-sessions-widget__totals-stats">
                        <p>Total Post-demo Views</p>
                        <h3>{meta?.totalPostDemoViews}</h3>
                    </div>
                </div>
            </div>

            <div className="demo-sessions-widget__schedule">
                <DemoSessionSchedule data={todaysSessions} title="Today" />
                <DemoSessionSchedule data={tomorrowsSessions} title="Tomorow" />
                <DemoSessionSchedule
                    data={dayAfterTomorrowSessions}
                    title={moment()
                        .add(2, 'days')
                        .format('MMM Do')}
                />
            </div>
        </Widget>
    );
};

export default DemoSessionsWidget;
