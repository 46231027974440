const hasPermission = (permission, meData) => {
    const { userPermissions } = { ...meData };

    const permissionElement =
        userPermissions &&
        userPermissions.length > 0 &&
        userPermissions.find(element => element === permission);

    if (permissionElement) {
        return true;
    }

    return false;
};

const getPermissionsAccessForEntities = meData => {
    const entitiesPermissions = {
        users: false,
        clients: false,
        projects: false,
        prospects: false,
        sessions: false,
        settings: false,
        organization: false,
        permissions: false,
        roles: false
    };

    // Dashboard
    entitiesPermissions.dashboard = true;

    // Users
    if (
        hasPermission('CAN_MANAGE_COMMON_USER', meData) ||
        hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData)
    ) {
        entitiesPermissions.users = true;
    }

    // Clients
    if (hasPermission('CAN_MANAGE_COMMON_CLIENT', meData)) {
        entitiesPermissions.clients = true;
    }

    // Projects
    if (
        hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) ||
        hasPermission('CAN_EDIT_ASSIGNED_PROJECTS', meData) ||
        hasPermission('CAN_READ_ASSIGNED_PROJECTS', meData)
    ) {
        entitiesPermissions.projects = true;
    }

    // Prospects
    if (
        hasPermission('CAN_MANAGE_CONTACT_BUNDLE_PROSPECT', meData) ||
        hasPermission('CAN_MANAGE_COMMON_USER', meData) ||
        hasPermission('CAN_MANAGE_PROSPECTS_BY_ASSIGNED_PROJECTS', meData) ||
        hasPermission('CAN_MANAGE_OWN_PROSPECTS', meData)
    ) {
        entitiesPermissions.prospects = true;
    }

    // Sessions
    if (
        hasPermission('CAN_MANAGE_COMMON_SESSION', meData) ||
        hasPermission('CAN_MANAGE_SESSIONS_BY_ASSIGNED_PROJECTS', meData) ||
        hasPermission('CAN_MANAGE_OWN_SESSIONS', meData)
    ) {
        entitiesPermissions.sessions = true;
    }

    // Settings
    if (
        hasPermission('CAN_MANAGE_COMMON_ORGANIZATIONSETTING', meData) ||
        hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) ||
        hasPermission('CAN_MANAGE_COMMON_DIVISION', meData) ||
        hasPermission('CAN_MANAGE_COMMON_DEPARTMENT', meData) ||
        hasPermission('CAN_MANAGE_COMMON_OFFICE', meData) ||
        hasPermission('CAN_MANAGE_COMMON_JOBTITLE', meData) ||
        hasPermission('CAN_MANAGE_COMMON_WORKTYPE', meData) ||
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) ||
        hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) ||
        hasPermission('CAN_MANAGE_COMMON_PAYMENTMETHOD', meData) ||
        hasPermission('CAN_MANAGE_COMMON_PAYMENTTERM', meData) ||
        hasPermission('CAN_MANAGE_COMMON_ORGANIZATION_SERVICE', meData)
    ) {
        entitiesPermissions.settings = true;
    }

    // Settings Organizations
    if (
        hasPermission('CAN_MANAGE_COMMON_ORGANIZATIONSETTING', meData) ||
        hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData)
    ) {
        entitiesPermissions.organization = true;
    }

    // Settings Roles
    if (hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData)) {
        entitiesPermissions.roles = true;
    }

    // Settings Permissions
    if (hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData)) {
        entitiesPermissions.permissions = true;
    }

    return entitiesPermissions;
};

export { hasPermission, getPermissionsAccessForEntities };
