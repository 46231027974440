import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import DealForm from 'erpcore/screens/Deals/components/DealForm';
import { actions as dealActions } from 'erpcore/screens/Deals/Deals.reducer';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getDealData } from 'erpcore/screens/Deals/Deals.selectors';
import Button from 'erpcore/components/Button';

const DealEdit = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const dealIri = `/api/deals/${match?.params?.id}`;
    const dealsData = useSelector(state => getDealData(state, dealIri)) || {};

    const {
        unit_type: unitType,
        hubspot_stage_label: hsStageLabel,
        hubspot_pipeline_label: hsPipelineLabel
    } = { ...dealsData };
    const initialValues = {
        name: dealsData?.name,
        project: dealsData?.project?.iri,
        prospects: dealsData?.prospects?.map(prospect => prospect?.iri) || [],
        unit: dealsData?.unit?.iri,
        unit_type: unitType?.iri,
        hubspot_stage_label: hsStageLabel,
        hubspot_pipeline_label: hsPipelineLabel
    };

    const title = `Edit Deal`;

    const fetchResourceData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dealActions.START_FETCHING_DEAL,
                iri
            });
        }).catch(error => ({ error }));
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dealActions.START_UPDATE_DEAL,
                formData,
                iri: dealIri
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    useEffect(() => {
        fetchResourceData(dealIri);
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button size="small" href="/deals" variation="tertiary" label="View All Deals" />
                <Button size="small" href={`/deals/${dealsData?.id}/view`} label="View Deal" />
            </PageHeader>
            <PageContent>
                {Object.keys(dealsData).length > 0 && (
                    <DealForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        form="ProspectDealEditForm"
                        submitLabel="Update"
                    />
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default DealEdit;
