import React from 'react';
import './Collapse.scss';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';

const Visible = ({ children }) => {
    return <div className="collapse__content-visible">{children}</div>;
};
Visible.defaultProps = {
    children: null
};
Visible.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

const Expandable = ({ children }) => {
    return <div className="collapse__content-expandable">{children}</div>;
};
Expandable.defaultProps = {
    children: null
};
Expandable.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

class Collapse extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;

        const { initiallyExpanded, hideToggle } = this.props;
        const expanded = hideToggle || initiallyExpanded; // if toggle is hidden, content is always expanded

        this.state = {
            expanded
        };
    }

    static Visible = Visible;

    static Expandable = Expandable;

    toggleExpandedState() {
        this.setState(state => ({
            expanded: !state.expanded
        }));
    }

    render() {
        const { children, hideToggle } = this.props;

        const { expanded } = this.state;

        const hasExpandable =
            children.filter(child => child.type && child.type === Expandable).length > 0;

        return (
            <div className="collapse" aria-expanded={expanded}>
                <div className="collapse__content">{children}</div>
                {!hideToggle && !!hasExpandable && (
                    <Button
                        variation="tertiary"
                        className="collapse__toggle"
                        size="small"
                        label="Toggle content"
                        labelOnlyAria
                        iconName="arrowDown"
                        onClick={() => this.toggleExpandedState()}
                    />
                )}
            </div>
        );
    }
}

Collapse.defaultProps = {
    initiallyExpanded: false,
    hideToggle: false,
    children: null
};

Collapse.propTypes = {
    initiallyExpanded: PropTypes.bool,
    hideToggle: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};

export default Collapse;
