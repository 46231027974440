export const actions = {
    START_FETCHING_BLOGS: 'START_FETCHING_BLOGS',
    FETCHING_SUCCESSFUL_BLOGS: 'FETCHING_SUCCESSFUL_BLOGS',
    FETCHING_FAILED_BLOGS: 'FETCHING_FAILED_BLOGS',

    START_DELETE_BLOG: 'START_DELETE_BLOG',
    DELETE_BLOG_SUCCESSFUL: 'DELETE_BLOG_SUCCESSFUL',
    DELETE_BLOG_FAILED: 'DELETE_BLOG_FAILED',

    START_CREATE_BLOG: 'START_CREATE_BLOG',
    CREATE_BLOG_SUCCESSFUL: 'CREATE_BLOG_SUCCESSFUL',
    CREATE_BLOG_FAILED: 'CREATE_BLOG_FAILED',

    START_UPDATE_BLOG: 'START_UPDATE_BLOG',
    UPDATE_BLOG_SUCCESSFUL: 'UPDATE_BLOG_SUCCESSFUL',
    UPDATE_BLOG_FAILED: 'UPDATE_BLOG_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_BLOGS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_BLOGS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_BLOGS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
