import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import DealForm from 'erpcore/screens/Prospects/components/DealForm';
import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getProspectData } from 'erpcore/screens/Prospects/Prospects.selectors';
import Button from 'erpcore/components/Button';

const ProspectDealEdit = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const prospectIri = `/api/prospects/${match?.params?.id}`;
    const dealIri = `/api/deals/${match?.params?.dealID}`;
    const prospectData = useSelector(state => getProspectData(state, prospectIri)) || {};
    const dealsData = useSelector(state => getProspectData(state, dealIri)) || {};

    const { unit_type: unitType } = { ...dealsData };
    const initialValues = {
        name: dealsData?.name,
        project: dealsData?.project?.iri,
        unit: dealsData?.unit?.iri,
        unit_type: unitType?.iri,
        prospects: dealsData?.prospects?.map(prospect => prospect?.iri) || []
    };

    const { first_name: firstName, last_name: lastName } = {
        ...prospectData
    };

    const title = `Edit Deal - ${firstName} ${lastName}`;

    const fetchResourceData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_SINGLE_PROSPECT,
                iri
            });
        }).catch(error => ({ error }));
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_UPDATE_PROSPECT_DEAL,
                formData,
                iri: dealIri
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    useEffect(() => {
        fetchResourceData(prospectIri);
        fetchResourceData(dealIri);
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button
                    size="small"
                    href={`/prospects/${prospectData?.id}/edit/deals`}
                    variation="tertiary"
                    label="View Prospect Deals"
                />
                <Button
                    size="small"
                    href={`/prospects/${prospectData?.id}/edit/deals/${dealsData?.id}`}
                    label="View Deal"
                />
            </PageHeader>
            <PageContent>
                {Object.keys(dealsData).length > 0 && (
                    <DealForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        fixedProspect={prospectIri}
                        form="ProspectDealEditForm"
                        submitLabel="Update"
                    />
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default ProspectDealEdit;
