import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ElementLoader from 'erpcore/components/ElementLoader';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PortalBannersForm from 'erpcore/screens/Settings/Portal/components/PortalBannersForm';
import PortalTabs from 'erpcore/screens/Settings/Portal/components/PortalTabs';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { getFetchingPortalSettings } from 'erpcore/screens/Settings/Portal/Portal.selectors';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import { getOrganizationSetting } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import orderBy from 'lodash/orderBy';

const PortalBanners = () => {
    const title = 'Homeowner Portal App - Banners';
    const [fetching, setFetching] = useState(false);
    const settingName = 'portalBanners';
    const dispatch = useDispatch();
    const banners = useSelector(state => getOrganizationSetting(state, settingName)) || [];
    const fetchingPortal = useSelector(state => getFetchingPortalSettings(state));

    const orderedData = orderBy(banners?.data, item => item?.settings?.position);

    const initialValues = {
        banners:
            orderedData?.map(banner => ({
                iri: banner?.iri,
                url: banner?.settings?.url,
                image: banner?.settings?.image
            })) || []
    };

    const fetchPortalBanners = (params = {}) => {
        params.limit = 999;
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName,
                params
            });
        })
            .then(() => setFetching(false))
            .catch(error => ({ error }));
    };

    const onCreateBanner = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: organizationActions.START_CREATE_ORGANIZATION_SETTING,
                settingName,
                portal: true,
                formData,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchPortalBanners());
    };

    const onUpdateBanner = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: organizationActions.START_UPDATE_ORGANIZATION_SETTING,
                iri: formData?.iri,
                settingName,
                portal: true,
                formData,
                promise: { resolve, reject },
                updateRedux: false
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchPortalBanners());
    };

    const onDeleteBanner = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: organizationActions.START_DELETE_ORGANIZATION_SETTING,
                settingName,
                iri: formData?.iri,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchPortalBanners());
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchPortalBanners();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <PortalTabs />
                {(!!fetchingPortal || fetching) && <ElementLoader overlay />}
                <PortalBannersForm
                    form="PortalBrandingForm"
                    submitLabel="Save"
                    onCreate={onCreateBanner}
                    onUpdate={onUpdateBanner}
                    onRemove={onDeleteBanner}
                    initialValues={initialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default PortalBanners;
