import { createSelector } from 'reselect';

export const getProjectData = (state, iri) => state.projects?.[iri];

export const getProjectFormValues = (state, formName, iri) =>
    state.form?.[`${formName}-${iri}`]?.values;

export const getProjectFetching = state => state.projects?.singleFetching;

export const getUnitsData = state => state.projects?.units?.unitsData || [];

export const getUnitsFetching = state => state.projects?.units?.unitsFetching || false;

export const getBuildingsData = (state, iri) => state.projects?.[iri]?.buildings || [];

export const getIsUpdating = state => state.projects.updating;

export default createSelector([
    getProjectData,
    getProjectFormValues,
    getProjectFetching,
    getUnitsData,
    getUnitsFetching,
    getBuildingsData,
    getIsUpdating
]);
