import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Menu from 'erpcore/components/Layout/MainNavigation/components/Menu';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import { isOiAccessSubdomain } from 'erpcore/utils/utils';
import {
    getDashboardData,
    getDashboardFetching
} from 'erpcore/screens/OiAccessDashboards/OiAccessDashboard.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import Svg from 'erpcore/components/Svg';

/**
 * Main Navigation
 * @param {Object} props
 * @return {Node} Returns Main Navigation
 */
const MainNavigation = () => {
    const menuId = 'main-navigation';
    const meData = useSelector(getMeData) || {};
    const { data: projectsData } = useSelector(state => getDashboardData(state));
    const filterUserRoles = meData?.roles?.map(userRole => userRole.attribute);
    const hasSuperAdminRole = filterUserRoles.indexOf('CAN_ROLE_SUPER_ADMIN') !== -1;
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);
    const { client_available_projects: clientAvailableProjects } = projectsData;
    const isFetchingOiAccessProjects = useSelector(state => getDashboardFetching(state));

    /**
     * Effect used only on component mount
     */
    useEffect(() => {
        // Expand Submenu on Page Load
        const menu = document.getElementById(menuId);
        const activeItems = menu.querySelectorAll('.main-navigation__submenu-link--active');

        if (activeItems.length) {
            activeItems.forEach(activeItem => {
                activeItem
                    .closest('.main-navigation__menu-item')
                    .classList.add('main-navigation__menu-item--open');
            });
        }
    }, []);

    const filterProjects = projectsList =>
        Array.isArray(projectsList) &&
        projectsList?.filter(project => project?.reporting && project?.reporting?.length);

    const projectsList = hasSuperAdminRole
        ? filterProjects(projectsData)
        : (clientAvailableProjects?.length && clientAvailableProjects) || [];

    if (isOiAccessSubdomain()) {
        return (
            <Menu id={menuId}>
                <>
                    {isFetchingOiAccessProjects && <ElementLoader overlay />}
                    {Array.isArray(projectsList) &&
                        projectsList?.length > 0 &&
                        projectsList?.map(project => {
                            return (
                                <Menu.Item
                                    key={project?.id}
                                    to={`/dashboard/project/${project?.id}`}
                                    label={project?.name}
                                />
                            );
                        })}
                </>
                {!isFetchingOiAccessProjects && projectsList?.length === 0 ? (
                    <div style={{ textAlign: 'center' }}>No project assigned to you!</div>
                ) : (
                    ''
                )}
                <Menu.Item
                    to="/help"
                    label={
                        <span>
                            <Svg icon="help" /> Help
                        </span>
                    }
                />
            </Menu>
        );
    }

    return (
        <Menu id={menuId}>
            {hasPermissionsAccessForEntities.dashboard && (
                <Menu.Item to="/dashboard" label="Dashboard" />
            )}
            {hasPermissionsAccessForEntities.users && <Menu.Item to="/users" label="Users" />}
            {hasPermissionsAccessForEntities.clients && (
                <Menu.Item to="/clients?clients[limit]=100" label="Clients" />
            )}
            {hasPermissionsAccessForEntities.projects && (
                <Menu.Item to="/projects" label="Projects" />
            )}
            {hasPermissionsAccessForEntities.prospects && (
                <Menu.Item to="/prospects" label="Prospects" />
            )}
            {hasPermissionsAccessForEntities.prospects && <Menu.Item to="/deals" label="Deals" />}
            {hasPermissionsAccessForEntities.sessions && (
                <Menu.Item to="/sessions" label="Sessions" />
            )}

            {hasPermissionsAccessForEntities.sessions && (
                <Menu.Submenu label="Reports">
                    <Menu.SubmenuItem to="/reports/session" label="Session" />
                </Menu.Submenu>
            )}

            {hasPermissionsAccessForEntities.settings && (
                <Menu.Submenu label="Settings">
                    <Menu.NestedSubmenu label="General" slug="/settings/general">
                        {hasPermissionsAccessForEntities.organization && (
                            <>
                                <Menu.SubmenuItem
                                    to="/settings/general/organization"
                                    label="Organization"
                                />
                                <Menu.SubmenuItem
                                    to="/settings/general/branding"
                                    label="Branding"
                                />
                            </>
                        )}
                        {hasPermissionsAccessForEntities.permissions && (
                            <Menu.SubmenuItem
                                to="/settings/general/permissions"
                                label="Permissions"
                            />
                        )}
                        {hasPermissionsAccessForEntities.roles && (
                            <Menu.SubmenuItem to="/settings/general/roles" label="Roles" />
                        )}
                        {hasPermissionsAccessForEntities.organization && (
                            <Menu.SubmenuItem
                                to="/settings/general/unit-types"
                                label="Unit Types"
                            />
                        )}
                        <Menu.SubmenuItem
                            to="/settings/general/product-types"
                            label="Product Types"
                        />
                        <Menu.SubmenuItem
                            to="/settings/general/avesdo-emails"
                            label="Avesdo Emails"
                        />
                        <Menu.SubmenuItem to="/settings/general/hubspot-log" label="Hubspot Log" />
                        <Menu.SubmenuItem
                            to="/settings/general/neghbourhood-location-sections"
                            label="Neighbourhood Location Sections"
                        />
                        {hasPermissionsAccessForEntities.organization && (
                            <>
                                <Menu.SubmenuItem
                                    to="/settings/general/integration/avesdo"
                                    label="Avesdo Integrations"
                                />
                                <Menu.NestedSubmenu
                                    label="Hubspot Integration"
                                    slug="/settings/general/integration/hubspot"
                                >
                                    <Menu.SubmenuItem
                                        to="/settings/general/integration/hubspot/settings"
                                        label="Settings"
                                    />
                                    <Menu.SubmenuItem
                                        to="/settings/general/integration/hubspot/connect"
                                        label="Connect"
                                    />
                                </Menu.NestedSubmenu>
                            </>
                        )}
                    </Menu.NestedSubmenu>
                    <Menu.NestedSubmenu label="MLA Live App" slug="/settings/mla-live">
                        {hasPermissionsAccessForEntities.sessions && (
                            <Menu.NestedSubmenu label="Session" slug="/settings/mla-live/session">
                                <Menu.SubmenuItem
                                    to="/settings/mla-live/session/sales-guidelines"
                                    label="Sales guidelines"
                                />
                                <Menu.SubmenuItem
                                    to="/settings/mla-live/session/prospect-profile-questions"
                                    label="Prospect profile questions"
                                />
                            </Menu.NestedSubmenu>
                        )}
                        {hasPermissionsAccessForEntities.organization && (
                            <Menu.SubmenuItem
                                to="/settings/mla-live/vonage-video-api"
                                label="Vonage Video API"
                            />
                        )}
                    </Menu.NestedSubmenu>
                    {hasPermissionsAccessForEntities.organization && (
                        <Menu.NestedSubmenu label="MLA Blue App" slug="/settings/mla-blue">
                            <Menu.SubmenuItem to="/settings/mla-blue/general" label="General" />
                            <Menu.SubmenuItem to="/settings/mla-blue/faq" label="FAQ" />
                            <Menu.SubmenuItem
                                to="/settings/mla-blue/faq-categories"
                                label="FAQ categories"
                            />
                        </Menu.NestedSubmenu>
                    )}
                    {hasPermissionsAccessForEntities.organization && (
                        <Menu.NestedSubmenu label="Homeowner Portal App" slug="/settings/portal">
                            <Menu.SubmenuItem to="/settings/portal/general" label="General" />
                            <Menu.SubmenuItem
                                to="/settings/portal/checklist-items"
                                label="Checklist items"
                            />
                            <Menu.SubmenuItem to="/settings/portal/faq" label="FAQ" />
                            <Menu.SubmenuItem to="/settings/portal/banners" label="Banners" />
                        </Menu.NestedSubmenu>
                    )}
                    {hasPermissionsAccessForEntities.organization && (
                        <Menu.NestedSubmenu label="OiAccess App" slug="/settings/oiaccess">
                            <Menu.SubmenuItem label="General" to="/settings/oiaccess/general" />
                            <Menu.SubmenuItem label="Help section" to="/settings/oiaccess/help" />
                        </Menu.NestedSubmenu>
                    )}
                </Menu.Submenu>
            )}
        </Menu>
    );
};

export default withRouter(MainNavigation);
