import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Departments Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const TimeOff = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'timeoff.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time off request successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timeoff.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time off request successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timeoff.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time off request successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timeoff.statusSuccessfullyChanged':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time off request status successfully changed!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

TimeOff.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

TimeOff.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default TimeOff;
