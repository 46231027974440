import React from 'react';
import PropTypes from 'prop-types';
import './StatusCircle.scss';

const StatusCircle = ({ type, className }) => (
    <span
        className={`status-circle${type !== '' ? ` status-circle--${type}` : ''}${
            className ? ` ${className}` : ''
        }`}
    />
);

StatusCircle.defaultProps = {
    type: '',
    className: ''
};

StatusCircle.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string
};

export default StatusCircle;
