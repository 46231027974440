import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import uniq from 'lodash/uniq';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Svg from 'erpcore/components/Svg';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';
import ProspectDelete from 'erpcore/screens/Prospects/components/ProspectDelete';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const ProspectsListing = () => {
    const reducerName = 'prospects';
    const actionName = 'PROSPECTS';

    const dispatch = useDispatch();
    const meData = useSelector(getMeData) || {};
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Prospects';

    const fetchProspects = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint:
                    'api/prospects?include=city,state,country,projects,projects.project,deals,deals.project'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'projects',
                label: 'Projects',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Projects',
                            options: {
                                endpoint: `/api/projects`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.schema = [
            {
                title: 'Prospect',
                field: 'prospect',
                sortable: 'lastName',
                mobile: 'title'
            },
            {
                title: 'City',
                field: 'city'
            },
            {
                title: 'Country',
                field: 'country'
            },
            {
                title: 'Primary Email',
                field: 'email'
            },
            {
                title: 'Primary Phone',
                field: 'phone'
            },
            {
                title: 'Projects',
                field: 'projects'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        const { data: listingData } = { ...listing };
        if (listingData) {
            listingData.map(row => {
                const {
                    id,
                    iri,
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    city,
                    country,
                    phone,
                    deals
                } = row;

                const prospectName = `${firstName || ''} ${lastName || ''}`;

                const prepareProjects = [];
                if (Array.isArray(deals))
                    deals.forEach(deal => prepareProjects.push(deal?.project?.name));

                return table.data.push({
                    id,
                    iri,
                    prospect: prospectName,
                    city: city?.name,
                    country: country?.name,
                    email,
                    phone,
                    projects: prepareProjects?.length > 0 ? uniq(prepareProjects).join(', ') : '-',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="View prospect">
                                    <NavLink to={`/prospects/${id}/details`}>
                                        <Svg icon="passShow" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            {meData?._type !== 'sales-agent' && (
                                <>
                                    <TableActions.Action>
                                        <Tooltip content="Edit prospect">
                                            <NavLink to={`/prospects/${id}/edit`}>
                                                <Svg icon="edit" />
                                            </NavLink>
                                        </Tooltip>
                                    </TableActions.Action>

                                    <TableActions.Action>
                                        <ProspectDelete iri={iri} key={`ProspectDelete ${iri}`} />
                                    </TableActions.Action>
                                </>
                            )}
                        </TableActions>
                    )
                });
            });
        }
        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/prospects/create" label="Create prospect" />
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchProspects(params)}
            />
        </LayoutManager>
    );
};

export default ProspectsListing;
