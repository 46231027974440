import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
import FaqRepeatableItem from 'erpcore/screens/Settings/Portal/components/PortalFaqRepeatableItem';
import ElementLoader from 'erpcore/components/ElementLoader';
import './PortalFAQForm.scss';
import AppStatuses from 'erpcore/components/AppStatuses';

const PortalFAQForm = ({
    form,
    onSaveAll,
    onRemoveItem,
    onSortEnd,
    newItemValues,
    onAddNewItem,
    loading
}) => {
    return (
        <Form>
            {!!loading && <ElementLoader overlay />}
            <Form.Row>
                <div className="faq-items">
                    <FieldArray
                        experimentalValidation
                        name="faqItems"
                        component={Repeater}
                        RepeatableItem={FaqRepeatableItem}
                        enableStickyControls
                        canAddMultiple
                        canSort
                        canSortNewItems={false}
                        canSave
                        saveButtonIntent="all"
                        onSaveAll={onSaveAll}
                        onRemoveItem={({ itemData }) => onRemoveItem(itemData)}
                        onSortEnd={onSortEnd}
                        onAddNewItem={onAddNewItem}
                        newItemValues={newItemValues}
                        smallButtons={false}
                        data={{
                            newItemValues,
                            formName: form
                        }}
                        addNewLabel="Add New Question"
                        uniqueIdentifier="iri"
                        unsavedChangesLabel="You have unsaved changes!"
                    />
                </div>
            </Form.Row>
            <AppStatuses mlaBlue portal />
        </Form>
    );
};

PortalFAQForm.defaultProps = {
    form: 'PortalFAQForm',
    onSaveAll: () => {},
    onRemoveItem: () => {},
    onSortEnd: () => {},
    onAddNewItem: () => {},
    newItemValues: null,
    loading: false
};

PortalFAQForm.propTypes = {
    form: PropTypes.string,
    onSaveAll: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onSortEnd: PropTypes.func,
    onAddNewItem: PropTypes.func,
    newItemValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    loading: PropTypes.bool
};

export default reduxForm({
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
    updateUnregisteredFields: true
})(PortalFAQForm);
