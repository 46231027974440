import axios from 'axios';
import restClient from 'erpcore/api/restClient';
import ElementLoader from 'erpcore/components/ElementLoader';
import PageContent from 'erpcore/components/Layout/PageContent';
import Pagination from 'erpcore/components/Listing/components/Pagination';
import PageLoader from 'erpcore/components/PageLoader';
import StatusCircle from 'erpcore/components/StatusCircle';
import Tooltip from 'erpcore/components/Tooltip';
import UserEditPageHeader from 'erpcore/screens/Users/components/UserEditPageHeader';
import UserEditTabs from 'erpcore/screens/Users/components/UserEditTabs';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { getSingleUserFetching, getUserData } from 'erpcore/screens/Users/Users.selectors';
import dto, { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './UserPortal.scss';

const dealsParams = {
    include: 'project,unit',
    'filters[meta.avesdo][exists]': 1,
    'filters[deleted][equals]': false,
    erp_side: 1,
    'filters[unit.listing_status][in]': ['Sold Firm']
};

const UserPortal = ({ hideFooter, draggable }) => {
    const history = useHistory();
    const params = useParams();
    const getAllQueryParams = new URLSearchParams(window.location.search);
    const usersPageByQueryParams = +getAllQueryParams.get('users[page]');
    const [fetchingDeals, setFetchingDeals] = useState(false);
    const [deals, setDeals] = useState({});
    const [token, setToken] = useState(false);
    const [currentPage, setCurrentPage] = useState(usersPageByQueryParams || 1);
    const dispatch = useDispatch();
    const userIri = useMemo(() => `/api/users/${params?.id}`, [params]);
    const userData = useSelector(state => dtoForm(getUserData(state, userIri))) || {};
    const fetching = useSelector(state => getSingleUserFetching(state));

    const fetchDeals = (savedToken = false) => {
        setFetchingDeals(true);
        if (!savedToken) {
            restClient.get(`/api/users/${params?.id}/impersonate`).then(response => {
                setToken(response?.data?.token);
                axios
                    .get('/api/deals', {
                        params: { ...dealsParams, page: currentPage },
                        baseURL: process.env.REACT_APP_REST_API,
                        timeout: process.env.REACT_APP_API_TIMEOUT,
                        headers: {
                            Authorization: `Bearer ${response?.data?.token}`,
                            Accept: ' application/vnd.api+json'
                        }
                    })
                    .then(responseDeals => {
                        setDeals(dto(responseDeals?.data));
                        setFetchingDeals(false);
                    })
                    .catch(() => {
                        setFetchingDeals(false);
                    });
            });
        } else {
            axios
                .get('/api/deals', {
                    params: { ...dealsParams, page: currentPage },
                    baseURL: process.env.REACT_APP_REST_API,
                    timeout: process.env.REACT_APP_API_TIMEOUT,
                    headers: {
                        Authorization: `Bearer ${savedToken}`,
                        Accept: ' application/vnd.api+json'
                    }
                })
                .then(responseDeals => {
                    setDeals(dto(responseDeals?.data));
                    setFetchingDeals(false);
                })
                .catch(() => {
                    setFetchingDeals(false);
                });
        }
    };

    const pageTitle = () => {
        const { first_name: firstName, last_name: lastName } = { ...userData };
        if (firstName || lastName) {
            return `Edit user - ${firstName} ${lastName}`;
        }

        return `Edit user`;
    };

    const fetchUserData = useCallback(() => {
        const queryParams = {
            include: 'organizations,country,state,city,image,projects,roles,prospect'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_FETCHING_SINGLE_USER,
                iri: userIri,
                params: queryParams
            });
        }).catch(error => ({ error }));
    }, [userIri]);

    /*
     * Effects
     */
    useEffect(() => {
        fetchUserData();
    }, []);

    useEffect(() => {
        fetchDeals();
    }, [history?.location?.search]);

    const isPurchaserCustomer = useMemo(() => {
        let isPC = false;
        if (userData?.prospect) isPC = true;

        return isPC;
    }, [userData]);

    const toggleVisibility = useCallback(
        data => {
            setFetchingDeals(true);
            restClient.patch(data?.iri, { hidden_on_portal: data?.value }).finally(() => {
                fetchDeals(token);
            });
        },
        [token]
    );

    const isTestPortalUser = useMemo(() => {
        const { is_test_portal_user: itpu } = { ...userData };
        return itpu;
    }, [userData]);

    const onChange = paginationData => {
        setCurrentPage(paginationData?.page);
        history.push({
            search: `users[page]=${paginationData?.page}`
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow user-portal" layoutType="merge">
            <UserEditPageHeader pageTitle={pageTitle()} />
            <UserEditTabs userData={userData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                {fetchingDeals && <ElementLoader overlay />}
                <>
                    <div className="user-portal__checks">
                        <div className="user-portal__check">
                            Purchaser/Customer{' '}
                            <span
                                className={`user-portal__checkbox${
                                    isPurchaserCustomer ? ' user-portal__checkbox--checked' : ''
                                }`}
                            />
                        </div>
                        <div className="user-portal__check">
                            Test Portal User
                            <span
                                className={`user-portal__checkbox${
                                    isTestPortalUser ? ' user-portal__checkbox--checked' : ''
                                }`}
                            />
                        </div>
                    </div>
                    <PageContent.Title>User deals:</PageContent.Title>
                    {fetching ? (
                        <ElementLoader />
                    ) : (
                        <>
                            {deals?.data?.length ? (
                                <>
                                    <div>
                                        {deals?.data?.map(deal => {
                                            const { strata_lot: strataLot, name: unitNumber } = {
                                                ...deal?.unit
                                            };
                                            const { hidden_on_portal: hiddenOnPortal } = {
                                                ...deal
                                            };

                                            const {
                                                test_portal_enabled: testPortalEnabled,
                                                portal_enabled: portalEnabled
                                            } = {
                                                ...deal?.project
                                            };

                                            const purchasers = deal?.meta?.avesdo?.purchasers?.map(
                                                user => {
                                                    return `${user?.firstName} ${user?.lastName}`;
                                                }
                                            );

                                            return (
                                                <div key={deal?.iri}>
                                                    <div className="user-portal__deal-row">
                                                        <span className="user-portal__deal-row-column">
                                                            {deal?.project?.name}
                                                        </span>
                                                        <span className="user-portal__deal-row-column">
                                                            <strong>Strata Lot: </strong>
                                                            {deal?.unit?.meta?.avesdo?.strataLot ||
                                                                strataLot ||
                                                                '-'}
                                                        </span>
                                                        <span className="user-portal__deal-row-column">
                                                            <strong>Unit Number: </strong>
                                                            {deal?.unit?.meta?.avesdo?.unitNumber ||
                                                                unitNumber ||
                                                                '-'}
                                                        </span>
                                                        <span className="user-portal__deal-row-column">
                                                            <strong>Unit Availabilty: </strong>
                                                            {deal?.unit?.meta?.avesdo
                                                                ?.availabilty || '-'}
                                                        </span>
                                                        {userData?._type === 'realtor' ? (
                                                            <>
                                                                <span className="user-portal__deal-row-column">
                                                                    <strong>Purchaser: </strong>
                                                                    {purchasers?.join(' | ')}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <span className="user-portal__deal-row-column user-portal__deal-row-column--status">
                                                            {portalEnabled && testPortalEnabled && (
                                                                <>
                                                                    <Tooltip content="Unit is active on the portal">
                                                                        <StatusCircle type="apple" />
                                                                    </Tooltip>
                                                                    <Tooltip content="Unit is active on the test portal">
                                                                        <StatusCircle type="sun" />
                                                                    </Tooltip>
                                                                </>
                                                            )}
                                                            {portalEnabled && !testPortalEnabled && (
                                                                <Tooltip content="Unit is active on the portal">
                                                                    <StatusCircle type="apple" />
                                                                </Tooltip>
                                                            )}
                                                            {!portalEnabled && testPortalEnabled && (
                                                                <Tooltip content="Unit is active on the test portal">
                                                                    <StatusCircle type="sun" />
                                                                </Tooltip>
                                                            )}
                                                            {!portalEnabled && !testPortalEnabled && (
                                                                <Tooltip content="Unit is inactive and only deal is synced">
                                                                    <StatusCircle type="tomato" />
                                                                </Tooltip>
                                                            )}
                                                        </span>
                                                        <Tooltip
                                                            content={
                                                                hiddenOnPortal
                                                                    ? 'Show this deal on portal'
                                                                    : 'Hide this deal from portal'
                                                            }
                                                        >
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    toggleVisibility({
                                                                        iri: deal?.iri,
                                                                        value: !hiddenOnPortal
                                                                    })
                                                                }
                                                                className={`user-portal__checkbox user-portal__checkbox--action${
                                                                    !hiddenOnPortal
                                                                        ? ' user-portal__checkbox--checked'
                                                                        : ''
                                                                }`}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            ) : (
                                'No Deals'
                            )}
                            <br />
                        </>
                    )}
                </>
                {hideFooter === false && draggable === false && (
                    <>
                        {deals?.meta?.totalItems >= 10 && (
                            <div className="listing__footer">
                                <Pagination
                                    meta={{
                                        ...deals?.meta,
                                        currentPage
                                    }}
                                    onChangePagination={onChange}
                                />
                            </div>
                        )}
                    </>
                )}
            </PageContent>
        </LayoutManager>
    );
};

UserPortal.defaultProps = {
    hideFooter: false,
    draggable: false
};

UserPortal.propTypes = {
    hideFooter: PropTypes.oneOfType(PropTypes.boolean),
    draggable: PropTypes.oneOfType(PropTypes.boolean)
};

export default UserPortal;
