import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { useSelector, useDispatch } from 'react-redux';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Button from 'erpcore/components/Button';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import ClientDelete from 'erpcore/screens/Clients/components/ClientDelete';
import Tooltip from 'erpcore/components/Tooltip';
import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const ClientsListing = () => {
    const reducerName = 'clients';
    const actionName = 'CLIENTS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Clients';

    const fetchClients = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                entity: actionName,
                type: listingActions.START_FETCHING_LISTING,
                name: reducerName,
                params,
                endpoint: 'api/clients?include=country,city,projects'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'city',
                label: 'City',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'City',
                            options: {
                                endpoint: `/api/cities`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'state',
                label: 'State',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'State',
                            options: {
                                endpoint: `/api/states`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'country',
                label: 'Country',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Country',
                            options: {
                                endpoint: `/api/countries`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'deleted',
                label: 'Archived',
                defaultOperator: 'equals',
                filterFields: {
                    defaultValue: '1'
                }
            }
        ];

        table.schema = [
            {
                title: 'Client name',
                field: 'name',
                sortable: 'name'
            },
            {
                title: 'City',
                field: 'city'
            },
            {
                title: 'Country',
                field: 'country'
            },
            {
                title: 'Projects',
                field: 'projects'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'ASC',
            sortable: 'name'
        };

        if (listing.data) {
            listing.data.map(row => {
                const { id, iri, name: clientName, city, country, projects } = {
                    ...row
                };

                const prepareProjects = [];
                if (Array.isArray(projects))
                    projects
                        .filter(project => !project?.deleted)
                        .forEach(project => prepareProjects.push(project?.name));

                return table.data.push({
                    id,
                    iri,
                    name: clientName,
                    city: city?.name,
                    country: country?.name,
                    projects: prepareProjects.join(', '),

                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Edit client">
                                    <NavLink to={`/clients/${id}/edit/general-info`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <ClientDelete
                                    clientName={clientName}
                                    iri={iri}
                                    key={`ClientDelete-${iri}`}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/clients/create" label="Create client" />
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchClients(params)}
            >
                {/* temp removed import/export */}
                {/*
                <Button size="small" variation="secondary" label="Export" />
                <Button size="small" variation="secondary" label="Import" />
                */}
            </Listing>
        </LayoutManager>
    );
};

export default ClientsListing;
