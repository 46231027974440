import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import LayoutManager from 'erpcore/utils/LayoutManager';
import OIAccessHelpForm from 'erpcore/screens/Settings/OIAccess/components/OIAccessHelpForm';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import { getOrganizationSetting } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import OIAccessTabs from 'erpcore/screens/Settings/OIAccess/components/OIAccessTabs';

const OIAccessHelp = () => {
    const title = 'OIAccess - Help section';
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const settingName = 'oiAccessHelp';
    const settings = useSelector(state => getOrganizationSetting(state, settingName)) || [];

    const settingData = settings?.data?.length ? settings?.data[0] : {};

    const { help } = {
        ...settingData?.settings
    };

    const initialValues = {
        help
    };

    const fetchSettings = () => {
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName
            });
        })
            .then(() => setFetching(false))
            .catch(error => ({ error }));
    };

    const onSubmit = formData => {
        new Promise((resolve, reject) =>
            dispatch({
                type: organizationActions.START_UPDATE_ORGANIZATION_SETTING,
                settingName,
                portal: true,
                formData,
                iri: settingData?.iri,
                promise: { resolve, reject }
            })
        )
            .then(() => fetchSettings())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchSettings();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <OIAccessTabs />
            <PageContent>
                {fetching && <ElementLoader overlay />}
                <OIAccessHelpForm
                    onSubmit={onSubmit}
                    form="OIAccessHelpForm"
                    submitLabel="Save"
                    initialValues={initialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default OIAccessHelp;
