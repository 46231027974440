import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import styles from './UserListingName.module.scss';

const UserListingName = ({ name, office, imageUrl }) => {
    const cssStyle = imageUrl ? { backgroundImage: `url(${imageUrl})` } : null;
    return (
        <div className={`${styles['user-listing-name']}`}>
            <div className={`${styles['user-listing-name__image']}`} style={cssStyle}>
                {!imageUrl && (
                    <Svg icon="profile" className={`${styles['user-listing-name__image-svg']}`} />
                )}
            </div>
            <div className={`${styles['user-listing-name__details']}`}>
                <p className={`${styles['user-listing-name__title']}`}>{name}</p>
                {!!office?.name && (
                    <p className={`${styles['user-listing-name__subtitle']}`}>
                        Office ({office?.name})
                    </p>
                )}
            </div>
        </div>
    );
};

UserListingName.defaultProps = {
    name: '',
    office: {},
    imageUrl: null
};

UserListingName.propTypes = {
    name: PropTypes.string,
    office: PropTypes.oneOfType([PropTypes.object]),
    imageUrl: PropTypes.string
};

export default UserListingName;
