import React, { useEffect, useState, useCallback, useMemo } from 'react';
import Jsona from 'jsona';
import PortalFAQForm from 'erpcore/screens/Settings/Portal/components/PortalFAQForm';
import restClient from 'erpcore/api/restClient';
import sortBy from 'lodash/sortBy';
import toInteger from 'lodash/toInteger';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';
import { getIdFromIri } from 'erpcore/utils/dto';
import { useDispatch } from 'react-redux';
import { initialize, change } from 'redux-form';
import PropTypes from 'prop-types';

const dataFormatter = new Jsona();

const allowedProperties = ['answer', 'question', 'order_weight'];

/**
 * Used by:
 *  1. Projects -> FAQ
 *  2. Settings -> Portal Settings -> FAQ
 */
const FaqWrapper = ({ formName, projectIri, fetching }) => {
    const dispatch = useDispatch();
    const [saveIsInProgress, setSaveIsInProgress] = useState(false);
    const [fetchingFaq, setFetchingFaq] = useState([]);
    const [faq, setFaq] = useState([]);
    const faqList = useMemo(() => {
        return faq?.data?.length
            ? faq.data.map(item => {
                  return { ...item, iri: item?.id };
              })
            : [];
    }, [faq?.data]);

    const loading = fetching || fetchingFaq || saveIsInProgress;

    const orderByWeightAndId = collection => {
        if (!collection?.length) {
            return [];
        }

        return sortBy(collection, [
            'order_weight',
            item => {
                return item.iri ? toInteger(getIdFromIri(item.iri)) : 0;
            }
        ]);
    };

    const initialValues = useMemo(() => {
        return {
            faqItems: orderByWeightAndId(faqList).reduce((accumulator, item) => {
                if (!item?.deleted) {
                    accumulator.push({
                        iri: item?.iri,
                        // eslint-disable-next-line camelcase
                        order_weight: item?.order_weight,
                        answer: item?.answer,
                        question: item?.question
                    });
                }
                return accumulator;
            }, [])
        };
    }, [faqList]);

    const fetchFaq = (params = {}) => {
        setFetchingFaq(true);

        params.pagination = false;

        if (projectIri) {
            params['filters[project][equals]'] = projectIri;
        } else {
            params['filters[project][not_exists]'] = 1;
        }

        restClient.get('/api/faqs', { params }).then(response => {
            setFetchingFaq(false);
            setFaq({ ...response?.data, data: dataFormatter.deserialize(response?.data) });
        });
    };

    useEffect(() => {
        fetchFaq();
    }, []);

    const manuallyReinitializeForm = useCallback(values => {
        dispatch(
            initialize(formName, values, false, {
                keepDirty: false,
                updateUnregisteredFields: true
            })
        );
    }, []);

    useEffect(() => {
        manuallyReinitializeForm(initialValues);
    }, [initialValues]);

    const onRemoveItem = data => {
        if (data?.iri) {
            restClient.delete(data.iri).then(() => {
                fetchFaq();
            });
        }
    };

    const changeFormField = (field, value) => {
        dispatch(change(formName, field, value));
    };

    const onSortEnd = ({ repeaterFieldName, allData }) => {
        allData.forEach((item, index) => {
            const orderFieldName = `${repeaterFieldName}[${index}]order_weight`;
            changeFormField(orderFieldName, index);
        });
    };

    const onAddNewItem = () => {
        setTimeout(() => {
            try {
                window.scrollTo({
                    top: document.body.scrollHeight,
                    behavior: 'smooth'
                });
            } catch (e) {
                try {
                    window.scroll(0, document.body.scrollHeight);
                } catch (e2) {
                    //
                }
            }
        }, 100);
    };

    function newItemValues({ fields = null }) {
        if (fields) {
            const fieldsLength = fields.length || 0;
            const data = {
                order_weight: fieldsLength
            };

            if (fieldsLength) {
                // eslint-disable-next-line
                const lastItemOrderWeight = fields.get(fieldsLength - 1)?.order_weight;
                if (lastItemOrderWeight) {
                    data.order_weight = lastItemOrderWeight + 1;
                }
            }
            return data;
        }
        return null;
    }

    const getInitialFaqItem = ({ faqItem, faqItemIri }) => {
        const faqItemIdentifier = faqItem?.iri || faqItemIri;
        return initialValues.faqItems.find(item => item?.iri === faqItemIdentifier) || null;
    };

    const getFinalFaqItemDiff = newData => {
        const allowedNewData = pick(newData, allowedProperties);
        const initialData = getInitialFaqItem({ faqItem: newData });

        const diff = pickBy(allowedNewData, (value, key) => {
            return initialData[key] !== value;
        });

        return size(diff) ? diff : null;
    };

    const updateFaqItem = (iri, formData) => {
        return restClient.patch(iri, formData);
    };

    const batchUpdateFaqItems = (oldItems, oldItemsDiff) => {
        return new Promise(resolve => {
            const promisesMap = [];
            if (Object.entries(oldItemsDiff).length) {
                Object.entries(oldItemsDiff).forEach(([diffKey]) => {
                    const faqItem = oldItems[diffKey];
                    if (faqItem) {
                        const faqItemIri = faqItem.iri;
                        const finalFaqItemDiff = getFinalFaqItemDiff(faqItem);
                        if (finalFaqItemDiff) {
                            promisesMap.push({
                                promise: updateFaqItem,
                                parameters: [faqItemIri, finalFaqItemDiff]
                            });
                        }
                    }
                });
                Promise.allSettled(
                    promisesMap.map(item => item.promise(...item.parameters))
                ).finally(() => {
                    resolve();
                });
            } else {
                resolve();
            }
        });
    };

    const createFaqItem = formData => {
        return restClient.post('/api/faqs', {
            ...formData,
            ...(projectIri ? { project: projectIri } : null)
        });
    };

    const batchCreateFaqItems = newItems => {
        return new Promise(resolve => {
            Promise.allSettled(newItems.map(item => createFaqItem(item))).finally(() => {
                resolve();
            });
        });
    };

    const onSaveAll = data => {
        const { oldItems, oldItemsDiff, newItems } = data;
        setSaveIsInProgress(true);

        const promisesMap = [];

        // update faq items
        if (Object.keys(oldItemsDiff)?.length) {
            promisesMap.push({
                promise: batchUpdateFaqItems,
                parameters: [oldItems, oldItemsDiff]
            });
        }

        // create new faq items
        if (newItems?.length) {
            promisesMap.push({
                promise: batchCreateFaqItems,
                parameters: [newItems]
            });
        }

        return Promise.allSettled([
            ...promisesMap.map(item => item.promise(...item.parameters))
        ]).finally(() => {
            fetchFaq();
            setSaveIsInProgress(false);
        });
    };

    return (
        <PortalFAQForm
            form={formName}
            initialValues={initialValues}
            onRemoveItem={onRemoveItem}
            onSortEnd={onSortEnd}
            onAddNewItem={onAddNewItem}
            newItemValues={newItemValues}
            onSaveAll={onSaveAll}
            loading={loading}
        />
    );
};

FaqWrapper.defaultProps = {
    projectIri: null,
    fetching: false
};

FaqWrapper.propTypes = {
    formName: PropTypes.string.isRequired,
    projectIri: PropTypes.string,
    fetching: PropTypes.bool
};

export default FaqWrapper;
