import React from 'react';
import Form, { Text } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const HubSpotIntegrationClientKeysForm = ({
    handleSubmit,
    onSubmit,
    invalid,
    submitting,
    disabled,
    hideConnect
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="client_id"
                    fieldProps={{ label: 'Client id' }}
                    fieldAttr={{ id: 'client_id', required: true, disabled }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="client_secret"
                    fieldProps={{ label: 'Client secret' }}
                    fieldAttr={{ id: 'client_secret', required: true, disabled }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {!hideConnect && (
                <Button loading={submitting} submit disabled={invalid} label="Connect" />
            )}
        </Form>
    );
};

HubSpotIntegrationClientKeysForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    submitting: false,
    disabled: false,
    hideConnect: false
};

HubSpotIntegrationClientKeysForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    disabled: PropTypes.bool,
    hideConnect: PropTypes.bool
};

export default reduxForm({
    form: 'HubSpotIntegrationClientKeysForm',
    enableReinitialize: true
})(HubSpotIntegrationClientKeysForm);
