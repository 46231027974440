import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';

import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import ProspectForm from 'erpcore/screens/Prospects/components/ProspectForm';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

const ProspectCreateActionModal = ({ formName, member }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: prospectActions.START_CREATE_PROSPECT,
                promise: { resolve, reject },
                formData
            });
        })
            .then(response => {
                handleModal();
                return dispatch(change(formName, `${member}.iri`, response?.data?.id));
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Fragment>
            <Button
                onClick={() => handleModal()}
                variation="action"
                iconName="plus"
                label="Create"
                labelOnlyAria
            />
            <Modal
                variation="medium"
                opened={opened}
                onClose={() => handleModal()}
                title="Add new prospect"
            >
                <ProspectForm
                    form="ProspectCreateForm"
                    onSubmit={onSubmit}
                    submitLabel="Create"
                    onCancel={() => handleModal()}
                />
            </Modal>
        </Fragment>
    );
};

ProspectCreateActionModal.defaultProps = {
    formName: null,
    member: null
};

ProspectCreateActionModal.propTypes = {
    formName: PropTypes.string,
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ProspectCreateActionModal;
