import { all } from 'redux-saga/effects';
import { authManagerSaga } from 'erpcore/utils/AuthManager/AuthManager.saga';
import { forgotPasswordSaga } from 'erpcore/screens/ForgotPassword/ForgotPassword.saga';
import imageManagerSaga from 'erpcore/components/ImageManager/ImageManager.saga';
import { myAccountSaga } from 'erpcore/screens/MyAccount/MyAccount.saga';
import activityLogSaga from 'erpcore/components/ActivityLog/ActivityLog.saga';
import { listingSaga } from 'erpcore/components/Listing/Listing.saga';
import { commentsSaga } from 'erpcore/components/Comments/Comments.saga';
import clientsSaga from 'erpcore/screens/Clients/Clients.saga';
import { contactsSaga } from 'erpcore/screens/Contacts/Contacts.saga';
import { prospectsSaga } from 'erpcore/screens/Prospects/Prospects.saga';
import {
    optionsSaga,
    organizationSaga,
    permissionsSaga,
    rolesSaga,
    sessionSettingsSaga,
    vonageVideoAPISaga,
    unitTypesSaga,
    neighbourhoodLocationSectionsSaga,
    hubSpotIntegrationSaga,
    productTypesSaga,
    hubspotLogSaga,
    blogsSaga,
    blogCategoriesSaga
} from 'erpcore/screens/Settings/Settings.saga';
import { employmentRecordsSaga } from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.saga';
import projectsSaga from 'erpcore/screens/Projects/Projects.saga';
import { sessionReportSaga } from 'erpcore/screens/Reports/Reports.saga';
import { sessionsSaga } from 'erpcore/screens/Sessions/Sessions.saga';
import { usersSaga } from 'erpcore/screens/Users/Users.saga';
import organizationSettingsSaga from 'erpcore/utils/OrganizationSettings/OrganizationSettings.saga';
import { dashboardSaga } from 'erpcore/screens/Dashboard/Dashboard.saga';
import { oiAccessDashboardSaga } from 'erpcore/screens/OiAccessDashboards/OiAccessDashboard.saga';
import dealsSaga from 'erpcore/screens/Deals/Deals.saga';
import portalSaga from 'erpcore/screens/Settings/Portal/Portal.saga';

export default function* rootSaga() {
    yield all([...authManagerSaga]);
    yield all([...forgotPasswordSaga]);
    yield all([...imageManagerSaga]);
    yield all([...myAccountSaga]);
    yield all([...activityLogSaga]);
    yield all([...listingSaga]);
    yield all([...commentsSaga]);
    yield all([...clientsSaga]);
    // yield all([...companyContactsSaga]);
    yield all([...contactsSaga]);
    yield all([...prospectsSaga]);
    yield all([...employmentRecordsSaga]);
    yield all([...optionsSaga]);
    yield all([...organizationSaga]);
    yield all([...organizationSettingsSaga]);
    yield all([...permissionsSaga]);
    yield all([...projectsSaga]);
    yield all([...rolesSaga]);
    yield all([...sessionsSaga]);
    yield all([...sessionSettingsSaga]);
    yield all([...sessionReportSaga]);
    yield all([...usersSaga]);
    yield all([...dashboardSaga]);
    yield all([...oiAccessDashboardSaga]);
    yield all([...vonageVideoAPISaga]);
    yield all([...unitTypesSaga]);
    yield all([...neighbourhoodLocationSectionsSaga]);
    yield all([...dealsSaga]);
    yield all([...portalSaga]);
    yield all([...hubSpotIntegrationSaga]);
    yield all([...productTypesSaga]);
    yield all([...blogsSaga]);
    yield all([...blogCategoriesSaga]);
    yield all([...hubspotLogSaga]);
}
