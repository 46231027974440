import React from 'react';
import Svg from 'erpcore/components/Svg';
import Button from 'erpcore/components/Button';
import LayoutManager from 'erpcore/utils/LayoutManager';

import './Error404.scss';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const Error404 = () => {
    const title = 'Error 404';

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <div className="error-404">
                <div className="error-404__wrapper">
                    <Svg icon="notFound" className="error-404__icon" />
                    <div className="error-404__content">
                        <h1 className="error-404__title">Error 404 - PAGE NOT FOUND</h1>
                        <Button href="/dashboard" variation="secondary" label="Back to Dashboard" />
                    </div>
                </div>
            </div>
        </LayoutManager>
    );
};

export default Error404;
