import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import IntegrationForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/IntegrationForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const ProjectEditMultiEffectIntegration = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);
    const { hubspot_name: hubspotName, syncable, avesdo_id: avesdoID, hubspot_id: hubspotID } = {
        ...projectData
    };

    const initialValues = {
        avesdo_id: avesdoID,
        hubspot_id: hubspotID,
        hubspot_name: hubspotName,
        syncable
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    const syncHubSpot = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_PROJECT_SYNC_HUBSPOT,
                iri: projectIri
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    const syncAvesdo = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_PROJECT_SYNC_AVESDO,
                iri: projectIri
            })
        );
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data
        const formDataDiff = diff(initialValues, formData);
        // Handle data
        const { documents } = { ...formDataDiff };
        if (documents) {
            formDataDiff.documents = cleanRepeaterOutput(formData.documents, ['customIdentifier']);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <IntegrationForm
                    onSubmit={onSubmit}
                    form={`IntegrationForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                    syncHubspot={syncHubSpot}
                    syncAvesdo={syncAvesdo}
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMultiEffectIntegration.defaultProps = {
    match: {}
};

ProjectEditMultiEffectIntegration.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProjectEditMultiEffectIntegration);
