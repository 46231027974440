import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';

import Form, { Media, Repeater, Text } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const BannersRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.url`}
                    fieldProps={{
                        label: 'Url',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.url`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <FieldArray
                    name={`${member}.image`}
                    id={`${member}.image`}
                    component={Media}
                    useLightbox
                    maxImageCount={1}
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

BannersRepeatableItem.defaultProps = {
    member: null
};

BannersRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const BlueSettingsForm = ({ handleSubmit, onSubmit, onCreate, onUpdate, onRemove, onFinish }) => {
    /**
     * Promise.all resolver
     * @param oldItems {Array}
     * @param oldItemDiff {Object}
     * @param newItems {Array}
     * @return {Promise}
     */
    const addUpdateItems = (oldItems, oldItemsDiff, newItems) => {
        const promises = [];

        if (Object.keys(oldItemsDiff)?.length > 0) {
            oldItems.map((item, index) => {
                item.position = index;
                return promises.push(onUpdate(item));
            });
        }

        if (newItems?.length > 0) {
            newItems.map(item => promises.push(onCreate(item)));
        }
        return Promise.all([promises]);
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <FieldArray
                        name="banners"
                        component={Repeater}
                        RepeatableItem={BannersRepeatableItem}
                        canSort
                        canSortNewItems
                        saveButtonIntent="all"
                        canSave
                        data={{ onFinish }}
                        onSaveAll={({ oldItems, oldItemsDiff, newItems }) =>
                            addUpdateItems(oldItems, oldItemsDiff, newItems).finally(() => {
                                onFinish();
                            })
                        }
                        onRemoveItem={({ itemData }) => onRemove(itemData)}
                        uniqueIdentifier="iri"
                        addNewLabel="Add banner"
                    />
                </Form.Row>
            </Form>
        </>
    );
};

BlueSettingsForm.defaultProps = {
    onSubmit: () => {},
    onCreate: () => {},
    onRemove: () => {},
    onFinish: () => {},
    onUpdate: () => {}
};

BlueSettingsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    onFinish: PropTypes.func,
    onUpdate: PropTypes.func
};

export default reduxForm({
    form: 'BlueSettingsForm',
    enableReinitialize: true
})(BlueSettingsForm);
