import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions as productTypesActions } from 'erpcore/screens/Settings/ProductTypes/ProductTypes.reducer';
import Modal from 'erpcore/components/Modal';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import ProductTypesEditCreateForm from 'erpcore/screens/Settings/ProductTypes/components/ProductTypesEditCreateForm';

const ProductTypesEdit = ({ data }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);
    const { name } = { ...data };

    const initialValues = { name };

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: productTypesActions.START_UPDATE_PRODUCT_TYPE,
                promise: { resolve, reject },
                formData,
                iri: data?.iri
            });
        }).finally(() => {
            handleModal();
        });
    };

    return (
        <Fragment>
            <Tooltip content="Edit Product Type">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal
                variation="medium"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Edit product Type"
            >
                <ProductTypesEditCreateForm
                    onSubmit={onSubmit}
                    onCancel={() => handleModal()}
                    form={`ProductTypesEditForm-${data?.id}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </Modal>
        </Fragment>
    );
};

ProductTypesEdit.defaultProps = {
    data: {}
};

ProductTypesEdit.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object])
};

export default ProductTypesEdit;
