import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { getUserCurrentOrganization } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as organizationActions } from './Organization.reducer';

const organizationParams = {
    include: 'currency'
};

/**
 * Fetch Organization
 * @param  {Object} promise
 * @param {string} iri Organization iri
 */
export function* fetchOrganization({ promise, iri }) {
    try {
        const fetchOrganizationAPI = yield restClient.get(iri, { params: organizationParams });
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: dto(fetchOrganizationAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Mine Organization
 * @param  {Object} promise
 */
export function* fetchMineOrganization({ promise }) {
    const currentUserOrganization = yield select(getUserCurrentOrganization);
    const { iri } = yield { ...currentUserOrganization };
    try {
        const fetchOrganizationAPI = yield restClient.get(iri, { params: organizationParams });
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: dto(fetchOrganizationAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCH_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Organization single data
 * @param  {Object} promise
 * @param {Object} formData
 * @param {string} iri Organization iri
 * @return {Object} Response from API
 */
export function* updateSingleOrganization({ promise, formData, iri }) {
    try {
        const updateSingleOrganizationAPI = yield restClient.put(iri, formData, {
            params: organizationParams
        });
        const dtoUpdateSingleOrganizationAPI = yield dto(updateSingleOrganizationAPI?.data);
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleOrganizationAPI?.data
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: dtoUpdateSingleOrganizationAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update mine Organization data
 * @param  {Object} promise
 * @param {Object} formData
 * @return {Object} Response from API
 */
export function* updateMineOrganization({ promise, formData }) {
    const currentUserOrganization = yield select(getUserCurrentOrganization);
    const { iri } = yield { ...currentUserOrganization };
    try {
        const updateSingleOrganizationAPI = yield restClient.put(iri, formData, {
            params: organizationParams
        });
        const dtoUpdateSingleOrganizationAPI = yield dto(updateSingleOrganizationAPI?.data);
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleOrganizationAPI?.data
        });
        yield put({
            type: organizationActions.STORE_SINGLE_ORGANIZATION_DATA,
            iri,
            response: dtoUpdateSingleOrganizationAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.UPDATE_SINGLE_ORGANIZATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* fetchOrganizationSettingsPublic({ promise }) {
    try {
        const fetchOrganizationSettingsAPI = yield restClient.get('/api/organization-settings', {
            params: { 'filters[name][equals]': enviromentVariables.REACT_APP_SLUG }
        });
        yield put({
            type: organizationActions.FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS_PUBLIC,
            response: dto(fetchOrganizationSettingsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCHING_FAILED_ORGANIZATION_SETTINGS_PUBLIC
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* fetchOrganizationSettings({ promise }) {
    try {
        const fetchOrganizationSettingsAPI = yield restClient.get('/api/organization-settings', {
            params: { 'filters[name][equals]': enviromentVariables.REACT_APP_SLUG }
        });
        yield put({
            type: organizationActions.FETCHING_SUCCESSFUL_ORGANIZATION_SETTINGS,
            response: dto(fetchOrganizationSettingsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCHING_FAILED_ORGANIZATION_SETTINGS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* updateOrganizationSettings({ promise, iri, formData }) {
    try {
        let OrganizationSettingsAPI = null;
        //  If exists patch it if not post
        if (iri) {
            OrganizationSettingsAPI = yield restClient.patch(iri, {
                name: enviromentVariables.REACT_APP_SLUG,
                portal: true,
                settings: { ...formData }
            });
        } else {
            OrganizationSettingsAPI = yield restClient.post('/api/organization-settings', {
                name: enviromentVariables.REACT_APP_SLUG,
                settings: { ...formData }
            });
        }
        yield put({
            type: organizationActions.UPDATE_SUCCESSFUL_ORGANIZATION_SETTINGS,
            response: dto(OrganizationSettingsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.UPDATE_FAILED_ORGANIZATION_SETTINGS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

//  abstract organization settings
export function* fetchOrganizationSetting({ promise, params, settingName }) {
    try {
        const request = yield restClient.get('api/organization-settings', {
            params: {
                'filters[name][equals]': settingName,
                ...params
            }
        });
        yield put({
            type: organizationActions.FETCH_ORGANIZATION_SETTING_SUCCESSFUL,
            response: dto(request?.data),
            settingName
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: organizationActions.FETCH_ORGANIZATION_SETTING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* updateOrganizationSetting({
    promise,
    formData,
    iri = null,
    settingName,
    portal = false,
    updateRedux = true
}) {
    try {
        let request = null;
        //  If exists patch it if not post
        if (iri) {
            request = yield restClient.patch(iri, {
                name: settingName,
                portal,
                settings: { ...formData }
            });
        } else {
            request = yield restClient.post('/api/organization-settings', {
                name: settingName,
                portal,
                settings: { ...formData }
            });
        }
        yield put({
            type: organizationActions.UPDATE_ORGANIZATION_SETTING_SUCCESSFUL,
            response: updateRedux ? dto(request?.data) : null,
            settingName
        });
        yield call(promise.resolve, request?.data);
    } catch (error) {
        yield put({
            type: organizationActions.UPDATE_ORGANIZATION_SETTING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* createOrganizationSetting({ promise, formData, settingName, portal = false }) {
    try {
        const request = yield restClient.post('/api/organization-settings', {
            name: settingName,
            portal,
            settings: { ...formData }
        });
        yield put({
            type: organizationActions.CREATE_ORGANIZATION_SETTING_SUCCESSFUL
        });
        yield call(promise.resolve, request?.data);
    } catch (error) {
        yield put({
            type: organizationActions.CREATE_ORGANIZATION_SETTING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* deleteOrganizationSetting({ promise, iri, settingName }) {
    try {
        const request = yield restClient.delete(iri, {
            name: settingName
        });
        yield put({
            type: organizationActions.DELETE_ORGANIZATION_SETTING_SUCCESSFUL
        });
        yield call(promise.resolve, request?.data);
    } catch (error) {
        yield put({
            type: organizationActions.DELETE_ORGANIZATION_SETTING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(organizationActions.START_FETCHING_SINGLE_ORGANIZATION, fetchOrganization),
    takeLatest(organizationActions.START_FETCHING_MINE_ORGANIZATION, fetchMineOrganization),
    takeLatest(organizationActions.START_UPDATE_SINGLE_ORGANIZATION, updateSingleOrganization),
    takeLatest(organizationActions.START_UPDATE_MINE_ORGANIZATION, updateMineOrganization),
    takeLatest(organizationActions.START_FETCHING_ORGANIZATION_SETTINGS, fetchOrganizationSettings),
    takeLatest(organizationActions.START_UPDATE_ORGANIZATION_SETTINGS, updateOrganizationSettings),

    //  Abstract actions
    takeEvery(organizationActions.START_FETCH_ORGANIZATION_SETTING, fetchOrganizationSetting),
    takeEvery(organizationActions.START_UPDATE_ORGANIZATION_SETTING, updateOrganizationSetting),
    takeEvery(organizationActions.START_CREATE_ORGANIZATION_SETTING, createOrganizationSetting),
    takeEvery(organizationActions.START_DELETE_ORGANIZATION_SETTING, deleteOrganizationSetting)
];
