import React from 'react';
import Form, { Switch } from 'erpcore/components/Form';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';

const UnitsSettingsFloorPlansToggle = ({ onToggleChanged }) => {
    return (
        <Form>
            <Field
                name="use_unit_type_floorplans"
                fieldProps={{
                    label: 'Enable floor plans to be generated automatically from Unit settings',
                    off: {
                        id: 'switch-off-false',
                        label: 'Off',
                        value: false
                    },
                    on: {
                        id: 'switch-on-true',
                        label: 'On',
                        value: true
                    }
                }}
                onChange={value => onToggleChanged({ use_unit_type_floorplans: value })}
                fieldAttr={{ id: 'use_unit_type_floorplans' }}
                component={Switch}
            />
        </Form>
    );
};

UnitsSettingsFloorPlansToggle.defaultProps = {
    onToggleChanged: () => {}
};

UnitsSettingsFloorPlansToggle.propTypes = {
    onToggleChanged: PropTypes.func
};

export default reduxForm({ form: 'UnitsSettingsFloorPlansToggle', enableReinitialize: true })(
    UnitsSettingsFloorPlansToggle
);
