import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as vonageVideoAPIActions } from 'erpcore/screens/Settings/VonageVideoAPI/VonageVideoAPI.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';

/**
 * Fetch Vonage Video API Key
 * @param  {Object} promise
 */
export function* fetchVonageVideoApiKey({ promise, params }) {
    try {
        const fetchVonageVideoApiKeyAPI = yield restClient.get('api/settings', { params });
        yield put({
            type: vonageVideoAPIActions.FETCH_VONAGE_VIDEO_API_KEY_SUCCESSFUL,
            response: dto(fetchVonageVideoApiKeyAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vonageVideoAPIActions.FETCH_VONAGE_VIDEO_API_KEY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *  Fetch Vonage Video Secret Key
 * @param  {Object} promise
 */
export function* fetchVonageVideoSecretKey({ promise, params }) {
    try {
        const fetchVonageVideoSecretKeyAPI = yield restClient.get('api/settings', { params });
        yield put({
            type: vonageVideoAPIActions.FETCH_VONAGE_VIDEO_SECRET_KEY_SUCCESSFUL,
            response: dto(fetchVonageVideoSecretKeyAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: vonageVideoAPIActions.FETCH_VONAGE_VIDEO_SECRET_KEY_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Vonage Settings
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createVonageVideoKey({ promise, formData }) {
    try {
        const createVonageVideoKeyAPI = yield restClient.post('/api/settings', formData);
        yield put({
            type: vonageVideoAPIActions.CREATE_VONAGE_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createVonageVideoKeyAPI?.data
        });

        yield call(promise.resolve, createVonageVideoKeyAPI?.data);
    } catch (error) {
        yield put({
            type: vonageVideoAPIActions.CREATE_VONAGE_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Vonage Settings
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateVonageVideoKey({ promise, formData, iri }) {
    try {
        const createVonageVideoKeyAPI = yield restClient.patch(iri, formData);
        yield put({
            type: vonageVideoAPIActions.UPDATE_VONAGE_SETTINGS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createVonageVideoKeyAPI?.data
        });

        yield call(promise.resolve, createVonageVideoKeyAPI?.data);
    } catch (error) {
        yield put({
            type: vonageVideoAPIActions.UPDATE_VONAGE_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const vonageVideoAPISaga = [
    takeLatest(vonageVideoAPIActions.START_CREATE_VONAGE_SETTINGS, createVonageVideoKey),
    takeLatest(vonageVideoAPIActions.START_FETCHING_VONAGE_VIDEO_API_KEY, fetchVonageVideoApiKey),
    takeLatest(
        vonageVideoAPIActions.START_FETCHING_VONAGE_VIDEO_SECRET_KEY,
        fetchVonageVideoSecretKey
    ),
    takeLatest(vonageVideoAPIActions.START_UPDATE_VONAGE_SETTINGS, updateVonageVideoKey)
];

export default vonageVideoAPISaga;
