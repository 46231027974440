import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as blogsActions } from 'erpcore/screens/Settings/Blue/screens/Blog/Blog.reducer';

const BlogsDelete = ({ handleSubmit, submitting, iri }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: blogsActions.START_DELETE_BLOG,
                promise: { resolve, reject },
                iri
            });
        }).finally(() => {
            handleModal();
        });
    };

    return (
        <Fragment>
            <Tooltip content="Delete FAQ Post">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Delete FAQ Post"
                subtitle="Are you sure you want to delete this FAQ Post?"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

BlogsDelete.defaultProps = {
    submitting: false,
    iri: null
};

BlogsDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    iri: PropTypes.string
};

export default reduxForm({
    form: 'BlogsDeleteForm',
    enableReinitialize: true
})(BlogsDelete);
