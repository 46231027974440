import React, { useState, useEffect } from 'react';
import Input from 'erpcore/components/Form/components/Input';
import PropTypes from 'prop-types';
import './TagsInput.scss';
import Svg from 'erpcore/components/Svg';

const TagsInput = ({ fieldProps, fieldAttr, field, input, meta }) => {
    const [newTagValue, setNewTagValue] = useState(null);
    const [tags, setTags] = useState([]);
    const { value = [] } = { ...input };
    const { enableDuplicates } = { ...fieldProps };

    useEffect(() => {
        if (value && value?.length > 0) {
            setTags(input.value);
        }

        if (tags?.length > 0 && value?.length === 0) {
            setTags([]);
        }
    }, [value]);

    const handleDelete = i => {
        const newTags = Array.from(tags);
        newTags.splice(i, 1);
        setTags(newTags);

        input.onChange(newTags);
    };

    const handleAddition = e => {
        if ((e.keyCode === 9 || e.keyCode === 13) && e.target.value !== null) {
            e.preventDefault();
            const trimmedTag = newTagValue.trim();
            const isDuplicate =
                tags?.filter(tag => tag?.id === trimmedTag)?.length > 0 ||
                (Array.isArray(value) && value?.filter(tag => tag?.id === trimmedTag)?.length > 0);

            if (tags.indexOf(trimmedTag) === -1) {
                if (!isDuplicate || enableDuplicates) {
                    setTags([...tags, { id: trimmedTag, name: trimmedTag }]);
                    setNewTagValue(null);
                }
            }

            e.target.value = '';
            if (!isDuplicate || enableDuplicates) {
                input.onChange([...tags, { id: trimmedTag, name: trimmedTag }]);
            }
        }
    };

    const onChange = e => {
        setNewTagValue(e?.target?.value);
    };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="tags"
        >
            <div className="input__field">
                <div className="tags__input">
                    {tags?.map((tag, index) => (
                        <span key={tag?.id} className="tags__input-title">
                            {tag?.name}
                            <button
                                className="tags__input-remove"
                                type="button"
                                onClick={() => handleDelete(index)}
                            >
                                <Svg icon="close" />
                            </button>
                        </span>
                    ))}
                    <input
                        id={input.name}
                        {...fieldProps}
                        aria-label={`${input.name}`}
                        type="text"
                        onChange={onChange}
                        onKeyDown={handleAddition}
                        onFocus={() => input.onFocus()}
                        className="tags__input-add"
                        placeholder={fieldProps?.placeholder || 'Add new tag'}
                    />
                </div>
            </div>
        </Input>
    );
};

TagsInput.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

TagsInput.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default TagsInput;
