import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';

import Button from 'erpcore/components/Button';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as rolesActions } from 'erpcore/screens/Settings/Roles/Roles.reducer';

const RemoveRoleFromUser = ({ handleSubmit, submitting, userData, roleIri, callback }) => {
    const dispatch = useDispatch();
    const { iri: userIri, roles } = { ...userData };

    const onSubmit = () => {
        // Remove role from roles
        const index = roles?.indexOf(roleIri);
        if (index > -1) {
            roles.splice(index, 1);
        }
        const formData = {
            roles
        };

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: rolesActions.START_REMOVE_ROLE_FROM_USER,
                userIri,
                formData
            })
        )
            .then(() => {
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Tooltip content="Remove role from user">
                <Button
                    submit
                    variation="table-action"
                    iconName="minusCircle"
                    loading={submitting}
                    label=""
                />
            </Tooltip>
        </Form>
    );
};

RemoveRoleFromUser.defaultProps = {
    submitting: false,
    userData: null,
    roleIri: null,
    callback: () => {}
};

RemoveRoleFromUser.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    userData: PropTypes.oneOfType([PropTypes.object]),
    roleIri: PropTypes.string,
    callback: PropTypes.func
};

export default reduxForm({
    enableReinitialize: false,
    form: 'RemoveRoleFromUserForm'
})(RemoveRoleFromUser);
