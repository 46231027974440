import React, { useEffect, useState } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import PageLoader from 'erpcore/components/PageLoader';
import PropTypes from 'prop-types';
import ToolsAndTipsForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/ToolsAndTipsForm';
import { diff } from 'deep-object-diff';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

const ProjectEditMLALiveTools = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);
    const [projectDataIteration, setProjectDataIteration] = useState(0);

    const { tools_and_tips: projectToolsAndTips = [] } = projectData;

    const initialValues = {
        tools_and_tips: projectToolsAndTips?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${projectDataIteration}-${i}` };
        })
    };
    useEffect(() => {
        setProjectDataIteration(projectDataIteration + 1);
    }, [projectData]);

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        const formDataDiff = diff(initialValues, formData);
        const { tools_and_tips: toolsAndTips } = { ...formData };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: {
                    ...formDataDiff,
                    tools_and_tips: cleanRepeaterOutput(toolsAndTips, ['customIdentifier'])
                }
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            {fetching === true && <PageLoader content />}
            <PageContent>
                <ProjectEditPageHeader data={projectData} />
                <ToolsAndTipsForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    form="ToolsAndTipsForm"
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMLALiveTools.defaultProps = {
    match: {}
};

ProjectEditMLALiveTools.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditMLALiveTools;
