import React from 'react';
import { withRouter } from 'react-router-dom';
import Tabs from 'erpcore/components/Tabs';
import PropTypes from 'prop-types';

const ProspectsTabs = ({ match }) => {
    const links = [];

    links.push(
        {
            link: `/prospects/${match?.params?.id}/edit`,
            label: 'General Information'
        },
        {
            link: `/prospects/${match?.params?.id}/edit/deals`,
            label: 'Deals'
        }
    );

    return <Tabs links={links} />;
};
ProspectsTabs.defaultProps = {
    match: {}
};

ProspectsTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};
export default withRouter(ProspectsTabs);
