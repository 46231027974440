import React from 'react';
import { withRouter } from 'react-router-dom';
import Tabs from 'erpcore/components/Tabs';
import PropTypes from 'prop-types';

const DealTabs = ({ match }) => {
    const links = [];

    links.push({
        link: `/deals/${match?.params?.id}/view`,
        label: 'General Information'
    });

    return <Tabs links={links} />;
};
DealTabs.defaultProps = {
    match: {}
};

DealTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};
export default withRouter(DealTabs);
