import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
import ReportsRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/ReportsRepeaterItem';
import Button from 'erpcore/components/Button';

const ProjectEditReportsForm = ({ handleSubmit, onSubmit, submitting, pristine, invalid }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <FieldArray
                    name="reporting"
                    component={Repeater}
                    RepeatableItem={ReportsRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add OI access dashboard"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Update" />
            </Form.Row>
        </Form>
    );
};

ProjectEditReportsForm.defaultProps = {
    handleSubmit: () => {},
    onSubmit: () => {},
    pristine: false,
    invalid: false,
    submitting: false
};

ProjectEditReportsForm.propTypes = {
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'ProjectEditReportsForm',
    enableReinitialize: true
})(ProjectEditReportsForm);
