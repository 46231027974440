import { createSelector } from 'reselect';

export const getRoleData = (state, id) => {
    if (state.roles && state.roles[id] && state.roles[id]) {
        const role = state.roles[id];

        if (role && role.permissions) {
            const permissions = [];
            const rolePermissions = [].concat(role.permissions.data);

            rolePermissions.map(permission => {
                if (permission) {
                    permissions.push(permission.id);
                }

                return permissions;
            });

            if (permissions.length > 0) {
                role.permissionsData = rolePermissions;
                role.permissions = permissions;
            }

            return role;
        }

        return role;
    }

    return null;
};

export const getRoleFetching = state => state.roles.singleFetching;

export default createSelector([getRoleData, getRoleFetching]);
