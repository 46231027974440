import React from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import ProjectKeyPlansRepeatableItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectKeyPlansRepeatableItem';
import { useSelector } from 'react-redux';
import { getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import AppStatuses from 'erpcore/components/AppStatuses';

const ProjectKeyPlans = ({ onUpdate, onCreate, onFinish, onRemove }) => {
    const projectFetching = useSelector(getProjectFetching);

    const addUpdateItems = (oldItems, oldItemsDiff, newItems) => {
        const promises = [];

        if (Object.keys(oldItemsDiff)?.length > 0) {
            oldItems.map(item => promises.push(onUpdate(item)));
        }

        if (newItems?.length > 0) {
            newItems.map(item => promises.push(onCreate(item)));
        }

        return Promise.all([promises]);
    };

    return (
        <Form>
            {projectFetching && <ElementLoader overlay />}
            <Form.Row>
                <FieldArray
                    name="key_plans"
                    component={Repeater}
                    RepeatableItem={ProjectKeyPlansRepeatableItem}
                    canSort={false}
                    canAddMultiple
                    canSortNewItems={false}
                    saveButtonIntent="all"
                    canSave
                    onSaveAll={({ oldItems, oldItemsDiff, newItems }) => {
                        addUpdateItems(oldItems, oldItemsDiff, newItems).finally(() => {
                            onFinish();
                        });
                    }}
                    onRemoveItem={({ itemData }) => onRemove(itemData)}
                    uniqueIdentifier="iri"
                    addNewLabel="Add key plan"
                />
            </Form.Row>
            <AppStatuses homestore mlaBlue />
        </Form>
    );
};

ProjectKeyPlans.defaultProps = {
    onUpdate: () => {},
    onFinish: () => {},
    onCreate: () => {},
    onRemove: () => {}
};

ProjectKeyPlans.propTypes = {
    onUpdate: PropTypes.func,
    onFinish: PropTypes.func,
    onCreate: PropTypes.func,
    onRemove: PropTypes.func
};

export default reduxForm({ form: 'ProjectKeyPlans', enableReinitialize: true })(ProjectKeyPlans);
