import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset, SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import DealForm from 'erpcore/screens/Deals/components/DealForm';

import { actions as dealsActions } from 'erpcore/screens/Deals/Deals.reducer';
import { getIdFromIri } from 'erpcore/utils/dto';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const DealCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const meData = useSelector(getMeData) || {};
    const title = 'Create new deal';

    const onSubmit = formData => {
        // Handle Location data
        const { location } = { ...formData };
        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: dealsActions.START_CREATE_DEAL,
                formData
            });
        })
            .then(response => {
                if (response?.data?.id) {
                    if (meData?._type !== 'sales-agent') {
                        history.push(`/deals/${getIdFromIri(response?.data?.id)}/edit`);
                    } else {
                        history.push(`/deals/${getIdFromIri(response?.data?.id)}/view`);
                    }
                } else {
                    dispatch(reset('DealCreateForm'));
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <DealForm onSubmit={onSubmit} form="DealCreateForm" />
            </PageContent>
        </LayoutManager>
    );
};

export default DealCreate;
