import React, { useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as routerManagerActions } from 'erpcore/utils/RouterManager/RouterManager.reducer';
import { getSignedIn, getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities, hasPermission } from 'erpcore/utils/RolesManager';

// Screens / Pages
// System
import Error404 from 'erpcore/screens/Error404';

// Auth
import SignIn from 'erpcore/screens/SignIn';
import OiAccessSignIn from 'erpcore/screens/OiAccessSignIn';
import SignOut from 'erpcore/screens/SignOut';
import ForgotPassword from 'erpcore/screens/ForgotPassword/screens/ForgotPassword';
import ResetPassword from 'erpcore/screens/ForgotPassword/screens/ResetPassword';
import ResetPasswordSignedIn from 'erpcore/screens/ForgotPassword/screens/ResetPasswordSignedIn';

// General
import Dashboard from 'erpcore/screens/Dashboard';

// My Account
import MyAccountPersonalDetails from 'erpcore/screens/MyAccount/screens/MyAccountPersonalDetails';
import MyAccountLinkedAccounts from 'erpcore/screens/MyAccount/screens/MyAccountLinkedAccounts';

// Clients
import ClientsListing from 'erpcore/screens/Clients/screens/ClientsListing';
import ClientCreate from 'erpcore/screens/Clients/screens/ClientCreate';
import ClientEdit from 'erpcore/screens/Clients/screens/ClientEdit';

// Prospects
import ProspectsListing from 'erpcore/screens/Prospects/screens/ProspectListing';
import ProspectCreate from 'erpcore/screens/Prospects/screens/ProspectCreate';
import ProspectEdit from 'erpcore/screens/Prospects/screens/ProspectEdit';
import ProspectDetails from 'erpcore/screens/Prospects/screens/ProspectView';
import ProspectDeals from 'erpcore/screens/Prospects/screens/ProspectDeals';
import ProspectDealCreate from 'erpcore/screens/Prospects/screens/ProspectDealCreate';
import ProspectDealEdit from 'erpcore/screens/Prospects/screens/ProspectDealEdit';
import ProspectDealView from 'erpcore/screens/Prospects/screens/ProspectDealView';

// Projects
import ProjectsListing from 'erpcore/screens/Projects/screens/Projects';
import ProjectCreate from 'erpcore/screens/Projects/screens/ProjectCreate';
import ProjectEditMultiEffectGeneralInfo from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectGeneralInfo';
import ProjectEditMLALiveBuildingDesign from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveBuildingDesign';
import ProjectEditMLALiveNeighbourhood from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveNeighbourhood';
import ProjectEditMLALiveFloorplans from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveFloorplans';
import ProjectEditMLALiveHomeTour from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveHomeTour';
import ProjectEditMLALiveFeatures from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveFeatures';
import ProjectEditMLALiveTools from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveTools';
import ProjectEditMLALiveDocuments from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveDocuments';
import ProjectEditMultiEffectConnect from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectConnect';
import ProjectEditMultiEffectIntegration from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectIntegration';
import ProjectEditMultiEffectFAQ from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectFAQ';
import ProjectEditMultiEffectUnits from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectUnits';
import ProjectEditMultiEffectUnitsSettings from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectUnitsSettings';
import ProjectEditMultiEffectSession from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectSession';
import ProjectEditMultiEffectFeaturesList from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectFeaturesList';
import ProjectEditMultiEffectRealtorPortal from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMultiEffectRealtorPortal';
import ProjectEditMLALiveGeneralInfo from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLALiveGeneralInfo';
import ProjectEditHomeownerPortal from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditHomeownerPortal';
import ProjectEditHomeStore from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditHomeStore';
import ProjectEditMLABlue from 'erpcore/screens/Projects/screens/ProjectEdit/screens/ProjectEditMLABlue';
import ProjectEditReports from 'erpcore/screens/Projects/screens/ProjectEdit/screens/Reports';

// Reports
import SessionReports from 'erpcore/screens/Reports/Session';

// Settings
import OrganizationGeneral from 'erpcore/screens/Settings/Organization/screens/OrganizationGeneral';
import OrganizationBranding from 'erpcore/screens/Settings/Organization/screens/OrganizationBranding';
import Permissions from 'erpcore/screens/Settings/Permissions';
import Roles from 'erpcore/screens/Settings/Roles/screens/Roles';
import RoleCreate from 'erpcore/screens/Settings/Roles/screens/RoleCreate';
import RoleEdit from 'erpcore/screens/Settings/Roles/screens/RoleEdit';
import SalesGuidelines from 'erpcore/screens/Settings/Session/screens/SalesGuidelines';
import ProspectProfileQuestions from 'erpcore/screens/Settings/Session/screens/ProspectProfileQuestions';
import VonageVideoAPI from 'erpcore/screens/Settings/VonageVideoAPI';
import HubSpotIntegrationConnection from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/screens/HubSpotIntegrationConnection';
import HubSpotIntegrationSettings from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/screens/HubSpotIntegrationSettings';
import UnitTypes from 'erpcore/screens/Settings/UnitTypes/screens/UnitTypes';
import Integration from 'erpcore/screens/Settings/Integration/Integration.jsx';
import AvesdoIntegration from 'erpcore/screens/Settings/Integration/screens/AvesdoIntegration';
import PortalGeneral from 'erpcore/screens/Settings/Portal/screens/PortalGeneral';
import PortalBanners from 'erpcore/screens/Settings/Portal/screens/PortalBanners';
import PortalFAQ from 'erpcore/screens/Settings/Portal/screens/PortalFAQ';
import PortalChecklistItems from 'erpcore/screens/Settings/Portal/screens/PortalChecklistItems';
import ProductTypes from 'erpcore/screens/Settings/ProductTypes/screens/ProductTypes';
import BlueSettings from 'erpcore/screens/Settings/Blue/screens/BlueSettings';
import BlueFAQ from 'erpcore/screens/Settings/Blue/screens/Blog/screens/Blog';
import BlogCategories from 'erpcore/screens/Settings/Blue/screens/BlogCategories/screens/BlogCategories';
import HubspotLog from 'erpcore/screens/Settings/HubspotLog/screens/HubspotLog';
import AvesdoEmails from 'erpcore/screens/Settings/AvesdoEmails/screens/AvesdoEmails';
import OIAccessGeneral from 'erpcore/screens/Settings/OIAccess/screens/OIAccessGeneral';
import OIAccessHelp from 'erpcore/screens/Settings/OIAccess/screens/OIAccessHelp';

// Sessions
import SessionsListing from 'erpcore/screens/Sessions/screens/SessionsListing';
import SessionCreate from 'erpcore/screens/Sessions/screens/SessionCreate';
import SessionEdit from 'erpcore/screens/Sessions/screens/SessionEdit';
import SessionView from 'erpcore/screens/Sessions/screens/SessionView';

// Deals
import DealsListing from 'erpcore/screens/Deals/screens/Deals';
import DealCreate from 'erpcore/screens/Deals/screens/DealCreate';
import DealEdit from 'erpcore/screens/Deals/screens/DealEdit';
import DealView from 'erpcore/screens/Deals/screens/DealView';

// Users
import UsersListing from 'erpcore/screens/Users/screens/UsersListing';
import UserCreate from 'erpcore/screens/Users/screens/UserCreate';
import UserEdit from 'erpcore/screens/Users/screens/UserEdit';
import UserPortal from 'erpcore/screens/Users/screens/UserPortal';
import UserDealsRestrictions from 'erpcore/screens/Users/screens/UserDealRestrictions';
import UserPermissions from 'erpcore/screens/Users/screens/UserPermissions';
import UserReportingDashboard from 'erpcore/screens/Users/screens/UserReportingDashboard';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import OiAccessDashboard from 'erpcore/screens/OiAccessDashboards/screens/OiAccessDashboard';
import OiAccessDashboardHelp from 'erpcore/screens/OiAccessDashboards/screens/OiAccessHelp';
import OiAccessProject from 'erpcore/screens/OiAccessDashboards/screens/OiAccessProject';
import NeighbourhoodLocationSections from 'erpcore/screens/Settings/NeighbourhoodLocationSections/screens/NeighbourhoodLocationSections';
import { isOiAccessSubdomain } from '../utils';

/**
 * Router Manager
 * @return {Node} Returns current active route component
 */
const RouterManager = ({ location }) => {
    const dispatch = useDispatch();
    const isSignedIn = useSelector(getSignedIn);
    const allowedToClearNotifications = useRef(false);
    const { pathname, search } = location;
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    //  Sings out user and redirects it to portal app
    const portalRedirect = () => {
        dispatch({
            type: authActions.START_SIGN_OUT
        });
        window.location.replace(enviromentVariables?.PORTAL_APP_URL);
    };
    const avesdoID = useMemo(() => {
        const { is_avesdo_user: isAvesdoUser } = meData;
        return isAvesdoUser;
    }, [meData]);
    if (avesdoID) {
        portalRedirect();
    }

    useEffect(() => {
        if (isSignedIn) {
            dispatch({ type: authActions.START_FETCHING_ME });
        }
    }, []);

    const clearNotificationManagerPageNotification = () => {
        dispatch({
            type: notificationManagerActions.REMOVE_PAGE_NOTIFICATIONS,
            initiator: 'router'
        });
    };

    const setUrlQueryParams = params => {
        dispatch({
            type: routerManagerActions.SET_URL_QUERY_PARAMS,
            response: params
        });
    };

    /**
     * Effect used only on location path (route) change
     */
    useEffect(() => {
        const handleLocationChange = () => {
            if (allowedToClearNotifications.current) {
                // Clear Page Notifications
                clearNotificationManagerPageNotification();
            }
            allowedToClearNotifications.current = true;
            setUrlQueryParams('');
        };

        const renderBodyClassName = () => {
            //  dynamicly udating body className
            let cssClass = 'page-homepage';
            if (pathname && pathname !== '/') {
                cssClass = location.pathname.replace(/\//, 'page-');
                cssClass = cssClass.replace(/\//g, '-');
            } else {
                cssClass = 'page-404';
            }

            document.body.className = cssClass;
        };
        // Invoke functions
        renderBodyClassName();
        handleLocationChange();
    }, [pathname]);

    /**
     * Effect used only on location search (query params) change
     */
    useEffect(() => {
        setUrlQueryParams(search);
    }, [search]);

    // Render the private routes when the user is signed in
    if (isSignedIn) {
        if (isOiAccessSubdomain())
            return (
                <Switch>
                    {/* Oi Access Dashboard */}
                    {hasPermissionsAccessForEntities.dashboard &&
                        isOiAccessSubdomain() && [
                            <Route
                                key="OiAccessDashboard"
                                path="/dashboard"
                                exact
                                component={OiAccessDashboard}
                            />,
                            <Route
                                key="OiAccessProject"
                                path="/dashboard/project/:projectId"
                                exact
                                component={OiAccessProject}
                            />
                        ]}
                    <Route
                        key="OiAccessDashboardHelp"
                        path="/help"
                        exact
                        component={OiAccessDashboardHelp}
                    />
                    <Route key="SignOut" path="/sign-out" exact component={SignOut} />
                    <Redirect exact from="*" to="/dashboard" />
                </Switch>
            );

        return (
            <Switch>
                {/* General Sign In Flow */}
                <Route key="SignOut" path="/sign-out" exact component={SignOut} />
                <Route exact path="/reset-password" component={ResetPasswordSignedIn} />
                <Route exact path="/reset-password/:token" component={ResetPasswordSignedIn} />
                <Route
                    key="ResetPasswordLogin"
                    path="/reset-password-login"
                    exact
                    component={ResetPassword}
                />

                {/* Dashboard */}
                {hasPermissionsAccessForEntities.dashboard && !isOiAccessSubdomain() && (
                    <Route key="Dashboard" path="/dashboard" exact component={Dashboard} />
                )}
                {/* Oi Access Dashboard */}
                {hasPermissionsAccessForEntities.dashboard &&
                    isOiAccessSubdomain() && [
                        <Route
                            key="OiAccessDashboard"
                            path="/dashboard"
                            exact
                            component={OiAccessDashboard}
                        />,
                        <Route
                            key="OiAccessProject"
                            path="/dashboard/project/:projectId"
                            exact
                            component={OiAccessProject}
                        />
                    ]}
                {/* My Account */}
                <Route
                    key="MyAccountPersonalDetails"
                    path="/my-account"
                    exact
                    component={MyAccountPersonalDetails}
                />
                <Route
                    key="MyAccountLinkedAccounts"
                    path="/my-account/linked-accounts"
                    exact
                    component={MyAccountLinkedAccounts}
                />
                {/* Users */}
                {hasPermissionsAccessForEntities.users && [
                    <Route key="UsersListing" path="/users" exact component={UsersListing} />,
                    <Route key="UserCreate" path="/users/create" exact component={UserCreate} />,
                    <Route
                        key="UserEdit"
                        path="/users/:id/edit/details"
                        exact
                        component={UserEdit}
                    />,
                    <Route
                        key="UserDetails"
                        path="/users/:id/edit/details"
                        exact
                        component={UserEdit}
                    />,
                    <Route
                        key="UserPermissions"
                        path="/users/:id/edit/permissions"
                        exact
                        component={UserPermissions}
                    />,
                    <Route
                        key="UserPortal"
                        path="/users/:id/edit/portal"
                        exact
                        component={UserPortal}
                    />,
                    <Route
                        key="UserDealsRestrictions"
                        path="/users/:id/edit/deals-restrictions"
                        exact
                        component={UserDealsRestrictions}
                    />,
                    <Route
                        key="UserReportingDashboard"
                        path="/users/:id/edit/reporting-dashboard"
                        exact
                        component={UserReportingDashboard}
                    />,
                    <Route
                        key="UserReportingDashboard"
                        path="/users/:id/edit/reporting-dashboard/:projectId"
                        exact
                        component={UserReportingDashboard}
                    />
                ]}

                {/* Settings */}
                {hasPermissionsAccessForEntities.settings && [
                    hasPermissionsAccessForEntities.organization && [
                        <Route
                            key="OrganizationGeneral"
                            path="/settings/general/organization"
                            exact
                            component={OrganizationGeneral}
                        />,
                        <Route
                            key="OrganizationBranding"
                            path="/settings/general/branding"
                            exact
                            component={OrganizationBranding}
                        />,
                        <Route
                            key="OrganizationGeneral"
                            path="/settings/portal/general"
                            exact
                            component={PortalGeneral}
                        />,
                        <Route
                            key="OrganizationGeneral"
                            path="/settings/portal/banners"
                            exact
                            component={PortalBanners}
                        />,
                        <Route
                            key="PortalFAQ"
                            path="/settings/portal/faq"
                            exact
                            component={PortalFAQ}
                        />,
                        <Route
                            key="PortalChecklistItems"
                            path="/settings/portal/checklist-items"
                            exact
                            component={PortalChecklistItems}
                        />
                    ],
                    hasPermissionsAccessForEntities.roles && [
                        <Route
                            key="Roles"
                            path="/settings/general/roles"
                            exact
                            component={Roles}
                        />,
                        <Route
                            key="RoleCreate"
                            path="/settings/general/roles/create"
                            exact
                            component={RoleCreate}
                        />,
                        <Route
                            key="RoleEdit"
                            path="/settings/general/roles/:id/edit"
                            exact
                            component={RoleEdit}
                        />
                    ],
                    hasPermissionsAccessForEntities.permissions && (
                        <Route
                            key="Permissions"
                            path="/settings/general/permissions"
                            exact
                            component={Permissions}
                        />
                    ),
                    hasPermissionsAccessForEntities.sessions && [
                        <Route
                            key="SalesGuidelines"
                            path="/settings/mla-live/session/sales-guidelines"
                            exact
                            component={SalesGuidelines}
                        />,
                        <Route
                            key="ProspectProfileQuestions"
                            path="/settings/mla-live/session/prospect-profile-questions"
                            exact
                            component={ProspectProfileQuestions}
                        />
                    ],
                    <Route
                        key="VonageVideoAPI"
                        path="/settings/mla-live/vonage-video-api"
                        exact
                        component={VonageVideoAPI}
                    />,
                    <Route
                        key="HubSpotIntegration"
                        path="/settings/general/integration/hubspot/connect"
                        exact
                        component={HubSpotIntegrationConnection}
                    />,
                    <Route
                        key="VonageVideoAPI"
                        path="/settings/vonage-video-api"
                        exact
                        component={VonageVideoAPI}
                    />,
                    <Route
                        key="Integration"
                        path="/settings/integration"
                        exact
                        component={Integration}
                    />,
                    <Route
                        key="HubSpotIntegrationSettings"
                        path="/settings/general/integration/hubspot/settings"
                        exact
                        component={HubSpotIntegrationSettings}
                    />,
                    <Route
                        key="AvesdoIntegration"
                        path="/settings/general/integration/avesdo"
                        exact
                        component={AvesdoIntegration}
                    />,
                    <Route
                        key="UnitTypes"
                        path="/settings/general/Unit-types"
                        exact
                        component={UnitTypes}
                    />,
                    <Route
                        key="NeighbourhoodLocationSections"
                        path="/settings/general/neghbourhood-location-sections"
                        exact
                        component={NeighbourhoodLocationSections}
                    />,
                    <Route
                        key="ProductTypes"
                        path="/settings/general/product-types"
                        exact
                        component={ProductTypes}
                    />,
                    <Route
                        key="HubspotLog"
                        path="/settings/general/hubspot-log"
                        exact
                        component={HubspotLog}
                    />,
                    <Route
                        key="AvesdoEmails"
                        path="/settings/general/avesdo-emails"
                        exact
                        component={AvesdoEmails}
                    />,
                    <Route
                        key="BlueSettings"
                        path="/settings/mla-blue/general"
                        exact
                        component={BlueSettings}
                    />,
                    <Route key="BlueFAQ" path="/settings/mla-blue/faq" exact component={BlueFAQ} />,
                    <Route
                        key="BlogCategories"
                        path="/settings/mla-blue/faq-categories"
                        exact
                        component={BlogCategories}
                    />,
                    <Route
                        key="OIAccessGeneral"
                        path="/settings/oiaccess/general"
                        exact
                        component={OIAccessGeneral}
                    />,
                    <Route
                        key="OIAccessHelp"
                        path="/settings/oiaccess/help"
                        exact
                        component={OIAccessHelp}
                    />
                ]}

                {/* Clients */}
                {hasPermissionsAccessForEntities.clients && [
                    <Route key="ClientsListing" path="/clients" exact component={ClientsListing} />,
                    <Route
                        key="ClientCreate"
                        path="/clients/create"
                        exact
                        component={ClientCreate}
                    />,
                    <Route
                        key="ClientEdit"
                        path="/clients/:id/edit/general-info"
                        exact
                        component={ClientEdit}
                    />
                ]}

                {/* Prospects */}
                {hasPermissionsAccessForEntities.prospects && [
                    <Route
                        key="ProspectsListing"
                        path="/prospects"
                        exact
                        component={ProspectsListing}
                    />,
                    <Route
                        key="ProspectCreate"
                        path="/prospects/create"
                        exact
                        component={ProspectCreate}
                    />,
                    meData?._type !== 'sales-agent' && (
                        <Route
                            key="ProspectEdit"
                            path="/prospects/:id/edit"
                            exact
                            component={ProspectEdit}
                        />
                    ),
                    <Route
                        key="ProspectDetails"
                        path="/prospects/:id/details"
                        exact
                        component={ProspectDetails}
                    />,
                    <Route
                        key="ProspectDeals"
                        path="/prospects/:id/edit/deals"
                        exact
                        component={ProspectDeals}
                    />,
                    hasPermission('CAN_EDIT_COMMON_DEAL', meData) && (
                        <Route
                            key="ProspectDealsCreate"
                            path="/prospects/:id/edit/deals/create"
                            exact
                            component={ProspectDealCreate}
                        />
                    ),
                    <Route
                        key="ProspectDealView"
                        path="/prospects/:id/edit/deals/:dealID"
                        exact
                        component={ProspectDealView}
                    />,
                    hasPermission('CAN_EDIT_COMMON_DEAL', meData) && (
                        <Route
                            key="ProspectDealEdit"
                            path="/prospects/:id/edit/deals/:dealID/edit"
                            exact
                            component={ProspectDealEdit}
                        />
                    )
                ]}

                {/* Projects */}
                {hasPermissionsAccessForEntities.projects && [
                    <Route key="Projects" path="/projects" exact component={ProjectsListing} />,
                    (hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) ||
                        hasPermission('CAN_EDIT_ASSIGNED_PROJECTS', meData)) && [
                        hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && [
                            <Route
                                key="ProjectCreate"
                                path="/projects/create"
                                exact
                                component={ProjectCreate}
                            />
                        ],
                        <Route
                            key="ProjectEditMultiEffectGeneralInfo"
                            path="/projects/:id/edit/multi-effect/general-info"
                            exact
                            component={ProjectEditMultiEffectGeneralInfo}
                        />,
                        <Route
                            key="ProjectEditMLALiveBuildingDesign"
                            path="/projects/:id/edit/mla-live/building-design"
                            exact
                            component={ProjectEditMLALiveBuildingDesign}
                        />,
                        <Route
                            key="ProjectEditMLALiveNeighbourhood"
                            path="/projects/:id/edit/mla-live/neighbourhood"
                            exact
                            component={ProjectEditMLALiveNeighbourhood}
                        />,
                        <Route
                            key="ProjectEditMLALiveFloorplans"
                            path="/projects/:id/edit/mla-live/floorplans"
                            exact
                            component={ProjectEditMLALiveFloorplans}
                        />,
                        <Route
                            key="ProjectEditMLALiveHomeTour"
                            path="/projects/:id/edit/mla-live/home-tour"
                            exact
                            component={ProjectEditMLALiveHomeTour}
                        />,
                        <Route
                            key="ProjectEditMLALiveFeatures"
                            path="/projects/:id/edit/mla-live/features"
                            exact
                            component={ProjectEditMLALiveFeatures}
                        />,
                        <Route
                            key="ProjectEditMLALiveTools"
                            path="/projects/:id/edit/mla-live/tools"
                            exact
                            component={ProjectEditMLALiveTools}
                        />,
                        <Route
                            key="ProjectEditMLALiveDocuments"
                            path="/projects/:id/edit/mla-live/documents"
                            exact
                            component={ProjectEditMLALiveDocuments}
                        />,
                        <Route
                            key="ProjectEditIntegration"
                            path="/projects/:id/edit/multi-effect/integrations"
                            exact
                            component={ProjectEditMultiEffectIntegration}
                        />,
                        <Route
                            key="ProjectEditMultiEffectUnits"
                            path="/projects/:id/edit/multi-effect/units"
                            exact
                            component={ProjectEditMultiEffectUnits}
                        />,
                        <Route
                            key="ProjectEditMultiEffectUnitsSettings"
                            path="/projects/:id/edit/multi-effect/unit-settings"
                            exact
                            component={ProjectEditMultiEffectUnitsSettings}
                        />,
                        <Route
                            key="ProjectEditMultiEffectFeaturesList"
                            path="/projects/:id/edit/multi-effect/features-list"
                            exact
                            component={ProjectEditMultiEffectFeaturesList}
                        />,
                        <Route
                            key="ProjectEditFeatureAndOptions"
                            path="/projects/:id/edit/multi-effect/connect"
                            exact
                            component={ProjectEditMultiEffectConnect}
                        />,
                        <Route
                            key="ProjectEditMultiEffectFAQ"
                            path="/projects/:id/edit/multi-effect/faq"
                            exact
                            component={ProjectEditMultiEffectFAQ}
                        />,
                        <Route
                            key="ProjectEditMultiEffectRealtorPortal"
                            path="/projects/:id/edit/multi-effect/realtor-portal"
                            exact
                            component={ProjectEditMultiEffectRealtorPortal}
                        />,
                        <Route
                            key="ProjectEditMLALiveGeneralInfo"
                            path="/projects/:id/edit/mla-live/general-info"
                            exact
                            component={ProjectEditMLALiveGeneralInfo}
                        />,
                        <Route
                            key="ProjectEditMultiEffectSession"
                            path="/projects/:id/edit/mla-live/session"
                            exact
                            component={ProjectEditMultiEffectSession}
                        />,
                        <Route
                            key="ProjectEditHomeownerPortal"
                            path="/projects/:id/edit/homeowner-portal"
                            exact
                            component={ProjectEditHomeownerPortal}
                        />,
                        <Route
                            key="ProjectEditHomeStore"
                            path="/projects/:id/edit/homestore"
                            exact
                            component={ProjectEditHomeStore}
                        />,
                        <Route
                            key="ProjectEditMLABlue"
                            path="/projects/:id/edit/mla-blue"
                            exact
                            component={ProjectEditMLABlue}
                        />,
                        <Route
                            key="ProjectEditReports"
                            path="/projects/:id/edit/reports"
                            exact
                            component={ProjectEditReports}
                        />
                    ]
                ]}

                {/* Reports */}
                {hasPermissionsAccessForEntities.sessions && (
                    <Route
                        key="SessionReports"
                        path="/reports/session"
                        exact
                        component={SessionReports}
                    />
                )}

                {/* Sessions */}
                {hasPermissionsAccessForEntities.sessions && [
                    <Route
                        key="SessionsListing"
                        path="/sessions"
                        exact
                        component={SessionsListing}
                    />,
                    <Route
                        key="SessionCreate"
                        path="/sessions/create"
                        exact
                        component={SessionCreate}
                    />,
                    <Route
                        key="SessionEdit"
                        path="/sessions/:id/edit"
                        exact
                        component={SessionEdit}
                    />,
                    <Route
                        key="SessionView"
                        path="/sessions/:id/view"
                        exact
                        component={SessionView}
                    />
                ]}
                {hasPermissionsAccessForEntities.prospects && [
                    <Route key="DealsListing" path="/deals" exact component={DealsListing} />,
                    <Route key="DealCreate" path="/deals/create" exact component={DealCreate} />,
                    hasPermission('CAN_EDIT_COMMON_DEAL', meData) && (
                        <Route key="DealEdit" path="/deals/:id/edit" exact component={DealEdit} />
                    ),
                    <Route key="DealView" path="/deals/:id/view" exact component={DealView} />
                ]}
                {hasPermissionsAccessForEntities.dashboard && (
                    <Redirect exact from="/" to="/dashboard" />
                )}
                <Route component={Error404} />
            </Switch>
        );
    }

    // Render the  router when the user is not signed in
    return (
        <Switch>
            <Route exact path="/" component={!isOiAccessSubdomain() ? SignIn : OiAccessSignIn} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:token" component={ResetPassword} />
            <Route exact path="/reset-password-login" component={ResetPassword} />
            <Redirect
                to={{
                    pathname: '/',
                    state: { from: location }
                }}
            />
        </Switch>
    );
};

RouterManager.defaultProps = {
    location: {}
};

RouterManager.propTypes = {
    location: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(RouterManager);
