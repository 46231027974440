import React from 'react';
import { Field } from 'redux-form';
import Form, { Media, Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const DocumentsRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Form.SectionTitleSmall>Document</Form.SectionTitleSmall>
                <Field
                    name={`${member}.documentIri`}
                    id={`${member}.documentIri`}
                    component={Media}
                    allowedFileTypes={['xls', 'pdf', 'doc', 'ppt', 'docx', 'xlsx', 'pptx']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

DocumentsRepeaterItem.defaultProps = {
    member: null
};

DocumentsRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DocumentsRepeaterItem;
