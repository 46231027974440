export const actions = {
    START_FETCHING_SESSIONS_DASHBOARD: 'START_FETCHING_SESSIONS_DASHBOARD',
    FETCHING_SUCCESSFUL_SESSIONS_DASHBOARD: 'FETCHING_SUCCESSFUL_SESSIONS_DASHBOARD',
    FETCHING_FAILED_SESSIONS_DASHBOARD: 'FETCHING_FAILED_SESSIONS_DASHBOARD'
};

export const initialState = {
    dashboardSessionData: {
        data: [],
        meta: {},
        included: []
    },
    dashboardFetching: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_SESSIONS_DASHBOARD: {
            return {
                ...state,
                dashboardFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SESSIONS_DASHBOARD: {
            return {
                ...state,
                dashboardFetching: false,
                dashboardSessionData: response
            };
        }
        case actions.FETCHING_FAILED_SESSIONS_DASHBOARD: {
            return {
                ...state,
                dashboardFetching: false
            };
        }
        default:
            return state;
    }
};
