import React from 'react';
import PropTypes from 'prop-types';
import './StatusBadge.scss';

const StatusBadge = ({ type, text, className }) => (
    <span
        className={`status-badge${type !== '' ? ` status-badge--${type}` : ''}${
            className ? ` ${className}` : ''
        }`}
    >
        {text}
    </span>
);

StatusBadge.defaultProps = {
    type: '',
    text: '',
    className: ''
};

StatusBadge.propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string
};

export default StatusBadge;
