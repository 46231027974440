import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import Input from '../Input';
import './ColorPicker.scss';

const ColorPicker = ({ input, meta, fieldProps, fieldAttr, field }) => {
    const [open, setOpen] = useState(false);
    const { value } = { ...input };
    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
        >
            <div className="color-picker">
                <button
                    type="button"
                    onClick={() => setOpen(!open)}
                    className="color-picker__trigger"
                >
                    <div
                        className="color-picker__selected-color"
                        style={{ backgroundColor: value }}
                    >
                        {value}
                    </div>
                </button>
                {!!open && (
                    <div className="color-picker__picker">
                        <button
                            type="button"
                            className="color-picker__cover"
                            onClick={() => setOpen(!open)}
                        />
                        <ChromePicker
                            disableAlpha
                            color={value}
                            onChangeComplete={color => input.onChange(color?.hex)}
                        />
                    </div>
                )}
            </div>
        </Input>
    );
};

ColorPicker.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

ColorPicker.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default ColorPicker;
