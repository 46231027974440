import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LayoutManager from 'erpcore/utils/LayoutManager';
import SessionTabs from 'erpcore/screens/Settings/Session/components/SessionTabs';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import ProspectProfileQuestionsForm from 'erpcore/screens/Settings/Session/components/ProspectProfileQuestionsForm';
import Listing from 'erpcore/components/Listing';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import ProspectProfileQuestionsEdit from 'erpcore/screens/Settings/Session/components/ProspectProfileQuestionsEdit';
import ProspectProfileQuestionsDelete from 'erpcore/screens/Settings/Session/components/ProspectProfileQuestionsDelete';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as sessionActions } from 'erpcore/screens/Settings/Session/SessionSettings.reducer';
import { SubmissionError, reset, getFormValues } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import './ProspectProfileQuestions.scss';
import { projectSections } from 'erpcore/screens/Projects/data/projectsData';
import ProspectProfileQuestionsFilters from 'erpcore/screens/Settings/Session/components/ProspectProfileQuestionsFilters';
import {
    getProspectProfileQuestionsData,
    getProspectProfileQuestionsFetching
} from 'erpcore/screens/Settings/Session/SessionSettings.selectors';

const ProspectProfileQuestions = () => {
    const title = 'Session - Prospect profile questions';
    const reducerName = 'prospectProfileQuestions';
    const actionName = 'PROFILE_QUESTIONS';
    const listing = useSelector(state => getProspectProfileQuestionsData(state));
    const listingFetching = useSelector(state => getProspectProfileQuestionsFetching(state));
    const formValues = useSelector(state =>
        getFormValues('ProspectProfileQuestionsFilters')(state)
    );

    const { section } = { ...formValues };

    const dispatch = useDispatch();

    // Local array, initial Value is always Home Page //
    const initialValues = {
        section: projectSections[0].value
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionActions.START_CREATE_PROFILE_QUESTION,
                promise: { resolve, reject },
                formData
            });
        })
            .then(() => {
                dispatch(reset('ProspectProfileQuestionForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    const fetchProfileQuestions = () => {
        const params = {
            'filters[section][equals]': formValues?.section || projectSections[0].value
        };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/prospect-profile-questions?order_by[position]=asc&pagination=false'
            });
        }).catch(error => {
            return error;
        });
    };

    useEffect(() => {
        fetchProfileQuestions();
    }, [section]);

    const updatePositions = items => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_UPDATE_POSITIONS,
                data: items
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'Question',
                field: 'question',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Type',
                field: 'questionType',
                sortable: false
            },
            {
                title: 'Answers',
                field: 'answers',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Section',
                field: 'section',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (listing && listing.data) {
            listing.data.map(row => {
                let { answer_type: answerType } = { ...row };
                answerType = answerType.charAt(0).toUpperCase() + answerType.slice(1);

                const projectSection = projectSections?.find(el => el.value === row?.section) || '';

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    position: row?.position,
                    question: row?.question,
                    questionType: answerType,
                    answers:
                        row?.answers?.map(answer => {
                            return (
                                <span className="prospect-profile-questions__answer">
                                    {answer?.name}
                                </span>
                            );
                        }) || '',
                    section: projectSection?.label,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <ProspectProfileQuestionsEdit data={row} />
                            </TableActions.Action>
                            <TableActions.Action>
                                <ProspectProfileQuestionsDelete
                                    iri={row?.iri}
                                    form={`ProspectProfileQuestionsDelete-${row?.id}`}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <SessionTabs />
                <ProspectProfileQuestionsForm
                    radioID="create"
                    onSubmit={onSubmit}
                    submitLabel="Add new"
                />
                <ProspectProfileQuestionsFilters initialValues={initialValues} />
                <Listing
                    name={reducerName}
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchProfileQuestions(params)}
                    draggable
                    onDragFinished={results => updatePositions(results)}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ProspectProfileQuestions;
