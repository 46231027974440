import { createSelector } from 'reselect';

export const getDashboardData = state =>
    state?.oiAccessDashboard?.oiAcceessDashboardProjectData || [];

export const getDashboardSingleProjectData = state =>
    state?.oiAccessDashboard?.oiAcceessDashboardSingleProjectData || [];

export const getDashboardFetching = state =>
    state?.oiAccessDashboard?.oiAccessDashboardFetching || false;

export default createSelector([
    getDashboardData,
    getDashboardSingleProjectData,
    getDashboardFetching
]);
