import React from 'react';
import PropTypes from 'prop-types';
import './DemoSessionSchedule.scss';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

const DemoSessionSchedule = ({ data, title }) => {
    const renderProspect = prospectData => {
        if (prospectData && prospectData?.length > 1) return <strong>Multiple prospects</strong>;
        if (prospectData && prospectData?.[0])
            return `${prospectData[0]?.prospect?.['first_name']} ${prospectData[0]?.prospect?.['last_name']}`;

        return <strong>For guests and audience</strong>;
    };

    const renderListItem = itemData => {
        const { session_prospects: sessionProspect = [], projects = [] } = {
            ...itemData
        };

        return (
            <div key={data.id} className="demo-session-schedule__list-item">
                <div className="demo-session-schedule__list-item-data">
                    <span>{moment(itemData?.date).format('h:mm a')} </span>
                    <span>{renderProspect(sessionProspect)}</span>
                    <span>|</span>
                    <span>
                        {projects?.length > 1 ? (
                            <strong>Multiple projects</strong>
                        ) : (
                            projects[0]?.name || 'No project'
                        )}
                    </span>
                </div>
                <div className="demo-session-schedule__list-item-link">
                    <Link to={`sessions/${itemData?.id}/view`}>View Session</Link>
                </div>
            </div>
        );
    };

    return (
        <div className="demo-session-schedule">
            {title && <h4>{title}</h4>}
            <div className="demo-session-schedule__list">
                {!data?.length ? (
                    <p>No scheduled sessions.</p>
                ) : (
                    data.map(item => renderListItem(item))
                )}
            </div>
        </div>
    );
};

DemoSessionSchedule.defaultProps = {
    data: [],
    title: null
};

DemoSessionSchedule.propTypes = {
    data: PropTypes.oneOfType([PropTypes.array]),
    title: PropTypes.string
};

export default DemoSessionSchedule;
