import React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import styles from './HorizontalBarChart.module.scss';

class HorizontalBarChart extends React.Component {
    // If used as callback to send data to <HorizontalBarChart /> , canvas argument is usable, its used for creating gradients for fill amongst other things //
    getChartData = () => {
        const { data: chartData } = this.props;

        return chartData;
    };

    render() {
        // Options are passed as props if needed, options include yAxis and xAxis labels, responsivness, toolpit data and style etc... //
        const { options, legend, getElementAtEvent, getDatasetAtEvent } = this.props;
        return (
            <div className={`${styles['horizontal-bar-chart']}`}>
                <HorizontalBar
                    data={this.getChartData}
                    options={options}
                    legend={legend}
                    getElementAtEvent={getElementAtEvent}
                    getDatasetAtEvent={getDatasetAtEvent}
                />
            </div>
        );
    }
}

HorizontalBarChart.defaultProps = {
    data: {},
    options: {},
    legend: {},
    getElementAtEvent: () => {},
    getDatasetAtEvent: () => {}
};

HorizontalBarChart.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    options: PropTypes.oneOfType([PropTypes.object]),
    legend: PropTypes.oneOfType([PropTypes.object]),
    getElementAtEvent: PropTypes.func,
    getDatasetAtEvent: PropTypes.func
};

export default HorizontalBarChart;
