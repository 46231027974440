import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    getProspectData,
    getProspectQA,
    getProspectQuestionCount
} from 'erpcore/screens/Prospects/Prospects.selectors';
import Card from 'erpcore/components/Layout/Card';
import orderBy from 'lodash/orderBy';
import moment from 'moment-timezone';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Collapse from 'erpcore/components/Collapse';
import ProgressBar from 'erpcore/components/ProgressBar';
import uniqBy from 'lodash/uniqBy';
import Button from 'erpcore/components/Button';
import { hasPermission } from 'erpcore/utils/RolesManager';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const ProspectDealView = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const meData = useSelector(getMeData) || {};
    const prospectIri = `/api/prospects/${match?.params?.id}`;
    const dealIri = `/api/deals/${match?.params?.dealID}`;
    const prospectData = useSelector(state => getProspectData(state, prospectIri)) || {};
    const dealsData = useSelector(state => getProspectData(state, dealIri)) || {};
    const prospectQA = uniqBy(useSelector(state => getProspectQA(state, dealIri)) || [], e => {
        return e.question?.question;
    });
    const prospectQuestionCount = useSelector(state => getProspectQuestionCount(state)) || 0;

    const { sessions = [], unit_type: unitType, project, notes, prospects } = {
        ...dealsData
    };

    const { first_name: firstName, last_name: lastName } = {
        ...prospectData
    };

    const title = `Deal - ${dealsData?.project?.name || ''}: ${firstName} ${lastName}`;

    const fetchResourceData = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_SINGLE_PROSPECT,
                iri
            });
        }).catch(error => ({ error }));
    };

    const fetchProspectQAData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_PROSPECT_QA,
                prospectIri: false,
                dealIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchResourceData(prospectIri);
        fetchResourceData(dealIri).then(fetchProspectQAData());
    }, []);

    const sortSessions = () => {
        let upcoming = [];
        let past = [];
        sessions.map(item => {
            return item?.duration ? past.push(item) : upcoming.push(item);
        });
        past = orderBy(past, ['session.date'], ['desc']);
        upcoming = orderBy(upcoming, ['session.date'], ['desc']);

        return { upcoming, past };
    };
    const sortedSessions = sortSessions();

    const prepareNotesData = () => {
        const allNotes = [];
        if (notes) {
            notes.map(note => {
                return allNotes.push({ note });
            });
        }
        return uniqBy(allNotes, e => e?.note?.comment);
    };

    const dealNotes = () => {
        const allNotes = prepareNotesData();
        if (allNotes?.length > 0) {
            return allNotes.map(noteDetails => (
                <div
                    key={`note-${noteDetails?.note?.date || noteDetails?.session?.date}`}
                    className="session-view__note"
                >
                    <div className="session-view__note-info">
                        <span>
                            {noteDetails?.note.date
                                ? moment(noteDetails?.note?.date).format('MMM Do, YYYY, h:mm a')
                                : moment(noteDetails?.session?.date).format('MMM Do, YYYY, h:mm a')}
                        </span>
                    </div>
                    <div
                        className="session-view__note-comment"
                        dangerouslySetInnerHTML={{ __html: noteDetails?.note.comment }}
                    />
                </div>
            ));
        }
        return 'There are no notes currently.';
    };

    const renderInfoBox = () => (
        <Card>
            <div className="prospect-view__info-box">
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">Project</span>
                    <span className="prospect-view__data-body">{dealsData?.project?.name}</span>
                </div>
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">Unit</span>
                    <span className="prospect-view__data-body">{dealsData?.unit?.name || '-'}</span>
                </div>
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">Unit Type</span>
                    <span className="prospect-view__data-body">{unitType?.name || '-'}</span>
                </div>
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">Demo Sessions</span>
                    <span className="prospect-view__data-body">{sessions?.length}</span>
                </div>
            </div>
        </Card>
    );

    const renderSessionProspectInfo = () => (
        <Card>
            {prospects?.length > 0 && (
                <>
                    <Card.Title>Prospects</Card.Title>
                    <p>
                        {prospects?.map(prospect => {
                            const {
                                first_name: sessionProspectFirstName,
                                last_name: sessionProspectLastName
                            } = prospect;
                            return (
                                <React.Fragment key={`deal-participant-${prospect?.iri}`}>
                                    <span>
                                        {sessionProspectFirstName || ''}{' '}
                                        {sessionProspectLastName || ''}
                                    </span>
                                    <br />
                                </React.Fragment>
                            );
                        })}
                    </p>
                </>
            )}
            <Card.Title>Upcoming Sessions</Card.Title>
            {sortedSessions?.upcoming?.length > 0 ? (
                sortedSessions.upcoming.map(session => {
                    const { sales_agent: salesAgent = {} } = session;
                    const {
                        first_name: salesAgentFirstName = '',
                        last_name: salesAgentLastName = ''
                    } = salesAgent;
                    return (
                        <div
                            key={`upcoming-session-${session.id}`}
                            className="prospect-view__session"
                        >
                            {session?.date ? (
                                <Card.Title>
                                    {moment(session?.date).format('MMM Do, YYYY, h:mm a')}
                                </Card.Title>
                            ) : (
                                ''
                            )}
                            {salesAgentFirstName && salesAgentLastName && (
                                <div className="prospect-view__session-line">
                                    <div className="prospect-view__session-line-label">
                                        {`${salesAgentFirstName} ${salesAgentLastName}`}
                                    </div>
                                </div>
                            )}
                            <div className="prospect-view__session-line">
                                <div className="prospect-view__session-line-content">
                                    <Svg icon="building" /> <p>{`${project.name}`}</p>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <p>There are currently no scheduled sessions for this deal.</p>
            )}
            {sortedSessions?.past?.length > 0 && (
                <>
                    <Card.Title>History Sessions</Card.Title>
                    {sortedSessions.past.map(session => {
                        const {
                            sales_agent: salesAgent = {},
                            duration = 0,
                            nr_of_views: nrOfViews
                        } = session;
                        const {
                            first_name: salesAgentFirstName = '',
                            last_name: salesAgentLastName = ''
                        } = salesAgent;
                        return (
                            <div
                                key={`past-session-${session.id}`}
                                className="prospect-view__session"
                            >
                                <Card.Title>
                                    {moment(session?.date).format('MMM Do, YYYY, h:mm a') || ''}
                                </Card.Title>
                                <div className="prospect-view__session-line">
                                    <div className="prospect-view__session-line-label">
                                        {`${salesAgentFirstName} ${salesAgentLastName}`}
                                    </div>
                                    <span className="prospect-view__session-details">
                                        <span className="prospect-view__session-detail">
                                            <Tooltip content="Number of prospects on session">
                                                <Svg icon="profile" />1
                                            </Tooltip>
                                        </span>
                                        <span className="prospect-view__session-detail">
                                            <Tooltip content="Duration of session">
                                                <Svg icon="presentation" />
                                                {moment.utc(duration * 1000).format('HH[h] mm[m]')}
                                            </Tooltip>
                                        </span>
                                        <span className="prospect-view__session-detail">
                                            <Tooltip content="Number of times a prospect viewed post-demo preview">
                                                <Svg icon="passShow" />
                                                {nrOfViews || 0}
                                            </Tooltip>
                                        </span>
                                    </span>
                                </div>
                                <div className="prospect-view__session-line">
                                    <div
                                        key={`project-${project.iri}`}
                                        className="prospect-view__session-line-content"
                                    >
                                        <Svg icon="building" /> <p>{`${project.name}`}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
        </Card>
    );

    const renderSidebar = () => (
        <Card>
            <Collapse initiallyExpanded>
                <Collapse.Visible>
                    <Card.Title>Prospect Questions</Card.Title>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <div className="prospect-view__session-questions">
                        <span className="prospect-view__label">Prospect Questions Answered</span>
                        <span className="prospect-view__value">
                            <strong>
                                {prospectQA?.length}/{prospectQuestionCount}
                            </strong>
                        </span>
                        <ProgressBar
                            percentage={(100 * prospectQA?.length) / prospectQuestionCount}
                        ></ProgressBar>
                        {prospectQA.map(qa => (
                            <React.Fragment key={`qa-${qa.iri}`}>
                                <span className="session-view__label">
                                    {qa?.question?.question}
                                </span>
                                <div className="session-view__value">
                                    {qa?.answers?.map((answer, index) => (
                                        <span
                                            key={`answer-${index}`}
                                            className="session-view__value-chip"
                                        >
                                            {answer}
                                        </span>
                                    ))}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                </Collapse.Expandable>
            </Collapse>
            <Collapse initiallyExpanded>
                <Collapse.Visible>
                    <Card.Title>Notes</Card.Title>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <div className="prospect-view__notes">{dealNotes()}</div>
                </Collapse.Expandable>
            </Collapse>
        </Card>
    );

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button
                    size="small"
                    href={`/prospects/${prospectData?.id}/edit/deals`}
                    variation="tertiary"
                    label="View Prospect Deals"
                />
                {hasPermission('CAN_EDIT_COMMON_DEAL', meData) && (
                    <Button
                        size="small"
                        href={`/prospects/${prospectData?.id}/edit/deals/${dealsData?.id}/edit`}
                        label="Edit Deal"
                    />
                )}
            </PageHeader>
            <PageContent>
                <div className="prospect-view">
                    <div className="prospect-view__header">{renderInfoBox()}</div>
                    <div className="prospect-view__content">
                        <div className="prospect-view__main">{renderSessionProspectInfo()}</div>
                        <div className="prospect-view__sidebar">{renderSidebar()}</div>
                    </div>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

export default ProspectDealView;
