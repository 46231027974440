import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues, SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { withRouter } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as sessionActions } from 'erpcore/screens/Sessions/Sessions.reducer';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import Button from 'erpcore/components/Button';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import SessionEditForm from 'erpcore/screens/Sessions/components/SessionEditForm';
import SessionEditProspectsForm from 'erpcore/screens/Sessions/components/SessionEditProspectsForm';
import {
    getSessionsData,
    getSingleSessionFetching,
    getSessionProspects
} from 'erpcore/screens/Sessions/Sessions.selectors';

const SessionEdit = ({ match }) => {
    const dispatch = useDispatch();
    const sessionIri = `/api/sessions/${match?.params?.id}`;
    const sessionData = useSelector(state => getSessionsData(state, sessionIri)) || {};
    const fetching = useSelector(state => getSingleSessionFetching(state));
    const sessionProspects = useSelector(state => getSessionProspects(state, sessionIri)) || [];
    const formValues = useSelector(state => getFormValues('SessionEditForm')(state));
    const title = 'Edit Session';

    const {
        date,
        sales_agent: salesAgent,
        hash,
        deal,
        projects = [],
        send_invitation_to_prospects: sendInvitationToProspects,
        send_recap_to_prospects: sendRecapToProspects,
        recap_days_to_expiration: recapDaysToExpiration,
        timezone,
        raising_hands_enabled: raisingHandsEnabled,
        chat_enabled: chatEnabled,
        spectators_enabled: spectatorsEnabled,
        guest_participants_enabled: guestParticipantsEnabled,
        send_notification_to_non_attendees: sendNotificationToNonAttendees
    } = {
        ...sessionData
    };

    const prospectData = orderBy(sessionProspects, ['id'])?.map(sessionProspect => {
        const { prospect, recap_days_to_expiration: prospectRecapDaysToExpiration } = {
            ...sessionProspect
        };
        const { first_name: firstName, last_name: lastName } = { ...prospect };

        return {
            prospectIri: sessionProspect?.prospect?.iri,
            sessionProspectIri: sessionProspect?.iri,
            name: `${firstName || ''} ${lastName || ''}`,
            hash: sessionProspect?.hash,
            recap_days_to_expiration: prospectRecapDaysToExpiration || 7,
            recap_days_to_expiration_toggle: !!prospectRecapDaysToExpiration
        };
    });

    const initialValues = {
        date,
        sales_agent: salesAgent?.iri,
        projects: projects.map(proj => proj.iri),
        hash,
        deal: deal?.iri,
        send_invitation_to_prospects: sendInvitationToProspects,
        send_recap_to_prospects: sendRecapToProspects,
        recap_days_to_expiration: recapDaysToExpiration || 7,
        recap_days_to_expiration_toggle: !!recapDaysToExpiration,
        timezone: timezone ? timezone.iri : null,
        raising_hands_enabled: raisingHandsEnabled,
        chat_enabled: chatEnabled,
        spectators_enabled: spectatorsEnabled,
        guest_participants_enabled: guestParticipantsEnabled,
        send_notification_to_non_attendees: sendNotificationToNonAttendees
    };

    const sessionProspectsInitialValues = {
        session_prospects: prospectData
    };

    const projectsChanged =
        sessionData?.projects?.length !== formValues?.projects?.length ||
        !sessionData?.projects?.some(project =>
            formValues?.projects?.some(projectValue => project?.iri === projectValue)
        );

    const fetchSessionData = () => {
        const params = {
            include: 'sessionProspects,salesAgent,projects,sessionProspects.prospect,timezone,deal'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_FETCHING_SINGLE_SESSION,
                iri: sessionIri,
                params
            });
        }).catch(error => ({ error }));
    };

    const onSubmit = formData => {
        // send only changed data
        const diffFormData = diff(initialValues, formData);

        if (!isEqual(initialValues?.projects, formData?.projects)) {
            Object.assign(diffFormData, {
                projects: formData?.projects
            });
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_UPDATE_SINGLE_SESSION,
                iri: sessionIri,
                formData: diffFormData
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    const createSessionProspect = prospectIri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_CREATE_SESSION_PROSPECT,
                prospectIri,
                sessionIri
            });
        }).catch(err => err);
    };

    const updateSessionProspect = formData => {
        if (!formData.recap_days_to_expiration_toggle) {
            formData.recap_days_to_expiration = null;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_UPDATE_SESSION_PROSPECT,
                formData,
                sessionIri
            });
        }).catch(err => err);
    };

    const deleteSessionProspect = prospectIri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_DELETE_SESSION_PROSPECT,
                prospectIri
            });
        }).then(() => {
            fetchSessionData();
        });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchSessionData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button
                    href={`/sessions/${match?.params?.id}/view`}
                    variation="tertiary"
                    label="View Session Details"
                />
            </PageHeader>
            <PageContent>
                {fetching === true && <PageLoader content />}
                <SessionEditForm
                    onSubmit={onSubmit}
                    form="SessionEditForm"
                    initialValues={initialValues}
                    submitLabel="Update"
                />
                <br />
                {!projectsChanged ? (
                    <SessionEditProspectsForm
                        form="SessionEditProspectsForm"
                        sessionFormName="SessionEditForm"
                        initialValues={sessionProspectsInitialValues}
                        onCreateProspectSession={createSessionProspect}
                        onDeleteProspectSession={deleteSessionProspect}
                        onUpdateProspectSession={updateSessionProspect}
                        onFinish={fetchSessionData}
                    />
                ) : (
                    <p>
                        <strong>
                            Changes were made to Session Projects, please save the changes to be
                            able to add prospects.
                        </strong>
                    </p>
                )}
            </PageContent>
        </LayoutManager>
    );
};

SessionEdit.defaultProps = {
    match: {}
};

SessionEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(SessionEdit);
