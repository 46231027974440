import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as dealsActions } from 'erpcore/screens/Deals/Deals.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const defaultIncludes =
    'project,sessions,sessions.salesAgent,sessions.sessionProspects,notes.note,unit,unitType,prospects,prospects.user';

/**
 * Create Deal
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createDeal({ promise, formData }) {
    try {
        const createDealAPI = yield restClient.post('api/deals', formData);
        yield put({
            type: dealsActions.CREATE_DEAL_SUCCESFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createDealAPI?.data
        });
        yield call(promise.resolve, createDealAPI?.data);
    } catch (error) {
        yield put({
            type: dealsActions.CREATE_DEAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Deal
 * @param  {Object} promise
 * @return {string} id Deal id
 */
export function* fetchDeal({ promise, iri, params }) {
    try {
        const fetchDealAPI = yield restClient.get(iri, {
            params: {
                ...params,
                ...{ include: defaultIncludes }
            }
        });
        yield put({
            type: dealsActions.FETCHING_DEAL_SUCCESSFUL
        });

        yield put({
            type: dealsActions.STORE_DEAL_DATA,
            iri,
            response: dto(fetchDealAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dealsActions.FETCHING_DEAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Deal data
 * @param  {Object} iri id of Deal
 * @return {Object} Response from API
 */
export function* updateDeal({ promise, iri, formData }) {
    try {
        const updateDealAPI = yield restClient.patch(iri, formData);
        yield put({
            type: dealsActions.UPDATE_DEAL_SUCCESSFUL
        });
        yield put({
            type: dealsActions.STORE_DEAL_DATA,
            iri,
            response: dto(updateDealAPI?.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateDealAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dealsActions.UPDATE_DEAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Deal
 * @param  {Object} dealIri iri of Permission
 * @return {Object} Response from API
 */
export function* deleteDeal({ promise, iri }) {
    try {
        const deleteDealAPI = yield restClient.delete(iri);
        yield put({
            type: dealsActions.DELETE_DEAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteDealAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'deals' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'DEALS',
            name: 'deals',
            endpoint: 'api/deals?include=client'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dealsActions.DELETE_DEAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Session Prospects
 * @param {Object} promise
 * @param {String} Prospect Iri
 * @return {Object} response from API
 */
export function* fetchDealQA({ promise, dealIri }) {
    try {
        const params = {};
        params['filters[deal][equals]'] = dealIri;
        const fetchAPI = yield restClient.get(
            `/api/profile-answers?pagination=false&include=question,sessionProspect,sessionProspect.prospect&order_by[question.position]=asc`,
            {
                params
            }
        );
        const fetchAllQuestionsAPI = yield restClient.get(
            `/api/prospect-profile-questions?pagination=false`
        );
        const dtoFetchAPI = dto(fetchAPI?.data);
        yield put({
            type: dealsActions.FETCH_DEAL_QA_SUCCESSFUL,
            response: dtoFetchAPI.data,
            iri: dealIri
        });
        yield put({
            type: dealsActions.SET_DEAL_QUESTION_COUNT,
            response: fetchAllQuestionsAPI?.data?.data?.length || 0
        });
        yield call(promise.resolve, dtoFetchAPI);
    } catch (error) {
        yield put({
            type: dealsActions.FETCH_DEAL_QA_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Single Prospect Sessions
 * @param  {Object} Iri of Prospect
 * @return {Object} Response from API
 */
export function* fetchDealSessionData({ promise, iri, dealIris }) {
    try {
        const fetchProspectSessionDataAPI = yield restClient.get(`/api/sessions`, {
            params: {
                include: 'project,salesAgent,deal,deal.project',
                'filters[deal][equals]': dealIris
            }
        });
        yield put({
            type: dealsActions.FETCHING_DEAL_SESSION_SUCCESSFUL
        });
        yield put({
            type: dealsActions.STORE_DEAL_DATA,
            iri,
            response: { data: { sessions: dto(fetchProspectSessionDataAPI?.data)?.data } }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dealsActions.FETCHING_DEAL_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Deal
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createMessage({ promise, formData }) {
    try {
        const createDealAPI = yield restClient.post('api/messages', formData);
        yield put({
            type: dealsActions.SUBMIT_MESSAGE_SUCCESS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createDealAPI?.data
        });
        yield call(promise.resolve, createDealAPI?.data);
    } catch (error) {
        yield put({
            type: dealsActions.SUBMIT_MESSAGE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const dealsSaga = [
    takeLatest(dealsActions.START_CREATE_DEAL, createDeal),
    takeLatest(dealsActions.START_FETCHING_DEAL, fetchDeal),
    takeLatest(dealsActions.START_UPDATE_DEAL, updateDeal),
    takeLatest(dealsActions.START_DELETE_DEAL, deleteDeal),
    takeLatest(dealsActions.START_DELETE_DEAL, deleteDeal),
    takeLatest(dealsActions.START_FETCHING_DEAL_QA, fetchDealQA),
    takeLatest(dealsActions.START_FETCHING_DEAL_SESSION, fetchDealSessionData),
    takeLatest(dealsActions.START_SUBMIT_MESSAGE, createMessage)
];

export default dealsSaga;
