import React from 'react';
import { Field } from 'redux-form';
import Form, { Text, Select } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { buttonSize } from 'erpcore/screens/Projects/data/projectsData';

const CTARepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.link`}
                    fieldProps={{ label: 'CTA link', clearable: true }}
                    fieldAttr={{ id: `${member}.link` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.label`}
                    fieldProps={{ label: 'CTA label', clearable: true }}
                    fieldAttr={{ id: `${member}.label` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.size`}
                    fieldProps={{ label: 'CTA size', clearable: true, options: buttonSize }}
                    fieldAttr={{ id: `${member}.size` }}
                    component={Select}
                />
            </Form.Row>
        </div>
    );
};

CTARepeaterItem.defaultProps = {
    member: null
};

CTARepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default CTARepeaterItem;
