import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as sessionActions } from './Sessions.reducer';

/**
 * Delete Session Prospect
 * @param  {Object} promise
 * @param  {Object} prospectIri
 * @return {Object} response from API
 */

export function* deleteSessionProspect({ promise, prospectIri }) {
    try {
        const deleteSessionProspectAPI = yield restClient.delete(prospectIri);

        yield put({
            type: sessionActions.DELETE_SESSION_PROSPECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSessionProspectAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.DELETE_SESSION_PROSPECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Session Prospect
 * @param  {Object} promise
 * @param  {Object} formData
 * @return {Object} response from API
 */

export function* createSessionProspect({ promise, sessionIri, prospectIri }) {
    try {
        const createSessionAPI = yield restClient.post(`/api/session-prospects`, {
            prospect: prospectIri,
            session: sessionIri
        });

        yield put({
            type: sessionActions.CREATE_SESSION_PROSPECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createSessionAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.CREATE_SESSION_PROSPECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Session Prospect
 * @param  {Object} promise
 * @param  {Object} formData
 * @return {Object} response from API
 */

export function* updateSessionProspect({ promise, formData }) {
    try {
        const createSessionAPI = yield restClient.patch(formData?.sessionProspectIri, {
            ...formData
        });

        yield put({
            type: sessionActions.UPDATE_SESSION_PROSPECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createSessionAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.UPDATE_SESSION_PROSPECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Session
 * @param  {Object} promise
 * @param  {Object} formData
 * @return {Object} response from API
 */

export function* createSession({ promise, formData }) {
    try {
        const { prospects } = { ...formData };
        const createSessionAPI = yield restClient.post(`/api/sessions`, formData);

        yield put({
            type: sessionActions.CREATE_SESSION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createSessionAPI?.data
        });
        yield call(promise.resolve, {
            sessionIri: createSessionAPI?.data?.data?.id,
            prospects
        });
    } catch (error) {
        yield put({
            type: sessionActions.CREATE_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Session
 * @param {Object} promise
 * @param {String} Session Iri
 * @return {Object} response from API
 */
export function* fetchSession({ promise, iri, params }) {
    try {
        const fetchSessionAPI = yield restClient.get(iri, { params });
        yield put({
            type: sessionActions.FETCH_SINGLE_SESSION_SUCCESSFUL
        });
        yield put({
            type: sessionActions.STORE_SINGLE_SESSION_DATA,
            iri,
            response: dto(fetchSessionAPI?.data)
        });
        yield call(promise.resolve, dto(fetchSessionAPI?.data));
    } catch (error) {
        yield put({
            type: sessionActions.FETCH_SINGLE_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Session Prospects
 * @param {Object} promise
 * @param {String} Session Iri
 * @return {Object} response from API
 */
export function* fetchSessionProspects({ promise, iri, params = null }) {
    try {
        // TODO implement correct enpoint for session-prospects filtered by session
        const fetchSessionProspectsAPI = yield restClient.get(
            `/api/session-prospects?include=prospect&filters[session][equals]=${iri}`,
            { params }
        );
        const dtoFetchSessionProspectsAPI = yield dto(fetchSessionProspectsAPI?.data);
        yield put({
            type: sessionActions.FETCH_SINGLE_SESSION_PROSPECTS_SUCCESSFUL
        });
        yield put({
            type: sessionActions.STORE_SINGLE_SESSION_PROSPECTS_DATA,
            iri,
            // eslint-disable-next-line camelcase
            response: dtoFetchSessionProspectsAPI?.data
        });
        yield call(promise.resolve, dtoFetchSessionProspectsAPI?.data);
    } catch (error) {
        yield put({
            type: sessionActions.FETCH_SINGLE_SESSION_PROSPECTS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Session single data
 * @param  {Object} Session Iri
 * @return {Object} Response from API
 */
export function* updateSingleSession({ promise, formData, iri }) {
    try {
        delete formData?.['session_prospects'];
        const updateSingleSessionAPI = yield restClient.put(`${iri}`, formData);
        yield put({
            type: sessionActions.UPDATE_SINGLE_SESSION_SUCCESSFUL
        });
        yield put({
            type: sessionActions.START_FETCHING_SINGLE_SESSION,
            iri,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleSessionAPI?.data
        });

        yield call(promise.resolve, updateSingleSessionAPI?.data);
    } catch (error) {
        yield put({
            type: sessionActions.UPDATE_SINGLE_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Single Session
 * @param  {Object} iri of Session
 * @return {Object} Response from API
 */
export function* deleteSingleSession({ promise, iri }) {
    try {
        const deleteSingleSessionAPI = yield restClient.delete(iri);
        yield put({
            type: sessionActions.DELETE_SINGLE_SESSION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleSessionAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'sessions' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'SESSIONS',
            name: 'sessions',
            endpoint: `api/sessions?include=prospects,salesAgent,projects,prospects.prospect`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.DELETE_SINGLE_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* resendSessionInvitation({ promise, iri, all }) {
    try {
        const resendSessionInvitationAPI = yield restClient.post(
            `${iri}/resend-invitation${all ? 's' : ''}`
        );
        yield put({
            type: sessionActions.RESEND_SESSION_INVITATION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: resendSessionInvitationAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.RESEND_SESSION_INVITATION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* resendSessionPostDemoMail({ promise, iri, all }) {
    try {
        const resendSessionPostDemoMailAPI = yield restClient.post(
            `${iri}/resend-recap${all ? 's' : ''}`
        );
        yield put({
            type: sessionActions.RESEND_SESSION_POST_DEMO_MAIL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: resendSessionPostDemoMailAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.RESEND_SESSION_POST_DEMO_MAIL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* resendSessionNonAttandeeMail({ promise, iri }) {
    try {
        const resendSessionNonAttandeeMailAPI = yield restClient.post(
            `${iri}/send-non-attendance-notification`
        );
        yield put({
            type: sessionActions.RESEND_SESSION_POST_DEMO_MAIL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: resendSessionNonAttandeeMailAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.RESEND_SESSION_POST_DEMO_MAIL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Session Prospects
 * @param {Object} promise
 * @param {String} Prospect Iri
 * @return {Object} response from API
 */
export function* fetchSessionProspectQA({ promise, prospectIri, sessionIri }) {
    try {
        const fetchAPI = yield restClient.get(
            `/api/profile-answers?pagination=false&include=question,sessionProspect,sessionProspect.prospect&order_by[question.position]=asc`,
            {
                params: {
                    'filters[session_prospect.prospect][equals]': prospectIri
                }
            }
        );
        const fetchAllQuestionsAPI = yield restClient.get(
            `/api/prospect-profile-questions?pagination=false`
        );
        const dtoFetchAPI = dto(fetchAPI?.data);
        // filtering manually because at the time of writing this code filtering by prospect didn't work on backend
        const filteredAnswers = (Array.isArray(dtoFetchAPI?.data) ? dtoFetchAPI?.data : []).filter(
            item => {
                const { session_prospect: sessionProspect } = { ...item };
                return sessionProspect?.prospect?.iri === prospectIri;
            }
        );
        const filteredResponse = { ...dtoFetchAPI, data: filteredAnswers };
        yield put({
            type: sessionActions.FETCH_SESSION_PROSPECT_QA_SUCCESSFUL,
            response: filteredResponse.data,
            iri: sessionIri
        });
        yield put({
            type: sessionActions.SET_SESSION_PROSPECT_QUESTION_COUNT,
            response: fetchAllQuestionsAPI?.data?.data?.length || 0
        });
        yield call(promise.resolve, filteredResponse);
    } catch (error) {
        yield put({
            type: sessionActions.FETCH_SESSION_PROSPECT_QA_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Get all session chat messages
 * @param {Object} promise
 * @return {Object} sessionIri
 */
export function* fetchSessionChat({ promise, sessionIri }) {
    try {
        const fetchAPI = yield restClient.get(
            `/api/chat-messages?filters[session][equals]=${sessionIri}&pagination=false`
        );
        yield put({
            type: sessionActions.FETCH_SESSION_CHAT_SUCCESSFUL,
            response: dto(fetchAPI?.data),
            iri: sessionIri
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.FETCH_SESSION_CHAT_FAILED
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const sessionsSaga = [
    takeLatest(sessionActions.START_CREATE_SESSION, createSession),
    takeLatest(sessionActions.START_FETCHING_SINGLE_SESSION, fetchSession),
    takeLatest(sessionActions.START_FETCHING_SINGLE_SESSION_PROSPECTS, fetchSessionProspects),
    takeEvery(sessionActions.START_UPDATE_SESSION_PROSPECT, updateSessionProspect),
    takeLatest(sessionActions.START_UPDATE_SINGLE_SESSION, updateSingleSession),
    takeLatest(sessionActions.START_DELETE_SINGLE_SESSION, deleteSingleSession),
    takeLatest(sessionActions.START_CREATE_SESSION_PROSPECT, createSessionProspect),
    takeLatest(sessionActions.START_DELETE_SESSION_PROSPECT, deleteSessionProspect),
    takeLatest(sessionActions.START_RESEND_SESSION_INVITATION, resendSessionInvitation),
    takeLatest(sessionActions.START_RESEND_SESSION_POST_DEMO_MAIL, resendSessionPostDemoMail),
    takeLatest(sessionActions.START_RESEND_SESSION_NON_ATTENDEE_MAIL, resendSessionNonAttandeeMail),
    takeLatest(sessionActions.START_FETCHING_SESSION_PROSPECT_QA, fetchSessionProspectQA),
    takeLatest(sessionActions.START_FETCHING_SESSION_CHAT, fetchSessionChat)
];
