import React from 'react';
import Form, { Repeater } from 'erpcore/components/Form';
import { FieldArray, reduxForm } from 'redux-form';
import FloorSegmentsRepeatableItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/FloorSegmentsRepeatableItem';
import PropTypes from 'prop-types';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';
import { diff } from 'deep-object-diff';
import { useDispatch } from 'react-redux';

const FloorSegments = ({ onFinish, initialValues, shouldBuildingDisplaySegments, buildingIri }) => {
    const dispatch = useDispatch();

    /**
     * Creates Floor Segment
     * @param formValues {Object}
     * @return {Promise}
     */
    const onCreateFloorSegment = formData => {
        const { level } = { ...formData };
        const { range_start: rangeStart, range_end: rangeEnd } = { ...level };
        formData = {
            ...formData,
            building: buildingIri,
            level_to: rangeEnd || rangeStart,
            level_from: rangeStart || rangeEnd
        };

        if (level) {
            delete formData.level;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_CREATE_PROJECT_BUILDING_LEVEL,
                promise: { resolve, reject },
                formData
            });
        });
    };

    /**
     * Updates Floor Segment
     * @param formValues {Object}
     * @return {Promise}
     */
    const onUpdateFloorSegment = data => {
        const levelInitialValue = initialValues?.levels?.find(level => level?.iri === data.iri);
        let formData = { ...data };

        if (levelInitialValue) {
            const formDiff = diff(levelInitialValue, formData);

            if (Object.keys(formDiff)?.length > 0) {
                const { level } = { ...formData };
                const { range_start: rangeStart, range_end: rangeEnd } = { ...level };
                formData = {
                    ...formDiff,
                    level_to: rangeEnd,
                    level_from: rangeStart
                };

                if (level) {
                    delete formData.level;
                }

                return new Promise((resolve, reject) => {
                    dispatch({
                        type: projectActions.START_UPDATE_PROJECT_BUILDING_LEVEL,
                        promise: { resolve, reject },
                        formData,
                        iri: data?.iri
                    });
                });
            }
        }

        return false;
    };

    /**
     * Removes Floor Segment
     * @param formValues {Object}
     * @return {Promise}
     */
    const onRemoveFloorSegment = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_DELETE_PROJECT_BUILDING_LEVEL,
                promise: { resolve, reject },
                iri: formData?.iri
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => {
                onFinish();
            });
    };

    /**
     * Promise.all resolver
     * @param oldItems {Array}
     * @param oldItemDiff {Object}
     * @param newItems {Array}
     * @return {Promise}
     */
    const addUpdateItems = (oldItems, oldItemsDiff, newItems) => {
        const promises = [];

        if (Object.keys(oldItemsDiff)?.length > 0) {
            Object.keys(oldItemsDiff).map(oldItem => {
                return promises.push(onUpdateFloorSegment(oldItems[oldItem]));
            });
        }

        if (newItems?.length > 0) {
            newItems.map(item => promises.push(onCreateFloorSegment(item)));
        }

        return Promise.all([promises]);
    };

    if (!shouldBuildingDisplaySegments) {
        return (
            <Form.Row>
                <Form.SectionTitleSmall>Floor segments</Form.SectionTitleSmall>

                <FieldArray
                    name="levels"
                    component={Repeater}
                    RepeatableItem={FloorSegmentsRepeatableItem}
                    canSort={false}
                    canSortNewItems={false}
                    saveButtonIntent="all"
                    onSaveAll={({ oldItems, oldItemsDiff, newItems }) =>
                        addUpdateItems(oldItems, oldItemsDiff, newItems).finally(() => {
                            onFinish();
                        })
                    }
                    onRemoveItem={({ itemData }) => onRemoveFloorSegment(itemData)}
                    uniqueIdentifier="customIdentifier"
                    addNewLabel="Add floor segment"
                />
            </Form.Row>
        );
    }

    return false;
};

FloorSegments.defaultProps = {
    onFinish: () => {},
    initialValues: {},
    shouldBuildingDisplaySegments: false,
    buildingIri: null
};

FloorSegments.propTypes = {
    onFinish: PropTypes.func,
    initialValues: PropTypes.oneOf([PropTypes.object]),
    shouldBuildingDisplaySegments: PropTypes.bool,
    buildingIri: PropTypes.string
};

export default reduxForm({
    form: 'FloorSegmentsForm',
    enableReinitialize: true
})(FloorSegments);
