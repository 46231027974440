import React, { useCallback } from 'react';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import Button from 'erpcore/components/Button';
import Form, {
    Text,
    Textarea,
    Select,
    ColorPicker,
    SwitchCard,
    Checkbox,
    Switch
} from 'erpcore/components/Form';

const Divider = () => {
    return (
        <span
            style={{
                display: 'block',
                width: '100%',
                height: '1px',
                margin: '30px 0',
                background: '#e5e5e5'
            }}
        />
    );
};

const ConnectForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, initialValues }) => {
    const dispatch = useDispatch();

    const copyCode = useCallback(() => {
        const { generatedCode } = initialValues;
        copy(generatedCode);
        dispatch({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'project.mlaConnectWidgetCodeCopySuccessfull' }
        });
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <br />
            <Form.Row>
                <Field
                    name="enable_connect_widget"
                    fieldProps={{
                        label: 'Enable MLA Connect Widget',
                        clearable: true,
                        off: {
                            value: false
                        },
                        on: {
                            value: true
                        }
                    }}
                    fieldAttr={{ id: 'enable_connect_widget' }}
                    component={Switch}
                />
            </Form.Row>
            <h4>Book a sales gallery visit</h4>
            <Form.Row>
                <Field
                    name="book_a_presentation_centre_visit_url"
                    fieldProps={{
                        label: 'URL',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'book_a_presentation_centre_visit_url' }}
                    component={Text}
                />
                <Field
                    name="book_a_presentation_centre_visit_label"
                    fieldProps={{
                        label: 'Custom Label',
                        helperText: (
                            <>
                                <strong>Default:</strong> Book a sales gallery visit
                            </>
                        ),
                        clearable: true
                    }}
                    fieldAttr={{ id: 'book_a_presentation_centre_visit_label' }}
                    component={Text}
                />
                <Field
                    name="book_a_presentation_centre_visit_new_tab"
                    fieldProps={{
                        label: 'Open in a new tab',
                        clearable: true,
                        value: true
                    }}
                    fieldAttr={{ id: 'book_a_presentation_centre_visit_new_tab' }}
                    component={Checkbox}
                />
            </Form.Row>
            <Divider />
            <h4>Secure your spot</h4>
            <Form.Row>
                <Field
                    name="secure_your_spot_calendar_cta"
                    fieldProps={{
                        label: 'URL',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'secure_your_spot_calendar_cta' }}
                    component={Text}
                />
                <Field
                    name="secure_your_spot_calendar_label"
                    fieldProps={{
                        label: 'Custom Label',
                        helperText: (
                            <>
                                <strong>Default:</strong> Secure your spot
                            </>
                        ),
                        clearable: true
                    }}
                    fieldAttr={{ id: 'secure_your_spot_calendar_label' }}
                    component={Text}
                />
                <Field
                    name="secure_your_spot_calendar_cta_new_tab"
                    fieldProps={{
                        label: 'Open in a new tab',
                        clearable: true,
                        value: true
                    }}
                    fieldAttr={{ id: 'secure_your_spot_calendar_cta_new_tab' }}
                    component={Checkbox}
                />
            </Form.Row>
            <Divider />
            <h4>Book a virtual tour</h4>
            <Form.Row>
                <Field
                    name="book_a_virtual_tour_url"
                    fieldProps={{
                        label: 'URL',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'book_a_virtual_tour_url' }}
                    component={Text}
                />
                <Field
                    name="book_a_virtual_tour_label"
                    fieldProps={{
                        label: 'Custom Label',
                        helperText: (
                            <>
                                <strong>Default:</strong> Book a virtual tour
                            </>
                        ),
                        clearable: true
                    }}
                    fieldAttr={{ id: 'book_a_virtual_tour_label' }}
                    component={Text}
                />
                <Field
                    name="book_a_virtual_tour_new_tab"
                    fieldProps={{
                        label: 'Open in a new tab',
                        clearable: true,
                        value: true
                    }}
                    fieldAttr={{ id: 'book_a_virtual_tour_new_tab' }}
                    component={Checkbox}
                />
            </Form.Row>
            <Divider />
            <h4>Schedule a call</h4>
            <Form.Row>
                <Field
                    name="schedule_a_call_url"
                    fieldProps={{
                        label: 'URL',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'schedule_a_call_url' }}
                    component={Text}
                />
                <Field
                    name="schedule_a_call_label"
                    fieldProps={{
                        label: 'Custom Label',
                        helperText: (
                            <>
                                <strong>Default:</strong> Schedule a call
                            </>
                        ),
                        clearable: true
                    }}
                    fieldAttr={{ id: 'schedule_a_call_label' }}
                    component={Text}
                />
                <Field
                    name="schedule_a_call_new_tab"
                    fieldProps={{
                        label: 'Open in a new tab',
                        clearable: true,
                        value: true
                    }}
                    fieldAttr={{ id: 'schedule_a_call_new_tab' }}
                    component={Checkbox}
                />
            </Form.Row>
            <Divider />
            <h4>Register for more information</h4>
            <Form.Row>
                <Field
                    name="register_for_more_information_url"
                    fieldProps={{
                        label: 'URL',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'register_for_more_information_url' }}
                    component={Text}
                />
                <Field
                    name="register_for_more_information_label"
                    fieldProps={{
                        label: 'Custom Label',
                        helperText: (
                            <>
                                <strong>Default:</strong> Register for more information
                            </>
                        ),
                        clearable: true
                    }}
                    fieldAttr={{ id: 'register_for_more_information_label' }}
                    component={Text}
                />
                <Field
                    name="register_for_more_information_tab"
                    fieldProps={{
                        label: 'Open in a new tab',
                        clearable: true,
                        value: true
                    }}
                    fieldAttr={{ id: 'register_for_more_information_tab' }}
                    component={Checkbox}
                />
            </Form.Row>
            <Divider />
            <h4>Book a Private Appointment</h4>
            <Form.Row>
                <Field
                    name="book_a_private_appointment"
                    fieldProps={{
                        label: 'URL',
                        helperText: (
                            <>
                                Email URL must start with{' '}
                                <code
                                    style={{
                                        padding: '2px',
                                        background: '#f2f2f2',
                                        fontSize: 'inherit',
                                        lineHeight: 'inherit'
                                    }}
                                >
                                    &nbsp;mailto:&nbsp;
                                </code>
                            </>
                        ),
                        clearable: true
                    }}
                    fieldAttr={{ id: 'book_a_private_appointment' }}
                    component={Text}
                />
                <Field
                    name="book_a_private_appointment_label"
                    fieldProps={{
                        label: 'Custom Label',
                        helperText: (
                            <>
                                <strong>Default:</strong> Book a Private Appointment
                            </>
                        ),
                        clearable: true
                    }}
                    fieldAttr={{ id: 'book_a_private_appointment_label' }}
                    component={Text}
                />
                <Field
                    name="book_a_private_appointment_tab"
                    fieldProps={{
                        label: 'Open in a new tab',
                        clearable: true,
                        value: true
                    }}
                    fieldAttr={{ id: 'book_a_private_appointment_tab' }}
                    component={Checkbox}
                />
            </Form.Row>
            <br />
            <br />
            <Form.Row>
                <Field
                    name="enable_hubspot_live_chat"
                    fieldProps={{
                        label: 'Enable HubSpot Live chat',
                        clearable: true,
                        onValue: true,
                        offValue: false,
                        description: (
                            <>
                                HubSpot chat widget needs to be set up within HubSpot and HubSpot
                                tracking code needs to be added to the website, for the chat widget
                                to appear.
                                <br />
                                <br />
                                <b>Steps:</b>
                                <br />
                                <br />
                                <ol>
                                    <li>
                                        Create and set up HubSpot chat:&nbsp;
                                        <a
                                            href="https://knowledge.hubspot.com/chatflows/create-a-live-chat"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://knowledge.hubspot.com/chatflows/create-a-live-chat
                                        </a>
                                    </li>
                                    <li>
                                        Install the HubSpot tracking code on the website:&nbsp;
                                        <a
                                            href="https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code
                                        </a>
                                    </li>
                                </ol>
                            </>
                        )
                    }}
                    fieldAttr={{ id: 'enable_hubspot_live_chat' }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="style"
                    fieldProps={{
                        label: 'Choose Style',
                        clearable: true,
                        options: [
                            {
                                value: 'normal',
                                label: 'Normal'
                            },
                            {
                                value: 'inverted',
                                label: 'Inverted'
                            }
                        ]
                    }}
                    fieldAttr={{ id: 'style' }}
                    component={Select}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="color"
                    fieldProps={{
                        label: 'Choose color',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'color' }}
                    component={ColorPicker}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Widget code</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="generatedCode"
                    fieldProps={{
                        label: '',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'generatedCode', disabled: true }}
                    component={Textarea}
                />
            </Form.Row>
            <Form.Row>
                <Button label="Copy Code" size="small" variation="secondary" onClick={copyCode} />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Update" />
            </Form.Row>
        </Form>
    );
};

ConnectForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    initialValues: {}
};

ConnectForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

export default reduxForm({
    form: 'ConnectProjectForm',
    enableReinitialize: true
})(ConnectForm);
