import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';

import Form, { Autocomplete, Repeater, Select, Text } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const FeaturedProjectRI = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.project`}
                    fieldProps={{
                        label: 'Project',
                        clearable: true,
                        options: {
                            endpoint:
                                '/api/projects?filters[mla_blue][equals]=true&filters[is_active][equals]=true',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'project' }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.tile_size`}
                    fieldProps={{
                        label: 'Tile Size',
                        clearable: true,
                        options: [
                            {
                                value: 'n',
                                label: 'normal'
                            },
                            {
                                value: 'l',
                                label: 'large'
                            },
                            {
                                value: 'xl',
                                label: 'extra large'
                            },
                            {
                                value: 'w',
                                label: 'wide'
                            }
                        ]
                    }}
                    fieldAttr={{ id: 'project' }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.video_url`}
                    fieldProps={{
                        label: 'Video Url',
                        clearable: true,
                        helperText:
                            'Please provide Youtube URL or Vimeo embed url for video modal preview'
                    }}
                    fieldAttr={{ id: `${member}.video_url` }}
                    component={Text}
                />
            </Form.Row>
        </div>
    );
};

FeaturedProjectRI.defaultProps = {
    member: null
};

FeaturedProjectRI.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const BlueSettingsForm = ({ handleSubmit, onSubmit, onCreate, onUpdate, onRemove, onFinish }) => {
    const addUpdateItems = (oldItems, oldItemsDiff, newItems) => {
        const promises = [];
        let lastPosition = 0;

        if (Object.keys(oldItemsDiff)?.length > 0) {
            oldItems.map((item, index) => {
                item.position = index;
                return promises.push(onUpdate(item));
            });
        }
        oldItems.map(item => {
            if (item?.position > lastPosition) lastPosition = item?.position;
            return true;
        });

        if (newItems?.length > 0) {
            newItems.map(item => {
                item.position = lastPosition + 1;
                lastPosition += 1;
                return promises.push(onCreate(item));
            });
        }
        return Promise.all([promises]);
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <FieldArray
                        name="featured_projects"
                        component={Repeater}
                        RepeatableItem={FeaturedProjectRI}
                        canSort
                        canSortNewItems
                        canAddMultiple
                        saveButtonIntent="all"
                        canSave
                        data={{ onFinish }}
                        onSaveAll={({ oldItems, oldItemsDiff, newItems }) =>
                            addUpdateItems(oldItems, oldItemsDiff, newItems).finally(() => {
                                onFinish();
                            })
                        }
                        onRemoveItem={({ itemData }) => onRemove(itemData)}
                        uniqueIdentifier="iri"
                        addNewLabel="Add Featured Project"
                    />
                </Form.Row>
            </Form>
        </>
    );
};

BlueSettingsForm.defaultProps = {
    onSubmit: () => {},
    onCreate: () => {},
    onRemove: () => {},
    onFinish: () => {},
    onUpdate: () => {}
};

BlueSettingsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    onFinish: PropTypes.func,
    onUpdate: PropTypes.func
};

export default reduxForm({
    form: 'BlueSettingsForm',
    enableReinitialize: true
})(BlueSettingsForm);
