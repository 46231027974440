import React from 'react';
import PropTypes from 'prop-types';
import Form, { Text, Location, Phone, Email, Media, TextEditor } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { Field, reduxForm } from 'redux-form';
import Button from 'erpcore/components/Button';
import AppStatuses from 'erpcore/components/AppStatuses';

const ClientCreateEditForm = ({
    handleSubmit,
    onSubmit,
    pristine,
    invalid,
    submitting,
    submitLabel,
    onCancel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    id="name"
                    fieldProps={{
                        label: 'Client name',
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="location"
                    id="location"
                    component={Location}
                    fieldProps={{
                        locationManualTogglelabel: 'Input address manually'
                    }}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="phone"
                    id="phone"
                    fieldProps={{
                        label: 'Phone'
                    }}
                    component={Phone}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="email"
                    id="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    component={Email}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="website"
                    id="website"
                    fieldProps={{
                        label: 'Website',
                        clearable: true
                    }}
                    component={Text}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Logo</Form.SectionTitleSmall>
            <Form.Column>
                <Field
                    name="logo_image"
                    id="logo_image"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
                <AppStatuses mlaLive mlaBlue />
            </Form.Column>
            <Form.Row>
                <Field
                    name="description"
                    fieldProps={{ label: 'About' }}
                    fieldAttr={{ id: 'description' }}
                    editorProps={{
                        toolbar: {
                            options: ['inline', 'list'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic', 'underline']
                            }
                        }
                    }}
                    component={TextEditor}
                />
            </Form.Row>
            <Button
                onClick={handleSubmit(onSubmit)}
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
            {onCancel && <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />}
        </Form>
    );
};

ClientCreateEditForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: null,
    onCancel: null
};

ClientCreateEditForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string,
    onCancel: PropTypes.func
};

export default reduxForm({
    form: 'ClientCreateEditForm',
    enableReinitialize: true
})(ClientCreateEditForm);
