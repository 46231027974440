import React from 'react';
import { Field } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';

const PurchaseCalculatorsRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Form.GridColumn size={4}>
                    <Field
                        name={`${member}.title`}
                        fieldProps={{
                            label: 'Title',
                            clearable: true
                        }}
                        fieldAttr={{ id: `${member}.title` }}
                        component={Text}
                    />
                </Form.GridColumn>
                <Form.GridColumn size={8}>
                    <Field
                        name={`${member}.link`}
                        fieldProps={{ label: 'Calculator URL' }}
                        fieldAttr={{ id: `${member}.link` }}
                        component={Text}
                    />
                </Form.GridColumn>
            </Form.Row>
        </div>
    );
};

PurchaseCalculatorsRepeaterItem.defaultProps = {
    member: null
};

PurchaseCalculatorsRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default PurchaseCalculatorsRepeaterItem;
