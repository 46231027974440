import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import Button from 'erpcore/components/Button';
import {
    getSingleProspectFetching,
    getProspectData
} from 'erpcore/screens/Prospects/Prospects.selectors';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import ProspectsTabs from 'erpcore/screens/Prospects/components/ProspectsTabs';
import Listing from 'erpcore/components/Listing';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import { hasPermission } from 'erpcore/utils/RolesManager';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const ProspectDeals = ({ match }) => {
    const dispatch = useDispatch();
    const prospectIri = `/api/prospects/${match?.params?.id}`;
    const actionName = 'PROSPECT_DEALS';
    const reducerName = 'prospects';
    const listing = useSelector(state => getListingResponse(state, 'prospects'));
    const listingFetching = useSelector(state => getListingFetching(state, 'prospects'));
    const prospectData = useSelector(state => getProspectData(state, prospectIri)) || {};
    const fetching = useSelector(state => getSingleProspectFetching(state));
    const meData = useSelector(getMeData) || {};

    const { first_name: firstName, last_name: lastName } = {
        ...prospectData
    };

    const pageTitle = () => {
        if (firstName || lastName) {
            return `Edit prospect - ${firstName} ${lastName}`;
        }

        return `Edit prospect`;
    };

    const fetchProspectDeals = params => {
        params = {
            ...params,
            'filters[prospects][equals]': prospectIri,
            include: 'unit,unitType,project,prospects',
            'order_by[created_by]': 'DESC'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: listingActions.START_FETCHING_LISTING,
                promise: { resolve, reject },
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/deals'
            });
        });
    };

    const fetchProspectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_SINGLE_PROSPECT,
                iri: prospectIri
            });
        }).catch(error => ({ error }));
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Filters
        table.filters = [
            {
                name: 'project',
                label: 'Project',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Select Project',
                            options: {
                                endpoint: `api/projects`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        // Table Schema
        table.schema = [
            {
                title: 'Deal Name',
                field: 'name'
            },
            {
                title: 'Prospects',
                field: 'prospects'
            },
            {
                title: 'Project',
                field: 'project'
            },
            {
                title: 'Unit',
                field: 'unit'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];
        // Table Data
        if (listing?.data?.length) {
            // eslint-disable-next-line no-unused-expressions
            listing?.data?.map(row => {
                let prospects = 'No prospects';
                if (row?.prospects?.length === 1) {
                    // eslint-disable-next-line camelcase
                    prospects = `${row?.prospects[0]?.first_name} ${row?.prospects[0]?.last_name}`;
                } else if (row?.prospects?.length > 1) {
                    prospects = 'Multiple prospects';
                }

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    name: row?.name || 'Unnamed Deal',
                    prospects,
                    project: row?.project?.name || 'No Project',
                    unit: row?.unit?.name || '-',
                    // eslint-disable-next-line camelcase
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="View Deal">
                                    <NavLink
                                        to={`/prospects/${prospectData?.id}/edit/deals/${row?.id}`}
                                    >
                                        <Svg icon="passShow" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            {hasPermission('CAN_EDIT_COMMON_DEAL', meData) && (
                                <TableActions.Action>
                                    <Tooltip content="Edit Deal">
                                        <NavLink
                                            to={`/prospects/${prospectData?.id}/edit/deals/${row?.id}/edit`}
                                        >
                                            <Svg icon="edit" />
                                        </NavLink>
                                    </Tooltip>
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };
    /*
     * Effects
     */
    useEffect(() => {
        dispatch({ type: prospectActions.CLEAR_LISTING });
        fetchProspectData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                {hasPermission('CAN_EDIT_COMMON_DEAL', meData) && (
                    <Button
                        label="New Deal"
                        variation="primary"
                        href={`/prospects/${prospectData?.id}/edit/deals/create`}
                        size="small"
                    />
                )}
            </PageHeader>
            <PageContent>
                <ProspectsTabs />
                {fetching === true ? (
                    <PageLoader content />
                ) : (
                    <Listing
                        name={reducerName}
                        reducerName={reducerName}
                        loading={listingFetching}
                        meta={listing?.meta}
                        table={tableData()}
                        onListingConfigUpdate={params => fetchProspectDeals(params)}
                    />
                )}
            </PageContent>
        </LayoutManager>
    );
};

ProspectDeals.defaultProps = {
    match: {}
};

ProspectDeals.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProspectDeals);
