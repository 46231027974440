import React, { Component } from 'react';
import get from 'lodash/get';
import unionWith from 'lodash/unionWith';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { getOptionByValue } from 'erpcore/components/Form/Form.utils';
import restClient from 'erpcore/api/restClient';
import dto, { getIdFromIri } from 'erpcore/utils/dto';
import Button from 'erpcore/components/Button';
import styles from '../Select/Select.styles';
import Input from '../Input';
import '../Select/Select.scss';
import { DropdownIndicator, ClearIndicator, selectPropsMapper } from '../Select';

class Autocomplete extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            labelActive: false,
            initialized: false,
            options: []
        };
    }

    componentDidUpdate(prevProps, state) {
        const { initialized } = state;
        const { input, fieldProps } = this.props;
        const { input: prevInput } = prevProps;
        if (
            !initialized &&
            input.value.includes(fieldProps.options.endpoint) &&
            input.value !== prevInput.value &&
            input.value
        ) {
            input.onChange(input.value);
            this.loadOptions();
            this.setInitialized(true);
        }
    }

    setInitialized(state) {
        this.setState({ initialized: state });
    }

    /**
     *
     * @param data
     * @returns {*}
     */
    formatApiValue = data => {
        const { fieldProps } = this.props;
        const formattedOptions = [];
        data.map(item => {
            const labelTemplateMatch = fieldProps.options.mapData.label.match(/[^{]+(?=})/g);
            let labelTemplate = fieldProps.options.mapData.label;
            if (labelTemplateMatch) {
                labelTemplateMatch.map(labelItem => {
                    labelTemplate = labelTemplate.replace(
                        `{${labelItem}}`,
                        get(item, labelItem, '')
                    );
                    return true;
                });
            }
            formattedOptions.push({
                value: item[fieldProps.options.mapData.value],
                label: labelTemplateMatch ? labelTemplate : item[fieldProps.options.mapData.label]
            });
            return true;
        });
        return formattedOptions;
    };

    /**
     *
     * @param inputValue
     */
    loadOptions = inputValue => {
        const { fieldProps, input } = this.props;
        if (fieldProps.options.endpoint) {
            return new Promise(resolve => {
                const { queryName = 'q' } = fieldProps.options;
                let { params } = fieldProps.options;
                if (inputValue) {
                    params = { [queryName]: inputValue, ...fieldProps.options.params };
                }
                if (input.value) {
                    params = { selected: input.value, ...params };
                }

                // Do a API call
                restClient
                    .get(fieldProps.options.endpoint, {
                        params
                    })
                    .then(response => {
                        const { options } = this.state;
                        response.data = dto(response.data);
                        const formatedOptions = this.formatApiValue(response.data.data);
                        const archivedOptions = unionWith(
                            options,
                            formatedOptions,
                            (a, b) => a.value === b.value
                        );
                        this.setState({ options: archivedOptions });
                        return resolve(formatedOptions);
                    })
                    .catch(() => resolve());
            });
        }

        return null;
    };

    /**
     *
     * @param fieldValue
     * @param actionButtonData {object}
     * @return {null|*}
     */
    renderActionButton(fieldValue, actionButtonData = null) {
        if (!actionButtonData) return null;

        const { edit, create } = actionButtonData;
        const { value, label } = fieldValue;
        let buttonLabel = `Edit ${label}` || 'Edit';
        let buttonIconName = 'edit';
        let buttonUrl = null;
        let buttonDisabled = !value;

        // Edit
        if (value && edit) {
            // If component provided
            if (edit.component) {
                return React.cloneElement(edit.component, { value });
            }
            // If url object provided
            if (edit.url) {
                const { prefix, suffix } = edit.url;
                let id = null;

                if (value) {
                    id = getIdFromIri(value);
                }

                buttonUrl = `${prefix}${id}${suffix}`;
            }

            // If entity archived, missing label
            if (!label) {
                buttonDisabled = true;
            }
        }

        // No value in the field
        if (!value) {
            // By defining buttonDisabled var we set it to disable
            // If create object provided
            if (create) {
                // If component provided
                if (create.component) {
                    return create.component;
                }
                // If url is provided
                if (create.url) {
                    buttonIconName = null;
                    buttonUrl = create.url;
                    buttonDisabled = false;
                    buttonIconName = 'plus';
                    buttonLabel = 'Create';
                }
            }
        }

        return (
            <Button
                disabled={buttonDisabled}
                variation="action"
                iconName={buttonIconName}
                href={buttonUrl}
                label={buttonLabel}
                labelOnlyAria
            />
        );
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { input, fieldProps, fieldAttr, meta, field } = this.props;
        const { labelActive, options, initialized } = this.state;
        fieldProps.forceLabelActive = labelActive;
        if (fieldProps.defaultOptions === undefined) {
            fieldProps.defaultOptions = true;
        }

        const { menuPlacement } = fieldProps;

        //  Getting select required value from options
        const fieldValue = getOptionByValue(input.value, options);

        //  Standardizing props for select
        const mappedConf = selectPropsMapper(fieldProps, fieldAttr);

        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <Async
                    key={JSON.stringify(fieldProps.options)}
                    styles={styles}
                    components={{ DropdownIndicator, ClearIndicator }}
                    onChange={selectedOption => {
                        if (selectedOption) {
                            input.onChange(selectedOption.value);
                        } else {
                            input.onChange(null);
                        }
                    }}
                    onInputChange={inputValue =>
                        inputValue !== ''
                            ? this.setState({ labelActive: true })
                            : this.setState({ labelActive: false })
                    }
                    onFocus={() => {
                        input.onBlur();
                        this.setState({ labelActive: true });
                    }}
                    placeholder=""
                    className={`react-select${
                        fieldProps.actionButton ? ' react-select--action' : ''
                    } ${menuPlacement === 'top' ? 'react-select--menu-top' : ''}`}
                    classNamePrefix="react-select"
                    isClearable
                    value={fieldValue}
                    name={input.name}
                    id={input.name}
                    {...mappedConf.fieldAttr}
                    {...mappedConf.fieldProps}
                    cacheOptions
                    defaultOptions={initialized || fieldProps.defaultOptions}
                    loadOptions={this.loadOptions}
                />
                {fieldProps.actionButton &&
                    this.renderActionButton(fieldValue, fieldProps.actionButton)}
            </Input>
        );
    }
}
Autocomplete.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

Autocomplete.propTypes = {
    fieldProps: PropTypes.shape({
        endpoint: PropTypes.string,
        formatApiValue: PropTypes.func,
        params: PropTypes.object,
        defaultOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
        options: PropTypes.object,
        actionButton: PropTypes.object,
        forceLabelActive: PropTypes.bool,
        menuPlacement: PropTypes.string
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default Autocomplete;
