import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Select, DateTime } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import ElementLoader from 'erpcore/components/ElementLoader';

const AppraiserAddProjectForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    disabled,
    fetching,
    projects
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                {fetching && <ElementLoader overlay />}
                <Field
                    name="project"
                    id="project"
                    fieldProps={{
                        label: 'Project',
                        options: projects?.map(project => ({
                            label: project?.name,
                            value: project?.iri
                        }))
                    }}
                    fieldAttr={{ required: true, disabled }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="valid_until"
                    id="valid_until"
                    fieldProps={{
                        label: 'Valid Until'
                    }}
                    fieldAttr={{ required: true }}
                    component={DateTime}
                />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Add" />
            </Form.Row>
        </Form>
    );
};

AppraiserAddProjectForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    disabled: false
};

AppraiserAddProjectForm.propTypes = {
    fetching: PropTypes.bool.isRequired,
    projects: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool
};

export default reduxForm({
    form: 'AppraiserAddProjectForm',
    enableReinitialize: true
})(AppraiserAddProjectForm);
