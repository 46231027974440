export const actions = {
    START_FETCHING_PROSPECTS: 'START_FETCHING_PROSPECTS',
    FETCHING_SUCCESSFUL_PROSPECTS: 'FETCHING_SUCCESSFUL_PROSPECTS',
    FETCHING_FAILED_PROSPECTS: 'FETCHING_FAILED_PROSPECTS',

    START_FETCHING_PROSPECT_DEALS: 'START_FETCHING_PROSPECT_DEALS',
    FETCHING_SUCCESSFUL_PROSPECT_DEALS: 'FETCHING_SUCCESSFUL_PROSPECT_DEALS',
    FETCHING_FAILED_PROSPECT_DEALS: 'FETCHING_FAILED_PROSPECT_DEALS',

    START_UPDATE_PROSPECT_DEAL: 'START_UPDATE_PROSPECT_DEAL',
    UPDATE_PROSPECT_DEAL_SUCCESSFUL: 'UPDATE_PROSPECT_DEAL_SUCCESSFUL',
    UPDATE_PROSPECT_DEAL_FAILED: 'UPDATE_PROSPECT_DEAL_FAILED',

    START_CREATE_PROSPECT_DEAL: 'START_CREATE_PROSPECT_DEAL',
    CREATE_PROSPECT_DEAL_SUCCESSFUL: 'CREATE_PROSPECT_DEAL_SUCCESSFUL',
    CREATE_PROSPECT_DEAL_FAILED: 'CREATE_PROSPECT_DEAL_FAILED',

    START_CREATE_PROSPECT: 'START_CREATE_PROSPECT',
    CREATE_PROSPECT_SUCCESSFUL: 'CREATE_PROSPECT_SUCCESSFUL',
    CREATE_PROSPECT_FAILED: 'CREATE_PROSPECT_FAILED',

    START_FETCHING_SINGLE_PROSPECT: 'START_FETCHING_SINGLE_PROSPECT',
    FETCH_SINGLE_PROSPECT_SUCCESSFUL: 'FETCH_SINGLE_PROSPECT_SUCCESSFUL',
    FETCH_SINGLE_PROSPECT_FAILED: 'FETCH_SINGLE_PROSPECT_FAILED',

    START_UPDATE_SINGLE_PROSPECT: 'START_UPDATE_SINGLE_PROSPECT',
    UPDATE_SINGLE_PROSPECT_SUCCESSFUL: 'UPDATE_SINGLE_PROSPECT_SUCCESSFUL',
    UPDATE_SINGLE_PROSPECT_FAILED: 'UPDATE_SINGLE_PROSPECT_FAILED',

    START_DELETE_SINGLE_PROSPECT: 'START_DELETE_SINGLE_PROSPECT',
    DELETE_SINGLE_PROSPECT_SUCCESSFUL: 'DELETE_SINGLE_PROSPECT_SUCCESSFUL',
    DELETE_SINGLE_PROSPECT_FAILED: 'DELETE_SINGLE_PROSPECT_FAILED',
    STORE_SINGLE_PROSPECT_DATA: 'STORE_SINGLE_PROSPECT_DATA',

    START_FETCHING_SINGLE_PROSPECT_SESSION: 'START_FETCHING_SINGLE_PROSPECT_SESSION',
    FETCHING_SINGLE_PROSPECT_SESSION_SUCCESSFUL: 'FETCHING_SINGLE_PROSPECT_SESSION_SUCCESSFUL',
    FETCHING_SINGLE_PROSPECT_SESSION_FAILED: 'FETCHING_SINGLE_PROSPECT_SESSION_FAILED',

    START_FETCHING_PROSPECT_QA: 'START_FETCHING_PROSPECT_QA',
    FETCH_PROSPECT_QA_SUCCESSFUL: 'FETCH_PROSPECT_QA_SUCCESSFUL',
    FETCH_PROSPECT_QA_FAILED: 'FETCH_PROSPECT_QA_FAILED',

    SET_PROSPECT_QUESTION_COUNT: 'SET_PROSPECT_QUESTION_COUNT',
    CLEAR_LISTING: 'CLEAR_LISTING'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    prospectSessionFetching: false,
    prospectQAFetching: false,
    prospectQA: null,
    prospectQuestionCount: 0
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.CLEAR_LISTING: {
            return {
                ...state,
                listingResponse: {
                    data: [],
                    meta: {},
                    included: []
                }
            };
        }
        case actions.START_FETCHING_PROSPECT_DEALS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROSPECT_DEALS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROSPECT_DEALS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_PROSPECTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROSPECTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROSPECTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_PROSPECT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_PROSPECT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_PROSPECT_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_PROSPECT_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_PROSPECT_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.START_FETCHING_SINGLE_PROSPECT_SESSION: {
            return {
                ...state,
                prospectSessionFetching: true
            };
        }
        case actions.FETCHING_SINGLE_PROSPECT_SESSION_SUCCESSFUL: {
            return {
                ...state,
                prospectSessionFetching: false
            };
        }
        case actions.FETCHING_SINGLE_PROSPECT_SESSION_FAILED: {
            return {
                ...state,
                prospectSessionFetching: false
            };
        }
        case actions.STORE_SINGLE_PROSPECT_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: { ...state[iri], ...response.data }
            });
        }
        // PROSPETCS QA
        case actions.START_FETCHING_PROSPECT_QA: {
            return {
                ...state,
                prospectQAFetching: true
            };
        }
        case actions.FETCH_PROSPECT_QA_SUCCESSFUL: {
            return {
                ...state,
                prospectQAFetching: false,
                prospectQA: { ...state?.prospectQA, [iri]: response }
            };
        }
        case actions.FETCH_PROSPECT_QA_FAILED: {
            return {
                ...state,
                prospectQAFetching: false
            };
        }
        case actions.SET_PROSPECT_QUESTION_COUNT: {
            return {
                ...state,
                prospectQuestionCount: response
            };
        }
        default:
            return state;
    }
};
