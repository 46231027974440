import React from 'react';
import Form, { Repeater } from 'erpcore/components/Form';
import { FieldArray, reduxForm } from 'redux-form';
import BuildingsRepeatableItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/BuildingsRepeatableItem';
import { useSelector } from 'react-redux';
import { getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import PropTypes from 'prop-types';

const UnitsSettingsBuildings = ({ onCreate, onUpdate, onRemove, onFinish }) => {
    const projectFetching = useSelector(getProjectFetching);

    /**
     * Promise.all resolver
     * @param oldItems {Array}
     * @param oldItemDiff {Object}
     * @param newItems {Array}
     * @return {Promise}
     */
    const addUpdateItems = (oldItems, oldItemsDiff, newItems) => {
        const promises = [];

        if (Object.keys(oldItemsDiff)?.length > 0) {
            oldItems.map(item => promises.push(onUpdate(item)));
        }

        if (newItems?.length > 0) {
            newItems.map(item => promises.push(onCreate(item)));
        }

        return Promise.all([promises]);
    };

    return (
        <Form>
            {projectFetching && <ElementLoader overlay />}
            <Form.Row>
                <FieldArray
                    name="buildings"
                    component={Repeater}
                    RepeatableItem={BuildingsRepeatableItem}
                    canSort={false}
                    canSortNewItems={false}
                    saveButtonIntent="all"
                    canSave
                    data={{ onFinish }}
                    onSaveAll={({ oldItems, oldItemsDiff, newItems }) =>
                        addUpdateItems(oldItems, oldItemsDiff, newItems).finally(() => {
                            onFinish();
                        })
                    }
                    onRemoveItem={({ itemData }) => onRemove(itemData)}
                    uniqueIdentifier="iri"
                    addNewLabel="Add building"
                />
            </Form.Row>
        </Form>
    );
};

UnitsSettingsBuildings.defaultProps = {
    onCreate: () => {},
    onRemove: () => {},
    onFinish: () => {},
    onUpdate: () => {}
};

UnitsSettingsBuildings.propTypes = {
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    onFinish: PropTypes.func,
    onUpdate: PropTypes.func
};

export default reduxForm({
    form: 'UnitsSettingsBuildings',
    enableReinitialize: true
})(UnitsSettingsBuildings);
