import React from 'react';
import { useSelector } from 'react-redux';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import UserWidget from 'erpcore/screens/Dashboard/components/UserWidget';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import DemoSessionsWidget from 'erpcore/screens/Dashboard/components/DemoSessionsWidget';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

const Dashboard = () => {
    const meData = useSelector(getMeData);
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);
    const title = 'Dashboard';

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />

            <PageContent>
                <UserWidget userData={meData} />
                {hasPermissionsAccessForEntities.sessions && <DemoSessionsWidget />}
            </PageContent>
        </LayoutManager>
    );
};

export default Dashboard;
