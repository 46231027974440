import React from 'react';
import Form, { Text } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';

const VonageVideoAPIKeyForm = ({ handleSubmit, onSubmit, invalid, pristine, submitting }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Columns>
                <Form.GridColumn size={10}>
                    <Field
                        name="vonage_api_key"
                        fieldProps={{ label: 'TokBox API key' }}
                        fieldAttr={{ id: 'vonage_api_key' }}
                        component={Text}
                    />
                </Form.GridColumn>
                <Form.GridColumn size={2}>
                    <Button
                        loading={submitting}
                        submit
                        disabled={invalid || pristine}
                        label="Submit"
                    />
                </Form.GridColumn>
            </Form.Columns>
        </Form>
    );
};

VonageVideoAPIKeyForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitting: false
};

VonageVideoAPIKeyForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'VonageVideoAPIKeyForm',
    enableReinitialize: true
})(VonageVideoAPIKeyForm);
