import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import Form, { Text, Repeater } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import PurchaseCalculatorsRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/PurchaseCalculatorsRepeaterItem';

const ProjectMLALiveForm = ({
    touch,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    isSlugRequired
}) => {
    useEffect(() => {
        if (!isSlugRequired) {
            touch('slug');
        }
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="homes"
                    fieldProps={{
                        label: 'Homes',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'homes' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="bedrooms"
                    fieldProps={{
                        label: 'Bedrooms',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'bedrooms' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="number_of_homes"
                    fieldProps={{
                        label: 'Number of homes',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'numberOfHomes' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="status"
                    fieldProps={{
                        label: 'Status',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'status' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="website"
                    fieldProps={{
                        label: 'Website',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'website' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="matterport_url"
                    fieldProps={{
                        label: 'Matterport URL',
                        clearable: true,
                        helperText: 'Example: https://my.matterport.com/show/?m=xxxxxxxxxxx'
                    }}
                    fieldAttr={{ id: 'matterport_url' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="intro_video_url"
                    fieldProps={{
                        label: 'YouTube URL',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'intro_video_url' }}
                    component={Text}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Purchase calculators</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="purchase_calculators"
                    component={Repeater}
                    RepeatableItem={PurchaseCalculatorsRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <br />
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

ProjectMLALiveForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    isSlugRequired: false
};

ProjectMLALiveForm.propTypes = {
    touch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isSlugRequired: PropTypes.bool
};

export default reduxForm({
    form: 'ProjectMLALiveForm',
    enableReinitialize: true
})(ProjectMLALiveForm);
