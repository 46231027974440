import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as blogCategoriesActions } from 'erpcore/screens/Settings/Blue/screens/BlogCategories/BlogCategories.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const actionName = 'BLOG_CATEGORIES';
const reducerName = 'blogCategories';

/**
 * Delete Blog Categorie
 * @param  {Object} product type iri
 * @return {Object} Response from API
 */
export function* deleteBlogCategory({ promise, iri }) {
    try {
        const deleteBlogCategoryAPI = yield restClient.delete(iri);
        yield put({
            type: blogCategoriesActions.DELETE_BLOG_CATEGORIE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteBlogCategoryAPI?.data
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/blog-categories`,
            promise
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: blogCategoriesActions.DELETE_BLOG_CATEGORIE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create BlogCategory
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createBlogCategory({ promise, formData }) {
    try {
        const createBlogCategoryAPI = yield restClient.post('api/blog-categories', formData);
        yield put({
            type: blogCategoriesActions.CREATE_BLOG_CATEGORIE_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/blog-categories`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBlogCategoryAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: blogCategoriesActions.CREATE_BLOG_CATEGORIE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Blog Categorie
 * @param  {Object} iri of product type
 * @return {Object} Response from API
 */
export function* updateBlogCategory({ promise, iri, formData }) {
    try {
        const updateBlogCategoryAPI = yield restClient.patch(iri, formData);
        yield put({
            type: blogCategoriesActions.UPDATE_BLOG_CATEGORIE_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/blog-categories`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateBlogCategoryAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: blogCategoriesActions.UPDATE_BLOG_CATEGORIE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const blogCategoriesSaga = [
    takeLatest(blogCategoriesActions.START_DELETE_BLOG_CATEGORIE, deleteBlogCategory),
    takeLatest(blogCategoriesActions.START_CREATE_BLOG_CATEGORIE, createBlogCategory),
    takeLatest(blogCategoriesActions.START_UPDATE_BLOG_CATEGORIE, updateBlogCategory)
];

export default blogCategoriesSaga;
