import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PermissionActiveToggle from 'erpcore/screens/Settings/Permissions/components/PermissionActiveToggle';
import PermissionEdit from 'erpcore/screens/Settings/Permissions/components/PermissionEdit';
import TableActions from 'erpcore/components/Listing/components/TableActions';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';

const Permissions = () => {
    const reducerName = 'permissions';
    const actionName = 'PERMISSIONS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Permissions';

    const fetchPermissions = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params: { ...params, 'order_by[name]': 'DESC' },
                entity: actionName,
                name: reducerName,
                endpoint: 'api/permissions'
            });
        }).catch(error => {
            return error;
        });
    };

    const getLabel = (name, description) => {
        if (!description) return name;

        return (
            <>
                {name}{' '}
                <Tooltip content={description} direction="up" styles={{ zIndex: 9 }}>
                    <Svg icon="help" />
                </Tooltip>
            </>
        );
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'Label',
                field: 'label',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Active',
                field: 'active',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Permission',
                field: 'permission',
                sortable: false
            },

            {
                title: 'Actions',
                field: 'actions'
            }
        ];
        // Table Data
        if (listing && listing.data) {
            listing.data.map(row => {
                return table.data.push({
                    id: row.id,
                    iri: row.iri,
                    permission: row.attribute,
                    active: (
                        <PermissionActiveToggle
                            permissionIri={row?.iri}
                            initialValue={row.active}
                            fetchPermissions={fetchPermissions}
                        />
                    ),
                    label: getLabel(row.name, row.description),
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <PermissionEdit permissionData={row} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />

            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchPermissions(params)}
                hideFooter
            />
        </LayoutManager>
    );
};

export default Permissions;
