import { put, take, takeLatest, fork, call, select } from 'redux-saga/effects';
import { channel } from 'redux-saga';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { getBulkActionsIris } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Fetch Listing Saga
 * @param  {Object} promise Resolve and reject promise
 * @param  {Object} params Set query params for listing request
 * @param  {String} entity Name of the listing entity
 * @param  {String} endpoint Endpoint to wich saga points
 * @return {Object} Response from API
 */
export function* fetchListing({ promise, params, entity = 'LISTING', endpoint }) {
    try {
        yield put({
            type: `${listingActions.START_FETCHING}_${entity}`
        });
        const fetchListingAPI = yield restClient.get(endpoint, {
            params: parseParamsForApi(params)
        });
        yield put({
            type: `${listingActions.FETCHING_SUCCESSFUL}_${entity}`,
            response: dto(fetchListingAPI?.data)
        });
        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        yield put({
            type: `${listingActions.FETCHING_FAILED}_${entity}`,
            response: error?.response?.data || error
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        if (promise) {
            yield call(promise.reject, error?.response?.data || error);
        }
    }
}

/**
 * Batch Requests
 * @param promise
 * @param reducerName
 * @param data
 * @param method
 * @param notificationCode
 * @returns {Generator<<"CALL", CallEffectDescriptor>|AxiosPromise<any>|<"PUT", PutEffectDescriptor<{response: *, type: string}>>|<"SELECT", SelectEffectDescriptor>|<"PUT", PutEffectDescriptor<{type: string}>>, void, ?>}
 */
export function* batchRequests({ promise, reducerName, data, method = 'PUT', notificationCode }) {
    try {
        let notification = null;
        const bulkActionsIris = yield select(getBulkActionsIris, reducerName);

        if (bulkActionsIris?.length > 1) {
            const payload = {
                method,
                body: { ...data },
                routes: bulkActionsIris || []
            };

            const batchRequestsAPI = yield restClient.post('api/batch-requests', payload);

            yield put({
                type: listingActions.BATCH_REQUESTS_SUCCESSFUL
            });

            notification = notificationCode ? { code: notificationCode } : batchRequestsAPI?.data;
        } else {
            notification = { code: 'bulkActions.notEnoughItemsSelected' };
        }

        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: notification
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: listingActions.BATCH_REQUESTS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

function takeLatestPerProps(propsOrSelector, pattern, worker, ...args) {
    // Not a generator
    return fork(function* generator() {
        // Fork a generator here to make it work like takeLatest
        const channelsMap = {};
        while (true) {
            const action = yield take(pattern); // yield necessary here
            const propsValue =
                typeof propsOrSelector === 'function'
                    ? propsOrSelector(action)
                    : action[propsOrSelector];
            if (!channelsMap[propsValue]) {
                channelsMap[propsValue] = channel();
                yield takeLatest(channelsMap[propsValue], worker, ...args);
            }
            yield put(channelsMap[propsValue], action);
        }
    });
}

/**
 * Register action to watcher
 */
export const listingSaga = [
    takeLatestPerProps('entity', listingActions.START_FETCHING_LISTING, fetchListing),
    takeLatest(listingActions.START_BATCH_REQUESTS, batchRequests)
];
