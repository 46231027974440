import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';

import Form, {
    Text,
    ColorPicker,
    Media,
    Textarea,
    Select,
    Repeater
} from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import DisclosureFormRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/DisclosureFormRepeaterItem';

const OrganizationBrandingForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    const fonts = [
        { value: 'Montserrat', label: 'Montserrat' },
        { value: 'Butler', label: 'Butler' },
        { value: 'Roboto', label: 'Roboto' },
        { value: 'Gelion', label: 'Gelion' },
        { value: 'Open Sans', label: 'Open Sans' }
    ];

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="name"
                        id="name"
                        fieldProps={{
                            label: 'Name'
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="primaryColor"
                        id="primaryColor"
                        fieldProps={{
                            label: 'Primary Color'
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={ColorPicker}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="buttonColor"
                        id="buttonColor"
                        fieldProps={{
                            label: 'Button Color',
                            clearable: true
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={ColorPicker}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="sidebarColor"
                        id="sidebarColor"
                        fieldProps={{
                            label: 'Sidebar Color'
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={ColorPicker}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="textColor"
                        id="textColor"
                        fieldProps={{
                            label: 'Text Color'
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={ColorPicker}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Columns>
                    <Form.Column>
                        <Form.SectionTitleSmall>Logo</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="logo"
                                id="logo"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif', 'svg']}
                            />
                        </Form.Row>
                    </Form.Column>
                    <Form.Column>
                        <Form.SectionTitleSmall>Logo Inverted</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="logoInverted"
                                id="logoInverted"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif', 'svg']}
                            />
                        </Form.Row>
                    </Form.Column>
                    <Form.Column>
                        <Form.SectionTitleSmall>Logo Icon</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="logoIcon"
                                id="logoIcon"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif', 'svg']}
                            />
                        </Form.Row>
                    </Form.Column>
                    <Form.Column>
                        <Form.SectionTitleSmall>Logo Icon Inverted</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="logoIconInverted"
                                id="logoIconInverted"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif', 'svg']}
                            />
                        </Form.Row>
                    </Form.Column>
                </Form.Columns>
                <Form.Columns>
                    <Form.Column>
                        <Form.SectionTitleSmall>ERP Login Background</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="loginBackground"
                                id="loginBackground"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif', 'svg']}
                            />
                        </Form.Row>
                    </Form.Column>
                    <Form.Column>
                        <Form.SectionTitleSmall>Favicon</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="favicon"
                                id="favicon"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif', 'svg']}
                            />
                        </Form.Row>
                    </Form.Column>
                </Form.Columns>
                <Form.Columns>
                    <Form.Column>
                        <Form.SectionTitleSmall>Email Logo</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="emailLogo"
                                id="emailLogo"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['png']}
                            />
                        </Form.Row>
                    </Form.Column>
                    <Form.Column>
                        <Form.SectionTitleSmall>Email Logo Inverted</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="emailLogoInverted"
                                id="emailLogoInverted"
                                component={Media}
                                useLightbox
                                useImageManager
                                allowedFileTypes={['png']}
                            />
                        </Form.Row>
                    </Form.Column>
                </Form.Columns>
                <Form.SectionTitleSmall>Portal Logo</Form.SectionTitleSmall>
                <Form.Row>
                    <Field
                        name="portalLogo"
                        id="portalLogo"
                        component={Media}
                        useLightbox
                        useImageManager
                        allowedFileTypes={['png']}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="bodyFont"
                        id="bodyFont"
                        fieldProps={{
                            label: 'Body Font',
                            options: fonts
                        }}
                        component={Select}
                    />
                    <Field
                        name="titleFont"
                        id="titleFont"
                        fieldProps={{
                            label: 'Title Font',
                            options: fonts
                        }}
                        component={Select}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="facebook"
                        id="facebook"
                        fieldProps={{
                            label: 'Facebook'
                        }}
                        component={Text}
                    />
                    <Field
                        name="twitter"
                        id="twitter"
                        fieldProps={{
                            label: 'Twitter'
                        }}
                        component={Text}
                    />
                    <Field
                        name="linkedIn"
                        id="linkedIn"
                        fieldProps={{
                            label: 'LinkedIn'
                        }}
                        component={Text}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="introText"
                        id="introText"
                        fieldProps={{
                            label: 'Intro Text'
                        }}
                        component={Textarea}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="footerDisclaimer"
                        id="footerDisclaimer"
                        fieldProps={{
                            label: 'MLA Live Footer Disclaimer'
                        }}
                        component={Textarea}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="portalFooterDisclaimer"
                        id="portalFooterDisclaimer"
                        fieldProps={{
                            label: 'MLA Portal Footer Disclaimer'
                        }}
                        component={Textarea}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="projectFooterDisclaimer"
                        id="projectFooterDisclaimer"
                        fieldProps={{
                            label: 'MLA Project Footer Disclaimer'
                        }}
                        component={Textarea}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="blueFooterDisclaimer"
                        id="blueFooterDisclaimer"
                        fieldProps={{
                            label: 'MLA Blue Project Footer Disclaimer'
                        }}
                        component={Textarea}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="homestoreFooterDisclaimer"
                        id="homestoreFooterDisclaimer"
                        fieldProps={{
                            label: 'MLA Homestore Footer Disclaimer'
                        }}
                        component={Textarea}
                    />
                </Form.Row>
                <Form.SectionTitleSmall>Disclosure Forms</Form.SectionTitleSmall>
                <Form.Row>
                    <FieldArray
                        name="disclosureForm"
                        component={Repeater}
                        RepeatableItem={DisclosureFormRepeaterItem}
                        canAddMultiple
                        canSortNewItems
                        canSave={false}
                        addNewLabel="Add new Disclosure Form"
                        uniqueIdentifier="disclosureForms"
                    />
                </Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form>
        </>
    );
};

OrganizationBrandingForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    pristine: false,
    invalid: false
};

OrganizationBrandingForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'OrganizationBrandingForm',
    enableReinitialize: true
})(OrganizationBrandingForm);
