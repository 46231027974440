import React from 'react';
import Form, { Email } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';

const HubSpotIntegrationEmailsForm = ({
    handleSubmit,
    onSubmit,
    invalid,
    pristine,
    submitting
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="email"
                    fieldProps={{ label: 'Hubspot sync notifications receiver' }}
                    fieldAttr={{ id: 'email' }}
                    component={Email}
                />
            </Form.Row>
            <Button loading={submitting} submit disabled={invalid || pristine} label="Update" />
        </Form>
    );
};

HubSpotIntegrationEmailsForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitting: false
};

HubSpotIntegrationEmailsForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'HubSpotIntegrationEmailsForm',
    enableReinitialize: true
})(HubSpotIntegrationEmailsForm);
