import React, { useCallback, useState } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import Listing from 'erpcore/components/Listing';
import Button from 'erpcore/components/Button';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { parseParamsForApi } from 'erpcore/components/Listing/Listing.utils';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useDispatch } from 'react-redux';

const AvesdoEmails = () => {
    const title = 'Avesdo Emails';
    const dispatch = useDispatch();
    const [prospectListing, setProspectListing] = useState({});
    const [fetchingProspectListing, setFetchingProspectListing] = useState(false);
    const [prospectListingParams, setProspectListingParams] = useState();
    const [realtorListing, setRealtorListing] = useState({});
    const [fetchingRealtorListing, setFetchingRealtorListing] = useState(false);
    const [realtorListingParams, setRealtorListingParams] = useState();
    const [updating, setUpdating] = useState(null);

    const fetchAvesdoProspectEmails = useCallback(params => {
        setFetchingProspectListing(true);
        restClient
            .get('api/avesdo-purchasers?include=project,prospect,prospect.user', {
                params: {
                    ...parseParamsForApi(params),
                    'filters[meta.email_changed][equals]': 1,
                    pagination: false
                }
            })
            .then(response => setProspectListing(dto(response?.data)))
            .finally(() => setFetchingProspectListing(false));
    }, []);

    const fetchAvesdoRealtorEmails = useCallback(params => {
        setFetchingRealtorListing(true);
        restClient
            .get('api/avesdo-realtors?include=project,realtor,realtor.user', {
                params: {
                    ...parseParamsForApi(params),
                    'filters[meta.email_changed][equals]': 1,
                    pagination: false
                }
            })
            .then(response => setRealtorListing(dto(response?.data)))
            .finally(() => setFetchingRealtorListing(false));
    }, []);

    const onAvesdoEmailApply = data => {
        const promises = [];
        if (data?.iri) {
            promises.push(restClient.patch(`${data?.iri}`, { meta: { email_changed: '0' } }));
        }
        if (data?.realtor?.iri) {
            promises.push(restClient.patch(`${data.realtor.iri}`, { email: data?.email }));
        }
        if (data?.prospect?.iri) {
            promises.push(restClient.patch(`${data.prospect.iri}`, { email: data?.email }));
        }
        const { elastic_search_override_fields_data: prospectElasticData } = data?.prospect || {};
        const { elastic_search_override_fields_data: realtorElasticData } = data?.realtor || {};
        if (prospectElasticData?.user?.id || realtorElasticData?.user?.id) {
            const userIri = `/api/users/${prospectElasticData?.user?.id ||
                realtorElasticData?.user?.id}`;
            if (prospectElasticData.email !== data?.email) {
                promises.push(
                    restClient
                        .patch(userIri, {
                            email: data?.email
                        })
                        .then(() => restClient.post(`${userIri}/email-changed-inform`, {}))
                );
            }
        }
        return Promise.all(promises);
    };

    const tableData = (data, isRealtor) => {
        const table = {};
        table.data = [];

        const contactType = isRealtor ? 'p' : 'r';

        // Table Filters
        table.filters = [
            {
                name: 'project',
                label: 'Project',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Select Project',
                            options: {
                                endpoint: `api/projects`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            isRealtor
                ? {
                      name: 'realtor',
                      label: 'Realtor',
                      defaultOperator: 'equals',
                      filterFields: {
                          value: {
                              component: 'autocomplete',
                              fieldProps: {
                                  label: 'Select Realtor',
                                  options: {
                                      endpoint: `api/realtors`,
                                      mapData: {
                                          value: 'iri',
                                          label: '{first_name} {last_name}'
                                      }
                                  },
                                  clearable: false
                              },
                              fieldValidation: [{ validator: 'required' }]
                          }
                      }
                  }
                : {
                      name: 'prospect',
                      label: 'Prospect',
                      defaultOperator: 'equals',
                      filterFields: {
                          value: {
                              component: 'autocomplete',
                              fieldProps: {
                                  label: 'Select Prospects',
                                  options: {
                                      endpoint: `api/prospects`,
                                      mapData: {
                                          value: 'iri',
                                          label: '{first_name} {last_name}'
                                      }
                                  },
                                  clearable: false
                              },
                              fieldValidation: [{ validator: 'required' }]
                          }
                      }
                  }
        ];

        // Table Schema
        table.schema = [
            {
                title: 'Email',
                field: 'email',
                sortable: false,
                mobile: 'title'
            },
            {
                title: isRealtor ? 'Realtor' : 'Prospect',
                field: 'userName',
                sortable: false,
                mobile: 'subtitle'
            },
            {
                title: 'Project',
                field: 'project',
                sortable: false,
                mobile: 'project'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (data) {
            data.map((row, index) => {
                const { email, project, prospect, realtor, meta } = { ...row };

                if (meta.email_changed === '0') return false;

                const {
                    first_name: prospectFirstName,
                    last_name: prospectLastName,
                    user: prospectUser
                } = {
                    ...prospect
                };
                const {
                    first_name: realtorFirstName,
                    last_name: realtorLastName,
                    user: realtorUser
                } = {
                    ...realtor
                };

                const userName = isRealtor
                    ? `${realtorFirstName} ${realtorLastName}`
                    : `${prospectFirstName} ${prospectLastName}`;

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    email: (
                        <>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <strong>Avesdo email:</strong> {email}
                                <br />
                            </span>
                            {prospect?.email && (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <strong>Prospect email:</strong> {prospect?.email} <br />
                                </span>
                            )}
                            {realtor?.email && (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <strong>Realtor email:</strong> {realtor?.email} <br />
                                </span>
                            )}
                            {(prospectUser?.email || realtorUser?.user) && (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <strong>User email:</strong>{' '}
                                    {prospectUser?.email || realtorUser?.user} <br />
                                </span>
                            )}
                        </>
                    ),
                    project: project?.name || '-',
                    userName,
                    actions: (
                        <div style={{ textAlign: 'right' }}>
                            <Button
                                size="small"
                                label="Apply Avesdo Email to all related contacts"
                                loading={`${contactType}${index}` === updating}
                                onClick={() => {
                                    setUpdating(`${contactType}${index}`);
                                    onAvesdoEmailApply(row).finally(() => {
                                        setUpdating(null);
                                        dispatch({
                                            type:
                                                notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                            response: { code: 'user.itemSuccessfulyUpdated' }
                                        });
                                        fetchAvesdoProspectEmails(prospectListingParams);
                                        fetchAvesdoRealtorEmails(realtorListingParams);
                                    });
                                }}
                            />
                        </div>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <h3>Prospect E-mails</h3>
                <Listing
                    name="avesdoEmailsProspect"
                    reducerName="avesdoEmailsProspect"
                    loading={fetchingProspectListing}
                    meta={prospectListing?.meta || {}}
                    table={tableData(prospectListing?.data || [])}
                    onListingConfigUpdate={params => {
                        setProspectListingParams(params);
                        fetchAvesdoProspectEmails(params);
                    }}
                />
                <h3>Realtor E-mails</h3>
                <Listing
                    name="avesdoEmailsRealtor"
                    reducerName="avesdoEmailsRealtor"
                    loading={fetchingRealtorListing}
                    meta={realtorListing?.meta || {}}
                    table={tableData(realtorListing?.data || [], true)}
                    onListingConfigUpdate={params => {
                        setRealtorListingParams(params);
                        fetchAvesdoRealtorEmails(params);
                    }}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default AvesdoEmails;
