import React, { useState } from 'react';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import Form, { FileUploader } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';
import { useRouteMatch } from 'react-router-dom';
import './UnitsImport.scss';

const UnitsImport = ({ runAfterImport, handleSubmit, pristine, invalid, submitting }) => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const [sampleFetching, setSampleFetching] = useState(false);
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_IMPORT_UNITS,
                formData,
                promise: { resolve, reject },
                id
            });
        });
    };

    const downloadSample = () => {
        setSampleFetching(true);
        restClient
            .get(`api/projects/${id}/units/import-example`, { headers: { Accept: 'text/csv' } })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `sample.csv`);
                link.click();
            })
            .catch(() => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            })
            .finally(() => setSampleFetching(false));
    };

    return (
        <>
            <Button
                label="Mass/Bulk Import"
                variation="secondary"
                size="small"
                iconName="clip"
                onClick={() => setOpened(true)}
            />
            <Modal opened={opened} title="Import units" onClose={() => setOpened(false)}>
                <Form onSubmit={handleSubmit(onSubmit)} className="units-import">
                    <p>1. Download sample import file</p>
                    <Button
                        label="Download .CSV"
                        iconName="download"
                        variation="secondary"
                        size="small"
                        onClick={downloadSample}
                        loading={sampleFetching}
                    />
                    <p>2. Fill the data</p>
                    <p>3. Upload import file by dragging & dropping or by browsing file</p>
                    <Form.Row>
                        <Field
                            name="files"
                            id="files"
                            fieldProps={{
                                allowedExtensions: ['csv'],
                                endpoint: `/api/projects/${id}/units/import`,
                                headers: { Accept: 'text/csv' },
                                inputName: 'qqfile',
                                onComplete: response => {
                                    if (response?.success === false) {
                                        const notificationData = {
                                            code: 'generalError'
                                            // detail: [response?.message] || response?.errors
                                        };

                                        if (response?.message) {
                                            notificationData.detail = response.message;
                                        } else if (response?.errors) {
                                            notificationData.detail = response.errors;
                                        } else if (response?.title) {
                                            notificationData.detail = response.title;
                                        }

                                        dispatch({
                                            type:
                                                notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                            response: notificationData
                                        });
                                    }

                                    runAfterImport();

                                    setOpened(false);
                                }
                            }}
                            component={FileUploader}
                        />
                    </Form.Row>
                    <Button
                        loading={submitting}
                        disabled={pristine || invalid}
                        submit
                        label="Import"
                    />
                </Form>
            </Modal>
        </>
    );
};

UnitsImport.defaultProps = {
    runAfterImport: () => {},
    handleSubmit: () => {},
    pristine: false,
    invalid: false,
    submitting: false
};

UnitsImport.propTypes = {
    runAfterImport: PropTypes.func,
    handleSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'UnitsImportForm',
    enableReinitialize: true
})(UnitsImport);
