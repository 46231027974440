import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
    getIsUpdating,
    getProjectData,
    getProjectFetching
} from 'erpcore/screens/Projects/Projects.selectors';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import FaqWrapper from 'erpcore/screens/Settings/Portal/components/FaqWrapper';

const ProjectEditMultiEffectFAQ = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const isUpdating = useSelector(getIsUpdating);
    const fetchingProject = useSelector(getProjectFetching);

    const fetching = fetchingProject || isUpdating;

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {!!projectIri && (
                    <FaqWrapper
                        fetching={fetching}
                        projectIri={projectIri}
                        formName="PortalProjectFAQForm"
                    />
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default ProjectEditMultiEffectFAQ;
