import React from 'react';
import PropTypes from 'prop-types';
import defaultLayout from './defaultLayout';
import './content.scss';

const LayoutManager = ({ layout, layoutType, className, children, slot }) => {
    const renderLayoutComponent = layoutItem => {
        if (!layoutItem) {
            return null;
        }

        // If slot exists, render children in the Component
        if (slot && layoutItem.slotName && layoutItem.slotName === slot) {
            return (
                <layoutItem.component
                    layout={layoutItem.content}
                    className={className}
                    key={layoutItem.slotName}
                >
                    {children}
                </layoutItem.component>
            );
        }
        return <layoutItem.component layout={layoutItem.content} key={layoutItem.slotName} />;
    };

    const renderLayout = () => {
        //  Load default main layout for render
        let layoutToRender = Object.assign({}, defaultLayout);

        //  Merge default layout and layout for render
        if (layoutType && layoutType === 'merge') {
            layoutToRender = Object.assign(layoutToRender, layout);
        }

        //  Override default layout with layout for render
        if (layoutType && layoutType === 'override') {
            layoutToRender = layout;
        }

        return (
            <>
                {layoutToRender &&
                    layoutToRender.data &&
                    layoutToRender.data
                        .sort((a, b) => a.position - b.position)
                        .map(layoutItem => {
                            return renderLayoutComponent(layoutItem);
                        })}
            </>
        );
    };

    if (layoutType === 'merge') {
        return (
            <div id="content" className="content">
                {renderLayout()}
            </div>
        );
    }
    return renderLayout();
};

LayoutManager.defaultProps = {
    layout: null,
    layoutType: 'override',
    className: '',
    children: null,
    slot: null
};

LayoutManager.propTypes = {
    layout: PropTypes.oneOfType([PropTypes.object]),
    layoutType: PropTypes.oneOf(['merge', 'override']),
    className: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number
    ]),
    slot: PropTypes.string
};
export default LayoutManager;
