import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { withRouter } from 'react-router-dom';
//  import { diff } from 'deep-object-diff';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';

import {
    getSingleProspectFetching,
    getProspectData
} from 'erpcore/screens/Prospects/Prospects.selectors';
import ProspectForm from 'erpcore/screens/Prospects/components/ProspectForm';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';
import ProspectsTabs from 'erpcore/screens/Prospects/components/ProspectsTabs';
import Button from 'erpcore/components/Button';

const ProspectEdit = ({ match }) => {
    const dispatch = useDispatch();
    const prospectIri = `/api/prospects/${match?.params?.id}`;
    const prospectData = useSelector(state => getProspectData(state, prospectIri)) || {};
    const fetching = useSelector(state => getSingleProspectFetching(state));

    const {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        full_address: address,
        street,
        country,
        state,
        city,
        zip,
        latitude,
        longitude,
        projects = []
    } = {
        ...prospectData
    };

    const initialValues = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        location: {
            full_address: address,
            street,
            country: country?.iri,
            state: state?.iri,
            city: city?.iri,
            zip,
            latitude,
            longitude
        },
        projects: projects.map(proj => proj?.project?.iri),
        projectsProspects: projects.map(proj => proj)
    };

    const pageTitle = () => {
        if (firstName || lastName) {
            return `Edit prospect - ${firstName} ${lastName}`;
        }

        return `Edit prospect`;
    };

    const fetchProspectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_SINGLE_PROSPECT,
                iri: prospectIri
            });
        }).catch(error => ({ error }));
    };

    const onSubmit = formData => {
        // send only changed data
        //  formData = diff(initialValues, formData);

        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        if (!formData?.projects) {
            formData.projects = [];
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_UPDATE_SINGLE_PROSPECT,
                iri: prospectIri,
                formData
            })
        )
            .then(() => fetchProspectData())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchProspectData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                {' '}
                <Button
                    size="small"
                    href={`/prospects/${prospectData?.id}/details`}
                    variation="tertiary"
                    label="View Prospect"
                />
            </PageHeader>
            <PageContent>
                <ProspectsTabs />
                {fetching === true ? (
                    <PageLoader content />
                ) : (
                    <ProspectForm
                        onSubmit={onSubmit}
                        form="ProspectEditForm"
                        initialValues={initialValues}
                        submitLabel="Update"
                    />
                )}
            </PageContent>
        </LayoutManager>
    );
};

ProspectEdit.defaultProps = {
    match: {}
};

ProspectEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProspectEdit);
