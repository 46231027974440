import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'erpcore/components/Modal';
import { getBulkActionsModalIsOpened } from 'erpcore/components/Listing/Listing.selectors';

const BulkActionsModal = ({ children, handleModal, title, modalVariation, reducerName }) => {
    const isOpened = useSelector(state => getBulkActionsModalIsOpened(state, reducerName));
    return (
        <Modal
            className="bulk-actions__modal"
            variation={modalVariation}
            opened={isOpened}
            onClose={() => handleModal()}
            title={title}
        >
            {children}
        </Modal>
    );
};

BulkActionsModal.defaultProps = {
    children: null,
    handleModal: () => {},
    title: null,
    modalVariation: 'small'
};

BulkActionsModal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    handleModal: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    modalVariation: PropTypes.string,
    reducerName: PropTypes.string.isRequired
};

export default BulkActionsModal;
