export const actions = {
    START_FETCHING_VONAGE_VIDEO_API_KEY: 'START_FETCHING_VONAGE_VIDEO_API_KEY',
    FETCH_VONAGE_VIDEO_API_KEY_SUCCESSFUL: 'FETCH_VONAGE_VIDEO_API_KEY_SUCCESSFUL',
    FETCH_VONAGE_VIDEO_API_KEY_FAILED: 'FETCH_VONAGE_VIDEO_API_KEY_FAILED',

    START_FETCHING_VONAGE_VIDEO_SECRET_KEY: 'START_FETCHING_VONAGE_VIDEO_SECRET_KEY',
    FETCH_VONAGE_VIDEO_SECRET_KEY_SUCCESSFUL: 'FETCH_VONAGE_VIDEO_SECRET_KEY_SUCCESSFUL',
    FETCH_VONAGE_VIDEO_SECRET_KEY_FAILED: 'FETCH_VONAGE_VIDEO_SECRET_KEY_FAILED',

    START_CREATE_VONAGE_SETTINGS: 'START_CREATE_VONAGE_SETTINGS',
    CREATE_VONAGE_SETTINGS_SUCCESSFUL: 'CREATE_VONAGE_SETTINGS_SUCCESSFUL',
    CREATE_VONAGE_SETTINGS_FAILED: 'CREATE_VONAGE_SETTINGS_FAILED',

    START_UPDATE_VONAGE_SETTINGS: 'START_UPDATE_VONAGE_SETTINGS',
    UPDATE_VONAGE_SETTINGS_SUCCESSFUL: 'UPDATE_VONAGE_SETTINGS_SUCCESSFUL',
    UPDATE_VONAGE_SETTINGS_FAILED: 'UPDATE_VONAGE_SETTINGS_FAILED'
};

export const initialState = {
    data: {},
    fetching: false,
    vonageVideoAPIKey: null,
    vonageVideoSecretKey: null
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_VONAGE_VIDEO_API_KEY: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCH_VONAGE_VIDEO_API_KEY_SUCCESSFUL: {
            return {
                ...state,
                fetching: false,
                vonageVideoAPIKey: response?.data?.[0]
            };
        }
        case actions.FETCH_VONAGE_VIDEO_API_KEY_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }

        case actions.START_FETCHING_VONAGE_VIDEO_SECRET_KEY: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCH_VONAGE_VIDEO_SECRET_KEY_SUCCESSFUL: {
            return {
                ...state,
                fetching: false,
                vonageVideoSecretKey: response?.data?.[0]
            };
        }
        case actions.FETCH_VONAGE_VIDEO_SECRET_KEY_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        default:
            return state;
    }
};
