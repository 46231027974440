import React from 'react';
import PropTypes from 'prop-types';
import { Field, getFormInitialValues, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Text, Switch } from 'erpcore/components/Form';
import { useSelector } from 'react-redux';

import './IntegrationForm.scss';

const IntegrationForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    syncHubspot,
    syncAvesdo,
    form
}) => {
    const initialValues = useSelector(state => getFormInitialValues(form)(state));
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitle>Avesdo</Form.SectionTitle>
            <Form.Row>
                <Field
                    component={Text}
                    name="avesdo_id"
                    fieldProps={{
                        label: 'Avesdo ID'
                    }}
                    fieldAttr={{
                        disabled: !!initialValues.avesdo_id
                    }}
                    /* eslint-disable-next-line consistent-return */
                    parse={val => {
                        if (val === '') return null;
                        if (!Number.isNaN(parseInt(val, 10))) return parseInt(val, 10);
                    }}
                />
            </Form.Row>
            <Form.SectionTitle>Hubspot</Form.SectionTitle>
            <Form.Row>
                <Field
                    component={Text}
                    name="hubspot_id"
                    fieldAttr={{
                        disabled: !!initialValues.hubspot_id
                    }}
                    fieldProps={{
                        label: 'HubSpot ID'
                    }}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    component={Text}
                    name="hubspot_name"
                    fieldProps={{
                        label: 'HubSpot Project Name'
                    }}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    component={Switch}
                    name="syncable"
                    fieldProps={{ label: 'Syncable', on: { value: true }, off: { value: false } }}
                />
            </Form.Row>
            <Button
                submit
                disabled={pristine || invalid}
                loading={submitting}
                label={submitLabel}
            />
            <Button
                iconName="hubspot"
                label="Sync now with HubSpot"
                onClick={syncHubspot}
                className="integration-hubspot-button"
            />
            <Button
                iconName="avesdo"
                label="Sync now with Avesdo"
                onClick={syncAvesdo}
                className="integration-avesdo-button"
            />
        </Form>
    );
};

IntegrationForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

IntegrationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    syncHubspot: PropTypes.func.isRequired,
    syncAvesdo: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'IntegrationForm',
    enableReinitialize: true
})(IntegrationForm);
