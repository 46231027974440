import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

// Notifications
import getDefaultNotifications from './notifications/default';
import getAuthNotifications from './notifications/auth';
import getListingNotifications from './notifications/listing';
import getMyAccountNotifications from './notifications/myAccount';
import getClientsNotifications from './notifications/clients';
import getContactsNotifications from './notifications/contacts';
import getCurrencyNotifications from './notifications/currency';
import getDepartmentsNotifications from './notifications/departments';
import getDivisionsNotifications from './notifications/divisions';
import getEmploymentRecords from './notifications/employmentRecords';
import getJobTitlesNotifications from './notifications/jobTitles';
import getMediaNotifications from './notifications/media';
import getOfficesNotifications from './notifications/offices';
import getOptionsNotifications from './notifications/options';
import getOrganizationsNotifications from './notifications/organizations';
import getPaymentMethodsNotifications from './notifications/paymentMethods';
import getPaymentTermsNotifications from './notifications/paymentTerms';
import getProjectsNotifications from './notifications/projects';
import getProjectMilestonesNotifications from './notifications/projectMilestones';
import getProjectFeaturesNotifications from './notifications/projectFeatures';
import getRolesAndPermissionsNotifications from './notifications/rolesAndPermissions';
import getSalesOrdersNotifications from './notifications/salesOrders';
import getServicesNotifications from './notifications/services';
import getTimeLogsNotifications from './notifications/timeLogs';
import getTimeOffNotifications from './notifications/timeOff';
import getUsersNotifications from './notifications/users';
import getWorkTypesNotifications from './notifications/workTypes';
import getContractsNotifications from './notifications/contracts';
import getProspectssNotifications from './notifications/prospects';
import getSessionsNotifications from './notifications/sessions';
import getProspectProfileQuestionsNotifications from './notifications/prospectProfileQuestions';
import getSalesGuidelineNotifications from './notifications/salesGuidelines';
import getSettingsNotifications from './notifications/settings';
import getUnitTypeNotifications from './notifications/unitTypes';
import getNeighbourhoodLocationSections from './notifications/neighbourhoodLocationSections';
import getProductTypeNotifications from './notifications/productTypes';
import getBlueNotifications from './notifications/blue';

/**
 * Notification Manager
 * @return {Node} Returns Notification component
 */
const NotificationManager = ({ code, identifier, detail, notificationData }) => {
    const forwardData = { code, identifier, detail, notificationData };

    // Default
    const defaultNotifications = getDefaultNotifications(forwardData);
    if (defaultNotifications) {
        return defaultNotifications;
    }

    // Auth
    const authNotifications = getAuthNotifications(forwardData);
    if (authNotifications) {
        return authNotifications;
    }

    // Prospects
    const prospectsNotifications = getProspectssNotifications(forwardData);
    if (prospectsNotifications) {
        return prospectsNotifications;
    }

    // Listing
    const listingNotifications = getListingNotifications(forwardData);
    if (listingNotifications) {
        return listingNotifications;
    }

    // MyAccount
    const myAccountNotifications = getMyAccountNotifications(forwardData);
    if (myAccountNotifications) {
        return myAccountNotifications;
    }

    // Users
    const usersNotifications = getUsersNotifications(forwardData);
    if (usersNotifications) {
        return usersNotifications;
    }

    // Employment Records
    const employmentRecords = getEmploymentRecords(forwardData);
    if (employmentRecords) {
        return employmentRecords;
    }

    // Departments
    const departmentsNotifications = getDepartmentsNotifications(forwardData);
    if (departmentsNotifications) {
        return departmentsNotifications;
    }

    // Divisions
    const divisionsNotifications = getDivisionsNotifications(forwardData);
    if (divisionsNotifications) {
        return divisionsNotifications;
    }

    // WorkTypes
    const workTypesNotifications = getWorkTypesNotifications(forwardData);
    if (workTypesNotifications) {
        return workTypesNotifications;
    }

    // JobTitles
    const jobTitlesNotifications = getJobTitlesNotifications(forwardData);
    if (jobTitlesNotifications) {
        return jobTitlesNotifications;
    }

    // Media
    const mediaNotifications = getMediaNotifications(forwardData);
    if (mediaNotifications) {
        return mediaNotifications;
    }

    // Offices
    const officesNotifications = getOfficesNotifications(forwardData);
    if (officesNotifications) {
        return officesNotifications;
    }

    // Options
    const optionsNotifications = getOptionsNotifications(forwardData);
    if (optionsNotifications) {
        return optionsNotifications;
    }

    // Organizations
    const organizationsNotifications = getOrganizationsNotifications(forwardData);
    if (organizationsNotifications) {
        return organizationsNotifications;
    }

    // Prospect Profile Questions
    const prospectProfileQuestionsNotifications = getProspectProfileQuestionsNotifications(
        forwardData
    );
    if (prospectProfileQuestionsNotifications) {
        return prospectProfileQuestionsNotifications;
    }

    // Roles and Permissions
    const rolesAndPermissionsNotifications = getRolesAndPermissionsNotifications(forwardData);
    if (rolesAndPermissionsNotifications) {
        return rolesAndPermissionsNotifications;
    }

    // Time logs
    const timeLogsNotifications = getTimeLogsNotifications(forwardData);
    if (timeLogsNotifications) {
        return timeLogsNotifications;
    }

    // Time Off
    const timeOffNotifications = getTimeOffNotifications(forwardData);
    if (timeOffNotifications) {
        return timeOffNotifications;
    }

    // Clients
    const clientsNotifications = getClientsNotifications(forwardData);
    if (clientsNotifications) {
        return clientsNotifications;
    }

    // Contacts
    const contactsNotifications = getContactsNotifications(forwardData);
    if (contactsNotifications) {
        return contactsNotifications;
    }

    // Currency
    const currencyNotifications = getCurrencyNotifications(forwardData);
    if (currencyNotifications) {
        return currencyNotifications;
    }

    // Projects
    const projectsNotifications = getProjectsNotifications(forwardData);
    if (projectsNotifications) {
        return projectsNotifications;
    }

    // Sessions
    const sessionsNotifications = getSessionsNotifications(forwardData);
    if (sessionsNotifications) {
        return sessionsNotifications;
    }

    // TODO: reorder/alphabetize
    // Project features
    const projectFeaturesNotifications = getProjectFeaturesNotifications(forwardData);
    if (projectFeaturesNotifications) {
        return projectFeaturesNotifications;
    }

    // Project milestones
    const projectMilestonesNotifications = getProjectMilestonesNotifications(forwardData);
    if (projectMilestonesNotifications) {
        return projectMilestonesNotifications;
    }

    // Contracts
    const contractsNotifications = getContractsNotifications(forwardData);
    if (contractsNotifications) {
        return contractsNotifications;
    }

    // Services
    const servicesNotifications = getServicesNotifications(forwardData);
    if (servicesNotifications) {
        return servicesNotifications;
    }

    // Payment Terms
    const paymentTermsNotifications = getPaymentTermsNotifications(forwardData);
    if (paymentTermsNotifications) {
        return paymentTermsNotifications;
    }

    // Payment Methods
    const paymentMethodsNotifications = getPaymentMethodsNotifications(forwardData);
    if (paymentMethodsNotifications) {
        return paymentMethodsNotifications;
    }

    // Sales Orders
    const salesOrdersNotifications = getSalesOrdersNotifications(forwardData);
    if (salesOrdersNotifications) {
        return salesOrdersNotifications;
    }

    // Sales Guidelines
    const salesGuidelineNotification = getSalesGuidelineNotifications(forwardData);
    if (salesGuidelineNotification) {
        return salesGuidelineNotification;
    }

    // Settings
    const settingsNotifications = getSettingsNotifications(forwardData);
    if (settingsNotifications) {
        return settingsNotifications;
    }

    // Unit Types
    const unitTypesNotifications = getUnitTypeNotifications(forwardData);
    if (unitTypesNotifications) {
        return unitTypesNotifications;
    }

    // Neighbourhood Location Sections
    const NeighbourhoodLocationSectionsNotifications = getNeighbourhoodLocationSections(
        forwardData
    );
    if (NeighbourhoodLocationSectionsNotifications) {
        return NeighbourhoodLocationSectionsNotifications;
    }

    // Product Types
    const ProductTypesNotifications = getProductTypeNotifications(forwardData);
    if (ProductTypesNotifications) {
        return ProductTypesNotifications;
    }

    // Blue
    const BlueNotifications = getBlueNotifications(forwardData);
    if (BlueNotifications) {
        return BlueNotifications;
    }

    return (
        <Notification
            identifier={identifier}
            title="Warning"
            text={
                <Fragment>
                    <strong>{code}</strong> - This is the message missing Notification case!
                    {!!detail && (
                        <Fragment>
                            <br />
                            {detail}
                        </Fragment>
                    )}
                </Fragment>
            }
            type="warning"
        />
    );
};

NotificationManager.defaultProps = {
    code: null,
    identifier: null,
    detail: null,
    notificationData: null
};

NotificationManager.propTypes = {
    code: PropTypes.string,
    identifier: PropTypes.string,
    detail: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    notificationData: PropTypes.oneOfType([PropTypes.object])
};

export default NotificationManager;
