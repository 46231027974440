export const actions = {
    START_FETCHING_AVESDO_EMAILS: 'START_FETCHING_AVESDO_EMAILS',
    FETCHING_SUCCESSFUL_AVESDO_EMAILS: 'FETCHING_SUCCESSFUL_AVESDO_EMAILS',
    FETCHING_FAILED_AVESDO_EMAILS: 'FETCHING_FAILED_AVESDO_EMAILS',

    START_DELETE_AVESDO_EMAIL: 'START_DELETE_AVESDO_EMAIL',
    DELETE_AVESDO_EMAIL_SUCCESSFUL: 'DELETE_AVESDO_EMAIL_SUCCESSFUL',
    DELETE_AVESDO_EMAIL_FAILED: 'DELETE_AVESDO_EMAIL_FAILED',

    START_CREATE_AVESDO_EMAIL: 'START_CREATE_AVESDO_EMAIL',
    CREATE_AVESDO_EMAIL_SUCCESSFUL: 'CREATE_AVESDO_EMAIL_SUCCESSFUL',
    CREATE_AVESDO_EMAIL_FAILED: 'CREATE_AVESDO_EMAIL_FAILED',

    START_UPDATE_AVESDO_EMAIL: 'START_UPDATE_AVESDO_EMAIL',
    UPDATE_AVESDO_EMAIL_SUCCESSFUL: 'UPDATE_AVESDO_EMAIL_SUCCESSFUL',
    UPDATE_AVESDO_EMAIL_FAILED: 'UPDATE_AVESDO_EMAIL_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_AVESDO_EMAILS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_AVESDO_EMAILS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_AVESDO_EMAILS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
