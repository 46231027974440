const userActiveStatus = [
    {
        label: 'Active',
        value: true
    },
    {
        label: 'Inactive',
        value: false
    }
];

const statusBadges = {
    active: {
        type: 'apple',
        label: 'Active'
    },
    inactive: {
        type: 'tomato',
        label: 'Inactive'
    }
};

const productAccess = [
    { label: 'OiAccess', id: 'oi_access', value: 'oi_access' },
    { label: 'Homeowner Portal', id: 'portal_access', value: 'portal_access' },
    { label: 'MLA Live', id: 'mla_live_access', value: 'mla_live_access' },
    { label: 'ERP', id: 'erp_access', value: 'erp_access' }
];

const userType = [
    {
        label: 'Staff',
        value: 'staff',
        id: 'staff',
        defaultProductAccess: productAccess.map(product => product.value) // all products
    },
    {
        label: 'Prospect',
        value: 'prospect',
        id: 'prospect',
        defaultProductAccess: ['portal_access']
    },
    {
        label: 'Sales Agent',
        value: 'sales-agent',
        id: 'sales-agent',
        defaultProductAccess: ['mla_live_access', 'erp_access']
    },
    {
        label: 'Realtor',
        value: 'realtor',
        id: 'realtor',
        defaultProductAccess: ['portal_access']
    },
    {
        label: 'Appraiser',
        value: 'appraiser',
        id: 'appraiser',
        defaultProductAccess: ['portal_access']
    },
    {
        label: 'Client',
        value: 'client',
        id: 'client',
        defaultProductAccess: []
    }
];

const gender = [
    {
        label: 'Male',
        value: 'male',
        id: 'male'
    },
    {
        label: 'Female',
        value: 'female',
        id: 'female'
    }
];

const tShirtSize = [
    {
        label: 'XS',
        value: 'XS'
    },
    {
        label: 'S',
        value: 'S'
    },
    {
        label: 'M',
        value: 'M'
    },
    {
        label: 'L',
        value: 'L'
    },
    {
        label: 'XL',
        value: 'XL'
    },
    {
        label: 'XXL',
        value: 'XXL'
    },
    {
        label: 'XXXL',
        value: 'XXXL'
    }
];

const employmentType = [
    {
        label: 'Full-time',
        value: 'Full-time'
    },
    {
        label: 'Part-time',
        value: 'Part-time'
    },
    {
        label: 'Contractor',
        value: 'Contractor'
    }
];

export {
    userActiveStatus,
    statusBadges,
    userType,
    productAccess,
    gender,
    tShirtSize,
    employmentType
};
