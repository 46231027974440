import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import TitleDescriptionRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleDescriptionRepeaterItem';
import AppStatuses from 'erpcore/components/AppStatuses';

const ProjectMultiEffectFeaturesListForm = ({
    touch,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    isSlugRequired
}) => {
    useEffect(() => {
        if (!isSlugRequired) {
            touch('slug');
        }
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Column>
                <FieldArray
                    name="features_list"
                    component={Repeater}
                    RepeatableItem={TitleDescriptionRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
                <AppStatuses mlaLive mlaBlue />
            </Form.Column>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

ProjectMultiEffectFeaturesListForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    isSlugRequired: false
};

ProjectMultiEffectFeaturesListForm.propTypes = {
    touch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isSlugRequired: PropTypes.bool
};

export default reduxForm({
    form: 'ProjectMultiEffectFeaturesListForm',
    enableReinitialize: true
})(ProjectMultiEffectFeaturesListForm);
