/* eslint-disable camelcase */
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';
import {
    getIsUpdating,
    getProjectData,
    getProjectFetching
} from 'erpcore/screens/Projects/Projects.selectors';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ProjectEditHomeStore = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectDataOriginal = useSelector(state => getProjectData(state, projectIri)) || {};
    const isUpdating = useSelector(getIsUpdating);
    const projectData = dtoForm(projectDataOriginal) || {};
    const fetching = useSelector(getProjectFetching);

    /**
     * Fetch unit settings data
     * @return {Promise}
     */
    const fetchUnitsSettings = () => {
        const params = {
            include:
                'keyPlans,keyPlans.keyPlan,unitTypes,unitTypes.floorPlan,unitTypes.unitType,unitTypes.unitPositions'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_FETCHING_UNIT_SETTINGS,
                promise: { resolve, reject },
                iri: projectIri,
                projectID: match?.params?.id,
                params
            });
        });
    };

    useEffect(() => {
        fetchUnitsSettings();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            {(fetching || isUpdating) && <PageLoader />}
            <ProjectEditPageHeader data={projectData} />
            <PageContent className="units-settings">
                <h3 className="form__section-title-small">HomeStore</h3>
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditHomeStore.defaultProps = {
    match: {}
};

ProjectEditHomeStore.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditHomeStore;
