import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { getOptionByValue } from 'erpcore/components/Form/Form.utils';
import Svg from 'erpcore/components/Svg';
import PropTypes from 'prop-types';
import styles from './Select.styles';
import Input from '../Input';
import './Select.scss';

export const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Svg icon="arrowDown" />
            </components.DropdownIndicator>
        )
    );
};

export const ClearIndicator = props => {
    return (
        components.ClearIndicator && (
            <components.ClearIndicator {...props}>
                <Svg icon="close" />
            </components.ClearIndicator>
        )
    );
};

export const getOptionByLabel = (label, options) => {
    //  Getting select required value from options
    let fieldValue = '';
    if (label) {
        const filteredOption = options.find(o => o.label === label);
        //  If option exits inside options array store it to option
        if (filteredOption) {
            fieldValue = {
                value: filteredOption.value,
                label: filteredOption.label
            };
        } else {
            // If Option is not found send the value
            fieldValue = {
                value: '',
                label: 'Unknown Option'
            };
        }
    }
    return fieldValue;
};

export const selectPropsMapper = (fieldProps, fieldAttr) => {
    if ('clearable' in fieldProps) {
        fieldProps.isClearable = fieldProps.clearable;
        delete fieldProps.clearable;
    }
    if ('disabled' in fieldAttr) {
        fieldProps.isDisabled = fieldAttr.disabled;
    }
    return { fieldProps, fieldAttr };
};

class SelectDropdown extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            labelActive: false
        };
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { input, fieldProps, fieldAttr, meta, field } = this.props;
        const { labelActive } = this.state;
        fieldProps.forceLabelActive = labelActive;

        const { options, menuPlacement } = fieldProps;

        //  Getting select required value from options
        const fieldValue = getOptionByValue(input.value, options);

        //  Standardizing props for select
        const mappedConf = selectPropsMapper(fieldProps, fieldAttr);

        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <Select
                    styles={styles}
                    components={{ DropdownIndicator, ClearIndicator }}
                    onChange={selectedOption => {
                        if (selectedOption) {
                            input.onChange(selectedOption.value);
                        } else {
                            input.onChange(null);
                        }
                    }}
                    onInputChange={inputValue =>
                        inputValue !== ''
                            ? this.setState({ labelActive: true })
                            : this.setState({ labelActive: false })
                    }
                    onFocus={() => {
                        input.onBlur();
                        this.setState({ labelActive: true });
                    }}
                    placeholder=""
                    className={`react-select ${
                        menuPlacement === 'top' ? 'react-select--menu-top' : ''
                    }`}
                    classNamePrefix="react-select"
                    isClearable
                    value={fieldValue}
                    name={input.name}
                    options={options}
                    id={input.name}
                    {...mappedConf.fieldAttr}
                    {...mappedConf.fieldProps}
                />
            </Input>
        );
    }
}
SelectDropdown.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
SelectDropdown.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default SelectDropdown;
