import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as hubspotIntegrationActions } from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/HubSpotIntegration.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';

/**
 *
 * @param promise
 * @param params
 * @return {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{response: (null|Object), type: string}>>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* fetchHubSpotIntegrationSettings({ promise, params, settingsName }) {
    try {
        const request = yield restClient.get('api/organization-settings', {
            params: {
                'filters[name][equals]': settingsName,
                ...params
            }
        });
        yield put({
            type: hubspotIntegrationActions.FETCH_HUBSPOT_INTEGRATION_SETTINGS_SUCCESSFUL,
            response: dto(request?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: hubspotIntegrationActions.FETCH_HUBSPOT_INTEGRATION_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *
 * @param promise
 * @param formData
 * @param iri
 * @return {Generator<SimpleEffect<"CALL", CallEffectDescriptor<RT | RT | RT>>|SimpleEffect<"PUT", PutEffectDescriptor<{response: *, type: string}>>|AxiosPromise<any>|SimpleEffect<"PUT", PutEffectDescriptor<{type: string}>>, void, *>}
 */
export function* updateHubSpotIntegrationSettingsEntry({
    promise,
    formData,
    iri = null,
    settingsName
}) {
    try {
        let request = null;
        //  If exists patch it if not post
        if (iri) {
            request = yield restClient.patch(iri, {
                name: settingsName,
                settings: { ...formData }
            });
        } else {
            request = yield restClient.post('/api/organization-settings', {
                name: settingsName,
                settings: { ...formData }
            });
        }
        yield put({
            type: hubspotIntegrationActions.UPDATE_HUBSPOT_INTEGRATION_SETTINGS_SUCCESSFUL,
            response: dto(request?.data)
        });
        yield call(promise.resolve, request?.data);
    } catch (error) {
        yield put({
            type: hubspotIntegrationActions.UPDATE_HUBSPOT_INTEGRATION_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* connectToHubSpot({ promise }) {
    try {
        const requestHubSpotUrl = yield restClient.get('api/integration/auth/hubspot');
        yield put({
            type: hubspotIntegrationActions.CONNECT_TO_HUBSPOT_SUCCESSFUL
        });
        window.open(requestHubSpotUrl?.data?.url);
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: hubspotIntegrationActions.CONNECT_TO_HUBSPOT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* validateHubSpot({ promise, code }) {
    try {
        yield restClient.post(`/api/integrations/callback/hubspot?code=${code}`);
        yield put({
            type: hubspotIntegrationActions.VALIDATE_HUBSPOT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: hubspotIntegrationActions.VALIDATE_HUBSPOT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* disconnectHubSpot({ promise, clientKeys, tokens }) {
    try {
        yield restClient.delete(clientKeys?.iri);
        yield restClient.delete(tokens?.iri);
        yield put({
            type: hubspotIntegrationActions.DISCONNECT_HUBSPOT_SUCCESSFUL
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: hubspotIntegrationActions.DISCONNECT_HUBSPOT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* syncHubSpot({ promise }) {
    try {
        const syncAPI = yield restClient.post('/api/integration/sync/hubspot');
        yield put({
            type: hubspotIntegrationActions.SYNC_HUBSPOT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: syncAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: hubspotIntegrationActions.SYNC_HUBSPOT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const vonageVideoAPISaga = [
    takeLatest(
        hubspotIntegrationActions.START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS,
        fetchHubSpotIntegrationSettings
    ),
    takeLatest(
        hubspotIntegrationActions.START_UPDATE_HUBSPOT_INTEGRATION_SETTINGS,
        updateHubSpotIntegrationSettingsEntry
    ),
    takeLatest(hubspotIntegrationActions.START_CONNECT_TO_HUBSPOT, connectToHubSpot),
    takeLatest(hubspotIntegrationActions.START_VALIDATE_HUBSPOT, validateHubSpot),
    takeLatest(hubspotIntegrationActions.START_DISCONNECT_HUBSPOT, disconnectHubSpot),
    takeLatest(hubspotIntegrationActions.START_SYNC_HUBSPOT, syncHubSpot)
];

export default vonageVideoAPISaga;
