import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues, change } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Autocomplete, Select, DateTime } from 'erpcore/components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import ElementLoader from 'erpcore/components/ElementLoader';

const AppraiserAddUnitForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    disabled,
    fetching,
    projects
}) => {
    const dispatch = useDispatch();
    const formValues = useSelector(getFormValues('AppraiserAddUnitsForm'));
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                {fetching && <ElementLoader overlay />}
                <Field
                    name="project"
                    id="project"
                    fieldProps={{
                        label: 'Project',
                        options: projects?.map(project => ({
                            label: project?.name,
                            value: project?.iri
                        }))
                    }}
                    onChange={() => {
                        dispatch(change('AppraiserAddUnitsForm', 'unit', ''));
                    }}
                    fieldAttr={{ required: true, disabled }}
                    validate={valueValidation([{ validator: 'required' }])}
                    component={Select}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="unit"
                    id="unit"
                    fieldProps={{
                        label: 'Unit',
                        options: {
                            queryName: 'filters[name][contains]',
                            endpoint: `/api/project/units?filters[meta.avesdo.deal.id][exists]=1${
                                formValues?.project
                                    ? `&filters[project][equals]=${formValues?.project}`
                                    : ''
                            }&include=project&limit=20`,
                            mapData: {
                                value: 'iri',
                                label: '{name} - {project.name}'
                            }
                        }
                    }}
                    fieldAttr={{ required: true, disabled: disabled || !formValues?.project }}
                    validate={valueValidation([{ validator: 'required' }])}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="valid_until"
                    id="valid_until"
                    fieldProps={{
                        label: 'Valid Until'
                    }}
                    fieldAttr={{ required: true }}
                    component={DateTime}
                />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Add" />
            </Form.Row>
        </Form>
    );
};

AppraiserAddUnitForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    disabled: false
};

AppraiserAddUnitForm.propTypes = {
    fetching: PropTypes.bool.isRequired,
    projects: PropTypes.oneOfType([PropTypes.object]).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool
};

export default reduxForm({
    form: 'AppraiserAddUnitsForm',
    enableReinitialize: true
})(AppraiserAddUnitForm);
