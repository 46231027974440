import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Divisions Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Users = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'user.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="User successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'user.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="User successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'user.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="User successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'user.alreadyExists':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="User is already registered using provided email address!"
                    type="error"
                    expire="3500"
                />
            );
        case 'appraiser.mail.success':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Appraiser inform email successfully sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'appraiser.mail.error':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Problem with sending appraiser inform email."
                    type="error"
                    expire="3500"
                />
            );
        case 'appraiser.already-assigned':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="This item has already been added!"
                    type="warning"
                    expire="3500"
                />
            );
        case 'client.mail.success':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Client inform email successfully sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'client.mail.error':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Problem with sending client inform email."
                    type="error"
                    expire="3500"
                />
            );
        case 'client.already-assigned':
            return (
                <Notification
                    identifier={identifier}
                    title="Warning"
                    text="This item has already been added!"
                    type="warning"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

Users.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Users.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Users;
