import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Button from 'erpcore/components/Button';
import Form, { CheckboxGroup } from 'erpcore/components/Form';

const UserReportingDashboardForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    projects
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Column>
                <Field
                    name="client_available_projects"
                    id="client_available_projects"
                    fieldProps={{
                        label: 'Client available projects',
                        options: projects,
                        displayAsRows: true,
                        column: true
                    }}
                    fieldAttr={{ required: true }}
                    component={CheckboxGroup}
                />
            </Form.Column>
            <br />
            <br />
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Update" />
            </Form.Row>
        </Form>
    );
};

UserReportingDashboardForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    projects: []
};

UserReportingDashboardForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    projects: PropTypes.oneOfType([PropTypes.array])
};

export default reduxForm({
    form: 'UserReportingDashboardForm',
    enableReinitialize: true
})(UserReportingDashboardForm);
