import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import NeighbourhoodForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/NeighbourhoodForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import {
    getProjectData,
    getProjectFetching,
    getProjectFormValues
} from 'erpcore/screens/Projects/Projects.selectors';
import { dtoForm } from 'erpcore/utils/dto';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const ProjectEditMLALiveNeighbourhood = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectFormValues =
        useSelector(state => getProjectFormValues(state, 'NeighbourhoodForm', projectIri)) || {};
    const {
        neighbourhood_locations: neighbourhoodLocationsFormList,
        use_hardcoded_pins: useHardcodedPins
    } = projectFormValues;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const projectDataForm = dtoForm(projectData) || {};
    const fetching = useSelector(getProjectFetching);

    const { neighbourhood_locations: neighbourhoodLocations } = projectDataForm;

    const initialValues = {
        neighbourhood_gallery: projectData.neighbourhood_gallery,
        neighbourhood_google_maps: projectData.neighbourhood_google_maps,
        neighbourhood_google_street: projectData.neighbourhood_google_street,
        neighborhood_map_configuration_id: projectData.neighborhood_map_configuration_id,
        neighborhood_scores: projectData.neighborhood_scores,
        neighbourhood_locations: neighbourhoodLocations,
        use_hardcoded_pins: projectData.use_hardcoded_pins
    };

    const coordinates =
        Array.isArray(neighbourhoodLocationsFormList) &&
        neighbourhoodLocationsFormList?.map(neighbourhoodLocationFormItem => ({
            id: neighbourhoodLocationFormItem?.id,
            name: neighbourhoodLocationFormItem?.name,
            lat: neighbourhoodLocationFormItem?.latitude,
            lng: neighbourhoodLocationFormItem?.longitude
        }));

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data
        const formDataDiff = diff(initialValues, formData);
        // Handle data
        const {
            neighbourhood_gallery: neighbourhoodGallery,
            neighbourhood_locations: neighbourhoodLocationsDiff
        } = { ...formDataDiff };

        if (neighbourhoodGallery) {
            formDataDiff.neighbourhood_gallery = formData.neighbourhood_gallery;
        }

        if (neighbourhoodLocationsDiff) {
            formDataDiff.neighbourhood_locations = cleanRepeaterOutput(
                formData.neighbourhood_locations,
                ['customIdentifier']
            );
        }

        formDataDiff.neighborhood_scores = formData.neighborhood_scores;

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <NeighbourhoodForm
                    onSubmit={onSubmit}
                    form={`NeighbourhoodForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                    coordinates={coordinates}
                    useHardcodedPins={useHardcodedPins}
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMLALiveNeighbourhood.defaultProps = {
    match: {}
};

ProjectEditMLALiveNeighbourhood.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProjectEditMLALiveNeighbourhood);
