import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto, { getIdFromIri } from 'erpcore/utils/dto';
import { actions as optionsActions } from 'erpcore/screens/Settings/Options/Options.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Create Option Item
 * @param  {Object} promise
 * @param  {Object} formData
 */
export function* postOptions({ promise, formData }) {
    try {
        const postOptionsAPI = yield restClient.post(`api/options`, formData);
        yield put({
            type: optionsActions.POST_OPTIONS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(postOptionsAPI?.data)
        });
        yield put({
            type: optionsActions.STORE_OPTIONS_DATA,
            response: dto(postOptionsAPI?.data)?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: optionsActions.POST_OPTIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Update Option Item
 * @param  {Object} promise
 * @param  {string} iri
 * @param  {Object} formData
 */
export function* updateOptions({ promise, iri, formData }) {
    try {
        const putOptionsAPI = yield restClient.put(`api/options/${getIdFromIri(iri)}`, formData);
        yield put({
            type: optionsActions.UPDATE_OPTIONS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: dto(putOptionsAPI?.data)
        });
        yield put({
            type: optionsActions.STORE_OPTIONS_DATA,
            response: dto(putOptionsAPI?.data)?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: optionsActions.UPDATE_OPTIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Fetch Option Item
 * @param  {Object} promise
 * @param  {Object} formData
 */
export function* fetchOptions({ promise, option = null }) {
    try {
        const fetchOptionsAPI = yield restClient.get(`api/options?filters[name][equals]=${option}`);
        yield put({
            type: optionsActions.FETCH_OPTIONS_SUCCESSFUL
        });
        yield put({
            type: optionsActions.STORE_OPTIONS_DATA,
            // always select first in array
            // as there should be only one options page
            response: dto(fetchOptionsAPI?.data)?.data?.[0]
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: optionsActions.FETCH_OPTIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(optionsActions.START_POSTING_OPTIONS, postOptions),
    takeLatest(optionsActions.START_UPDATING_OPTIONS, updateOptions),
    takeLatest(optionsActions.START_FETCHING_OPTIONS, fetchOptions)
];
