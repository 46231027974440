import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as clientActions } from 'erpcore/screens/Clients/Clients.reducer';

const ClientDelete = ({ iri, handleSubmit, submitting, clientName }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: clientActions.START_DELETE_CLIENT,
                iri
            })
        )
            .then(() => {
                handleModal();
            })
            .catch(error => {
                handleModal();
                return error;
            });
    };

    return (
        <Fragment>
            <Tooltip content="Delete client">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title="Delete client"
                subtitle={
                    <>
                        Are you sure you want to delete client <strong>{clientName}</strong>?
                        <br />
                        Client will be archived, you will be able to access it in the future along
                        with all related records.
                    </>
                }
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

ClientDelete.defaultProps = {
    submitting: false,
    iri: null,
    clientName: null
};

ClientDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    iri: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    clientName: PropTypes.string
};

export default reduxForm({
    form: 'ClientDeleteForm',
    enableReinitialize: true
})(ClientDelete);
