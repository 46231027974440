import React, { useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import orderBy from 'lodash/orderBy';

// import { generateKey } from 'erpcore/';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageLoader from 'erpcore/components/PageLoader';
import Card from 'erpcore/components/Layout/Card';
import Collapse from 'erpcore/components/Collapse';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Button from 'erpcore/components/Button';

import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import {
    getSingleProspectFetching,
    getProspectData,
    getProspectSessionFetching
} from 'erpcore/screens/Prospects/Prospects.selectors';

import './ProspectView.scss';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const ProspectView = ({ match }) => {
    const dispatch = useDispatch();
    const meData = useSelector(getMeData) || {};
    const prospectIri = `/api/prospects/${match?.params?.id}`;
    const prospectData = useSelector(state => getProspectData(state, prospectIri)) || {};
    const fetching = useSelector(state => getSingleProspectFetching(state));
    const sessionFetching = useSelector(state => getProspectSessionFetching(state));

    const {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        city = {},
        deals = [],
        sessions = []
    } = {
        ...prospectData
    };

    const getProjectByIri = (projectIri, sessionData) =>
        sessionData?.projects?.filter(project => project.iri === projectIri)[0] || {};

    const fetchProspectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_SINGLE_PROSPECT,
                iri: prospectIri
            });
        }).catch(error => ({ error }));
    };

    const fetchProspectQAData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_PROSPECT_QA,
                prospectIri
            });
        }).catch(error => ({ error }));
    };

    const fetchProspectSessionData = () => {
        return new Promise((resolve, reject) => {
            const dealIris = [];
            deals.map(deal => dealIris.push(deal?.iri));
            if (dealIris?.length > 0) {
                dispatch({
                    promise: { resolve, reject },
                    type: prospectActions.START_FETCHING_SINGLE_PROSPECT_SESSION,
                    iri: prospectIri,
                    dealIris
                });
            }
        }).catch(error => ({ error }));
    };

    const pageTitle = () => {
        if (firstName || lastName) {
            return `Prospect details - ${firstName} ${lastName}`;
        }

        return `Prospect details`;
    };

    const prepareNotesData = () => {
        const allNotes = [];
        deals.map(item => {
            const { notes } = { ...item };
            if (notes) {
                notes.map(note => {
                    return allNotes.push({ note, item });
                });
            }
            return null;
        });
        return uniqBy(allNotes, e => e?.note?.comment);
    };

    const prospectNotes = () => {
        const notes = prepareNotesData();
        if (notes?.length > 0) {
            return notes.map(noteDetails => (
                <div
                    key={`note-${noteDetails?.note?.date || noteDetails?.session?.date}`}
                    className="session-view__note"
                >
                    <div className="session-view__note-info">
                        <span>
                            {noteDetails?.note.date
                                ? moment(noteDetails?.note?.date).format('MMM Do, YYYY, h:mm a')
                                : moment(noteDetails?.session?.date).format('MMM Do, YYYY, h:mm a')}
                        </span>
                        {!!noteDetails?.note.project && (
                            <span>
                                {' '}
                                @{' '}
                                {
                                    getProjectByIri(noteDetails?.note.project, noteDetails?.session)
                                        ?.name
                                }
                            </span>
                        )}
                    </div>
                    <div
                        className="session-view__note-comment"
                        dangerouslySetInnerHTML={{ __html: noteDetails?.note.comment }}
                    />
                </div>
            ));
        }
        return 'There are no notes currently.';
    };

    const renderInfoBox = () => (
        <Card>
            <div className="prospect-view__info-box">
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">Prospect</span>
                    <span className="prospect-view__data-body">
                        {firstName} {lastName}
                    </span>
                </div>
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">City</span>
                    <span className="prospect-view__data-body">{city?.name || '-'}</span>
                </div>
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">Email</span>
                    <span className="prospect-view__data-body">{email || '-'}</span>
                </div>
                <div className="prospect-view__data">
                    <span className="prospect-view__data-header">Telephone</span>
                    <span className="prospect-view__data-body">{phone || '-'}</span>
                </div>
            </div>
        </Card>
    );

    const sortSessions = () => {
        let upcoming = [];
        let past = [];
        sessions.map(item => {
            return item?.duration ? past.push(item) : upcoming.push(item);
        });
        past = orderBy(past, ['session.date'], ['desc']);
        upcoming = orderBy(upcoming, ['session.date'], ['desc']);

        return { upcoming, past };
    };
    const sortedSessions = sortSessions();

    const renderSessionProspectInfo = () => (
        <Card>
            <Card.Title>Upcoming Sessions</Card.Title>
            {sortedSessions?.upcoming?.length > 0 ? (
                sortedSessions.upcoming.map(session => {
                    const { sales_agent: salesAgent = {} } = session;
                    const {
                        first_name: salesAgentFirstName = '',
                        last_name: salesAgentLastName = ''
                    } = salesAgent;
                    return (
                        <div
                            key={`upcoming-session-${session.id}`}
                            className="prospect-view__session"
                        >
                            {session?.date ? (
                                <Card.Title>
                                    {moment(session?.date).format('MMM Do, YYYY, h:mm a')}
                                </Card.Title>
                            ) : (
                                ''
                            )}
                            {salesAgentFirstName && salesAgentLastName && (
                                <div className="prospect-view__session-line">
                                    <div className="prospect-view__session-line-label">
                                        {`${salesAgentFirstName} ${salesAgentLastName}`}
                                    </div>
                                </div>
                            )}
                            <div className="prospect-view__session-line">
                                {session?.deal?.project?.id && (
                                    <div className="prospect-view__session-line-content">
                                        <Svg icon="building" />{' '}
                                        <p>{`${session?.deal?.project.name}`}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })
            ) : (
                <p>There are currently no scheduled sessions for this prospect.</p>
            )}
            {sortedSessions?.past?.length > 0 && (
                <>
                    <Card.Title>History Sessions</Card.Title>
                    {sortedSessions.past.map(session => {
                        const {
                            sales_agent: salesAgent = {},
                            duration = 0,
                            nr_of_views: nrOfViews
                        } = session;
                        const {
                            first_name: salesAgentFirstName = '',
                            last_name: salesAgentLastName = ''
                        } = salesAgent;
                        return (
                            <div
                                key={`past-session-${session.id}`}
                                className="prospect-view__session"
                            >
                                <Card.Title>
                                    {moment(session?.date).format('MMM Do, YYYY, h:mm a') || ''}
                                </Card.Title>
                                <div className="prospect-view__session-line">
                                    <div className="prospect-view__session-line-label">
                                        {`${salesAgentFirstName} ${salesAgentLastName}`}
                                    </div>
                                    <span className="prospect-view__session-details">
                                        <span className="prospect-view__session-detail">
                                            <Tooltip content="Number of prospects on session">
                                                <Svg icon="profile" />1
                                            </Tooltip>
                                        </span>
                                        <span className="prospect-view__session-detail">
                                            <Tooltip content="Duration of session">
                                                <Svg icon="presentation" />
                                                {moment.utc(duration * 1000).format('HH[h] mm[m]')}
                                            </Tooltip>
                                        </span>
                                        <span className="prospect-view__session-detail">
                                            <Tooltip content="Number of times a prospect viewed post-demo preview">
                                                <Svg icon="passShow" />
                                                {nrOfViews || 0}
                                            </Tooltip>
                                        </span>
                                    </span>
                                </div>
                                <div className="prospect-view__session-line">
                                    {session?.deal?.project?.id && (
                                        <div className="prospect-view__session-line-content">
                                            <Svg icon="building" />{' '}
                                            <p>{`${session?.deal?.project.name}`}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
        </Card>
    );

    const renderSidebar = () => (
        <Card>
            <Collapse initiallyExpanded>
                <Collapse.Visible>
                    <Card.Title>Deals</Card.Title>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <div className="prospect-view__deals">
                        {deals.length === 0 && 'There are no deals currently'}
                        {deals?.map(deal => {
                            const { unit_type: unitType } = { ...deal };
                            return (
                                <div key={`deal-${deal?.iri}`} className="prospect-view__deal">
                                    <div className="prospect-view__deal-header">
                                        <span className="prospect-view__deal-title">
                                            {deal?.name || '-'}
                                        </span>
                                        <span className="prospect-view__deal-link">
                                            {meData?._type === 'sales-agent' ? (
                                                <Button
                                                    size="small"
                                                    href={`/deals/${deal?.id}/view`}
                                                    variation="link"
                                                    label="View"
                                                />
                                            ) : (
                                                <Button
                                                    size="small"
                                                    href={`/prospects/${match?.params?.id}/edit/deals/${deal?.id}`}
                                                    variation="link"
                                                    label="View"
                                                />
                                            )}
                                        </span>
                                    </div>
                                    <div className="prospect-view__deal-info">
                                        <span>{deal?.project?.name || '-'}</span>
                                        <span>{deal?.unit?.name || '-'}</span>
                                        <span>{unitType?.name || '-'}</span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Collapse.Expandable>
            </Collapse>
            <Collapse initiallyExpanded>
                <Collapse.Visible>
                    <Card.Title>Notes</Card.Title>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <div className="prospect-view__notes">{prospectNotes()}</div>
                </Collapse.Expandable>
            </Collapse>
        </Card>
    );

    /*
     * Effects
     */
    useEffect(() => {
        fetchProspectData().then(fetchProspectSessionData().then(fetchProspectQAData()));
    }, []);

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                {meData?._type !== 'sales-agent' && (
                    <Button
                        size="small"
                        href={`/prospects/${prospectData?.id}/edit`}
                        variation="tertiary"
                        label="Edit Prospect"
                    />
                )}
            </PageHeader>
            <PageContent>
                {(fetching || sessionFetching) && <PageLoader content />}
                <div className="prospect-view">
                    <div className="prospect-view__header">{renderInfoBox()}</div>
                    <div className="prospect-view__content">
                        <div className="prospect-view__main">{renderSessionProspectInfo()}</div>
                        <div className="prospect-view__sidebar">{renderSidebar()}</div>
                    </div>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

ProspectView.defaultProps = {
    match: {}
};

ProspectView.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProspectView);
