import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';

import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import { hasPermission } from 'erpcore/utils/RolesManager';
import { hsPipelines, hsStages } from 'erpcore/screens/Deals/data/dealsData';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

const Deals = () => {
    const reducerName = 'deals';
    const actionName = 'DEALS';

    const dispatch = useDispatch();
    const meData = useSelector(getMeData) || {};
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Deals';

    const fetchDeals = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/deals?include=client,project,unit,unitType,prospects'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Filters
        table.filters = [
            {
                name: 'project',
                label: 'Project',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Select Project',
                            options: {
                                endpoint: `api/projects`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'prospects',
                label: 'Prospects',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'autocomplete',
                        fieldProps: {
                            label: 'Select Prospects',
                            options: {
                                endpoint: `api/prospects`,
                                mapData: {
                                    value: 'iri',
                                    label: '{first_name} {last_name}'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'hubspot_pipeline',
                label: 'Hubspot Pipeline',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Select Hubspot Pipeline',
                            options: hsPipelines,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'hubspot_stage',
                label: 'Hubspot Stage',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Select Hubspot Stage',
                            options: hsStages,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'meta.duplicate_info_reason_delete',
                label: 'Deleted',
                filterTagLabel: 'Deal has delete info',
                defaultOperator: 'exists',
                filterFields: {
                    defaultValue: '1',
                    additionalFilter: {
                        filterTagLabel: 'Deleted deals',
                        fieldName: 'deleted',
                        defaultValue: '1'
                    }
                }
            }
        ];

        // Table Schema
        table.schema = [
            {
                title: 'Deal Name',
                field: 'name'
            },
            {
                title: 'Prospects',
                field: 'prospects'
            },
            {
                title: 'Project',
                field: 'project'
            },
            {
                title: 'Unit',
                field: 'unit'
            },
            {
                title: 'HS Pipeline',
                field: 'hs_pipeline'
            },
            {
                title: 'HS Stage',
                field: 'hs_stage'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        // Table Data
        if (listing && listing.data) {
            listing.data.map(row => {
                console.error(row);
                let prospects = 'No prospects';
                if (row?.prospects?.length === 1) {
                    // eslint-disable-next-line camelcase
                    prospects = `${row?.prospects[0]?.first_name} ${row?.prospects[0]?.last_name}`;
                } else if (row?.prospects?.length > 1) {
                    prospects = 'Multiple prospects';
                }

                const { hubspot_pipeline_label: hsPipeline, hubspot_stage_label: hsStage } = {
                    ...row
                };

                const { duplicate_info_reason_delete: duplicateInfoReasonDelete } = {
                    ...(row?.meta || {})
                };

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    name: (
                        <>
                            {row?.name || 'Unnamed Deal'}
                            {duplicateInfoReasonDelete && (
                                <>
                                    <br />
                                    <small>
                                        <strong>Delete info: </strong>
                                        {duplicateInfoReasonDelete}
                                    </small>
                                </>
                            )}
                        </>
                    ),
                    prospects,
                    project: row?.project?.name || 'No Project',
                    unit: row?.unit?.name || '-',
                    hs_pipeline: hsPipeline || '-',
                    hs_stage: hsStage || '-',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="View Deal">
                                    <NavLink to={`/deals/${row?.id}/view`}>
                                        <Svg icon="passShow" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            {hasPermission('CAN_EDIT_COMMON_DEAL', meData) && (
                                <TableActions.Action>
                                    <Tooltip content="Edit Deal">
                                        <NavLink to={`/deals/${row?.id}/edit`}>
                                            <Svg icon="edit" />
                                        </NavLink>
                                    </Tooltip>
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/deals/create" label="Create new" />
            </PageHeader>

            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchDeals(params)}
            />
        </LayoutManager>
    );
};

export default Deals;
