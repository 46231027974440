import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import restClient from 'erpcore/api/restClient';
import Svg from 'erpcore/components/Svg';
import { operations } from 'erpcore/data/filterOperations';
import { getOptionByValue } from 'erpcore/components/Form/Form.utils';
import { generateKey } from 'erpcore/utils/utils';
import './FilterTags.scss';

const FilterTags = ({ onChangeFilterTag, queryParams, filterSchema }) => {
    const [apiLabels, setApiLabels] = useState([]);

    const getFilterSchemaData = queryParamsItemKey => {
        let filterSchemaItem = find(
            filterSchema,
            item =>
                item.name === queryParamsItemKey.replace(/>/g, '.') ||
                item?.filterFields?.additionalFilter?.fieldName ===
                    queryParamsItemKey.replace(/>/g, '.')
        );
        if (
            queryParamsItemKey.replace(/>/g, '.') ===
            filterSchemaItem?.filterFields?.additionalFilter?.fieldName
        ) {
            filterSchemaItem = filterSchemaItem?.filterFields?.additionalFilter;
        }
        const hasAPIOptions = !!(
            filterSchemaItem?.filterFields?.value?.fieldProps?.options?.endpoint &&
            filterSchemaItem?.filterFields?.value?.fieldProps?.options?.mapData
        );
        let mapData = false;
        if (hasAPIOptions) {
            mapData = filterSchemaItem?.filterFields?.value?.fieldProps?.options?.mapData;
        }
        const hasOptions = !!(
            filterSchemaItem?.filterFields?.value?.fieldProps?.options &&
            isArray(filterSchemaItem?.filterFields?.value?.fieldProps?.options)
        );
        let filterSchemaOptions = [];
        if (hasOptions) {
            filterSchemaOptions = filterSchemaItem?.filterFields?.value?.fieldProps?.options;
        }
        const { defaultOperator } = filterSchemaItem || {};
        return {
            filterSchemaItem,
            hasAPIOptions,
            mapData,
            hasOptions,
            filterSchemaOptions,
            defaultOperator
        };
    };

    useEffect(() => {
        map(queryParams.filter, (queryParamsItem, queryParamsItemKey) => {
            const filterSchemaData = getFilterSchemaData(queryParamsItemKey);
            if (filterSchemaData.hasAPIOptions) {
                map(queryParamsItem, queryParamValue => {
                    map(queryParamValue, (valueItem, key) => {
                        if (key !== 'operator') {
                            if (!isArray(valueItem)) valueItem = [valueItem];
                            map(valueItem, valueItemItem => {
                                if (getOptionByValue(valueItemItem, apiLabels)?.notFound) {
                                    restClient
                                        .get(valueItemItem)
                                        .then(response => {
                                            let labelTemplate = filterSchemaData.mapData?.label;
                                            const labelTemplateMatch = labelTemplate?.match(
                                                /[^{]+(?=})/g
                                            );
                                            if (labelTemplateMatch) {
                                                labelTemplateMatch.forEach(labelItem => {
                                                    labelTemplate = labelTemplate.replace(
                                                        `{${labelItem}}`,
                                                        response?.data?.data?.attributes?.[
                                                            labelItem
                                                        ] || ''
                                                    );
                                                });
                                            }
                                            const apiLabel = labelTemplateMatch
                                                ? labelTemplate
                                                : response?.data?.data?.attributes?.[labelTemplate];
                                            setApiLabels(oldApiLabels => [
                                                ...oldApiLabels,
                                                { value: valueItemItem, label: apiLabel }
                                            ]);
                                        })
                                        .catch(error => error);
                                }
                            });
                        }
                    });
                });
            }
        });
    }, [queryParams]);

    const tags = [];
    map(queryParams.filter, (queryParamsItem, queryParamsItemKey) => {
        const filterSchemaData = getFilterSchemaData(queryParamsItemKey);
        map(queryParamsItem, (queryParamValue, index) => {
            const values = [];
            const operator = queryParamValue?.operator || filterSchemaData?.defaultOperator;
            map(queryParamValue, (valueArray, key) => {
                if (key !== 'operator') {
                    if (!isArray(valueArray)) valueArray = [valueArray];
                    map(valueArray, valueItem => {
                        values.push(
                            getOptionByValue(valueItem, [
                                ...apiLabels,
                                ...filterSchemaData.filterSchemaOptions
                            ])?.label || '...'
                        );
                    });
                }
            });
            tags.push({
                key: queryParamsItemKey,
                index,
                filterTagLabel: filterSchemaData?.filterSchemaItem?.filterTagLabel,
                label: filterSchemaData?.filterSchemaItem?.label || '',
                operator: getOptionByValue(operator, operations)?.label || operator,
                value: values.join(' or ')
            });
        });
    });

    const removeTag = item => {
        queryParams.filter[item.key].splice(item.index, 1);
        onChangeFilterTag(queryParams);
    };

    return (
        tags.length > 0 && (
            <ul className="filtertags">
                {map(tags, item => (
                    <li className="filtertags__item" key={generateKey(`${item.key}${item.index}`)}>
                        <span className="filtertags__label">
                            {item.filterTagLabel ? (
                                item?.filterTagLabel
                            ) : (
                                <>
                                    {item.label}
                                    {item.operator && ` ${item.operator}`}
                                    {item.value && ` ${item.value}`}
                                </>
                            )}
                        </span>
                        <button
                            type="button"
                            className="filtertags__btn"
                            key={`${item.type}${item.value}`}
                            onClick={() => removeTag(item)}
                        >
                            <Svg icon="close" />
                        </button>
                    </li>
                ))}
                <li className="filtertags__clear">
                    <button
                        type="button"
                        className="filtertags__btn-clear"
                        onClick={() => {
                            queryParams.filter = {};
                            onChangeFilterTag(queryParams);
                        }}
                    >
                        Clear All
                    </button>
                </li>
            </ul>
        )
    );
};

FilterTags.defaultProps = {
    onChangeFilterTag: () => {},
    queryParams: {},
    filterSchema: []
};

FilterTags.propTypes = {
    onChangeFilterTag: PropTypes.func,
    queryParams: PropTypes.oneOfType([PropTypes.object]),
    filterSchema: PropTypes.oneOfType([PropTypes.array])
};

export default FilterTags;
