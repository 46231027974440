import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getOptionsByValues } from 'erpcore/components/Form/Form.utils';
import styles from './MultiSelect.styles';
import Input from '../Input';
import { DropdownIndicator, ClearIndicator, selectPropsMapper } from '../Select';

class MultiSelectDropdown extends Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            labelActive: false,
            menuIsOpen: false
        };
    }

    setMenuVisibility(open = true) {
        this.setState({ menuIsOpen: open });
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { input, fieldProps, fieldAttr, meta, field } = this.props;
        const { labelActive, menuIsOpen } = this.state;

        const { options, menuPlacement } = fieldProps;

        fieldProps.forceLabelActive = labelActive;

        //  Getting select required value from options
        const fieldValues = getOptionsByValues(input.value, options);

        //  Standardizing props for select
        const mappedConf = selectPropsMapper(fieldProps, fieldAttr);

        return (
            <Input
                fieldProps={fieldProps}
                fieldAttr={fieldAttr}
                field={field}
                input={input}
                meta={meta}
            >
                <Select
                    isMulti
                    styles={styles}
                    components={{ DropdownIndicator, ClearIndicator }}
                    onChange={selectedOption => {
                        if (selectedOption && selectedOption.length) {
                            const selectedOptionValues = [];
                            selectedOption.forEach(item => {
                                selectedOptionValues.push(item.value);
                            });
                            input.onChange(selectedOptionValues);
                        } else {
                            input.onChange([]);
                        }
                        this.setMenuVisibility(true);
                    }}
                    onInputChange={inputValue =>
                        inputValue !== ''
                            ? this.setState({ labelActive: true })
                            : this.setState({ labelActive: false })
                    }
                    onFocus={() => {
                        input.onBlur();
                        this.setState({ labelActive: true });
                    }}
                    placeholder=""
                    className={`react-select react-select--multi ${
                        menuPlacement === 'top' ? 'react-select--menu-top' : ''
                    }`}
                    classNamePrefix="react-select"
                    isClearable
                    menuIsOpen={menuIsOpen}
                    onMenuOpen={() => this.setMenuVisibility(true)}
                    onMenuClose={() => this.setMenuVisibility(false)}
                    value={fieldValues}
                    name={input.name}
                    options={options}
                    id={input.name}
                    {...mappedConf.fieldAttr}
                    {...mappedConf.fieldProps}
                />
            </Input>
        );
    }
}
MultiSelectDropdown.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};
MultiSelectDropdown.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};
export default MultiSelectDropdown;
