import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions as sessionActions } from 'erpcore/screens/Settings/Session/SessionSettings.reducer';
import ProspectProfileQuestionsForm from 'erpcore/screens/Settings/Session/components/ProspectProfileQuestionsForm';
import Modal from 'erpcore/components/Modal';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import { diff } from 'deep-object-diff';

const ProspectProfileQuestions = ({ data }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);
    const { question, answers, section, answer_type: answerType } = { ...data };

    const initialValues = { question, answers, section, answer_type: answerType };

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = formData => {
        const formDataDiff = diff(initialValues, formData);

        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionActions.START_UPDATE_PROFILE_QUESTION,
                promise: { resolve, reject },
                formData: {
                    ...formDataDiff,
                    answers: formData?.answers
                },
                iri: data?.iri
            });
        }).finally(() => {
            handleModal();
        });
    };

    return (
        <Fragment>
            <Tooltip content="Edit Question">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal
                variation="medium"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Edit Question"
            >
                <ProspectProfileQuestionsForm
                    onSubmit={onSubmit}
                    onCancel={() => handleModal()}
                    form={`ProspectProfileQuestionsForm-${data?.id}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                    radioID="edit"
                />
            </Modal>
        </Fragment>
    );
};

ProspectProfileQuestions.defaultProps = {
    data: {}
};

ProspectProfileQuestions.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object])
};

export default ProspectProfileQuestions;
