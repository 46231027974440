import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';

const SignOut = () => {
    const dispatch = useDispatch();
    const signOut = () => {
        dispatch({
            type: authActions.START_SIGN_OUT
        });
    };

    useEffect(() => {
        signOut();
    }, []);
    return (
        <>
            <h1>Signin out...</h1>
        </>
    );
};
SignOut.propTypes = {};
export default SignOut;
