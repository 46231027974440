import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import ProspectForm from 'erpcore/screens/Prospects/components/ProspectForm';
import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import { reset, SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { useDispatch } from 'react-redux';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

const ProspectCreate = () => {
    const dispatch = useDispatch();
    const title = 'Create new prospect';

    const onSubmit = formData => {
        // Handle Location data
        const { location } = { ...formData };
        if (location) {
            formData = setLocationData('location', formData);
        }

        if (!formData?.projects) {
            formData.projects = [];
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_CREATE_PROSPECT,
                formData
            });
        })
            .then(() => {
                dispatch(reset('ProspectCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <ProspectForm onSubmit={onSubmit} form="ProspectCreateForm" />
            </PageContent>
        </LayoutManager>
    );
};

export default ProspectCreate;
