import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { Link } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import ClientCreateEditForm from 'erpcore/screens/Clients/components/ClientCreateEditForm';
import Button from 'erpcore/components/Button';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import Form from 'erpcore/components/Form';
import { actions as clientsActions } from 'erpcore/screens/Clients/Clients.reducer';
import { dtoForm } from 'erpcore/utils/dto';
import { getSingleClientFetching, getClientData } from 'erpcore/screens/Clients/Clients.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

const ClientEdit = ({ match }) => {
    const dispatch = useDispatch();
    const clientIri = `/api/clients/${match?.params?.id}`;
    const clientData = dtoForm(useSelector(state => getClientData(state, clientIri)));
    const clientProjectData = useSelector(state => getClientData(state, clientIri));
    const fetching = useSelector(getSingleClientFetching);
    const params = {
        include: 'city,country,state,logoImage,projects'
    };
    const {
        name,
        email,
        phone,
        website,
        description,
        logo_image: logo,
        full_address: address,
        street,
        country,
        state,
        city,
        zip,
        latitude,
        longitude
    } = { ...clientData };

    const initialValues = {
        name,
        email,
        phone,
        website,
        description,
        logo_image: logo,
        location: {
            full_address: address,
            street,
            country,
            state,
            city,
            zip,
            latitude,
            longitude
        }
    };

    const pageTitle = () => {
        if (clientData?.name) {
            return `Client - ${clientData.name}`;
        }

        return `Client`;
    };

    const fetchClientData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: clientsActions.START_FETCHING_CLIENT,
                iri: clientIri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        fetchClientData();
    }, []);

    const onSubmitEditClientForm = formData => {
        formData = diff(clientData, formData);

        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: clientsActions.START_UPDATE_CLIENT,
                promise: { resolve, reject },
                iri: clientIri,
                formData
            });
        });
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                <Button href="/clients/create" label="Create client" />
            </PageHeader>
            <PageContent>
                {fetching === true && <PageLoader content />}
                <ClientCreateEditForm
                    initialValues={initialValues}
                    onSubmit={onSubmitEditClientForm}
                    form="ClientEditForm"
                    submitLabel="Update"
                />
                {clientProjectData?.projects?.length ? (
                    <Form.SectionTitleSmall>Projects</Form.SectionTitleSmall>
                ) : null}
                <br />
                {clientProjectData?.projects?.map(clientProject => {
                    return (
                        <ul>
                            <li key={clientProject.id}>
                                <Link
                                    to={{
                                        pathname: `/projects/${clientProject.id}/edit/general-info`,
                                        state: { fromClientEdit: true }
                                    }}
                                >
                                    {clientProject?.name}
                                </Link>
                            </li>
                        </ul>
                    );
                })}
            </PageContent>
        </LayoutManager>
    );
};

ClientEdit.defaultProps = {
    match: {}
};

ClientEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ClientEdit;
