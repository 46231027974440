import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { NavLink } from 'react-router-dom';
import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import ProjectDelete from 'erpcore/screens/Projects/components/ProjectDelete';
import StatusCircle from 'erpcore/components/StatusCircle';
import Svg from 'erpcore/components/Svg';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';

import { hasPermission } from 'erpcore/utils/RolesManager';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';

import { projectIsActive } from 'erpcore/screens/Projects/data/projectsData';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

const Projects = () => {
    const reducerName = 'projects';
    const actionName = 'PROJECTS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const meData = useSelector(getMeData) || {};
    const title = 'Projects';

    const fetchProjects = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/projects?include=client'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Filters
        table.filters = [
            {
                name: 'client',
                label: 'Client',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Client',
                            options: {
                                endpoint: `/api/clients`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'is_active',
                label: 'Is active',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Is active',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'mla_blue',
                label: 'Is blue active',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Is active',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'mla_oi',
                label: 'Is OI active',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Is active',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'portal_enabled',
                label: 'Is portal active',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Is active',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'test_portal_enabled',
                label: 'Is test portal active',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Is active',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'syncable',
                label: 'Syncable Active',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Active is',
                            options: projectIsActive,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        // Table Schema
        table.schema = [
            {
                title: 'Project',
                field: 'name',
                sortable: 'name'
            },
            {
                title: 'Client',
                field: 'client',
                sortable: false
            },
            {
                title: 'Avesdo & HS synced',
                field: 'avesdoAndHsSynced',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Is active',
                field: 'isActive',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Is Portal active',
                field: 'portal_active',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Is Blue active',
                field: 'mla_blue',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Is Oi active',
                field: 'mla_oi',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Is Test Portal active',
                field: 'realtor_portal_active',
                sortable: false,
                align: 'center'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        /*
        table.defaultSort = {
            order: 'ASC',
            sortable: 'name'
        };
        */

        // Table Data
        if (listing && listing.data) {
            listing.data.map(row => {
                const {
                    id,
                    iri,
                    name,
                    client,
                    is_active: isActive,
                    mla_blue: mlaBlue,
                    mla_oi: mlaOi,
                    portal_enabled: portalActive,
                    test_portal_enabled: testPortalEnabled,
                    avesdo_id: avesdoID,
                    hubspot_id: hubspotID,
                    syncable
                } = {
                    ...row
                };

                return table.data.push({
                    id,
                    iri,
                    name,
                    client: client?.name,
                    avesdoAndHsSynced: (
                        <StatusCircle
                            type={avesdoID && hubspotID && syncable ? 'apple' : 'tomato'}
                        />
                    ),
                    portal_active: <StatusCircle type={portalActive ? 'apple' : 'tomato'} />,
                    isActive: <StatusCircle type={isActive ? 'apple' : 'tomato'} />,
                    realtor_portal_active: (
                        <StatusCircle type={testPortalEnabled ? 'apple' : 'tomato'} />
                    ),
                    mla_blue: <StatusCircle type={mlaBlue ? 'apple' : 'tomato'} />,
                    mla_oi: <StatusCircle type={mlaOi ? 'apple' : 'tomato'} />,
                    actions: (
                        <TableActions>
                            {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) ||
                            hasPermission('CAN_EDIT_ASSIGNED_PROJECTS', meData) ? (
                                <>
                                    <TableActions.Action>
                                        <Tooltip content="Edit Project">
                                            <NavLink
                                                to={`/projects/${id}/edit/multi-effect/general-info`}
                                            >
                                                <Svg icon="edit" />
                                            </NavLink>
                                        </Tooltip>
                                    </TableActions.Action>
                                    <TableActions.Action>
                                        <ProjectDelete projectData={row} />
                                    </TableActions.Action>
                                </>
                            ) : (
                                <TableActions.Action>
                                    <Tooltip content="View Project">
                                        <Button
                                            external
                                            labelOnlyAria
                                            iconName="passShow"
                                            href={`${enviromentVariables.DEMO_APP_URL}/project/${id}`}
                                            label=""
                                        />
                                    </Tooltip>
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Button href="/projects/create" label="Create new" />
                )}
            </PageHeader>

            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchProjects(params)}
            />
        </LayoutManager>
    );
};

export default Projects;
