import React from 'react';
import { useDispatch } from 'react-redux';
import { SubmissionError, reset } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import RoleForm from 'erpcore/screens/Settings/Roles/components/RoleForm';

import { actions as rolesActions } from 'erpcore/screens/Settings/Roles/Roles.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const RoleCreate = () => {
    const dispatch = useDispatch();
    const title = 'Create new role';

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: rolesActions.START_CREATE_ROLE,
                formData
            });
        })
            .then(() => {
                dispatch(reset('RoleCreateForm'));
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <RoleForm onSubmit={onSubmit} form="RoleCreateForm" />
            </PageContent>
        </LayoutManager>
    );
};

export default RoleCreate;
