export const actions = {
    START_FETCHING_CLIENTS: 'START_FETCHING_CLIENTS',
    FETCHING_SUCCESSFUL_CLIENTS: 'FETCHING_SUCCESSFUL_CLIENTS',
    FETCHING_FAILED_CLIENTS: 'FETCHING_FAILED_CLIENTS',

    START_DELETE_CLIENT: 'START_DELETE_CLIENT',
    DELETE_CLIENT_SUCCESSFUL: 'DELETE_CLIENT_SUCCESSFUL',
    DELETE_CLIENT_FAILED: 'DELETE_CLIENT_FAILED',

    START_CREATE_CLIENT: 'START_CREATE_CLIENT',
    CREATE_CLIENT_SUCCESSFUL: 'CREATE_CLIENT_SUCCESSFUL',
    CREATE_CLIENT_FAILED: 'CREATE_CLIENT_FAILED',

    START_FETCHING_CLIENT: 'START_FETCHING_CLIENT',
    FETCH_CLIENT_SUCCESSFUL: 'FETCH_CLIENT_SUCCESSFUL',
    FETCH_CLIENT_FAILED: 'FETCH_CLIENT_FAILED',
    STORE_CLIENT_DATA: 'STORE_CLIENT_DATA',

    START_UPDATE_CLIENT: 'START_UPDATE_CLIENT',
    UPDATE_CLIENT_SUCCESSFUL: 'UPDATE_CLIENT_SUCCESSFUL',
    UPDATE_CLIENT_FAILED: 'UPDATE_CLIENT_FAILED',

    START_BULK_ACTIONS_DELETE_CLIENTS: 'START_BULK_ACTIONS_DELETE_CLIENTS',
    BULK_ACTIONS_DELETE_CLIENTS_SUCCESS: 'BULK_ACTIONS_DELETE_CLIENTS_SUCCESS',
    BULK_ACTIONS_DELETE_CLIENTS_FAILED: 'BULK_ACTIONS_DELETE_CLIENTS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    submittingBulkAction: false
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_CLIENTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_FETCHING_CLIENT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_CLIENT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_CLIENT_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response?.data
            });
        }
        case actions.FETCH_CLIENT_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_SUCCESSFUL_CLIENTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_CLIENTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.DELETE_CLIENT_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }

        case actions.UPDATE_CLIENT_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }

        case actions.START_BULK_ACTIONS_DELETE_CLIENTS: {
            return {
                ...state,
                submittingBulkAction: true
            };
        }

        case actions.BULK_ACTIONS_DELETE_CLIENTS_SUCCESS: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }
        case actions.BULK_ACTIONS_DELETE_CLIENTS_FAILED: {
            return {
                ...state,
                submittingBulkAction: false
            };
        }

        default:
            return state;
    }
};
