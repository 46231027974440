import React from 'react';
import { Field } from 'redux-form';
import Form, { Text, Media } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ArchitecturalPlansRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Form.SectionTitleSmall>Document</Form.SectionTitleSmall>
                <Field
                    name={`${member}.iri`}
                    id={`${member}.iri`}
                    component={Media}
                    useLightbox={false}
                    useImageManager={false}
                    allowedFileTypes={['pdf']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

ArchitecturalPlansRepeaterItem.defaultProps = {
    member: null
};

ArchitecturalPlansRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ArchitecturalPlansRepeaterItem;
