import React from 'react';
import PropTypes from 'prop-types';
import Form, {
    Autocomplete,
    SwitchCard,
    Text,
    Currency,
    Select,
    Repeater
} from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { unitOrientation } from 'erpcore/screens/Projects/data/projectsData';
import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { useRouteMatch } from 'react-router-dom';
import UnitAttributesRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitAttributesRepeaterItem';
import { useSelector } from 'react-redux';
import style from './UnitsCreateEditForm.module.scss';

const UnitsCreateEditForm = ({
    handleSubmit,
    onSubmit,
    invalid,
    pristine,
    submitLabel,
    onCancel,
    submitting
}) => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const formValues = useSelector(getFormValues('UnitsCreateEditForm'));
    const { amount, price_description: priceDescription } = { ...formValues };
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="is_portal_enabled"
                    fieldProps={{
                        label: 'Portal Enabled',
                        description:
                            'Turn on this option to create user credentials for the portal app, turn it off to make portal unaccessible.',
                        type: 'single',
                        clearable: true
                    }}
                    fieldAttr={{
                        id: 'is_portal_enabled'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="name"
                    fieldProps={{
                        label: 'Name'
                    }}
                    fieldAttr={{ id: 'name', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="strata_lot"
                    fieldProps={{
                        label: 'Strata lot'
                    }}
                    fieldAttr={{ id: 'strata_lot' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="building"
                    fieldProps={{
                        label: 'Building',
                        options: {
                            endpoint: `api/project/buildings?filters[project.id][equals]=${id}`,
                            mapData: { label: 'name', value: 'iri' }
                        }
                    }}
                    fieldAttr={{ id: 'building', required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="unitType"
                    fieldProps={{
                        label: 'Unit type',
                        options: {
                            endpoint: `api/project/unit-types?filters[project.id][equals]=${id}`,
                            mapData: { label: 'name', value: 'iri' }
                        }
                    }}
                    fieldAttr={{ id: 'unit-type', required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="level"
                    fieldProps={{
                        label: 'Level'
                    }}
                    parse={value => (!value ? null : Number(value))}
                    fieldAttr={{ id: 'level', required: true }}
                    component={Text}
                    validate={valueValidation([
                        { validator: 'required' },
                        { validator: 'numericality' }
                    ])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="orientation"
                    fieldProps={{
                        label: 'Orientation',
                        clearable: true,
                        options: unitOrientation
                    }}
                    fieldAttr={{ id: 'orientation' }}
                    component={Select}
                />
            </Form.Row>
            <Form.Row className={style.amount}>
                <Field
                    name="price_description"
                    fieldProps={{
                        label: 'Amount description'
                    }}
                    fieldAttr={{ id: 'price_description' }}
                    component={Text}
                />
                <Field
                    name="amount"
                    fieldProps={{
                        label: 'Amount'
                    }}
                    fieldAttr={{ id: 'amount' }}
                    component={Currency}
                />

                {(!!amount || !!priceDescription) && (
                    <Field
                        name="amount_public_on_blue"
                        fieldProps={{
                            label: 'Amount publicly visible on MLA Blue'
                        }}
                        fieldAttr={{ id: 'amount_public_on_blue' }}
                        component={SwitchCard}
                    />
                )}
            </Form.Row>
            <Form.Row>
                <Field
                    name="currency"
                    fieldProps={{
                        label: 'Currency',
                        options: {
                            endpoint: `api/currencies?pagination=false`,
                            mapData: { label: '{name} - {code}', value: 'code' }
                        }
                    }}
                    fieldAttr={{ id: 'currency' }}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="listing_status"
                    fieldProps={{
                        label: 'Listings status'
                    }}
                    fieldAttr={{ disabled: true }}
                    component={Text}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Attributes</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="unit_attributes"
                    component={Repeater}
                    RepeatableItem={UnitAttributesRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    canSort={false}
                    addNewLabel="Add new attribute"
                    uniqueIdentifier="iri"
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="is_available"
                    fieldProps={{
                        label: 'Availability',
                        description:
                            'Turn on this option to display this Unit status as Available, turn it off to set it to Unavailable.',
                        type: 'single',
                        clearable: true
                    }}
                    fieldAttr={{
                        id: 'is_available'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>

            <Form.Row>
                <Button
                    loading={submitting}
                    label={submitLabel}
                    submit
                    disabled={invalid || pristine}
                />
                {onCancel && <Button label="Cancel" variation="secondary" onClick={onCancel} />}
            </Form.Row>
        </Form>
    );
};

UnitsCreateEditForm.defaultProps = {
    handleSubmit: () => {},
    onSubmit: () => {},
    onCancel: null,
    invalid: false,
    pristine: false,
    submitting: false,
    submitLabel: ''
};

UnitsCreateEditForm.propTypes = {
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancel: () => {},
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string
};

export default reduxForm({
    form: 'UnitsCreateEditForm',
    enableReinitialize: true
})(UnitsCreateEditForm);
