import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as unitTypesActions } from 'erpcore/screens/Settings/UnitTypes/UnitTypes.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const actionName = 'UNIT_TYPES';
const reducerName = 'unitTypes';

/**
 * Delete Unit Type
 * @param  {Object} unit type iri
 * @return {Object} Response from API
 */
export function* deleteUnitType({ promise, iri }) {
    try {
        const deleteUnitTypeAPI = yield restClient.delete(iri);
        yield put({
            type: unitTypesActions.DELETE_UNIT_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteUnitTypeAPI?.data
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/unit-types`,
            promise
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: unitTypesActions.DELETE_UNIT_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create UnitType
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createUnitType({ promise, formData }) {
    try {
        const createUnitTypeAPI = yield restClient.post('api/unit-types', formData);
        yield put({
            type: unitTypesActions.CREATE_UNIT_TYPE_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/unit-types`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createUnitTypeAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: unitTypesActions.CREATE_UNIT_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Unit Type
 * @param  {Object} iri of unit type
 * @return {Object} Response from API
 */
export function* updateUnitType({ promise, iri, formData }) {
    try {
        const updateUnitTypeAPI = yield restClient.patch(iri, formData);
        yield put({
            type: unitTypesActions.UPDATE_UNIT_TYPE_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/unit-types`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateUnitTypeAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: unitTypesActions.UPDATE_UNIT_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const unitTypesSaga = [
    takeLatest(unitTypesActions.START_DELETE_UNIT_TYPE, deleteUnitType),
    takeLatest(unitTypesActions.START_CREATE_UNIT_TYPE, createUnitType),
    takeLatest(unitTypesActions.START_UPDATE_UNIT_TYPE, updateUnitType)
];

export default unitTypesSaga;
