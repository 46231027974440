import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as dashboardActions } from './Dashboard.reducer';

/**
 * Fetch Sessions
 * @param {Object} promise
 * @return {Object} response from API
 */
export function* fetchSessions({ promise, params }) {
    try {
        const fetchSessionsAPI = yield restClient.get('api/sessions?pagination=false', { params });
        yield put({
            type: dashboardActions.FETCHING_SUCCESSFUL_SESSIONS_DASHBOARD,
            response: dto(fetchSessionsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardActions.FETCHING_FAILED_SESSIONS_DASHBOARD
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const dashboardSaga = [
    takeLatest(dashboardActions.START_FETCHING_SESSIONS_DASHBOARD, fetchSessions)
];
