import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { Text, TagsInput, Select, RadioGroup } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { projectSections } from 'erpcore/screens/Projects/data/projectsData';
import answerTypes from 'erpcore/screens/Settings/Session/data/sessionData';

const ProspectProfileQuestionsForm = ({
    handleSubmit,
    onSubmit,
    submitting,
    pristine,
    invalid,
    submitLabel,
    onCancel,
    radioID
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="answer_type"
                    fieldProps={{
                        options: answerTypes,
                        label: <strong>This question is: </strong>
                    }}
                    fieldAttr={{
                        id: radioID
                    }}
                    component={RadioGroup}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Columns>
                <Form.GridColumn size={8}>
                    <Form.Row>
                        <Field
                            name="question"
                            fieldProps={{
                                label: 'Question',
                                clearable: true
                            }}
                            fieldAttr={{ id: 'question', required: true }}
                            component={Text}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                </Form.GridColumn>
                <Form.GridColumn size={4}>
                    <Form.Row>
                        <Field
                            name="section"
                            fieldProps={{ label: 'Section', options: projectSections }}
                            fieldAttr={{ id: 'section', required: true }}
                            component={Select}
                            validate={valueValidation([{ validator: 'required' }])}
                        />
                    </Form.Row>
                </Form.GridColumn>
            </Form.Columns>

            <Form.Row>
                <Field
                    name="answers"
                    fieldProps={{
                        label: 'Answers',
                        helperText: 'Hit `Tab` or `Enter` to add new answers',
                        placeholder: 'Add new answer'
                    }}
                    fieldAttr={{ id: 'answers', required: true }}
                    component={TagsInput}
                    validate={valueValidation([{ validator: 'length', args: { min: 1 } }])}
                />
            </Form.Row>

            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
                {onCancel && (
                    <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />
                )}
            </Form.Row>
        </Form>
    );
};

ProspectProfileQuestionsForm.defaultProps = {
    handleSubmit: () => {},
    onSubmit: () => {},
    onCancel: null,
    pristine: false,
    invalid: false,
    submitting: false,
    submitLabel: 'Submit'
};

ProspectProfileQuestionsForm.propTypes = {
    handleSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.string,
    radioID: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'ProspectProfileQuestionForm',
    enableReinitialize: true
})(ProspectProfileQuestionsForm);
