import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { MultiAutocomplete } from 'erpcore/components/Form';

import Button from 'erpcore/components/Button';

const AddRoleToUsersForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, roleIri }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="users"
                    fieldProps={{
                        label: 'Find users',
                        clearable: true,
                        options: {
                            endpoint: `/api/users?filters[roles][not_in][]=${roleIri}`,
                            mapData: {
                                value: 'iri',
                                label: '{first_name} {last_name}'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'users' }}
                    component={MultiAutocomplete}
                />
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label="Add role to users"
                />
            </Form.Row>
        </Form>
    );
};

AddRoleToUsersForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    roleIri: null
};

AddRoleToUsersForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    roleIri: PropTypes.string
};

export default reduxForm({
    form: 'AddRoleToUsersForm',
    enableReinitialize: true
})(AddRoleToUsersForm);
