import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as NeighbourhoodLocationSectionsActions } from 'erpcore/screens/Settings/NeighbourhoodLocationSections/NeighbourhoodLocationSections.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const actionName = 'NEIGHBOURHOOD_LOCATION_SECTIONS';
const reducerName = 'neighbourhoodSections';

/**
 * Delete Neighbourhood section
 * @param  {Object} neighbourhood section iri
 * @return {Object} Response from API
 */
export function* deleteNeighbourhoodLocationSection({ promise, iri }) {
    try {
        const deleteNeighbourhoodSectionAPI = yield restClient.delete(iri);
        yield put({
            type:
                NeighbourhoodLocationSectionsActions.DELETE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteNeighbourhoodSectionAPI?.data
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/neighbourhood-location-sections`,
            promise
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: NeighbourhoodLocationSectionsActions.DELETE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create NeighbourhoodLocationSection
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createNeighbourhoodLocationSection({ promise, formData }) {
    try {
        const createNeighbourhoodLocationSectionAPI = yield restClient.post(
            'api/neighbourhood-location-sections',
            formData
        );
        yield put({
            type:
                NeighbourhoodLocationSectionsActions.CREATE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/neighbourhood-location-sections`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createNeighbourhoodLocationSectionAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: NeighbourhoodLocationSectionsActions.CREATE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Neighbourhood section
 * @param  {Object} iri of Neighbourhood section
 * @return {Object} Response from API
 */
export function* updateNeighbourhoodLocationSection({ promise, iri, formData }) {
    try {
        const updateNeighbourhoodLocationSectionAPI = yield restClient.patch(iri, formData);
        yield put({
            type:
                NeighbourhoodLocationSectionsActions.UPDATE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/neighbourhood-location-sections`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateNeighbourhoodLocationSectionAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: NeighbourhoodLocationSectionsActions.UPDATE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const NeighbourhoodLocationSectionsSaga = [
    takeLatest(
        NeighbourhoodLocationSectionsActions.START_DELETE_NEIGHBOURHOOD_LOCATION_SECTION,
        deleteNeighbourhoodLocationSection
    ),
    takeLatest(
        NeighbourhoodLocationSectionsActions.START_CREATE_NEIGHBOURHOOD_LOCATION_SECTION,
        createNeighbourhoodLocationSection
    ),
    takeLatest(
        NeighbourhoodLocationSectionsActions.START_UPDATE_NEIGHBOURHOOD_LOCATION_SECTION,
        updateNeighbourhoodLocationSection
    )
];

export default NeighbourhoodLocationSectionsSaga;
