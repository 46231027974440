export const actions = {
    START_FETCHING_SINGLE_PORTAL: 'START_FETCHING_SINGLE_PORTAL',
    START_FETCHING_MINE_PORTAL: 'START_FETCHING_MINE_PORTAL',
    FETCH_SINGLE_PORTAL_SUCCESSFUL: 'FETCH_SINGLE_PORTAL_SUCCESSFUL',
    FETCH_SINGLE_PORTAL_FAILED: 'FETCH_SINGLE_PORTAL_FAILED',
    START_UPDATE_SINGLE_PORTAL: 'START_UPDATE_SINGLE_PORTAL',
    START_UPDATE_MINE_PORTAL: 'START_UPDATE_MINE_PORTAL',
    UPDATE_SINGLE_PORTAL_SUCCESSFUL: 'UPDATE_SINGLE_PORTAL_SUCCESSFUL',
    UPDATE_SINGLE_PORTAL_FAILED: 'UPDATE_SINGLE_PORTAL_FAILED',
    STORE_SINGLE_PORTAL_DATA: 'STORE_SINGLE_PORTAL_DATA',
    START_FETCHING_PORTAL_SETTINGS: 'START_FETCHING_PORTAL_SETTINGS',
    FETCHING_SUCCESSFUL_PORTAL_SETTINGS: 'FETCHING_SUCCESSFUL_PORTAL_SETTINGS',
    FETCHING_FAILED_PORTAL_SETTINGS: 'FETCHING_FAILED_PORTAL_SETTINGS',
    START_UPDATE_PORTAL_SETTINGS: 'START_UPDATE_PORTAL_SETTINGS',
    UPDATE_SUCCESSFUL_PORTAL_SETTINGS: 'UPDATE_SUCCESSFUL_PORTAL_SETTINGS',
    UPDATE_FAILED_PORTAL_SETTINGS: 'UPDATE_FAILED_PORTAL_SETTINGS'
};

export const initialState = {
    singleFetching: false,
    singleError: null,
    fetchingPortalSettings: false,
    portalSettings: {}
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_SINGLE_PORTAL:
        case actions.START_FETCHING_MINE_PORTAL: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_SINGLE_PORTAL_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCH_SINGLE_PORTAL_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.UPDATE_SINGLE_PORTAL_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.STORE_SINGLE_PORTAL_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        case actions.START_FETCHING_PORTAL_SETTINGS: {
            return {
                ...state,
                fetchingPortalSettings: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PORTAL_SETTINGS: {
            return {
                ...state,
                fetchingPortalSettings: false,
                portalSettings: response.data[0] || {}
            };
        }
        case actions.FETCHING_FAILED_PORTAL_SETTINGS: {
            return {
                ...state,
                fetchingPortalSettings: false,
                portalSettings: response
            };
        }
        case actions.START_UPDATE_PORTAL_SETTINGS: {
            return {
                ...state,
                fetchingPortalSettings: true
            };
        }
        case actions.UPDATE_SUCCESSFUL_PORTAL_SETTINGS: {
            return {
                ...state,
                fetchingPortalSettings: false,
                portalSettings: response.data
            };
        }
        case actions.UPDATE_FAILED_PORTAL_SETTINGS: {
            return {
                ...state,
                fetchingPortalSettings: false,
                portalSettings: response
            };
        }
        default:
            return state;
    }
};
