const projectIsActive = [
    {
        label: 'Active',
        value: true
    },
    {
        label: 'Inactive',
        value: false
    }
];

const enableDisable = [
    {
        label: 'Enabled',
        value: true
    },
    {
        label: 'Disabled',
        value: false
    }
];

const buttonSize = [
    {
        label: 'Small',
        value: 'small'
    },
    {
        label: 'Large',
        value: 'large'
    }
];

const projectSections = [
    {
        label: 'Home Page',
        value: 'home-page'
    },
    {
        label: 'Neighbourhood',
        value: 'neighbourhood'
    },
    {
        label: 'Building design',
        value: 'building-design'
    },

    {
        label: 'Floor plans',
        value: 'floor-plans'
    },
    {
        label: 'Home tour',
        value: 'home-tour'
    },
    {
        label: 'Features & Options',
        value: 'features-and-options'
    },
    {
        label: 'Developer',
        value: 'developer'
    }
];

const unitOrientation = [
    {
        label: 'N',
        value: 'N'
    },
    {
        label: 'E',
        value: 'E'
    },
    {
        label: 'S',
        value: 'S'
    },
    {
        label: 'W',
        value: 'W'
    },
    {
        label: 'NE',
        value: 'NE'
    },
    {
        label: 'SE',
        value: 'SE'
    },
    {
        label: 'SW',
        value: 'SW'
    },
    {
        label: 'NW',
        value: 'NW'
    }
];

const neighborhoodMapConfigurationOptions = [
    {
        label: 'Channel locations',
        value: 'channel'
    },
    {
        label: 'Ellery locations',
        value: 'ellery'
    },
    {
        label: 'Hollywood locations',
        value: 'hollywood'
    },
    {
        label: 'Lemonade Lane locations',
        value: 'lemonade'
    }
];

const placeholder = null;

export {
    placeholder,
    projectIsActive,
    enableDisable,
    buttonSize,
    projectSections,
    unitOrientation,
    neighborhoodMapConfigurationOptions
};
