import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import colors from 'erpcore/data/colors';
import { setGradientColor } from 'erpcore/components/Charts/utils';
import styles from './LineChart.module.scss';

class LineChart extends React.Component {
    // If used as callback to send data to <LineChart /> , canvas argument is usable, its used for creating gradients for fill amongst other things //
    getChartData = canvas => {
        const { data, gradientFill } = this.props;

        const defaultChartData = {
            datasets: [
                {
                    fill: false,
                    lineTension: 0.1,
                    borderColor: '#000',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#000',
                    pointBackgroundColor: '#000',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10
                }
            ]
        };

        if (gradientFill) {
            const gradientChartData = data.datasets.map((chart, i) => {
                return {
                    ...defaultChartData.datasets[0],
                    ...chart,
                    fill: true,
                    backgroundColor: setGradientColor(canvas, colors[i])
                };
            });

            return Object.assign(data, {
                datasets: gradientChartData
            });
        }

        const chartData = { ...defaultChartData, ...data };

        if (data && data.datasets) {
            const datasets = data.datasets.map(dataSet => {
                return {
                    ...defaultChartData.datasets[0],
                    ...dataSet
                };
            });

            Object.assign(chartData, {
                datasets
            });
        }

        return chartData;
    };

    getChartOptions() {
        // Options are passed as props if needed, options include yAxis and xAxis labels, responsivness, tooltip data and style etc... //
        const { options } = this.props;

        const defaultOptions = {
            responsive: true,
            maintainAspectRatio: false,
            legend: { labels: { usePointStyle: true } }
        };

        const chartOptions = { ...defaultOptions, ...options };

        return chartOptions;
    }

    render() {
        const { getElementAtEvent, getDatasetAtEvent } = this.props;

        return (
            <div className={`${styles['line-chart']}`}>
                <Line
                    data={this.getChartData}
                    options={this.getChartOptions()}
                    getElementAtEvent={getElementAtEvent}
                    getDatasetAtEvent={getDatasetAtEvent}
                />
            </div>
        );
    }
}

LineChart.defaultProps = {
    data: {},
    gradientFill: false,
    options: {},
    getElementAtEvent: () => {},
    getDatasetAtEvent: () => {}
};

LineChart.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    gradientFill: PropTypes.bool,
    options: PropTypes.oneOfType([PropTypes.object]),
    getElementAtEvent: PropTypes.func,
    getDatasetAtEvent: PropTypes.func
};

export default LineChart;
