import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';

import ElementLoader from 'erpcore/components/ElementLoader';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import './LocationMap.scss';

const libraries = ['places', 'geometry'];

const LocationMap = ({ disabled, activeMarker, coordinates, onChange, onActivateMarker }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: enviromentVariables.GOOGLE_MAPS_API_KEY,
        libraries
    });

    const centerData =
        Array.isArray(coordinates) && coordinates?.length
            ? coordinates?.map(({ lat, lng }) => ({ lat: +lat, lng: +lng }))
            : { lat: +coordinates?.lat || 39.9636615, lng: +coordinates?.lng || -100.5430035 };

    const mapZoom = () => {
        let zoom = 2;

        if (Array.isArray(coordinates) && coordinates?.length) {
            zoom = 3;
        } else if (coordinates && Object.keys(coordinates)?.length) {
            zoom = 15;
        } else {
            zoom = 2;
        }

        return zoom;
    };

    const options = {
        id: 'circle-example',
        mapContainerClassName: 'location-map__container',
        center: Array.isArray(centerData)
            ? { lat: +centerData[0]?.lat, lng: +centerData[0]?.lng }
            : { ...centerData },
        zoom: mapZoom(),
        clickableIcons: false,
        onClick: onChange
    };

    const renderMap = () => {
        return (
            <div className={`location-map${disabled ? ' location-map--disabled' : ''}`}>
                <GoogleMap {...options}>
                    {Array.isArray(coordinates) &&
                        coordinates?.length &&
                        coordinates?.map(({ id, lat, lng, name }) => {
                            return (
                                <>
                                    <Marker
                                        key={`marker-${id}`}
                                        position={{ lat: +lat, lng: +lng }}
                                        draggable={!disabled}
                                        onDragEnd={e => onChange(e, id)}
                                        onClick={() => onActivateMarker(id)}
                                    >
                                        {activeMarker === id && name ? (
                                            <InfoWindow position={{ lat: +lat, lng: +lng }}>
                                                <div>{name}</div>
                                            </InfoWindow>
                                        ) : null}
                                    </Marker>
                                </>
                            );
                        })}
                    {coordinates?.lat && coordinates?.lng && (
                        <Marker
                            position={coordinates}
                            draggable={!disabled}
                            onDragEnd={e => onChange(e, coordinates?.id)}
                            onClick={() => onActivateMarker(coordinates?.id)}
                        >
                            {activeMarker === coordinates?.id && coordinates?.name ? (
                                <InfoWindow
                                    position={{ lat: +coordinates?.lat, lng: +coordinates?.lng }}
                                >
                                    <div>{coordinates?.name}</div>
                                </InfoWindow>
                            ) : null}
                        </Marker>
                    )}
                </GoogleMap>
            </div>
        );
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>;
    }

    return isLoaded ? renderMap() : <ElementLoader overlay />;
};

LocationMap.defaultProps = {
    disabled: false,
    activeMarker: null,
    coordinates: null,
    onChange: () => {},
    onActivateMarker: () => {}
};

LocationMap.propTypes = {
    disabled: PropTypes.bool,
    activeMarker: PropTypes.number,
    coordinates: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    onActivateMarker: PropTypes.func
};

export default LocationMap;
