import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Svg from 'erpcore/components/Svg';
import './BulkActions.scss';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { getBulkActionsIris } from 'erpcore/components/Listing/Listing.selectors';

const TableRowBulkAction = ({ iri, reducerName }) => {
    const dispatch = useDispatch();
    const bulkActionsIris = useSelector(state => getBulkActionsIris(state, reducerName));

    const onChange = () => {
        dispatch({
            type: listingActions.UPDATE_BULK_ACTIONS_IRIS,
            response: iri,
            name: reducerName
        });
    };

    const isChecked = bulkActionsIris.includes(iri);

    return (
        <div className="bulk-actions-checkbox">
            <input
                type="checkbox"
                aria-label="Select for Bulk Action"
                name={`tableRowBulkAction${iri}`}
                id={iri}
                value={iri}
                onChange={onChange}
                checked={isChecked}
            />
            <label className="bulk-actions-checkbox__label" htmlFor={iri}>
                <span className="bulk-actions-checkbox__icon">
                    <Svg icon="checkmark" />
                </span>
            </label>
        </div>
    );
};

TableRowBulkAction.defaultProps = {
    iri: null
};

TableRowBulkAction.propTypes = {
    iri: PropTypes.string,
    reducerName: PropTypes.string.isRequired
};

export default TableRowBulkAction;
