import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { actions as clientsActions } from './Clients.reducer';

const clientListingIncludeString = 'include=country,city,projects&limit=100';

/**
 * Delete Single Client
 * @param  {Object} id ID of a Client
 * @return {Object} Response from API
 */
export function* deleteSingleClient({ promise, iri }) {
    try {
        const deleteSingleClientAPI = yield restClient.delete(iri);
        yield put({
            type: clientsActions.DELETE_CLIENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleClientAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'clients' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'CLIENTS',
            name: 'clients',
            endpoint: `api/clients?${clientListingIncludeString}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: clientsActions.DELETE_CLIENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Client
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createClient({ promise, formData }) {
    try {
        const createClientAPI = yield restClient.post(`/api/clients`, formData);
        yield put({
            type: clientsActions.CREATE_CLIENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createClientAPI?.data
        });
        yield call(promise.resolve, createClientAPI?.data);
    } catch (error) {
        yield put({
            type: clientsActions.CREATE_CLIENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Client
 * @param  {Object} promise
 * @param {string} client Iri
 * @return {Object} response from API
 */
export function* fetchSingleClient({ promise, iri, params }) {
    try {
        const fetchSingleClientAPI = yield restClient.get(iri, { params });
        yield put({
            type: clientsActions.FETCH_CLIENT_SUCCESSFUL
        });
        yield put({
            type: clientsActions.STORE_CLIENT_DATA,
            iri,
            response: dto(fetchSingleClientAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: clientsActions.FETCH_CLIENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Single Client data
 * @param  {Object} id ID of an User
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateSingleClient({ promise, formData, iri }) {
    try {
        const updateSingleClientAPI = yield restClient.put(iri, formData);
        yield put({
            type: clientsActions.UPDATE_CLIENT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleClientAPI?.data
        });
        yield put({
            type: clientsActions.STORE_CLIENT_DATA,
            iri,
            response: dto(updateSingleClientAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: clientsActions.UPDATE_CLIENT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(clientsActions.START_DELETE_CLIENT, deleteSingleClient),
    takeLatest(clientsActions.START_CREATE_CLIENT, createClient),
    takeLatest(clientsActions.START_FETCHING_CLIENT, fetchSingleClient),
    takeLatest(clientsActions.START_UPDATE_CLIENT, updateSingleClient)
];
