import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import ElementLoader from 'erpcore/components/ElementLoader';
import LayoutManager from 'erpcore/utils/LayoutManager';
import OrganizationGeneralForm from 'erpcore/screens/Settings/Organization/components/OrganizationGeneralForm';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { getFetchingMe } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import {
    getMineOrganizationData,
    getOrganizationFetching
} from 'erpcore/screens/Settings/Organization/Organization.selectors';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';

const OrganizationGeneral = () => {
    const title = 'Organization';
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);
    const organization = useSelector(state => getMineOrganizationData(state));
    const fetchingMe = useSelector(state => getFetchingMe(state));
    const fetchingOrganization = useSelector(state => getOrganizationFetching(state));
    const initialValues = {
        currency: organization?.currency?.iri
    };

    const onSubmit = formData => {
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_UPDATE_MINE_ORGANIZATION,
                formData
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    const fetchMeData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: authActions.START_FETCHING_ME
            });
        }).catch(error => ({ error }));
    };

    const fetchOrganization = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCHING_MINE_ORGANIZATION
            });
        }).catch(error => ({ error }));
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchMeData().finally(() => {
            fetchOrganization().finally(() => {
                setInitialized(true);
            });
        });
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                {(!!fetchingMe || !!fetchingOrganization) && <ElementLoader />}
                {!!initialized && (
                    <OrganizationGeneralForm
                        onSubmit={onSubmit}
                        form="OrganizationGeneralForm"
                        submitLabel="Save"
                        initialValues={initialValues}
                        isCurrencyDisabled={initialValues?.currency}
                    />
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default OrganizationGeneral;
