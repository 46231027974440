import Form, { Repeater, Text } from 'erpcore/components/Form';
import { Field, FieldArray } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import React from 'react';
import PropTypes from 'prop-types';
import DepositStructureDescriptionRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/DepositStructureDescriptionRepeaterItem';

const DepositStructureRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.name`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Form.SectionTitleSmall>Deposit items</Form.SectionTitleSmall>

                <FieldArray
                    name={`${member}.items`}
                    component={Repeater}
                    RepeatableItem={DepositStructureDescriptionRepeaterItem}
                    canSort={false}
                    canSortNewItems={false}
                    canSave={false}
                    canAddMultiple
                    uniqueIdentifier="customInnerIdentifier"
                    addNewLabel="Add new item"
                />
            </Form.Row>
        </div>
    );
};

DepositStructureRepeaterItem.defaultProps = {
    member: null
};

DepositStructureRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DepositStructureRepeaterItem;
