import React from 'react';
import PropTypes from 'prop-types';
import './Projects.scss';
import OIAccessProject from 'erpcore/components/OIAccessProject';

const Projects = ({ projects }) => {
    return (
        <>
            {projects &&
                projects?.length > 0 &&
                projects?.map(project => {
                    return <OIAccessProject project={project} />;
                })}
        </>
    );
};

Projects.displayName = 'Projects';

Projects.defaultProps = {
    projects: null
};

Projects.propTypes = {
    projects: PropTypes.oneOf([PropTypes.array, PropTypes.arrayOf(PropTypes.object)])
};

export default Projects;
