import { createSelector } from 'reselect';

export const getImage = (state, iri) => state?.imageManager?.collection?.[iri];

export const getImageUrl = (state, iri, version = null) => {
    let url = null;
    if (state?.imageManager?.collection?.[iri]) {
        const {
            content_url_modified: contentUrlModified,
            content_url: contentUrl
        } = state?.imageManager?.collection?.[iri];

        url = contentUrlModified || contentUrl;

        if (version) {
            const targetVersion = state?.imageManager?.collection?.[iri]?.versions.find(
                item => item.name === version
            );
            if (targetVersion) {
                const { content_url: versionContentUrl = null } = targetVersion;
                if (versionContentUrl) {
                    url = versionContentUrl;
                }
            }
        }
    }

    return url;
};

export const getImageCollection = state => state?.imageManager?.collection;

export const getImageFetching = state => state?.imageManager?.fetching;
export const getImageFetchingData = state => state?.imageManager?.fetchingData;
export const getImageFetchingDataItem = (state, iri) => state?.imageManager?.fetchingData?.[iri];
export const getImageCreating = state => state?.imageManager?.creating;
export const getImageDeleting = state => state?.imageManager?.deleting;
export const getImageUpdating = state => state?.imageManager?.updating;

export default createSelector([
    getImage,
    getImageUrl,
    getImageCollection,
    getImageFetching,
    getImageFetchingData,
    getImageFetchingDataItem,
    getImageCreating,
    getImageDeleting,
    getImageUpdating
]);
