import { diff } from 'deep-object-diff';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { SubmissionError } from 'redux-form';

import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import ProjectForm from 'erpcore/screens/Projects/components/ProjectForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import LayoutManager from 'erpcore/utils/LayoutManager';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { dtoForm } from 'erpcore/utils/dto';

const ProjectEditMultiEffectGeneralInfo = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};

    const projectDataForm = dtoForm(projectData) || {};
    const fetching = useSelector(getProjectFetching);
    const initBlueScoreValues = {
        amenities: '',
        appreciation: '',
        developer: '',
        location: '',
        value: ''
    };
    const {
        additional_purchasing_information: additionalPurchasingInformation = [],
        mla_blue_score_scores: mlaBlueScoreScores
    } = {
        ...projectData
    };

    const { neighbourhood_locations: neighbourhoodLocations } = projectDataForm;

    const initialValues = {
        name: projectDataForm.name,
        is_active: projectDataForm.is_active,
        mla_blue: projectDataForm.mla_blue || false,
        mla_oi: projectDataForm.mla_oi || false,
        blue_listing_status: projectDataForm.blue_listing_status,
        slug: projectDataForm.slug,
        client: projectDataForm.client,
        contact_email: projectDataForm.contact_email,
        headline: projectDataForm.headline,
        description: projectDataForm.description,
        homes: projectDataForm.homes,
        bedrooms: projectDataForm.bedrooms,
        number_of_homes: projectDataForm.number_of_homes,
        status: projectDataForm.status,
        website: projectDataForm.website,
        matterport_url: projectDataForm.matterport_url,
        intro_video_url: projectDataForm.intro_video_url,
        development_project_logo: projectDataForm.development_project_logo,
        featured_image: projectDataForm.featured_image,
        main_gallery: projectData.main_gallery,
        purchase_calculators: projectData.purchase_calculators?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        location: {
            full_address: projectDataForm.full_address,
            street: projectDataForm.street,
            country: projectDataForm.country,
            state: projectDataForm.state,
            city: projectDataForm.city,
            zip: projectDataForm.zip,
            latitude: projectDataForm.latitude,
            longitude: projectDataForm.longitude
        },
        construction_status: Object.keys(projectDataForm.construction_status || {}).map(item => ({
            ...projectDataForm.construction_status[item],
            isNewRepeaterItem: false
        })),
        key_contacts: Object.keys(projectDataForm.key_contacts || {}).map(item => ({
            ...projectDataForm.key_contacts[item],
            isNewRepeaterItem: false
        })),
        test_portal_enabled: projectDataForm.test_portal_enabled,
        portal_enabled: projectDataForm.portal_enabled,
        estimated_completion: projectDataForm.estimated_completion,
        type_of_construction: projectDataForm.type_of_construction,
        architect: projectDataForm.architect,
        interior_designer: projectDataForm.interior_designer,
        product_type: projectDataForm.product_type,
        hero_slider: Object.keys(projectDataForm.hero_slider || {}).map(item => ({
            ...projectDataForm.hero_slider[item],
            isNewRepeaterItem: false
        })),
        avg_ppsf: projectDataForm.avg_ppsf,
        deposit: projectDataForm.deposit,
        maintenance: projectDataForm.maintenance,
        parking: projectDataForm.parking,
        storage: projectDataForm.storage,
        assignment_fee: projectDataForm.assignment_fee,
        sales_launch: projectDataForm.sales_launch,
        additional_purchasing_information: additionalPurchasingInformation?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        site_plan_image: projectDataForm.site_plan_image,
        secure_your_unit_form_id: projectDataForm.secure_your_unit_form_id,
        create_realtor_credentials_and_send_email:
            projectDataForm.create_realtor_credentials_and_send_email,
        mla_blue_preview_configuration: projectDataForm.mla_blue_preview_configuration,
        mla_blue_branding: projectDataForm.mla_blue_branding,
        mla_blue_disclaimer: projectData.mla_blue_disclaimer,
        mla_blue_score_scores: Array.isArray(mlaBlueScoreScores)
            ? initBlueScoreValues
            : mlaBlueScoreScores,
        neighbourhood_locations: neighbourhoodLocations
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data
        let formDataDiff = diff(initialValues, formData);
        const {
            key_contacts: keyContacts,
            construction_status: constructionStatus,
            hero_slider: heroSlider,
            mla_blue_score_scores: mlaBlueScoreScoresFormData
        } = formData;
        // Handle data
        const {
            location,
            main_gallery: mainGallery,
            purchase_calculators: purchaseCalculators,
            additional_purchasing_information: additionalPurchasingInformationDiff,
            neighbourhood_locations: neighbourhoodLocationsDiff
        } = {
            ...formDataDiff
        };
        if (location) {
            formDataDiff = setLocationData('location', formDataDiff);
        }
        if (mainGallery) {
            formDataDiff.main_gallery = formData.main_gallery;
        }
        if (purchaseCalculators) {
            formDataDiff.purchase_calculators = cleanRepeaterOutput(formData.purchase_calculators, [
                'customIdentifier'
            ]);
        }

        if (additionalPurchasingInformationDiff) {
            formDataDiff.additional_purchasing_information = cleanRepeaterOutput(
                formData.additional_purchasing_information,
                ['customIdentifier']
            );
        }

        if (neighbourhoodLocationsDiff) {
            formDataDiff.neighbourhood_locations = cleanRepeaterOutput(
                formData.neighbourhood_locations,
                ['customIdentifier']
            );
        }

        formDataDiff.construction_status = Object.assign({}, constructionStatus);
        formDataDiff.key_contacts = Object.assign({}, keyContacts);
        formDataDiff.hero_slider = Object.assign({}, heroSlider);
        formDataDiff.main_messages_users = formData.main_messages_users;
        formDataDiff.mla_blue_preview_configuration = formData.mla_blue_preview_configuration;
        formDataDiff.mla_blue_branding = formData.mla_blue_branding;

        formDataDiff.mla_blue_score_scores = mlaBlueScoreScoresFormData;

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <ProjectForm
                    onSubmit={onSubmit}
                    form={`ProjectEditForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                    isSlugRequired
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMultiEffectGeneralInfo.displayName = 'ProjectEditMultiEffectGeneralInfo';
ProjectEditMultiEffectGeneralInfo.defaultProps = {
    match: {}
};

ProjectEditMultiEffectGeneralInfo.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProjectEditMultiEffectGeneralInfo);
