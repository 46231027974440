import React from 'react';

import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';

import IntegrationTabs from './components/IntegrationTabs';

const Integration = () => {
    const title = 'Integrations';

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <IntegrationTabs />
        </LayoutManager>
    );
};

export default Integration;
