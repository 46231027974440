import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Payment Methods Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const PaymentMethods = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'paymentmethod.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payment method successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'paymentmethod.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payment method successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'paymentmethod.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payment method successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

PaymentMethods.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

PaymentMethods.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default PaymentMethods;
