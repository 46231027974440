import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, getFormValues, change } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Autocomplete, DateTime } from 'erpcore/components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ClientAddUnitForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, disabled }) => {
    const dispatch = useDispatch();
    const formValues = useSelector(getFormValues('ClientAddUnitForm'));
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="project"
                    id="project"
                    fieldProps={{
                        label: 'Project',
                        options: {
                            endpoint: '/api/projects?filters[portal_enabled][equals]=true',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    onChange={() => {
                        dispatch(change('AppraiserAddUnitsForm', 'unit', ''));
                    }}
                    fieldAttr={{ required: true, disabled }}
                    validate={valueValidation([{ validator: 'required' }])}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="unit"
                    id="unit"
                    fieldProps={{
                        label: 'Unit',
                        options: {
                            queryName: 'filters[name][contains]',
                            endpoint: `/api/project/units?filters[meta.avesdo.deal.id][exists]=1&filters[project.portal_enabled][equals]=true&filters[project][equals]=${formValues?.project ||
                                null}&include=project&limit=20`,
                            mapData: {
                                value: 'iri',
                                label: '{name} - {project.name}'
                            }
                        }
                    }}
                    fieldAttr={{ required: true, disabled: disabled || !formValues?.project }}
                    validate={valueValidation([{ validator: 'required' }])}
                    component={Autocomplete}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="valid_until"
                    id="valid_until"
                    fieldProps={{
                        label: 'Valid Until'
                    }}
                    fieldAttr={{ required: true }}
                    component={DateTime}
                />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Add" />
            </Form.Row>
        </Form>
    );
};

ClientAddUnitForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    disabled: false
};

ClientAddUnitForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool
};

export default reduxForm({
    form: 'ClientAddUnitForm',
    enableReinitialize: true
})(ClientAddUnitForm);
