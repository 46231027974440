export const actions = {
    START_FETCHING_SUPER_ADMIN_PROJECTS: 'START_FETCHING_SUPER_ADMIN_PROJECTS',
    FETCHING_SUCCESSFUL_SUPER_ADMIN_PROJECTS: 'FETCHING_SUCCESSFUL_SUPER_ADMIN_PROJECTS',
    FETCHING_FAILED_SUPER_ADMIN_PROJECTS: 'FETCHING_FAILED_SUPER_ADMIN_PROJECTS',
    START_FETCHING_SINGLE_PROJECT: 'START_FETCHING_SINGLE_PROJECT',
    FETCHING_SUCCESSFUL_SINGLE_PROJECT: 'FETCHING_SUCCESSFUL_SINGLE_PROJECT',
    FETCHING_FAILED_SINGLE_PROJECT: 'FETCHING_FAILED_SINGLE_PROJECT'
};

export const initialState = {
    oiAcceessDashboardProjectData: {
        data: [],
        meta: {},
        included: []
    },
    oiAcceessDashboardSingleProjectData: {
        data: {},
        meta: {},
        included: []
    },
    oiAccessDashboardFetching: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_SUPER_ADMIN_PROJECTS: {
            return {
                ...state,
                oiAccessDashboardFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SUPER_ADMIN_PROJECTS: {
            return {
                ...state,
                oiAccessDashboardFetching: false,
                oiAcceessDashboardProjectData: response
            };
        }
        case actions.FETCHING_FAILED_SUPER_ADMIN_PROJECTS: {
            return {
                ...state,
                oiAccessDashboardFetching: false
            };
        }
        case actions.START_FETCHING_SINGLE_PROJECT: {
            return {
                ...state,
                oiAccessDashboardFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SINGLE_PROJECT: {
            return {
                ...state,
                oiAccessDashboardFetching: false,
                oiAcceessDashboardSingleProjectData: response
            };
        }
        case actions.FETCHING_FAILED_SINGLE_PROJECT: {
            return {
                ...state,
                oiAccessDashboardFetching: false
            };
        }
        default:
            return state;
    }
};
