/* eslint-disable camelcase */
import { diff } from 'deep-object-diff';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import ProjectMLABlueForm from 'erpcore/screens/Projects/components/ProjectMLABlueForm';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import {
    getIsUpdating,
    getProjectData,
    getProjectFetching,
    getProjectFormValues
} from 'erpcore/screens/Projects/Projects.selectors';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionError } from 'redux-form';

const ProjectEditMLABlue = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectDataOriginal = useSelector(state => getProjectData(state, projectIri)) || {};
    const isUpdating = useSelector(getIsUpdating);
    const projectData = dtoForm(projectDataOriginal) || {};
    const projectDataForm = dtoForm(projectData) || {};
    const fetching = useSelector(getProjectFetching);
    const {
        additional_purchasing_information: additionalPurchasingInformation = [],
        realtor_passwords: realtorPasswordsValues,
        realtor_commission: realtorCommissionValues,
        realtor_incentives: realtorIncentivesValues,
        realtor_deposit_structure: realtorDepositStructureValues,
        realtor_videos: realtorVideosValues,
        realtor_marketing: realtorMarketingValues,
        realtor_social_media: realtorSocialMediaValues,
        realtor_social_media_image: realtorSocialMediaImage,
        feature_documents: featureDocs = []
    } = {
        ...projectData
    };
    const { realtor_images: realtorImagesValues } = {
        ...projectDataOriginal
    };

    const projectFormValues =
        useSelector(state => getProjectFormValues(state, 'ProjectMLABlueForm', projectIri)) || {};
    const {
        neighbourhood_locations: neighbourhoodLocationsFormList,
        use_hardcoded_pins: useHardcodedPins
    } = projectFormValues;

    /**
     * Fetch unit settings data
     * @return {Promise}
     */
    const fetchUnitsSettings = () => {
        const params = {
            include:
                'keyPlans,keyPlans.keyPlan,unitTypes,unitTypes.floorPlan,unitTypes.unitType,unitTypes.unitPositions,realtorSocialMediaImage'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectsActions.START_FETCHING_UNIT_SETTINGS,
                promise: { resolve, reject },
                iri: projectIri,
                projectID: match?.params?.id,
                params
            });
        });
    };

    useEffect(() => {
        fetchUnitsSettings();
    }, []);

    const initialValues = {
        slug: projectDataForm.slug || '',
        mla_blue: projectDataForm.mla_blue || false,
        mla_oi: projectDataForm.mla_oi || false,
        sales_start: projectDataForm.sales_start,
        estimated_completion: projectDataForm.estimated_completion,
        type_of_construction: projectDataForm.type_of_construction,
        architect: projectDataForm.architect,
        interior_designer: projectDataForm.interior_designer,
        product_type: projectDataForm.product_type,
        features_hero_slider: projectData.features_hero_slider?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        feature_documents: featureDocs?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        hero_slider: Object.keys(projectDataForm.hero_slider || {}).map(item => ({
            ...projectDataForm.hero_slider[item],
            isNewRepeaterItem: false
        })),
        avg_ppsf: projectDataForm.avg_ppsf,
        deposit: projectDataForm.deposit,
        maintenance: projectDataForm.maintenance,
        parking: projectDataForm.parking,
        storage: projectDataForm.storage,
        assignment_fee: projectDataForm.assignment_fee,
        sales_launch: projectDataForm.sales_launch,
        additional_purchasing_information: additionalPurchasingInformation?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        secure_your_unit_form_id: projectDataForm.secure_your_unit_form_id,
        create_realtor_credentials_and_send_email:
            projectDataForm.create_realtor_credentials_and_send_email,
        mla_blue_preview_configuration: projectDataForm.mla_blue_preview_configuration,
        mla_blue_branding: projectDataForm.mla_blue_branding,
        mla_blue_disclaimer: projectData.mla_blue_disclaimer,
        realtor_passwords:
            !!realtorPasswordsValues?.length &&
            realtorPasswordsValues?.map((password, i) => {
                return {
                    password,
                    customIdentifier: `customIdentifier-${i}`
                };
            }),
        realtor_deposit_structure: realtorDepositStructureValues?.map((table, i) => {
            const output = { ...table, customIdentifier: `customIdentifier-${i}` };
            if (output?.items?.length) {
                output.items = output.items.map((item, j) => {
                    const newItem = { ...item };
                    delete newItem.isNewRepeaterItem;
                    return { ...newItem, customInnerIdentifier: `customIdentifier-${j}` };
                });
            }
            return output;
        }),
        realtor_videos: realtorVideosValues?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        realtor_marketing: realtorMarketingValues?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        realtor_social_media: realtorSocialMediaValues?.map((item, i) => {
            return {
                ...item,
                customIdentifier: `customIdentifier-${i}`
            };
        }),
        realtor_images: realtorImagesValues,
        realtor_commission: realtorCommissionValues,
        realtor_incentives: realtorIncentivesValues,
        realtor_social_media_image: realtorSocialMediaImage,
        neighborhood_map_configuration_id: projectDataForm.neighborhood_map_configuration_id,
        mla_blue_score_scores: projectDataForm.mla_blue_score_scores,
        blue_listing_status: projectDataForm.blue_listing_status,
        neighbourhood_locations: projectDataForm.neighbourhood_locations,
        use_hardcoded_pins: projectData.use_hardcoded_pins
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: `${projectIri}?include=realtorSocialMediaImage`
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = async formData => {
        // send only changed data
        const formDataDiff = diff(initialValues, formData);
        const { hero_slider: heroSlider } = formData;
        // Handle data
        const {
            realtor_passwords: realtorPasswordsDiff,
            realtor_marketing: realtorMarketingDiff,
            realtor_social_media: realtorSocialMediaDiff,
            additional_purchasing_information: additionalPurchasingInformationDiff,
            features_hero_slider: featuresHeroSlider,
            feature_documents: featureDocumentsForm
        } = {
            ...formDataDiff
        };

        if (realtorPasswordsDiff) {
            formDataDiff.realtor_passwords = formData.realtor_passwords.map(item => item?.password);
        }

        if (realtorMarketingDiff) {
            formDataDiff.realtor_marketing = cleanRepeaterOutput(formData.realtor_marketing, [
                'customIdentifier'
            ]);
        }

        if (realtorSocialMediaDiff) {
            formDataDiff.realtor_social_media = cleanRepeaterOutput(formData.realtor_social_media, [
                'customIdentifier'
            ]);
        }

        if (additionalPurchasingInformationDiff) {
            formDataDiff.additional_purchasing_information = cleanRepeaterOutput(
                formData.additional_purchasing_information,
                ['customIdentifier']
            );
        }

        if (featuresHeroSlider) {
            formDataDiff.features_hero_slider = cleanRepeaterOutput(formData.features_hero_slider, [
                'customIdentifier'
            ]);
        }

        if (featureDocumentsForm) {
            formDataDiff.feature_documents = cleanRepeaterOutput(formData.feature_documents, [
                'customIdentifier'
            ]);
        }

        formDataDiff.realtor_images = formData.realtor_images;
        formDataDiff.hero_slider = Object.assign({}, heroSlider);
        formDataDiff.main_messages_users = formData.main_messages_users;
        formDataDiff.mla_blue_preview_configuration = formData.mla_blue_preview_configuration;
        formDataDiff.mla_blue_branding = formData.mla_blue_branding;

        const { neighbourhood_locations: neighbourhoodLocationsDiff } = { ...formDataDiff };

        if (neighbourhoodLocationsDiff) {
            formDataDiff.neighbourhood_locations = cleanRepeaterOutput(
                formData.neighbourhood_locations,
                ['customIdentifier']
            );
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    const coordinates =
        Array.isArray(neighbourhoodLocationsFormList) &&
        neighbourhoodLocationsFormList?.map(neighbourhoodLocationFormItem => ({
            id: neighbourhoodLocationFormItem?.id || Math.floor(100000 + Math.random() * 900000),
            name: neighbourhoodLocationFormItem?.name,
            lat: neighbourhoodLocationFormItem?.latitude,
            lng: neighbourhoodLocationFormItem?.longitude
        }));

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            {(fetching || isUpdating) && <PageLoader />}
            <ProjectEditPageHeader data={projectData} />
            <PageContent className="units-settings">
                <ProjectMLABlueForm
                    onSubmit={onSubmit}
                    form={`ProjectMLABlueForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                    coordinates={coordinates}
                    useHardcodedPins={useHardcodedPins}
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMLABlue.defaultProps = {
    match: {}
};

ProjectEditMLABlue.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditMLABlue;
