import React, { useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import VonageVideoAPIKeyForm from 'erpcore/screens/Settings/VonageVideoAPI/components/VonageVideoAPIKeyForm';
import VonageVideoAPISecretKeyForm from 'erpcore/screens/Settings/VonageVideoAPI/components/VonageVideoAPISecretKeyForm';
import { useDispatch, useSelector } from 'react-redux';
import { actions as vonageVideoAPIActions } from 'erpcore/screens/Settings/VonageVideoAPI/VonageVideoAPI.reducer';
import {
    getVonageVideoAPIKey,
    getVonageVideoFetching,
    getVonageVideoSecretKey
} from 'erpcore/screens/Settings/VonageVideoAPI/VonageVideoAPI.selectors';
import PageLoader from 'erpcore/components/PageLoader';

const VonageVideoAPI = () => {
    const title = 'Vonage Video API';
    const dispatch = useDispatch();
    const vonageVideoAPIKey = useSelector(getVonageVideoAPIKey);
    const vonageVideoSecretKey = useSelector(getVonageVideoSecretKey);
    const vonageVideoKeysFetching = useSelector(getVonageVideoFetching);

    const vonageVideoAPIKeyInitialValues = {
        [vonageVideoAPIKey?.name]: vonageVideoAPIKey?.value
    };

    const vonageVideoSecretKeyInitialValues = {
        [vonageVideoSecretKey?.name]: vonageVideoSecretKey?.value
    };

    const fetchVonageVideoSecretKey = () => {
        const params = {
            'filters[name][equals]': 'vonage_api_secret'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: vonageVideoAPIActions.START_FETCHING_VONAGE_VIDEO_SECRET_KEY,
                promise: { resolve, reject },
                params
            });
        }).catch(err => err);
    };

    const fetchVonageVideoAPIKey = () => {
        const params = {
            'filters[name][equals]': 'vonage_api_key'
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: vonageVideoAPIActions.START_FETCHING_VONAGE_VIDEO_API_KEY,
                promise: { resolve, reject },
                params
            });
        }).catch(err => err);
    };

    const onSubmitVonageVideoAPIKey = formData => {
        const name = 'vonage_api_key';
        formData = {
            name,
            value: formData[name]
        };

        if (!vonageVideoAPIKey) {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: vonageVideoAPIActions.START_CREATE_VONAGE_SETTINGS,
                    promise: { resolve, reject },
                    formData
                });
            })
                .then(() => {
                    fetchVonageVideoAPIKey();
                })
                .catch(err => err);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: vonageVideoAPIActions.START_UPDATE_VONAGE_SETTINGS,
                promise: { resolve, reject },
                formData,
                iri: vonageVideoAPIKey?.iri
            });
        })
            .then(() => {
                fetchVonageVideoAPIKey();
            })
            .catch(err => err);
    };

    const onSubmitVonageVideoAPISecretKey = formData => {
        const name = 'vonage_api_secret';
        formData = {
            name,
            value: formData[name]
        };

        if (!vonageVideoSecretKey) {
            return new Promise((resolve, reject) => {
                dispatch({
                    type: vonageVideoAPIActions.START_CREATE_VONAGE_SETTINGS,
                    promise: { resolve, reject },
                    formData
                });
            })
                .then(() => {
                    fetchVonageVideoSecretKey();
                })
                .catch(err => err);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: vonageVideoAPIActions.START_UPDATE_VONAGE_SETTINGS,
                promise: { resolve, reject },
                formData,
                iri: vonageVideoSecretKey?.iri
            });
        })
            .then(() => {
                fetchVonageVideoSecretKey();
            })
            .catch(err => err);
    };

    useEffect(() => {
        fetchVonageVideoSecretKey();
        fetchVonageVideoAPIKey();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            {vonageVideoKeysFetching && <PageLoader />}
            <PageContent>
                <VonageVideoAPIKeyForm
                    initialValues={vonageVideoAPIKeyInitialValues}
                    onSubmit={onSubmitVonageVideoAPIKey}
                />
                <VonageVideoAPISecretKeyForm
                    initialValues={vonageVideoSecretKeyInitialValues}
                    onSubmit={onSubmitVonageVideoAPISecretKey}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default VonageVideoAPI;
