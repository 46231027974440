import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import Svg from 'erpcore/components/Svg';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import moment from 'moment-timezone';

import SessionDelete from 'erpcore/screens/Sessions/components/SessionDelete';

const SessionsListing = () => {
    const reducerName = 'sessions';
    const actionName = 'SESSIONS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const title = 'Sessions';

    const fetchSessions = params => {
        const { order_by: orderBy } = { ...params };
        params = {
            ...params,
            order_by: orderBy || { id: 'DESC' }
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint:
                    'api/sessions?include=sessionProspects,salesAgent,projects,sessionProspects.prospect,deal,deal.prospect,timezone'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Filters
        table.filters = [
            {
                name: 'date',
                label: 'Date',
                defaultOperator: 'in',
                filterFields: {
                    defaultValue: 'now',
                    operator: {
                        component: 'select',
                        fieldProps: {
                            clearable: false,
                            label: 'Date',
                            options: [
                                { label: 'Past Sessions', value: 'smaller_than' },
                                { label: 'Future Sessions', value: 'larger_than' }
                            ]
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'sales_agent',
                label: 'Sales agent',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Sales Agent',
                            options: {
                                endpoint: `/api/users`,
                                mapData: {
                                    value: 'iri',
                                    label: '{first_name} {last_name}'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'projects.id',
                label: 'Project',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Project',
                            options: {
                                endpoint: `/api/projects`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            }
        ];

        table.schema = [
            {
                title: 'Scheduled Sessions',
                field: 'date',
                sortable: 'date',
                mobile: 'title'
            },
            {
                title: 'Prospect',
                field: 'prospect'
            },
            {
                title: 'Sales Agent',
                field: 'sales_agent'
            },
            {
                title: 'Project',
                field: 'project'
            },
            {
                title: 'Duration',
                field: 'duration'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        const { data: listingData } = { ...listing };
        if (listingData) {
            listingData.map(row => {
                const {
                    id,
                    iri,
                    date,
                    timezone,
                    session_prospects: sessionProspects = [],
                    sales_agent: salesAgent,
                    projects,
                    duration,
                    deal = {}
                } = {
                    ...row
                };
                const { prospect: dealProspect = {} } = deal;
                const { first_name: dFirstName, last_name: dLastName } = dealProspect;
                const dealProspectName = dFirstName
                    ? `${dFirstName || ''} ${dLastName || ''}`
                    : false;

                const { first_name: agentfirstName, last_name: agentLastName } = { ...salesAgent };

                const salesAgentName = `${agentfirstName || ''} ${agentLastName || ''}`;

                const formattedDate = moment(date).format('MMM Do, YYYY, h:mm a ') || '';
                const formattedTimezone = timezone ? (
                    <Tooltip content={timezone.title}>
                        {moment()
                            .tz(timezone.name)
                            .format('z')}
                    </Tooltip>
                ) : (
                    ''
                );

                const renderProspect = () => {
                    if (sessionProspects?.length) {
                        if (sessionProspects?.length === 1) {
                            const { prospect } = { ...sessionProspects?.[0] };
                            const { first_name: firstName, last_name: lastName } = {
                                ...prospect
                            };
                            const prospectName = `${firstName || ''} ${lastName || ''}`;
                            return <span>{prospectName}</span>;
                        }
                        return (
                            <small className="color--mid-grey">
                                <i>Multiple prospects</i>
                            </small>
                        );
                    }

                    return null;
                };

                const renderProject = () => {
                    if (projects?.length) {
                        if (projects?.length === 1) {
                            return (
                                <NavLink to={`projects/${projects?.[0]?.id}/edit/general-info`}>
                                    {projects?.[0]?.name}
                                </NavLink>
                            );
                        }
                        return (
                            <small className="color--mid-grey">
                                <i>Multiple projects</i>
                            </small>
                        );
                    }

                    return null;
                };

                return table.data.push({
                    id,
                    iri,
                    date: (
                        <>
                            {formattedDate}
                            {formattedTimezone}
                        </>
                    ),
                    prospect: dealProspectName || renderProspect(),
                    sales_agent: salesAgentName,
                    project: renderProject(),
                    duration: duration
                        ? moment.utc(duration * 1000).format('HH[h] mm[m] ss[s]')
                        : '-',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="View Session">
                                    <NavLink to={`/sessions/${id}/view`}>
                                        <Svg icon="passShow" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <Tooltip content="Edit session">
                                    <NavLink to={`/sessions/${id}/edit`}>
                                        <Svg icon="edit" />
                                    </NavLink>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <SessionDelete iri={iri} key={`SessionDelete ${iri}`} />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }
        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button href="/sessions/create" label="Create Session" />
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchSessions(params)}
            />
        </LayoutManager>
    );
};

export default SessionsListing;
