export const actions = {
    START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS: 'START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS',
    FETCH_HUBSPOT_INTEGRATION_SETTINGS_SUCCESSFUL: 'FETCH_HUBSPOT_INTEGRATION_SETTINGS_SUCCESSFUL',
    FETCH_HUBSPOT_INTEGRATION_SETTINGS_FAILED: 'FETCH_HUBSPOT_INTEGRATION_SETTINGS_FAILED',

    START_UPDATE_HUBSPOT_INTEGRATION_SETTINGS: 'START_UPDATE_HUBSPOT_INTEGRATION_SETTINGS',
    UPDATE_HUBSPOT_INTEGRATION_SETTINGS_SUCCESSFUL:
        'UPDATE_HUBSPOT_INTEGRATION_SETTINGS_SUCCESSFUL',
    UPDATE_HUBSPOT_INTEGRATION_SETTINGS_FAILED: 'UPDATE_HUBSPOT_INTEGRATION_SETTINGS_FAILED',

    START_CONNECT_TO_HUBSPOT: 'START_CONNECT_TO_HUBSPOT',
    CONNECT_TO_HUBSPOT_SUCCESSFUL: 'CONNECT_TO_HUBSPOT_SUCCESSFUL',
    CONNECT_TO_HUBSPOT_FAILED: 'CONNECT_TO_HUBSPOT_FAILED',

    START_VALIDATE_HUBSPOT: 'START_VALIDATE_HUBSPOT',
    VALIDATE_HUBSPOT_SUCCESSFUL: 'VALIDATE_HUBSPOT_SUCCESSFUL',
    VALIDATE_HUBSPOT_FAILED: 'VALIDATE_HUBSPOT_FAILED',

    START_DISCONNECT_HUBSPOT: 'START_DISCONNECT_HUBSPOT',
    DISCONNECT_HUBSPOT_SUCCESSFUL: 'DISCONNECT_HUBSPOT_SUCCESSFUL',
    DISCONNECT_HUBSPOT_FAILED: 'DISCONNECT_HUBSPOT_FAILED',

    START_SYNC_HUBSPOT: 'START_SYNC_HUBSPOT',
    SYNC_HUBSPOT_SUCCESSFUL: 'SYNC_HUBSPOT_SUCCESSFUL',
    SYNC_HUBSPOT_FAILED: 'SYNC_HUBSPOT_FAILED'
};

export const initialState = {
    data: {},
    fetching: false,
    syncing: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS: {
            return {
                ...state,
                fetching: true
            };
        }
        case actions.FETCH_HUBSPOT_INTEGRATION_SETTINGS_SUCCESSFUL: {
            const settingsName = response?.data?.[0]?.name;
            return {
                ...state,
                fetching: false,
                data: {
                    ...state?.data,
                    ...(settingsName
                        ? {
                              [settingsName]: {
                                  ...response?.data?.[0]
                              }
                          }
                        : null)
                }
            };
        }
        case actions.START_DISCONNECT_HUBSPOT: {
            return {
                ...state,
                fetching: true,
                data: {}
            };
        }
        case actions.DISCONNECT_HUBSPOT_SUCCESSFUL: {
            return {
                ...state,
                fetching: false,
                data: {}
            };
        }
        case actions.FETCH_HUBSPOT_INTEGRATION_SETTINGS_FAILED: {
            return {
                ...state,
                fetching: false
            };
        }
        case actions.START_SYNC_HUBSPOT: {
            return {
                ...state,
                syncing: true
            };
        }
        case actions.SYNC_HUBSPOT_SUCCESSFUL || actions.SYNC_HUBSPOT_FAILED: {
            return {
                ...state,
                syncing: false
            };
        }
        default:
            return state;
    }
};
