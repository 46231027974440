import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { reset } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dto, { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { getUserData } from 'erpcore/screens/Users/Users.selectors';
import UserEditTabs from 'erpcore/screens/Users/components/UserEditTabs';
import UserEditPageHeader from 'erpcore/screens/Users/components/UserEditPageHeader';
import './UserDealRestrictions.scss';
import restClient from 'erpcore/api/restClient';
import Listing from 'erpcore/components/Listing';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import UserCreateDealRestrictionForm from 'erpcore/screens/Users/components/UserCreateDealRestrictionForm';
import Tooltip from 'erpcore/components/Tooltip';
import Svg from 'erpcore/components/Svg';
import Modal from 'erpcore/components/Modal';
import Button from 'erpcore/components/Button';
import axios from 'axios';

const dealsParams = {
    include: 'project,unit',
    pagination: false,
    'filters[meta.avesdo][exists]': 1,
    'filters[deleted][equals]': false,
    erp_side: 1,
    'filters[unit.listing_status][in]': ['Sold Firm', 'Sold Rescission Period']
};

const UserPortal = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const userIri = useMemo(() => `/api/users/${params?.id}`, [params]);
    const userData = useSelector(state => dtoForm(getUserData(state, userIri))) || {};
    const [restrictions, setRestrictions] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [deleteRestriction, setDeleteRestriction] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [fetchingDeals, setFetchingDeals] = useState(false);
    const [deals, setDeals] = useState({});

    console.error(deals, fetchingDeals);

    const pageTitle = () => {
        const { first_name: firstName, last_name: lastName } = { ...userData };
        if (firstName || lastName) {
            return `Edit user - ${firstName} ${lastName}`;
        }

        return `Edit user`;
    };

    const fetchDeals = (savedToken = false) => {
        setFetchingDeals(true);
        if (!savedToken) {
            restClient.get(`/api/users/${params?.id}/impersonate`).then(response => {
                axios
                    .get('/api/deals', {
                        params: dealsParams,
                        baseURL: process.env.REACT_APP_REST_API,
                        timeout: process.env.REACT_APP_API_TIMEOUT,
                        headers: {
                            Authorization: `Bearer ${response?.data?.token}`,
                            Accept: ' application/vnd.api+json'
                        }
                    })
                    .then(responseDeals => {
                        setDeals(dto(responseDeals?.data));
                        setFetchingDeals(false);
                    })
                    .catch(() => {
                        setFetchingDeals(false);
                    });
            });
        } else {
            axios
                .get('/api/deals', {
                    params: dealsParams,
                    baseURL: process.env.REACT_APP_REST_API,
                    timeout: process.env.REACT_APP_API_TIMEOUT,
                    headers: {
                        Authorization: `Bearer ${savedToken}`,
                        Accept: ' application/vnd.api+json'
                    }
                })
                .then(responseDeals => {
                    setDeals(dto(responseDeals?.data));
                    setFetchingDeals(false);
                })
                .catch(() => {
                    setFetchingDeals(false);
                });
        }
    };

    const fetchUserData = useCallback(() => {
        const queryParams = {
            include: 'organizations,country,state,city,image,projects,roles,prospect,realtor'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_FETCHING_SINGLE_USER,
                iri: userIri,
                params: queryParams
            });
        }).catch(error => ({ error }));
    }, [userIri]);

    const fetchUserRestrictions = useCallback(() => {
        setFetching(true);
        restClient
            .get('/api/user-deal-restrictions', {
                params: {
                    'filter[user][equals]': userIri,
                    include: 'deal,user'
                }
            })
            .then(response => setRestrictions(dto(response.data).data))
            .finally(() => setFetching(false));
    }, [userIri]);

    const createRestriction = formData =>
        new Promise(resolve =>
            restClient.post('/api/user-deal-restrictions', formData).finally(() => {
                fetchUserRestrictions();
                dispatch(reset('UserCreateDealRestrictionForm'));
                resolve();
            })
        );

    const deleteRestrictionHandle = iri => {
        setDeleting(true);
        return new Promise(resolve =>
            restClient.delete(iri).finally(() => {
                fetchUserRestrictions();
                setDeleteRestriction(false);
                setDeleting(false);
                resolve();
            })
        );
    };
    /*
     * Effects
     */
    useEffect(() => {
        fetchUserData();
        fetchUserRestrictions();
        fetchDeals();
    }, []);

    const tableData = () => {
        const table = {};
        table.data = [];

        table.schema = [
            {
                title: 'Restricted Deal',
                field: 'deal'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (restrictions) {
            restrictions.map(row => {
                const { id, iri, deal } = row;

                return table.data.push({
                    id,
                    iri,
                    deal: deal?.name || '-',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <>
                                    <Tooltip content="Delete user restriction">
                                        <button
                                            type="button"
                                            onClick={() => setDeleteRestriction(iri)}
                                        >
                                            <Svg icon="remove" />
                                        </button>
                                    </Tooltip>
                                    <Modal
                                        variation="small"
                                        opened={deleteRestriction === iri}
                                        onClose={() => setDeleteRestriction(false)}
                                        title="Delete user restriction"
                                        subtitle="Are you sure you want to delete this User Restriction?"
                                    >
                                        <Button
                                            label="Delete"
                                            onClick={() => deleteRestrictionHandle(iri)}
                                            loading={deleting}
                                        />
                                        <Button
                                            variation="secondary"
                                            label="Cancel"
                                            onClick={() => setDeleteRestriction(false)}
                                        />
                                    </Modal>
                                </>
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }
        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow user-portal" layoutType="merge">
            <UserEditPageHeader pageTitle={pageTitle()} />
            <UserEditTabs userData={userData} />
            <PageContent>
                <UserCreateDealRestrictionForm
                    prospectIri={userData?.prospect}
                    realtorIri={userData?.realtor}
                    deals={deals}
                    fetchingDeals={fetchingDeals}
                    initialValues={{
                        user: userIri
                    }}
                    onSubmit={createRestriction}
                />
                <Listing
                    hideFilters
                    hideFooter
                    hideSearch
                    loading={fetching}
                    table={tableData()}
                    name="Restrictions"
                    reducerName="restrictions"
                />
            </PageContent>
        </LayoutManager>
    );
};

UserPortal.defaultProps = {};

UserPortal.propTypes = {};

export default UserPortal;
