import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Divisions Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const SettingsNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'setting.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Vonage Video Settings successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'setting.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Vonage Video Settings successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'integration.hubspot.sync.ok':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="HubSpot sync completed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'integration.avesdo.sync.ok':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Avesdo sync started!"
                    type="success"
                    expire="3500"
                />
            );
        case 'integration.avesdo.sync.error':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Avesdo snyc already running!"
                    type="error"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

SettingsNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

SettingsNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default SettingsNotifications;
