import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as HubspotLogsActions } from 'erpcore/screens/Settings/HubspotLog/HubspotLog.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const actionName = 'HUBSPOT_LOGS';
const reducerName = 'hubspotLogs';

/**
 * Delete Product Type
 * @param  {Object} product type iri
 * @return {Object} Response from API
 */
export function* deleteHubspotLog({ promise, iri }) {
    try {
        const deleteHubspotLogAPI = yield restClient.delete(iri);
        yield put({
            type: HubspotLogsActions.DELETE_HUBSPOT_LOG_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteHubspotLogAPI?.data
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/product-types`,
            promise
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: HubspotLogsActions.DELETE_HUBSPOT_LOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create HubspotLog
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createHubspotLog({ promise, formData }) {
    try {
        const createHubspotLogAPI = yield restClient.post('api/product-types', formData);
        yield put({
            type: HubspotLogsActions.CREATE_HUBSPOT_LOG_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/product-types`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createHubspotLogAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: HubspotLogsActions.CREATE_HUBSPOT_LOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Product Type
 * @param  {Object} iri of product type
 * @return {Object} Response from API
 */
export function* updateHubspotLog({ promise, iri, formData }) {
    try {
        const updateHubspotLogAPI = yield restClient.patch(iri, formData);
        yield put({
            type: HubspotLogsActions.UPDATE_HUBSPOT_LOG_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/product-types`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateHubspotLogAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: HubspotLogsActions.UPDATE_HUBSPOT_LOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const hubspotLogSaga = [
    takeLatest(HubspotLogsActions.START_DELETE_HUBSPOT_LOG, deleteHubspotLog),
    takeLatest(HubspotLogsActions.START_CREATE_HUBSPOT_LOG, createHubspotLog),
    takeLatest(HubspotLogsActions.START_UPDATE_HUBSPOT_LOG, updateHubspotLog)
];

export default hubspotLogSaga;
