import React, { useEffect } from 'react';

import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import HubSpotIntegrationEmailsForm from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/components/HubSpotIntegrationEmailsForm';
import { useDispatch, useSelector } from 'react-redux';
import { actions as hubspotIntegrationActions } from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/HubSpotIntegration.reducer';
import {
    isHubSpotFetching,
    getHubSpotIntegrationData
} from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/HubSpotIntegration.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

const clientKeysSettingsName = 'hubspot-integration-settings';

const HubSpotIntegrationSettings = () => {
    const title = 'HubSpot integration';
    const dispatch = useDispatch();
    const isFetching = useSelector(isHubSpotFetching);

    const clientKeys = useSelector(state =>
        getHubSpotIntegrationData(state, clientKeysSettingsName)
    );

    const initialValues = {
        email: clientKeys?.settings?.email
    };

    const fetchClientKeys = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: hubspotIntegrationActions.START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS,
                promise: { resolve, reject },
                settingsName: clientKeysSettingsName
            });
        }).catch(err => err);
    };

    const fetchAll = () => {
        fetchClientKeys();
    };

    const onSubmitForm = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                ...(clientKeys?.iri ? { iri: clientKeys.iri } : null),
                type: hubspotIntegrationActions.START_UPDATE_HUBSPOT_INTEGRATION_SETTINGS,
                settingsName: clientKeysSettingsName,
                formData
            })
        )
            .then(fetchAll)
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    useEffect(() => {
        fetchAll();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            {isFetching && <PageLoader />}
            <PageContent>
                <PageContent.Title>Settings</PageContent.Title>
                <HubSpotIntegrationEmailsForm
                    initialValues={initialValues}
                    onSubmit={onSubmitForm}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default HubSpotIntegrationSettings;
