import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Payment Terms Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const PaymentTerms = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'paymentterm.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payment term successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'paymentterm.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payment term successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'paymentterm.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Payment term successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

PaymentTerms.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

PaymentTerms.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default PaymentTerms;
