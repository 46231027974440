import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as NeighbourhoodLocationSectionsActions } from 'erpcore/screens/Settings/NeighbourhoodLocationSections/NeighbourhoodLocationSections.reducer';

const NeighbourhoodLocationSectionsDelete = ({ handleSubmit, submitting, iri }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type:
                    NeighbourhoodLocationSectionsActions.START_DELETE_NEIGHBOURHOOD_LOCATION_SECTION,
                promise: { resolve, reject },
                iri
            });
        }).finally(() => {
            handleModal();
        });
    };

    return (
        <Fragment>
            <Tooltip content="Delete neighbourhood location section">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Delete neighbourhood location section"
                subtitle="Are you sure you want to delete this neighbourhood location section?"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

NeighbourhoodLocationSectionsDelete.defaultProps = {
    submitting: false,
    iri: null
};

NeighbourhoodLocationSectionsDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    iri: PropTypes.string
};

export default reduxForm({
    form: 'NeighbourhoodLocationSectionsDeleteForm',
    enableReinitialize: true
})(NeighbourhoodLocationSectionsDelete);
