import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';

import Form, { Repeater } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import OIAccessHelpRepeaterItem from 'erpcore/screens/Settings/OIAccess/components/OIAccessHelpRepeaterItem';

const OIAccessHelpForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <FieldArray
                        name="help"
                        component={Repeater}
                        RepeatableItem={OIAccessHelpRepeaterItem}
                        canAddMultiple
                        canSortNewItems
                        canSave={false}
                        addNewLabel="Add new item"
                    />
                </Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form>
        </>
    );
};

OIAccessHelpForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    pristine: false,
    invalid: false
};

OIAccessHelpForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'OIAccessHelpForm',
    enableReinitialize: true
})(OIAccessHelpForm);
