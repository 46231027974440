import React from 'react';
import { Field } from 'redux-form';
import Form, { TextEditor, Select } from 'erpcore/components/Form';
import { projectSections } from 'erpcore/screens/Projects/data/projectsData';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ToolsAndTipsRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.section`}
                    fieldProps={{
                        label: 'Section',
                        clearable: true,
                        options: projectSections
                    }}
                    fieldAttr={{ id: `${member}.section`, required: true }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.tip`}
                    fieldProps={{ label: 'Tools and tips' }}
                    fieldAttr={{ id: `${member}.tip`, required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

ToolsAndTipsRepeaterItem.defaultProps = {
    member: null
};

ToolsAndTipsRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ToolsAndTipsRepeaterItem;
