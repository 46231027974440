export const actions = {
    START_FETCHING_NEIGHBOURHOOD_LOCATION_SECTIONS:
        'START_FETCHING_NEIGHBOURHOOD_LOCATION_SECTIONS',
    FETCHING_SUCCESSFUL_NEIGHBOURHOOD_LOCATION_SECTIONS:
        'FETCHING_SUCCESSFUL_NEIGHBOURHOOD_LOCATION_SECTIONS',
    FETCHING_FAILED_NEIGHBOURHOOD_LOCATION_SECTIONS:
        'FETCHING_FAILED_NEIGHBOURHOOD_LOCATION_SECTIONS',

    START_DELETE_NEIGHBOURHOOD_LOCATION_SECTION: 'START_DELETE_NEIGHBOURHOOD_LOCATION_SECTION',
    DELETE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL:
        'DELETE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL',
    DELETE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED: 'DELETE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED',

    START_CREATE_NEIGHBOURHOOD_LOCATION_SECTION: 'START_CREATE_NEIGHBOURHOOD_LOCATION_SECTION',
    CREATE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL:
        'CREATE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL',
    CREATE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED: 'CREATE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED',

    START_UPDATE_NEIGHBOURHOOD_LOCATION_SECTION: 'START_UPDATE_NEIGHBOURHOOD_LOCATION_SECTION',
    UPDATE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL:
        'UPDATE_NEIGHBOURHOOD_LOCATION_SECTION_SUCCESSFUL',
    UPDATE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED: 'UPDATE_NEIGHBOURHOOD_LOCATION_SECTION_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_NEIGHBOURHOOD_LOCATION_SECTIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_NEIGHBOURHOOD_LOCATION_SECTIONS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_NEIGHBOURHOOD_LOCATION_SECTIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
