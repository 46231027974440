import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';

const NeighbourhoodLocationSectionsEditCreateForm = ({
    handleSubmit,
    onSubmit,
    invalid,
    pristine,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    fieldProps={{
                        label: 'Neighbourhood location section'
                    }}
                    fieldAttr={{
                        required: true,
                        id: 'unit-type'
                    }}
                    component={Text}
                    validation={valueValidation([{ validator: 'required' }])}
                />
                <Button disabled={invalid || pristine} submit label={submitLabel} />
            </Form.Row>
        </Form>
    );
};

NeighbourhoodLocationSectionsEditCreateForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitLabel: 'Add new'
};

NeighbourhoodLocationSectionsEditCreateForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitLabel: PropTypes.string
};

export default reduxForm({
    form: 'NeighbourhoodLocationSectionsEditCreateForm',
    enableReinitialize: true
})(NeighbourhoodLocationSectionsEditCreateForm);
