import { createSelector } from 'reselect';

export const getPortalData = (state, iri) => state.portal?.[iri];

export const getMinePortalData = state => state.portal?.[state?.authManager?.userPortal?.iri];

export const getPortalFetching = state => state.portal?.singleFetching;

export const getPortalCurrencyData = (state, portalIri = null) =>
    portalIri
        ? state.portal?.[portalIri]?.currency || null
        : state.portal?.[state?.authManager?.user?.portal?.iri]?.currency || null;

export const getPortalSettings = state => state?.portal?.portalSettings;

export const getFetchingPortalSettings = state => state?.portal?.fetchingPortalSettings;

export default createSelector([
    getPortalData,
    getPortalFetching,
    getPortalCurrencyData,
    getPortalSettings,
    getFetchingPortalSettings
]);
