import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Options Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Options = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'option.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Option item successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'option.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Option item successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'option.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Option item successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

Options.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Options.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Options;
