import React from 'react';
import { Field } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const UnitAttributesRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Columns>
                <Form.GridColumn size={6}>
                    <Field
                        name={`${member}.name`}
                        fieldProps={{
                            label: 'Name',
                            clearable: true
                        }}
                        fieldAttr={{ id: `${member}.name`, required: true }}
                        validate={valueValidation([{ validator: 'required' }])}
                        component={Text}
                    />
                </Form.GridColumn>
                <Form.GridColumn size={6}>
                    <Field
                        name={`${member}.value`}
                        fieldProps={{
                            label: 'Value',
                            clearable: true
                        }}
                        fieldAttr={{ id: `${member}.value`, required: true }}
                        validate={valueValidation([{ validator: 'required' }])}
                        component={Text}
                    />
                </Form.GridColumn>
            </Form.Columns>
        </div>
    );
};

UnitAttributesRepeaterItem.defaultProps = {
    member: null
};

UnitAttributesRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UnitAttributesRepeaterItem;
