import React from 'react';
import Form, { Autocomplete, SwitchCard, NumberField } from 'erpcore/components/Form';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import ProspectCreateActionModal from 'erpcore/screens/Prospects/components/ProspectCreateActionModal';
import ProspectEditActionModal from 'erpcore/screens/Prospects/components/ProspectEditActionModal';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import './SessionProspectsRepeatableItem.scss';
import { getSessionsData } from 'erpcore/screens/Sessions/Sessions.selectors';

const SessionProspectRepeatableItem = ({ member, data }) => {
    const match = useRouteMatch();
    const sessionIri = `/api/sessions/${match?.params?.id}`;
    const sessionData = useSelector(state => getSessionsData(state, sessionIri)) || {};
    const prospectFilters =
        sessionData?.projects?.map(project => {
            return `filters[projects][equals][]=${project?.iri}`;
        }) || [];

    const expirationField = (
        <Form.Row>
            <Field
                name={`${member}.recap_days_to_expiration_toggle`}
                fieldProps={{
                    label: 'Prospect Post demo expiration',
                    clearable: true
                }}
                component={SwitchCard}
            >
                <Field
                    name={`${member}.recap_days_to_expiration`}
                    fieldProps={{
                        clearable: true,
                        label: 'How many days till post demo expires for this prospect?',
                        min: 1
                    }}
                    fieldAttr={{ id: 'recap_days_to_expiration' }}
                    component={NumberField}
                />
            </Field>
        </Form.Row>
    );

    if (data?.isNewRepeaterItem) {
        return (
            <>
                <Form.Row>
                    <Field
                        name={`${member}.iri`}
                        fieldProps={{
                            label: 'Prospect',
                            options: {
                                endpoint: `/api/prospects?${prospectFilters.join('&')}`,
                                mapData: {
                                    value: 'iri',
                                    label: '{first_name} {last_name}'
                                }
                            },
                            clearable: true,
                            actionButton: {
                                edit: {
                                    component: (
                                        <ProspectEditActionModal
                                            formName={data?.form}
                                            member={member}
                                        />
                                    )
                                },
                                create: {
                                    component: (
                                        <ProspectCreateActionModal
                                            formName={data?.form}
                                            member={member}
                                        />
                                    )
                                }
                            }
                        }}
                        fieldAttr={{ id: `${member}.iri`, required: true }}
                        component={Autocomplete}
                        validate={valueValidation([
                            { validator: 'required' },
                            {
                                validator: 'exclusion',
                                args: {
                                    in: data?.sessionProspectIris,
                                    msg: 'This prospect has already been added to session.'
                                }
                            }
                        ])}
                    />
                </Form.Row>
            </>
        );
    }

    const demoUrl = enviromentVariables.DEMO_APP_URL;
    const demoUrlLive = `${demoUrl}/session/prospect/${data?.hash}`;
    const demoUrlPostPresentation = `${demoUrl}/presentation/${data?.hash}`;

    return (
        <div className="session-repeatable-item">
            <p className="session-repeatable-item__title">{data?.name}</p>

            {!!data?.hash && (
                <div className="session-repeatable-item__data">
                    <div className="session-repeatable-item__data-item">
                        <p className="session-repeatable-item__data-item-title">Live demo URL:</p>
                        <a
                            className="session-repeatable-item__data-item-link"
                            href={demoUrlLive}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {demoUrlLive}
                        </a>
                    </div>
                    <div className="session-repeatable-item__data-item">
                        <p className="session-repeatable-item__data-item-title">
                            Post presentation URL:
                        </p>
                        <a
                            className="session-repeatable-item__data-item-link"
                            href={demoUrlPostPresentation}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {demoUrlPostPresentation}
                        </a>
                    </div>
                    <div className="session-repeatable-item__data-item">{expirationField}</div>
                </div>
            )}
        </div>
    );
};

SessionProspectRepeatableItem.defaultProps = {
    member: null,
    data: null
};

SessionProspectRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object])
};

export default SessionProspectRepeatableItem;
