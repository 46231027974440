import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { DateTime } from 'erpcore/components/Form';

const AppraiserEditValidUntilForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="valid_until"
                    id="valid_until"
                    fieldProps={{
                        label: 'Valid Until'
                    }}
                    fieldAttr={{ required: true }}
                    component={DateTime}
                />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Update" />
            </Form.Row>
        </Form>
    );
};

AppraiserEditValidUntilForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false
};

AppraiserEditValidUntilForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'AppraiserEditValidUntilForm',
    enableReinitialize: true
})(AppraiserEditValidUntilForm);
