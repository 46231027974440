import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Tabs from 'erpcore/components/Tabs';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

const UserEditTabs = ({ match }) => {
    const meData = useSelector(getMeData) || {};
    const { id } = match?.params;
    const links = [];

    if (hasPermission('CAN_MANAGE_COMMON_USER', meData)) {
        links.push({
            link: `/users/${id}/edit/details`,
            label: 'General info'
        });
    }
    if (hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData)) {
        links.push({
            link: `/users/${id}/edit/employment-records`,
            label: 'Employment records'
        });
    }
    if (hasPermission('CAN_MANAGE_COMMON_USER', meData)) {
        links.push({
            link: `/users/${id}/edit/permissions`,
            label: 'Permissions'
        });
    }
    if (hasPermission('CAN_MANAGE_COMMON_USER', meData)) {
        links.push({
            link: `/users/${id}/edit/portal`,
            label: 'Portal'
        });
    }

    if (hasPermission('CAN_MANAGE_COMMON_USER', meData)) {
        links.push({
            link: `/users/${id}/edit/deals-restrictions`,
            label: 'Deals Restrictions'
        });
    }

    if (hasPermission('CAN_MANAGE_COMMON_USER', meData)) {
        links.push({
            link: `/users/${id}/edit/reporting-dashboard`,
            label: 'Reporting dashboard'
        });
    }

    return <Tabs links={links} />;
};

UserEditTabs.defaultProps = {
    match: {}
};

UserEditTabs.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(UserEditTabs);
