import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import DealForm from 'erpcore/screens/Prospects/components/DealForm';
import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import { reset, SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { useDispatch, useSelector } from 'react-redux';
import { getProspectData } from 'erpcore/screens/Prospects/Prospects.selectors';

const ProspectDealCreate = () => {
    const match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const prospectIri = `/api/prospects/${match?.params?.id}`;
    const prospectData = useSelector(state => getProspectData(state, prospectIri)) || {};

    const { first_name: firstName, last_name: lastName } = {
        ...prospectData
    };

    const title = `Create New Deal - ${firstName} ${lastName}`;

    const fetchProspectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_FETCHING_SINGLE_PROSPECT,
                iri: prospectIri
            });
        }).catch(error => ({ error }));
    };

    const onSubmit = formData => {
        formData.prospect = prospectIri;

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: prospectActions.START_CREATE_PROSPECT_DEAL,
                formData
            });
        })
            .then(() => {
                dispatch(reset('ProspectDealCreateForm'));
                history.push(`/prospects/${prospectData?.id}/edit/deals`);
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    useEffect(() => {
        fetchProspectData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <DealForm
                    initialValues={{
                        prospects: [prospectIri]
                    }}
                    fixedProspect={prospectIri}
                    onSubmit={onSubmit}
                    form="ProspectDealCreateForm"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ProspectDealCreate;
