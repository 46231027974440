import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Image from 'erpcore/components/Image';
import Svg from 'erpcore/components/Svg';
import Widget from 'erpcore/components/Widget';

import './UserWidget.scss';

const UserWidget = ({ userData }) => {
    const { first_name: firstName } = { ...userData };

    return (
        <Widget className="user-widget">
            <Link to="/my-account" className="user-widget__photo">
                <Image
                    iri={userData?.image?.iri}
                    version="small"
                    width={100}
                    height={100}
                    alt="Profile"
                    fallback={<Svg icon="profile" />}
                />
            </Link>
            <div className="user-widget__content">
                <h3 className="user-widget__title">Hello {firstName}!</h3>

                <ul className="user-widget__menu">
                    <li className="user-widget__menu-item">
                        <Link className="user-widget__menu-item-link" to="/my-account">
                            Edit your account
                        </Link>
                    </li>
                </ul>
            </div>
        </Widget>
    );
};

UserWidget.defaultProps = {
    userData: null
};

UserWidget.propTypes = {
    userData: PropTypes.oneOfType([PropTypes.object])
};

export default UserWidget;
