import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'erpcore/screens/Users/Users.selectors';
import { useRouteMatch } from 'react-router-dom';
import ElementLoader from 'erpcore/components/ElementLoader';
import PageContent from 'erpcore/components/Layout/PageContent';
import Card from 'erpcore/components/Layout/Card';
import AppraiserAddProjectForm from 'erpcore/screens/Users/components/Appraiser/components/AppraiserAddProjectForm';
import { reset } from 'redux-form';
import Tooltip from 'erpcore/components/Tooltip';
import { formatDate } from 'erpcore/utils/utils';
import Button from 'erpcore/components/Button';
import AppraiserAddUnitForm from 'erpcore/screens/Users/components/Appraiser/components/AppraiserAddUnitForm';
import AppraiserEditValidUntilForm from 'erpcore/screens/Users/components/Appraiser/components/AppraiserEditValidUntilForm';
import Modal from 'erpcore/components/Modal';

const dateInPast = (firstDate, secondDate) => {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
    }
    return false;
};

function dateWithMonthsDelay(months) {
    const date = new Date();
    date.setMonth(date.getMonth() + months);

    return date;
}

const Appraiser = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const userIri = `/api/users/${match?.params?.id}`;
    const userData = useSelector(state => getUserData(state, userIri)) || {};
    const [appraiserProjects, setAppraiserProjects] = useState({});
    const [appraiserUnits, setAppraiserUnits] = useState({});
    const [fetchingAppraiser, setFetchingAppraiser] = useState(false);
    const [editValidUntil, setEditValidUntil] = useState(false);
    const isAppraiser = useMemo(() => userData?._type === 'appraiser', [userData]);
    const [projects, setProjects] = useState([]);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        restClient
            .get('/api/projects?pagination=false')
            .then(response => setProjects(dto(response.data).data))
            .finally(() => setFetching(false));
    }, []);

    const fetchAppraiserData = useCallback(() => {
        setFetchingAppraiser(true);
        axios
            .all([
                restClient
                    .get(
                        `/api/appraiser-assigned-projects?filters[appraiser][equals]=${userIri}&include=project`,
                        {
                            headers: {
                                Accept: ' application/vnd.api+json'
                            }
                        }
                    )
                    .then(response => {
                        setAppraiserProjects(dto(response?.data));
                    }),
                restClient
                    .get(
                        `/api/appraiser-assigned-units?filters[appraiser][equals]=${userIri}&include=unit,unit.project`,
                        {
                            headers: {
                                Accept: ' application/vnd.api+json'
                            }
                        }
                    )
                    .then(response => {
                        setAppraiserUnits(dto(response?.data));
                    })
            ])
            .then(() => {
                setFetchingAppraiser(false);
            });
    }, [setAppraiserUnits, setAppraiserProjects]);

    useEffect(() => {
        if (isAppraiser) {
            fetchAppraiserData();
        }
    }, [isAppraiser]);

    const removeAppraiserAssignment = useCallback(iri => {
        setFetchingAppraiser(true);
        restClient.delete(iri).then(() => {
            fetchAppraiserData();
        });
    }, []);

    const addAppraiserAssignment = useCallback(
        (url, data) => {
            setFetchingAppraiser(true);
            data.appraiser = userIri;
            restClient.post(url, data).then(() => {
                fetchAppraiserData();
            });
        },
        [userIri]
    );

    const informAppraiser = useCallback(() => {
        restClient
            .post(
                `${userIri}/appraiser-inform`,
                {},
                {
                    headers: {
                        'content-type': 'application/json'
                    }
                }
            )
            .then(() => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'appraiser.mail.success' }
                });
            })
            .catch(() => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'appraiser.mail.error' }
                });
            });
    }, [userIri]);

    const updateValidUntil = useCallback(
        formData =>
            new Promise(() => {
                const { valid_until: validUntil, iri } = { ...formData };
                return restClient
                    .put(iri, {
                        valid_until: validUntil
                    })
                    .then(() => {
                        setEditValidUntil();
                        fetchAppraiserData();
                    });
            }),
        []
    );

    return (
        <div className="user-permissions__appraiser">
            {fetchingAppraiser === true && <ElementLoader overlay />}
            <PageContent.Section>
                <PageContent.Title>Appraiser settings</PageContent.Title>
                <div className="user-permissions__appraiser-column">
                    <Card>
                        <Card.Title>Project assignment</Card.Title>
                        <br />
                        <AppraiserAddProjectForm
                            fetching={fetching}
                            projects={projects}
                            initialValues={{
                                valid_until: dateWithMonthsDelay(1)
                            }}
                            disabled={fetchingAppraiser}
                            onSubmit={formData => {
                                if (
                                    appraiserProjects?.data?.filter(
                                        appraiserAssignment =>
                                            appraiserAssignment?.project?.iri === formData?.project
                                    ).length > 0
                                ) {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: { code: 'appraiser.already-assigned' }
                                    });
                                } else {
                                    addAppraiserAssignment(
                                        '/api/appraiser-assigned-projects',
                                        formData
                                    );
                                    dispatch(reset('AppraiserAddProjectForm'));
                                }
                            }}
                        />
                        {appraiserProjects?.data?.map(assignment => {
                            const { valid_until: validUntil, iri } = { ...assignment };
                            return (
                                <div
                                    className="user-permissions__appraiser-assignment"
                                    key={assignment?.iri}
                                >
                                    {assignment?.project?.name}
                                    <div>
                                        <Tooltip
                                            content={`Valid until ${formatDate(validUntil)}`}
                                            className={
                                                dateInPast(new Date(validUntil), new Date())
                                                    ? 'user-permissions__red'
                                                    : ''
                                            }
                                        >
                                            <Button
                                                variation="link"
                                                className="user-permissions__valid-until"
                                                label={formatDate(validUntil)}
                                                onClick={() =>
                                                    setEditValidUntil({
                                                        iri,
                                                        valid_until: validUntil
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                        <Button
                                            size="small"
                                            variation="link"
                                            iconName="remove"
                                            labelOnlyAria
                                            onClick={() =>
                                                removeAppraiserAssignment(assignment?.iri)
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <Button
                            variation="link"
                            size="small"
                            label="Inform appraiser"
                            onClick={() => informAppraiser()}
                        />
                    </Card>
                </div>
                <div className="user-permissions__appraiser-column">
                    <Card>
                        <Card.Title>Unit assignment</Card.Title>
                        <br />
                        <AppraiserAddUnitForm
                            fetching={fetching}
                            projects={projects}
                            initialValues={{
                                valid_until: dateWithMonthsDelay(1)
                            }}
                            disabled={fetchingAppraiser}
                            onSubmit={formData => {
                                if (
                                    appraiserUnits?.data?.filter(
                                        appraiserAssignment =>
                                            appraiserAssignment?.unit?.iri === formData?.unit
                                    ).length > 0
                                ) {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: { code: 'appraiser.already-assigned' }
                                    });
                                } else {
                                    addAppraiserAssignment(
                                        '/api/appraiser-assigned-units',
                                        formData
                                    );
                                    dispatch(reset('AppraiserAddUnitsForm'));
                                }
                            }}
                        />
                        {appraiserUnits?.data?.map(assignment => {
                            const { valid_until: validUntil, iri } = { ...assignment };
                            return (
                                <div
                                    key={assignment?.iri}
                                    className="user-permissions__appraiser-assignment"
                                >
                                    {assignment?.unit?.name} - {assignment?.unit?.project?.name}
                                    <div>
                                        <Tooltip
                                            content={`Valid until ${formatDate(validUntil)}`}
                                            className={
                                                dateInPast(new Date(validUntil), new Date())
                                                    ? 'user-permissions__red'
                                                    : ''
                                            }
                                        >
                                            <Button
                                                variation="link"
                                                className="user-permissions__valid-until"
                                                label={formatDate(validUntil)}
                                                onClick={() =>
                                                    setEditValidUntil({
                                                        iri,
                                                        valid_until: validUntil
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                        <Button
                                            size="small"
                                            variation="link"
                                            iconName="remove"
                                            labelOnlyAria
                                            onClick={() =>
                                                removeAppraiserAssignment(assignment?.iri)
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <Button
                            variation="link"
                            size="small"
                            label="Inform appraiser"
                            onClick={() => informAppraiser()}
                        />
                    </Card>
                </div>
            </PageContent.Section>
            <Modal
                title="Valid Until"
                variation="small"
                onClose={() => setEditValidUntil(false)}
                opened={editValidUntil}
            >
                <AppraiserEditValidUntilForm
                    onSubmit={updateValidUntil}
                    initialValues={editValidUntil}
                />
            </Modal>
        </div>
    );
};

Appraiser.defaultProps = {};

Appraiser.propTypes = {};

export default Appraiser;
