import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import './DateTime.scss';
import Input from '../Input';

/**
 *
 * @param input
 * @param meta
 * @param fieldProps
 * @param fieldAttr
 * @param field
 * @param outputNoTimeZone {boolean} if true; value is saved as formatted string,
 *                                   otherwise value is saved as Date string with timezone
 * @return {*}
 * @constructor
 */
const DateTime = ({ input, meta, fieldProps, fieldAttr, field, outputNoTimeZone }) => {
    //  Preparing specific date format
    let dateFormat = 'M/d/yyyy'; // react-datepicker formatting
    let dateFormatMoment = outputNoTimeZone ? 'M/D/YYYY' : ''; // moment formatting
    if (fieldProps?.showTimeSelect) {
        dateFormat = 'M/d/yyyy   h:mm aa'; // react-datepicker formatting
        dateFormatMoment = outputNoTimeZone ? 'M/D/YYYY   h:mm a' : ''; // moment formatting

        if (fieldProps?.showTimeSelectOnly) {
            dateFormat = 'h:mm aa'; // react-datepicker formatting
            dateFormatMoment = outputNoTimeZone ? 'h:mm a' : ''; // moment formatting
        }
    }

    if (fieldProps?.showTimeSelect) {
        fieldProps.timeIntervals = 15;
    }

    const CustomInput = props => {
        const { fieldAttr: inputFieldAttr } = props;
        return (
            <input
                className="input__field"
                autoComplete="off"
                onClick={props?.onClick}
                value={props?.value}
                {...inputFieldAttr}
            />
        );
    };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="input--datepicker"
        >
            <DatePicker
                {...fieldAttr}
                {...fieldProps}
                onBlur={() => input.onBlur()}
                onCalendarClose={() => input.onBlur()}
                dateFormat={dateFormat}
                selected={input.value ? new Date(input.value) : ''}
                // if outputNoTimeZone === true -> value is saved as formatted string, otherwise as Date string with timezone
                onChange={value => input.onChange(moment(value).format(dateFormatMoment))}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                popperPlacement="bottom"
                popperModifiers={{
                    flip: {
                        behavior: ['bottom'] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                    }
                }}
                dropdownMode="select"
                customInput={<CustomInput fieldAttr={fieldAttr} />}
            />
        </Input>
    );
};

DateTime.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {},
    outputNoTimeZone: true
};

DateTime.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    outputNoTimeZone: PropTypes.bool
};

export default DateTime;
