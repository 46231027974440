export const actions = {
    START_FETCHING_HUBSPOT_LOGS: 'START_FETCHING_HUBSPOT_LOGS',
    FETCHING_SUCCESSFUL_HUBSPOT_LOGS: 'FETCHING_SUCCESSFUL_HUBSPOT_LOGS',
    FETCHING_FAILED_HUBSPOT_LOGS: 'FETCHING_FAILED_HUBSPOT_LOGS',

    START_DELETE_HUBSPOT_LOG: 'START_DELETE_HUBSPOT_LOG',
    DELETE_HUBSPOT_LOG_SUCCESSFUL: 'DELETE_HUBSPOT_LOG_SUCCESSFUL',
    DELETE_HUBSPOT_LOG_FAILED: 'DELETE_HUBSPOT_LOG_FAILED',

    START_CREATE_HUBSPOT_LOG: 'START_CREATE_HUBSPOT_LOG',
    CREATE_HUBSPOT_LOG_SUCCESSFUL: 'CREATE_HUBSPOT_LOG_SUCCESSFUL',
    CREATE_HUBSPOT_LOG_FAILED: 'CREATE_HUBSPOT_LOG_FAILED',

    START_UPDATE_HUBSPOT_LOG: 'START_UPDATE_HUBSPOT_LOG',
    UPDATE_HUBSPOT_LOG_SUCCESSFUL: 'UPDATE_HUBSPOT_LOG_SUCCESSFUL',
    UPDATE_HUBSPOT_LOG_FAILED: 'UPDATE_HUBSPOT_LOG_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: []
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_HUBSPOT_LOGS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_HUBSPOT_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_HUBSPOT_LOGS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        default:
            return state;
    }
};
