export const actions = {
    START_FETCHING_SESSIONS: 'START_FETCHING_SESSIONS',
    FETCHING_SUCCESSFUL_SESSIONS: 'FETCHING_SUCCESSFUL_SESSIONS',
    FETCHING_FAILED_SESSIONS: 'FETCHING_FAILED_SESSIONS',

    START_CREATE_SESSION: 'START_CREATE_SESSION',
    CREATE_SESSION_SUCCESSFUL: 'CREATE_SESSION_SUCCESSFUL',
    CREATE_SESSION_FAILED: 'CREATE_SESSION_FAILED',

    START_FETCHING_SINGLE_SESSION: 'START_FETCHING_SINGLE_SESSION',
    FETCH_SINGLE_SESSION_SUCCESSFUL: 'FETCH_SINGLE_SESSION_SUCCESSFUL',
    FETCH_SINGLE_SESSION_FAILED: 'FETCH_SINGLE_SESSION_FAILED',

    START_UPDATE_SINGLE_SESSION: 'START_UPDATE_SINGLE_SESSION',
    UPDATE_SINGLE_SESSION_SUCCESSFUL: 'UPDATE_SINGLE_SESSION_SUCCESSFUL',
    UPDATE_SINGLE_SESSION_FAILED: 'UPDATE_SINGLE_SESSION_FAILED',

    START_DELETE_SINGLE_SESSION: 'START_DELETE_SINGLE_SESSION',
    DELETE_SINGLE_SESSION_SUCCESSFUL: 'DELETE_SINGLE_SESSION_SUCCESSFUL',
    DELETE_SINGLE_SESSION_FAILED: 'DELETE_SINGLE_SESSION_FAILED',
    STORE_SINGLE_SESSION_DATA: 'STORE_SINGLE_SESSION_DATA',

    START_CREATE_SESSION_PROSPECT: 'START_CREATE_SESSION_PROSPECT',
    CREATE_SESSION_PROSPECT_SUCCESSFUL: 'CREATE_SESSION_PROSPECT_SUCCESSFUL',
    CREATE_SESSION_PROSPECT_FAILED: 'CREATE_SESSION_PROSPECT_FAILED',

    START_DELETE_SESSION_PROSPECT: 'START_DELETE_SESSION_PROSPECT',
    DELETE_SESSION_PROSPECT_SUCCESSFUL: 'DELETE_SESSION_PROSPECT_SUCCESSFUL',
    DELETE_SESSION_PROSPECT_FAILED: 'DELETE_SESSION_PROSPECT_FAILED',

    START_UPDATE_SESSION_PROSPECT: 'START_UPDATE_SESSION_PROSPECT',
    UPDATE_SESSION_PROSPECT_SUCCESSFUL: 'UPDATE_SESSION_PROSPECT_SUCCESSFUL',
    UPDATE_SESSION_PROSPECT_FAILED: 'UPDATE_SESSION_PROSPECT_FAILED',

    START_FETCHING_SINGLE_SESSION_PROSPECTS: 'START_FETCHING_SINGLE_SESSION_PROSPECTS',
    FETCH_SINGLE_SESSION_PROSPECTS_SUCCESSFUL: 'FETCH_SINGLE_SESSION_PROSPECTS_SUCCESSFUL',
    FETCH_SINGLE_SESSION_PROSPECTS_FAILED: 'FETCH_SINGLE_SESSION_PROSPECTS_FAILED',
    STORE_SINGLE_SESSION_PROSPECTS_DATA: 'STORE_SINGLE_SESSION_PROSPECTS_DATA',

    START_RESEND_SESSION_INVITATION: 'START_RESEND_SESSION_INVITATION',
    RESEND_SESSION_INVITATION_SUCCESSFUL: 'RESEND_SESSION_INVITATION_SUCCESSFUL',
    RESEND_SESSION_INVITATION_FAILED: 'RESEND_SESSION_INVITATION_FAILED',

    START_RESEND_SESSION_POST_DEMO_MAIL: 'START_RESEND_SESSION_POST_DEMO_MAIL',
    RESEND_SESSION_POST_DEMO_MAIL_SUCCESSFUL: 'RESEND_SESSION_POST_DEMO_MAIL_SUCCESSFUL',
    RESEND_SESSION_POST_DEMO_MAIL_FAILED: 'RESEND_SESSION_POST_DEMO_MAIL_FAILED',

    START_RESEND_SESSION_NON_ATTENDEE_MAIL: 'START_RESEND_SESSION_NON_ATTENDEE_MAIL',
    RESEND_SESSION_NON_ATTENDEE_MAIL_SUCCESSFUL: 'RESEND_SESSION_NON_ATTENDEE_MAIL_SUCCESSFUL',
    RESEND_SESSION_NON_ATTENDEE_MAIL_FAILED: 'RESEND_SESSION_NON_ATTENDEE_MAIL_FAILED',

    START_FETCHING_SESSION_PROSPECT_QA: 'START_FETCHING_SESSION_PROSPECT_QA',
    FETCH_SESSION_PROSPECT_QA_SUCCESSFUL: 'FETCH_SESSION_PROSPECT_QA_SUCCESSFUL',
    FETCH_SESSION_PROSPECT_QA_FAILED: 'FETCH_SESSION_PROSPECT_QA_FAILED',

    SET_SESSION_PROSPECT_QUESTION_COUNT: 'SET_SESSION_PROSPECT_QUESTION_COUNT',

    START_FETCHING_SESSION_CHAT: 'START_FETCHING_SESSION_CHAT',
    FETCH_SESSION_CHAT_SUCCESSFUL: 'FETCH_SESSION_CHAT_SUCCESSFUL',
    FETCH_SESSION_CHAT_FAILED: 'FETCH_SESSION_CHAT_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    singleError: null,
    deleteSingleError: null,
    singleSessionProspectsFetching: false,
    sessionProspects: null,
    sessionProspectQAFetching: false,
    sessionProspectQA: null,
    sessionProspectQuestionCount: 0,
    sessionChat: null
};

export default (state = initialState, { type, iri, response }) => {
    switch (type) {
        case actions.START_FETCHING_SESSIONS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.START_CREATE_SESSION_PROSPECT: {
            return {
                ...state,
                singleSessionProspectsFetching: true
            };
        }
        case actions.START_UPDATE_SESSION_PROSPECT: {
            return {
                ...state,
                singleSessionProspectsFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_SESSIONS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_SESSIONS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_SINGLE_SESSION: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.CREATE_SESSION_PROSPECT_SUCCESSFUL: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.UPDATE_SESSION_PROSPECT_SUCCESSFUL: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.CREATE_SESSION_PROSPECT_FAILED: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.UPDATE_SESSION_PROSPECT_FAILED: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.START_DELETE_SESSION_PROSPECT: {
            return {
                ...state,
                singleSessionProspectsFetching: true
            };
        }
        case actions.FETCH_SINGLE_SESSION_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.DELETE_SESSION_PROSPECT_SUCCESSFUL: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.FETCH_SINGLE_SESSION_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.DELETE_SESSION_PROSPECT_FAILED: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.UPDATE_SINGLE_SESSION_FAILED: {
            return {
                ...state,
                singleError: response
            };
        }
        case actions.DELETE_SINGLE_SESSION_FAILED: {
            return {
                ...state,
                deleteSingleError: response
            };
        }
        case actions.STORE_SINGLE_SESSION_DATA: {
            // Updating the store
            return Object.assign({}, state, {
                [iri]: response.data
            });
        }
        // SESSION PROSPETCS
        case actions.START_FETCHING_SINGLE_SESSION_PROSPECTS: {
            return {
                ...state,
                singleSessionProspectsFetching: true
            };
        }
        case actions.FETCH_SINGLE_SESSION_PROSPECTS_SUCCESSFUL: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.FETCH_SINGLE_SESSION_PROSPECTS_FAILED: {
            return {
                ...state,
                singleSessionProspectsFetching: false
            };
        }
        case actions.STORE_SINGLE_SESSION_PROSPECTS_DATA: {
            return Object.assign({}, state, {
                sessionProspects: { ...state?.sessionProspects, [iri]: response }
            });
        }
        // SESSION PROSPETCS QA
        case actions.START_FETCHING_SESSION_PROSPECT_QA: {
            return {
                ...state,
                sessionProspectQAFetching: true
            };
        }
        case actions.FETCH_SESSION_PROSPECT_QA_SUCCESSFUL: {
            return {
                ...state,
                sessionProspectQAFetching: false,
                sessionProspectQA: { ...state?.sessionProspectQA, [iri]: response }
            };
        }
        case actions.FETCH_SESSION_PROSPECT_FAILED_QA: {
            return {
                ...state,
                sessionProspectQAFetching: false
            };
        }
        case actions.SET_SESSION_PROSPECT_QUESTION_COUNT: {
            return {
                ...state,
                sessionProspectQuestionCount: response
            };
        }
        case actions.FETCH_SESSION_CHAT_SUCCESSFUL: {
            return {
                ...state,
                sessionChat: { ...state?.sessionChat, [iri]: response?.data }
            };
        }
        default:
            return state;
    }
};
