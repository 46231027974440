import { put, takeLatest, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as commentsActions } from './Comments.reducer';

/**
 * Fetch Comments Saga
 * @param  {Object} params
 * @return {Object} Response from API
 */
export function* fetchComments({ promise, entity, params, id }) {
    try {
        entity = entity.toLowerCase();

        const fetchCommentsAPI = yield restClient.get(`api/${entity}/${id}/comments`, {
            params
        });
        yield put({
            type: commentsActions.FETCH_SUCCESSFUL_COMMENTS
        });
        yield put({
            type: commentsActions.STORE_COMMENTS_DATA,
            id,
            entity,
            response: dto(fetchCommentsAPI.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: commentsActions.FETCH_FAILED_COMMENTS
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/*
 * Post Comment
 * @param  {Object} promise
 * @return {string} id
 */
export function* postComment({ promise, entity, params, id, formData }) {
    try {
        entity = entity.toLowerCase();
        yield restClient.post(`api/${entity}/${id}/comments`, formData);
        yield put({
            type: commentsActions.POST_SINGLE_COMMENT_SUCCESSFUL
        });
        yield put({
            promise,
            type: commentsActions.START_FETCHING_COMMENTS,
            entity,
            id,
            params
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: commentsActions.POST_SINGLE_COMMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/*
 * Delete Comment
 * @param  {Object} promise
 * @return {string} id
 */
export function* deleteComment({ promise, entity, params, id, data }) {
    try {
        entity = entity.toLowerCase();
        yield restClient.delete(`api/${entity}/${id}/comments`, { data });
        yield put({
            type: commentsActions.DELETE_SINGLE_COMMENT_SUCCESSFUL
        });
        yield put({
            promise,
            type: commentsActions.START_FETCHING_COMMENTS,
            entity,
            id,
            params
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: commentsActions.DELETE_SINGLE_COMMENT_FAILED
        });
        yield put({
            type: notificationManagerActions.SET_PAGE_NOTIFICATION,
            response: error.response.data
        });
        yield call(promise.reject, error.response.data);
    }
}

/**
 * Register action to watcher
 */
export const commentsSaga = [
    takeLatest(commentsActions.START_FETCHING_COMMENTS, fetchComments),
    takeLatest(commentsActions.START_POSTING_SINGLE_COMMENT, postComment),
    takeLatest(commentsActions.START_DELETING_SINGLE_COMMENT, deleteComment)
];
