import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Form, {
    Text,
    TextEditor,
    Textarea,
    Media,
    Autocomplete,
    DateTime
} from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';

const BlogsEditCreateForm = ({ handleSubmit, onSubmit, invalid, pristine, submitLabel }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="title"
                    fieldProps={{
                        label: 'Title'
                    }}
                    fieldAttr={{
                        required: true,
                        id: 'title'
                    }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="publish_date"
                    fieldProps={{
                        label: 'Publish Date'
                    }}
                    fieldAttr={{
                        required: true,
                        id: 'publish_date'
                    }}
                    component={DateTime}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="category"
                    fieldProps={{
                        label: 'Category',
                        options: {
                            endpoint: '/api/blog-categories',
                            mapData: {
                                value: 'iri',
                                label: '{name}'
                            }
                        }
                    }}
                    fieldAttr={{
                        id: 'category',
                        required: true
                    }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="excerpt"
                    fieldProps={{
                        label: 'Excerpt'
                    }}
                    fieldAttr={{
                        required: true,
                        id: 'excerpt'
                    }}
                    component={Textarea}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="content"
                    fieldProps={{
                        label: 'Content'
                    }}
                    fieldAttr={{
                        required: true,
                        id: 'content'
                    }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Post Image *</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="image"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Button disabled={invalid || pristine} submit label={submitLabel} />
        </Form>
    );
};

BlogsEditCreateForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitLabel: 'Add new'
};

BlogsEditCreateForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitLabel: PropTypes.string
};

export default reduxForm({
    form: 'BlogsEditCreateForm',
    enableReinitialize: true
})(BlogsEditCreateForm);
