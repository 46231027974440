import React from 'react';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Form, { Text, Media } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const ProjectKeyPlansRepeatableItem = ({ member }) => {
    return (
        <>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{ label: 'Name' }}
                    fieldAttr={{ id: 'name', required: true }}
                    validate={valueValidation([{ validator: 'required' }])}
                    component={Text}
                />
            </Form.Row>
            <Form.SectionTitleSmall>Key plan</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name={`${member}.key_plan`}
                    id={`${member}.key_plan`}
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['png', 'jpg', 'jpeg']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </>
    );
};

ProjectKeyPlansRepeatableItem.defaultProps = {
    member: null
};

ProjectKeyPlansRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectKeyPlansRepeatableItem;
