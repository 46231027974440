import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as authActions } from 'erpcore/utils/AuthManager/AuthManager.reducer';
import { actions as usersActions } from './Users.reducer';

const usersListingIncludeString = 'include=image,office,jobTitle,department,reportsTo';

/**
 * Create User
 * @param  {Object} promise
 * @return {Object} formData
 */

export function* createUser({ promise, formData }) {
    try {
        const createUserAPI = yield restClient.post(`/api/users`, formData);
        yield put({
            type: usersActions.CREATE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createUserAPI?.data
        });
        yield call(promise.resolve, createUserAPI?.data);
    } catch (error) {
        yield put({
            type: usersActions.CREATE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch User
 * @param  {Object} promise
 * @return {string} id User id
 */
export function* fetchUser({ promise, iri, params }) {
    try {
        const fetchUserAPI = yield restClient.get(iri, { params });
        yield put({
            type: usersActions.FETCH_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: usersActions.STORE_SINGLE_USER_DATA,
            iri,
            response: dto(fetchUserAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.FETCH_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update User single data
 * @param  {Object} id ID of an User
 * @return {Object} Response from API
 */
export function* updateSingleUser({ promise, formData, iri }) {
    try {
        const updateSingleUserAPI = yield restClient.put(
            `${iri}?include=organizations,country,state,city,image,roles,permissions,projects,prospect,realtor`,
            formData
        );
        const dtoUpdateSingleUserAPI = yield dto(updateSingleUserAPI?.data);
        yield put({
            type: usersActions.UPDATE_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleUserAPI?.data
        });
        if (formData?.email) yield restClient.post(`${iri}/email-changed-inform`, {});
        yield put({
            type: usersActions.STORE_SINGLE_USER_DATA,
            iri,
            response: dtoUpdateSingleUserAPI
        });
        // Check if I updated myself
        // if true, Fetch Me Data
        const currentUser = yield select(getMeData);
        const isUpdatingMyself = yield currentUser?.iri === iri;
        if (isUpdatingMyself) {
            // update "me" if update-id matches me-id
            yield put({
                promise,
                type: authActions.START_FETCHING_ME
            });
        }
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.UPDATE_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Single User
 * @param  {Object} id ID of an User
 * @return {Object} Response from API
 */
export function* deleteSingleUser({ promise, iri }) {
    try {
        const deleteSingleUserAPI = yield restClient.delete(iri);
        yield put({
            type: usersActions.DELETE_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleUserAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'users' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'USERS',
            name: 'users',
            endpoint: `api/users?${usersListingIncludeString}`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.DELETE_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Change Password Single User
 * @param  {Object} id ID of an User
 * @return {Object} Response from API
 */
export function* changePasswordSingleUser({ promise, formData, id }) {
    try {
        const changePasswordSingleUserAPI = yield restClient.patch(
            `/api/users/${id}/password`,
            formData
        );
        yield put({
            type: usersActions.CHANGE_PASSWORD_SINGLE_USER_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: changePasswordSingleUserAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.CHANGE_PASSWORD_SINGLE_USER_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* updateAvesdoEmail({ promise, email, iri, prospectIri, realtorIri, avesdoIri }) {
    try {
        const updateSingleUserAPI = yield restClient.put(
            `${iri}?include=organizations,country,state,city,image,roles,permissions,projects,prospect,realtor`,
            { email }
        );
        if (avesdoIri) {
            yield restClient.patch(`${avesdoIri}`, { meta: { email_changed: '0' } });
        }
        if (prospectIri) {
            yield restClient.patch(`${prospectIri}`, { email });
        }
        if (realtorIri) {
            yield restClient.patch(`${realtorIri}`, { email });
        }
        const dtoUpdateSingleUserAPI = yield dto(updateSingleUserAPI?.data);
        yield put({
            type: usersActions.UPDATE_AVESDO_EMAIL_SUCCESSFUL
        });
        yield put({
            type: usersActions.STORE_SINGLE_USER_DATA,
            iri,
            response: dtoUpdateSingleUserAPI
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleUserAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: usersActions.UPDATE_AVESDO_EMAIL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const usersSaga = [
    takeLatest(usersActions.START_CREATE_USER, createUser),
    takeLatest(usersActions.START_FETCHING_SINGLE_USER, fetchUser),
    takeLatest(usersActions.START_UPDATE_SINGLE_USER, updateSingleUser),
    takeLatest(usersActions.START_DELETE_SINGLE_USER, deleteSingleUser),
    takeLatest(usersActions.START_CHANGE_PASSWORD_SINGLE_USER, changePasswordSingleUser),
    takeLatest(usersActions.START_UPDATE_AVESDO_EMAIL, updateAvesdoEmail)
];
