export default {
    clearIndicator: () => ({}),
    container: () => ({}),
    control: () => ({}),
    dropdownIndicator: () => ({}),
    group: () => ({}),
    groupHeading: () => ({}),
    indicatorsContainer: () => ({}),
    indicatorSeparator: () => ({}),
    input: () => ({}),
    loadingMessage: () => ({}),
    menu: () => ({}),
    menuPortal: () => ({}),
    multiValue: (base, state) => {
        return state.data.isFixed ? { paddingRight: 16, backgroundColor: 'black' } : {};
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed ? { fontWeight: 'bold', color: 'white' } : {};
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { display: 'none' } : {};
    },
    noOptionsMessage: () => ({}),
    option: () => ({}),
    placeholder: () => ({}),
    singleValue: () => ({}),
    valueContainer: () => ({})
};
