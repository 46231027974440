import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';

const UnitsDelete = ({ handleSubmit, submitting, unitData, onFinish }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_DELETE_PROJECT_UNIT,
                promise: { resolve, reject },
                iri: unitData?.iri
            });
        })
            .catch(err => err)
            .finally(() => {
                handleModal();
                onFinish();
            });
    };

    return (
        <Fragment>
            <Tooltip content="Delete unit">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Delete unit"
                subtitle="Are you sure you want to delete this unit?"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

UnitsDelete.defaultProps = {
    submitting: false,
    unitData: null,
    onFinish: () => {}
};

UnitsDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    unitData: PropTypes.oneOfType([PropTypes.object]),
    onFinish: PropTypes.func
};

export default reduxForm({
    form: 'UnitsDeleteForm',
    enableReinitialize: true
})(UnitsDelete);
