import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Offices Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Offices = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'office.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Office successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'office.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Office successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'office.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Office successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

Offices.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Offices.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Offices;
