import React from 'react';
import { withRouter } from 'react-router-dom';

import Tabs from 'erpcore/components/Tabs';

const BlueTabs = () => {
    const links = [];

    links.push({
        link: '/settings/mla-blue/general',
        label: 'General'
    });
    links.push({
        link: `/settings/mla-blue/faq`,
        label: 'FAQ'
    });
    links.push({
        link: `/settings/mla-blue/faq-categories`,
        label: 'FAQ categories'
    });

    return <Tabs links={links} />;
};

export default withRouter(BlueTabs);
