import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import Form, { DateTime, Autocomplete, NumberField, SwitchCard } from 'erpcore/components/Form';
import { useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import { getSessionProspects } from 'erpcore/screens/Sessions/Sessions.selectors';
import { useRouteMatch } from 'react-router-dom';

const SessionEditForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    const match = useRouteMatch();
    const sessionIri = `/api/sessions/${match?.params?.id}`;
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);
    const sessionProspects = useSelector(state => getSessionProspects(state, sessionIri));

    const hasProspects = !!sessionProspects?.length;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="date"
                    fieldProps={{
                        label: 'Date and Time',
                        clearable: true,
                        showTimeSelect: true,
                        icon: 'date',
                        minDate: new Date()
                    }}
                    fieldAttr={{ id: 'date', required: true }}
                    outputNoTimeZone
                    component={DateTime}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="timezone"
                    fieldProps={{
                        label: 'Timezone',
                        clearable: true,
                        options: {
                            endpoint: '/api/timezones?pagination=false&order_by[offset]=DESC',
                            mapData: {
                                value: 'iri',
                                label: '{name} ({offset})'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'timezone', required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="deal"
                    fieldProps={{
                        label: 'Deal',
                        clearable: true,
                        options: {
                            endpoint: '/api/deals',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{
                        id: 'deals',
                        required: true
                    }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {hasPermissionsAccessForEntities.users && (
                <Form.Row>
                    <Field
                        name="sales_agent"
                        fieldProps={{
                            label: 'Sales Agent',
                            clearable: true,
                            options: {
                                endpoint: '/api/users',
                                mapData: {
                                    value: 'iri',
                                    label: '{first_name} {last_name}'
                                }
                            }
                        }}
                        fieldAttr={{ id: 'sales_agent', required: true }}
                        component={Autocomplete}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
            )}
            <Form.Row>
                <Field
                    name="recap_days_to_expiration_toggle"
                    fieldProps={{
                        label: 'Post Interactive Tour Expiration',
                        clearable: true
                    }}
                    component={SwitchCard}
                >
                    <Field
                        name="recap_days_to_expiration"
                        fieldProps={{
                            clearable: true,
                            label: 'How many days till post demo expires?',
                            min: 1
                        }}
                        fieldAttr={{ id: 'recap_days_to_expiration' }}
                        component={NumberField}
                    />
                </Field>
            </Form.Row>
            {hasProspects && (
                <>
                    <Form.Row>
                        <Field
                            name="send_recap_to_prospects"
                            id="send_recap_to_prospects"
                            fieldProps={{
                                label:
                                    'Send post interactive tour email after completing a session?',
                                clearable: true
                            }}
                            component={SwitchCard}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="send_notification_to_non_attendees"
                            id="send_notification_to_non_attendees"
                            fieldProps={{
                                label: 'Send non-attendee email after session completion?',
                                clearable: true
                            }}
                            component={SwitchCard}
                        />
                    </Form.Row>
                </>
            )}
            <Form.Row>
                <Field
                    name="guest_participants_enabled"
                    id="guest_participants_enabled"
                    fieldProps={{
                        label: 'Enable guest participants',
                        type: 'single',
                        clearable: true,
                        description:
                            'Add guests to participate in your interactive home tour presentation. They will be able to use their microphone or camera, and can use chat and raise their hand (if enabled for the session).'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="spectators_enabled"
                    id="spectators_enabled"
                    fieldProps={{
                        label: 'Enable audience',
                        type: 'single',
                        clearable: true,
                        description:
                            'Allow additional audience participants to view your interactive home tour presentation. They will not have the ability to use their microphone or camera, however will be able to observe the demo and use chat (if enabled for the session).'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="chat_enabled"
                    id="chat_enabled"
                    fieldProps={{
                        label: 'Enable chat',
                        type: 'single',
                        clearable: true
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="raising_hands_enabled"
                    id="raising_hands_enabled"
                    fieldProps={{
                        label: 'Enable raising hands',
                        type: 'single',
                        clearable: true,
                        description:
                            'Allow your participants to send you a raised hand notification when they have a questions or would like to speak'
                    }}
                    component={SwitchCard}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

SessionEditForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

SessionEditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'SessionEditForm',
    enableReinitialize: true
})(SessionEditForm);
