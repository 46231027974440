export const actions = {
    START_FETCHING_PROFILE_QUESTIONS: 'START_FETCHING_PROFILE_QUESTIONS',
    FETCHING_SUCCESSFUL_PROFILE_QUESTIONS: 'FETCHING_SUCCESSFUL_PROFILE_QUESTIONS',
    FETCHING_FAILED_PROFILE_QUESTIONS: 'FETCHING_FAILED_PROFILE_QUESTIONS',

    START_CREATE_PROFILE_QUESTION: 'START_CREATE_PROFILE_QUESTION',
    CREATE_PROFILE_QUESTION_SUCCESFUL: 'CREATE_PROFILE_QUESTION_SUCCESFUL',
    CREATE_PROFILE_QUESTION_FAILED: 'CREATE_PROFILE_QUESTION_FAILED',

    START_UPDATE_PROFILE_QUESTION: 'START_UPDATE_PROFILE_QUESTION',
    UPDATE_PROFILE_QUESTION_SUCCESSFUL: 'UPDATE_PROFILE_QUESTION_SUCCESSFUL',
    UPDATE_PROFILE_QUESTION_FAILED: 'UPDATE_PROFILE_QUESTION_FAILED',

    START_DELETE_PROFILE_QUESTION: 'START_DELETE_PROFILE_QUESTION',
    DELETE_PROFILE_QUESTION_SUCCESSFUL: 'DELETE_PROFILE_QUESTION_SUCCESSFUL',
    DELETE_PROFILE_QUESTION_FAILED: 'DELETE_PROFILE_QUESTION_FAILED',

    START_CREATE_SALES_GUIDELINES: 'START_CREATE_SALES_GUIDELINES',
    CREATE_SALES_GUIDELINES_SUCCESSFUL: 'CREATE_SALES_GUIDELINES_SUCCESSFUL',
    CREATE_SALES_GUIDELINES_FAILED: 'CREATE_SALES_GUIDELINES_FAILED',

    START_FETCHING_SALES_GUIDELINES: 'START_FETCHING_SALES_GUIDELINES',
    FETCHING_SALES_GUIDELINES_SUCCESSFUL: 'FETCHING_SALES_GUIDELINES_SUCCESSFUL',
    FETCHING_SALES_GUIDELINES_FAILED: 'FETCHING_SALES_GUIDELINES_FAILED',

    START_DELETE_SALES_GUIDELINE: 'START_DELETE_SALES_GUIDELINE',
    DELETE_SALES_GUIDELINES_SUCCESSFUL: 'DELETE_SALES_GUIDELINES_SUCCESSFUL',
    DELETE_SALES_GUIDELINES_FAILED: 'DELETE_SALES_GUIDELINES_FAILED',

    START_UPDATE_SALES_GUIDELINE: 'START_UPDATE_SALES_GUIDELINE',
    UPDATE_SALES_GUIDELINE_SUCCESSFUL: 'UPDATE_SALES_GUIDELINE_SUCCESSFUL',
    UPDATE_SALES_GUIDELINE_FAILED: 'UPDATE_SALES_GUIDELINE_FAILED',

    START_UPDATE_POSITIONS: 'START_UPDATE_POSITIONS',
    UPDATE_POSITIONS_SUCCESSFUL: 'UPDATE_POSITIONS_SUCCESSFUL',
    UPDATE_POSITIONS_FAILED: 'UPDATE_POSITIONS_FAILED'
};

export const initialState = {
    prospectProfileQuestions: {
        listingFetching: false,
        listingResponse: {
            data: [],
            meta: {},
            included: []
        },
        listingError: []
    },
    salesGuidelines: {
        salesGuidelinesData: [],
        salesGuidelinesFetching: false
    }
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_PROFILE_QUESTIONS: {
            return {
                ...state,
                prospectProfileQuestions: {
                    ...state.prospectProfileQuestions,
                    listingFetching: true
                }
            };
        }
        case actions.START_UPDATE_POSITIONS: {
            return {
                ...state,
                prospectProfileQuestions: {
                    ...state.prospectProfileQuestions,
                    listingFetching: true
                }
            };
        }
        case actions.UPDATE_POSITIONS_SUCCESSFUL: {
            return {
                ...state,
                prospectProfileQuestions: {
                    ...state.prospectProfileQuestions,
                    listingFetching: false
                }
            };
        }
        case actions.UPDATE_POSITIONS_FAILED: {
            return {
                ...state,
                prospectProfileQuestions: {
                    ...state.prospectProfileQuestions,
                    listingFetching: false
                }
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROFILE_QUESTIONS: {
            return {
                ...state,
                prospectProfileQuestions: {
                    ...state.prospectProfileQuestions,
                    listingFetching: false,
                    listingResponse: response
                }
            };
        }
        case actions.START_DELETE_SALES_GUIDELINE: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: true
                }
            };
        }
        case actions.DELETE_SALES_GUIDELINES_SUCCESSFUL: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: false
                }
            };
        }
        case actions.DELETE_SALES_GUIDELINES_FAILED: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: false
                }
            };
        }
        case actions.FETCHING_FAILED_PROFILE_QUESTIONS: {
            return {
                ...state,
                prospectProfileQuestions: {
                    ...state.prospectProfileQuestions,
                    listingFetching: false,
                    listingError: response
                }
            };
        }
        case actions.START_FETCHING_SALES_GUIDELINES: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: true
                }
            };
        }
        case actions.FETCHING_SALES_GUIDELINES_SUCCESSFUL: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: false,
                    salesGuidelinesData: response?.data
                }
            };
        }
        case actions.FETCHING_SALES_GUIDELINES_FAILED: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: false
                }
            };
        }
        case actions.START_UPDATE_SALES_GUIDELINE: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: true
                }
            };
        }
        case actions.UPDATE_SALES_GUIDELINE_SUCCESSFUL: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: false
                }
            };
        }
        case actions.UPDATE_SALES_GUIDELINE_FAILED: {
            return {
                ...state,
                salesGuidelines: {
                    ...state.salesGuidelines,
                    salesGuidelinesFetching: false
                }
            };
        }
        default:
            return state;
    }
};
