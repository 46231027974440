import React from 'react';
import PropTypes from 'prop-types';
import TabsNavigation from 'erpcore/components/TabsNavigation';
import './ProjectEditTabsNavigation.scss';

const ProjectEditTabsNavigation = ({ id }) => {
    const tabsNavigation = {
        'multi-effect': {
            tabId: 1,
            label: 'Multi-Effect fields',
            link: `/projects/${id}/edit/multi-effect/general-info`,
            slug: 'multi-effect',
            subTabs: [
                {
                    subTabId: 1,
                    label: 'General Info',
                    link: `/projects/${id}/edit/multi-effect/general-info`
                },
                { subTabId: 2, label: 'Units', link: `/projects/${id}/edit/multi-effect/units` },
                {
                    subTabId: 3,
                    label: 'Unit Settings',
                    link: `/projects/${id}/edit/multi-effect/unit-settings`
                },
                {
                    subTabId: 4,
                    label: 'Features List',
                    link: `/projects/${id}/edit/multi-effect/features-list`
                },
                {
                    subTabId: 5,
                    label: 'FAQ',
                    link: `/projects/${id}/edit/multi-effect/faq`
                },
                {
                    subTabId: 7,
                    label: 'Realtor Portal',
                    link: `/projects/${id}/edit/multi-effect/realtor-portal`
                },
                {
                    subTabId: 8,
                    label: 'Integrations',
                    link: `/projects/${id}/edit/multi-effect/integrations`
                },
                {
                    subTabId: 9,
                    label: 'Connect',
                    link: `/projects/${id}/edit/multi-effect/connect`
                }
            ]
        },
        'mla-live': {
            tabId: 2,
            label: 'MLA Live',
            link: `/projects/${id}/edit/mla-live/general-info`,
            slug: 'mla-live',
            subTabs: [
                {
                    subTabId: 1,
                    label: 'General Info',
                    link: `/projects/${id}/edit/mla-live/general-info`
                },
                {
                    subTabId: 2,
                    label: 'Building Design',
                    link: `/projects/${id}/edit/mla-live/building-design`
                },
                {
                    subTabId: 3,
                    label: 'Neighbourhood',
                    link: `/projects/${id}/edit/mla-live/neighbourhood`
                },
                {
                    subTabId: 4,
                    label: 'Floorplans',
                    link: `/projects/${id}/edit/mla-live/floorplans`
                },
                {
                    subTabId: 5,
                    label: 'Home Tour',
                    link: `/projects/${id}/edit/mla-live/home-tour`
                },
                {
                    subTabId: 6,
                    label: 'Features',
                    link: `/projects/${id}/edit/mla-live/features`
                },
                {
                    subTabId: 7,
                    label: 'Tools',
                    link: `/projects/${id}/edit/mla-live/tools`
                },
                {
                    subTabId: 8,
                    label: 'Documents',
                    link: `/projects/${id}/edit/mla-live/documents`
                }
            ]
        },
        'homeowner-portal': {
            tabId: 3,
            label: 'Homeowner Portal',
            link: `/projects/${id}/edit/homeowner-portal`,
            slug: 'homeowner-portal'
        },
        'mla-blue': {
            tabId: 5,
            label: 'MLA Blue & Oi',
            link: `/projects/${id}/edit/mla-blue`,
            slug: 'mla-blue'
        },
        reports: {
            tabId: 5,
            label: 'Oi Access',
            link: `/projects/${id}/edit/reports`,
            slug: 'reports'
        }
    };

    return <TabsNavigation tabs={tabsNavigation} />;
};

ProjectEditTabsNavigation.defaultProps = {
    id: 0
};

ProjectEditTabsNavigation.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ProjectEditTabsNavigation;
