import React, { useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import PageLoader from 'erpcore/components/PageLoader';
import PropTypes from 'prop-types';
import ProjectEditReportsForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditReportsForm';
import { diff } from 'deep-object-diff';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

const Reports = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);

    const { reporting } = projectData;

    const initialValues = {
        reporting
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        const formDataDiff = diff(initialValues, formData);
        const { reporting: resportingFormData } = { ...formData };

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: {
                    ...formDataDiff,
                    reporting: cleanRepeaterOutput(resportingFormData, ['customIdentifier'])
                }
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            {fetching === true && <PageLoader content />}
            <PageContent>
                <ProjectEditPageHeader data={projectData} />
                <ProjectEditReportsForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    form="ProjectEditReportsForm"
                />
            </PageContent>
        </LayoutManager>
    );
};

Reports.defaultProps = {
    match: {}
};

Reports.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default Reports;
