import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PortalGeneralForm from 'erpcore/screens/Settings/Portal/components/PortalGeneralForm';
import PortalTabs from 'erpcore/screens/Settings/Portal/components/PortalTabs';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import { getOrganizationSetting } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';

const PortalGeneral = () => {
    const title = 'Homeowner Portal App - General';
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const settingName = 'portalGeneral';
    const TWOFAsettingName = 'portal';
    const settings = useSelector(state => getOrganizationSetting(state, settingName)) || [];
    const TWOFAsettings =
        useSelector(state => getOrganizationSetting(state, TWOFAsettingName)) || [];

    const settingData = settings?.data?.length ? settings?.data[0] : {};
    const TWOFAsettingsData = TWOFAsettings?.data?.length ? TWOFAsettings?.data[0] : {};

    const { avesdo_link: avesdoLink, contact_page_email: contactPageEmail } = {
        ...settingData?.settings
    };

    const TWOFA = TWOFAsettingsData?.settings ? TWOFAsettingsData?.settings['2fa'] : false;

    const initialValues = {
        avesdo_link: avesdoLink,
        contact_page_email: contactPageEmail,
        '2fa': TWOFA
    };

    const fetchPortalBanners = () => {
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName
            });
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName: TWOFAsettingName
            });
        })
            .then(() => setFetching(false))
            .catch(error => ({ error }));
    };

    const onSubmit = formData => {
        new Promise((resolve, reject) =>
            dispatch({
                type: organizationActions.START_UPDATE_ORGANIZATION_SETTING,
                settingName,
                portal: true,
                formData,
                iri: settingData?.iri,
                promise: { resolve, reject }
            })
        )
            .then(() => fetchPortalBanners())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });

        return new Promise((resolve, reject) =>
            dispatch({
                type: organizationActions.START_UPDATE_ORGANIZATION_SETTING,
                settingName: TWOFAsettingName,
                portal: true,
                formData: { '2fa': formData['2fa'] },
                iri: TWOFAsettingsData?.iri,
                promise: { resolve, reject }
            })
        )
            .then(() => fetchPortalBanners())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchPortalBanners();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                {fetching && <ElementLoader overlay />}
                <PortalTabs />
                <PortalGeneralForm
                    onSubmit={onSubmit}
                    form="PortalGeneralForm"
                    submitLabel="Save"
                    initialValues={initialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default PortalGeneral;
