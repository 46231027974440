import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Fields, FieldArray, reduxForm, change, getFormValues } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import {
    neighborhoodMapConfigurationOptions,
    projectIsActive
} from 'erpcore/screens/Projects/data/projectsData';
import copy from 'copy-to-clipboard';
import Svg from 'erpcore/components/Svg';

import Button from 'erpcore/components/Button';
import Form, {
    Text,
    Textarea,
    Autocomplete,
    Media,
    Repeater,
    TextEditor,
    DateTime,
    Switch,
    SwitchCard,
    ColorPicker,
    Select
} from 'erpcore/components/Form';

import TitleDescriptionRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleDescriptionRepeaterItem';
import CTARepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/CTARepeaterItem';
import DocumentsRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/DocumentsRepeaterItem';
import PasswordRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/PasswordRepeaterItem';
import TitleUrlRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleUrlRepeaterItem';
import TitleImageDownloadFileRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/TitleImageDownloadFileRepeaterItem';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import NeighborhoodLoactionsRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/NeighborhoodLoactionsRepeaterItem';
import LocationMap from 'erpcore/components/Form/components/Location/components/LocationMap';
import styles from '../ProjectForm/ProjectForm.module.scss';

const oiAppUrl = 'https://projects.mlacanada.com' || enviromentVariables.OI_APP_URL;
const blueAppUrl = 'https://mlablue.com' || enviromentVariables.BLUE_APP_URL;

const CopyButton = ({ copyText }) => {
    const dispatch = useDispatch();
    return (
        <button
            title="Click to copy to clipboard."
            className={styles['copy-link']}
            type="button"
            onClick={() => {
                copy(copyText);
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: {
                        code: 'copy.successful'
                    }
                });
            }}
        >
            <Svg icon="link" /> {copyText}
        </button>
    );
};

CopyButton.propTypes = {
    copyText: PropTypes.string.isRequired
};

const FeaturesHeroRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Form.SectionTitleSmall>Hero Image *</Form.SectionTitleSmall>
                <FieldArray
                    name={`${member}.image`}
                    id={`${member}.image`}
                    component={Media}
                    useLightbox
                    maxImageCount={1}
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
        </div>
    );
};

FeaturesHeroRepeatableItem.defaultProps = {
    member: null
};

FeaturesHeroRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const KeyContactsRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{
                        label: 'Contact Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.url`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.email`}
                    fieldProps={{
                        label: 'Contact Email',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.email`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Column>
                <Field
                    name={`${member}.content`}
                    fieldProps={{
                        label: 'Contact Content',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.content`, required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Column>
        </div>
    );
};

KeyContactsRepeatableItem.defaultProps = {
    member: null
};

KeyContactsRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const ProjectHeroRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Form.SectionTitleSmall>Hero Image *</Form.SectionTitleSmall>
                <FieldArray
                    name={`${member}.image`}
                    id={`${member}.image`}
                    component={Media}
                    useLightbox
                    maxImageCount={1}
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
        </div>
    );
};

ProjectHeroRepeatableItem.defaultProps = {
    member: null
};

ProjectHeroRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const ProjectMLABlueForm = ({
    touch,
    handleSubmit,
    submitting,
    pristine,
    onSubmit,
    submitLabel,
    isSlugRequired,
    form,
    coordinates,
    useHardcodedPins
}) => {
    const dispatch = useDispatch();
    const formData = useSelector(state => getFormValues(form)(state));
    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = markerId => {
        setActiveMarker(markerId);
    };

    useEffect(() => {
        if (!isSlugRequired) {
            touch('slug');
        }
    }, []);

    const handleChangeCoordinate = ({ latLng }, markerId) => {
        const latitude = latLng.lat();
        const longitude = latLng.lng();
        const coordinateIndex = coordinates.findIndex(coord => coord.id === markerId);

        setActiveMarker(null);
        dispatch(change(form, `neighbourhood_locations[${coordinateIndex}].latitude`, latitude));
        dispatch(change(form, `neighbourhood_locations[${coordinateIndex}].longitude`, longitude));
    };

    const blueFields = useCallback(props => {
        const { slug } = { ...props };
        return (
            <>
                <CopyButton copyText={`${blueAppUrl}/project/${slug?.input?.value}`} />
                <br />
                <Form.Row>
                    <Field
                        name="blue_listing_status"
                        fieldProps={{
                            label: 'Blue Projects Listing Status',
                            clearable: true,
                            options: [
                                {
                                    value: 'featured_projects',
                                    label: 'Featured Projects'
                                },
                                {
                                    value: 'currently_selling',
                                    label: 'Currently Selling'
                                },
                                {
                                    value: 'past_projects',
                                    label: 'Past Projects'
                                }
                            ]
                        }}
                        fieldAttr={{ id: 'blue_listing_status', required: true }}
                        component={Select}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.SectionTitleSmall>Blue Scores</Form.SectionTitleSmall>
                <Form.Row>
                    <Field
                        name="mla_blue_score_scores.location"
                        fieldProps={{
                            label: 'Location',
                            clearable: true
                        }}
                        fieldAttr={{ id: `location`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="mla_blue_score_scores.value"
                        fieldProps={{
                            label: 'Value',
                            clearable: true
                        }}
                        fieldAttr={{ id: `value`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="mla_blue_score_scores.developer"
                        fieldProps={{
                            label: 'Developer',
                            clearable: true
                        }}
                        fieldAttr={{ id: `developer`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="mla_blue_score_scores.appreciation"
                        fieldProps={{
                            label: 'Appreciation',
                            clearable: true
                        }}
                        fieldAttr={{ id: `appreciation`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="mla_blue_score_scores.amenities"
                        fieldProps={{
                            label: 'Amenities',
                            clearable: true
                        }}
                        fieldAttr={{ id: `amenities`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
            </>
        );
    }, []);

    const websiteBuilderFields = useCallback(() => {
        return (
            <>
                <Form.Row>
                    <Field
                        name="mla_blue_branding.primary_color"
                        fieldProps={{
                            label: 'Primary Color',
                            clearable: true
                        }}
                        component={ColorPicker}
                    />
                    <Field
                        name="mla_blue_branding.secondary_color"
                        fieldProps={{
                            label: 'Secondary Color',
                            clearable: true
                        }}
                        component={ColorPicker}
                    />
                </Form.Row>
                <Form.Row>
                    <Form.Column>
                        <Field
                            name="mla_blue_branding.hide_mla_logo"
                            fieldProps={{
                                label: 'Hide MLA Logo in footer',
                                clearable: true,
                                options: projectIsActive,
                                off: {
                                    value: false
                                },
                                on: {
                                    value: true
                                }
                            }}
                            fieldAttr={{ id: 'mla_blue_branding.hide_mla_logo' }}
                            component={Switch}
                        />
                    </Form.Column>
                    <Form.Column>
                        <Field
                            name="mla_blue_preview_configuration.hide_realtor_section"
                            fieldProps={{
                                label: 'Hide Realtor Section',
                                clearable: true,
                                options: projectIsActive,
                                off: {
                                    value: false
                                },
                                on: {
                                    value: true
                                }
                            }}
                            fieldAttr={{
                                id: 'mla_blue_preview_configuration.hide_realtor_section'
                            }}
                            component={Switch}
                        />
                    </Form.Column>
                    <Form.Column>
                        <Field
                            name="mla_blue_preview_configuration.hide_my_units_section"
                            fieldProps={{
                                label: 'Hide My Units Section',
                                clearable: true,
                                options: projectIsActive,
                                off: {
                                    value: false
                                },
                                on: {
                                    value: true
                                }
                            }}
                            fieldAttr={{
                                id: 'mla_blue_preview_configuration.hide_my_units_section'
                            }}
                            component={Switch}
                        />
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column>
                        <Field
                            name="mla_blue_preview_configuration.hide_neighborhood_section"
                            fieldProps={{
                                label: 'Hide Neighborhood Section',
                                clearable: true,
                                options: projectIsActive,
                                off: {
                                    value: false
                                },
                                on: {
                                    value: true
                                }
                            }}
                            fieldAttr={{
                                id: 'mla_blue_preview_configuration.hide_neighborhood_section'
                            }}
                            component={Switch}
                        />
                    </Form.Column>
                    <Form.Column>
                        <Field
                            name="mla_blue_preview_configuration.hide_features"
                            fieldProps={{
                                label: 'Hide Features Page',
                                clearable: true,
                                options: projectIsActive,
                                off: {
                                    value: false
                                },
                                on: {
                                    value: true
                                }
                            }}
                            fieldAttr={{ id: 'mla_blue_preview_configuration.hide_features' }}
                            component={Switch}
                        />
                    </Form.Column>
                    <Form.Column></Form.Column>
                </Form.Row>
                <>
                    <Form.SectionTitleSmall>Project CTA</Form.SectionTitleSmall>
                    <Form.Row>
                        <FieldArray
                            name="mla_blue_preview_configuration.project_ctas"
                            component={Repeater}
                            RepeatableItem={CTARepeaterItem}
                            saveButtonIntent="all"
                            canSave={false}
                            canSort
                            canSortNewItems
                            uniqueIdentifier="iri"
                            addNewLabel="Add New CTA"
                            canAddMultiple
                            rerenderOnEveryChange
                        />
                    </Form.Row>
                </>
                <Form.Row>
                    <Field
                        name="mla_blue_disclaimer"
                        fieldProps={{
                            label: 'Disclaimer',
                            clearable: true,
                            helperText: 'Overrides global footer disclaimer'
                        }}
                        fieldAttr={{
                            id: 'mla_blue_disclaimer'
                        }}
                        component={Textarea}
                    />
                </Form.Row>
            </>
        );
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles['project-form__website-builder']}>
                <h3>Website builder</h3>
                <Form.Row>
                    <Field
                        name="mla_blue"
                        fieldProps={{
                            label: (
                                <>
                                    Enable MLA <strong style={{ color: '#4b729a' }}>Blue</strong>{' '}
                                    for this project
                                </>
                            ),
                            onValue: true,
                            offValue: false,
                            description: null
                        }}
                        onChange={value => {
                            if (value) dispatch(change(form, 'mla_oi', false));
                        }}
                        fieldAttr={{ id: 'mla_blue' }}
                        component={SwitchCard}
                    >
                        <div style={{ width: '100%' }}>
                            <Fields
                                names={['slug']}
                                component={blueFields}
                                {...(isSlugRequired
                                    ? {
                                          validate: {
                                              slug: valueValidation([
                                                  { validator: 'required' },
                                                  {
                                                      validator: 'format',
                                                      args: {
                                                          without: /^\d+$/,
                                                          message: 'Slug as a number is not allowed'
                                                      }
                                                  }
                                              ])
                                          }
                                      }
                                    : null)}
                            />
                        </div>
                    </Field>
                </Form.Row>
                <Form.Row>
                    <Field
                        name="mla_oi"
                        fieldProps={{
                            label: (
                                <>
                                    Enable Project <strong style={{ color: 'red' }}>Oi</strong> for
                                    this project
                                </>
                            ),
                            onValue: true,
                            offValue: false,
                            description: null
                        }}
                        onChange={value => {
                            if (value) dispatch(change(form, 'mla_blue', false));
                        }}
                        fieldAttr={{ id: 'mla_oi' }}
                        component={SwitchCard}
                    >
                        <CopyButton
                            copyText={`${oiAppUrl}/project/${formData?.slug || '[slug]'}`}
                        />
                    </Field>
                </Form.Row>
            </div>
            <div className={styles['project-form__website-builder']}>
                <h4>Customization:</h4>
                <Form.Row>
                    <Fields
                        names={[
                            'mla_blue_branding.primary_color',
                            'mla_blue_branding.secondary_color',
                            'mla_blue_branding.hide_mla_logo',
                            'mla_blue_preview_configuration.hide_realtor_section',
                            'mla_blue_preview_configuration.hide_my_units_section',
                            'mla_blue_preview_configuration.hide_neighborhood_section',
                            'mla_blue_preview_configuration.hide_features',
                            'mla_blue_preview_configuration.project_ctas',
                            'mla_blue_preview_configuration.mla_blue_disclaimer'
                        ]}
                        component={websiteBuilderFields}
                    />
                </Form.Row>
            </div>
            <Form.Row>
                <Form.Row>
                    <Field
                        name="secure_your_unit_form_id"
                        fieldProps={{
                            label: 'Secure Your Unit Form ID',
                            clearable: true
                        }}
                        fieldAttr={{ id: 'secure_your_unit_form_id' }}
                        component={Text}
                    />
                </Form.Row>
            </Form.Row>
            <Form.SectionTitle>Project Blue Hero Slider</Form.SectionTitle>
            <Form.Row>
                <FieldArray
                    name="hero_slider"
                    component={Repeater}
                    RepeatableItem={ProjectHeroRepeatableItem}
                    saveButtonIntent="all"
                    canSave={false}
                    canSort
                    canSortNewItems
                    uniqueIdentifier="iri"
                    addNewLabel="Add New Project Hero"
                    canAddMultiple
                    rerenderOnEveryChange
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="product_type"
                    fieldProps={{
                        label: 'Product type',
                        options: {
                            endpoint: '/api/product-types',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true
                    }}
                    fieldAttr={{ id: 'product_type' }}
                    component={Autocomplete}
                />
            </Form.Row>
            {/* TODO: should be removed, should check this out */}
            <Form.Row>
                <Field
                    name="sales_start"
                    fieldProps={{
                        label: 'Sales start',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'sales_start' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="estimated_completion"
                    fieldProps={{
                        label: 'Estimated completion',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'estimated_completion' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="type_of_construction"
                    fieldProps={{
                        label: 'Type of construction',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'type_of_construction' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="architect"
                    fieldProps={{
                        label: 'Architect',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'architect' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="interior_designer"
                    fieldProps={{
                        label: 'Interior designer',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'interior_designer' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="avg_ppsf"
                    fieldProps={{
                        label: 'Avg. PPSF',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'avg_ppsf' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="deposit"
                    fieldProps={{
                        label: 'Deposit',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'deposit' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="maintenance"
                    fieldProps={{
                        label: 'Maintenance',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'maintenance' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="parking"
                    fieldProps={{
                        label: 'Parking',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'parking' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="storage"
                    fieldProps={{
                        label: 'Storage',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'storage' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="assignment_fee"
                    fieldProps={{
                        label: 'Assignment fee',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'assignment_fee' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="sales_launch"
                    fieldProps={{
                        label: 'Sales launch',
                        clearable: true,
                        showTimeSelect: true
                    }}
                    fieldAttr={{ id: 'sales_launch' }}
                    outputNoTimeZone={false}
                    component={DateTime}
                />
            </Form.Row>
            <Form.Section>
                <Form.SectionTitle>Additional purchasing information</Form.SectionTitle>
                <FieldArray
                    name="additional_purchasing_information"
                    component={Repeater}
                    RepeatableItem={TitleDescriptionRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Additional purchasing information"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Section>
            <Form.SectionTitleSmall>Features Hero Slider</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="features_hero_slider"
                    component={Repeater}
                    RepeatableItem={FeaturesHeroRepeatableItem}
                    saveButtonIntent="all"
                    canSave={false}
                    canSort
                    canSortNewItems
                    uniqueIdentifier="customIdentifier"
                    addNewLabel="Add New Features Hero"
                    canAddMultiple
                    rerenderOnEveryChange
                />
            </Form.Row>
            <Form.SectionTitleSmall>Features documents</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="feature_documents"
                    component={Repeater}
                    RepeatableItem={DocumentsRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Passwords</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="realtor_passwords"
                    component={Repeater}
                    RepeatableItem={PasswordRepeaterItem}
                    canAddMultiple
                    canSort={false}
                    canSortNewItems={false}
                    canSave={false}
                    addNewLabel="Add new password"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Commission</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    id="realtor_commission.title"
                    name="realtor_commission.title"
                    component={Text}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    id="realtor_commission.content"
                    name="realtor_commission.content"
                    component={Text}
                    fieldProps={{
                        label: 'Content',
                        clearable: true
                    }}
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Incentives</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    id="realtor_incentives.title"
                    name="realtor_incentives.title"
                    component={Text}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    id="realtor_incentives.content"
                    name="realtor_incentives.content"
                    component={Text}
                    fieldProps={{
                        label: 'Content',
                        clearable: true
                    }}
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Video</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="realtor_videos"
                    component={Repeater}
                    RepeatableItem={TitleUrlRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new video"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Marketing</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="realtor_marketing"
                    component={Repeater}
                    RepeatableItem={TitleImageDownloadFileRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="realtor_images"
                    id="realtor_images"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['png', 'jpg', 'jpeg']}
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Social media image</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="realtor_social_media_image"
                    id="realtor_social_media_image"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['png', 'jpg', 'jpeg']}
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Social media</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="realtor_social_media"
                    component={Repeater}
                    RepeatableItem={TitleDescriptionRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <br />
            <br />
            <Form.SectionTitleSmall>Mark neighborhood location</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name="use_hardcoded_pins"
                    fieldProps={{
                        label: 'Use predefined (hardcoded) pins',
                        off: {
                            value: false
                        },
                        on: {
                            value: true
                        }
                    }}
                    fieldAttr={{ id: 'use_hardocoded_pins' }}
                    component={Switch}
                />
            </Form.Row>
            {!useHardcodedPins && (
                <>
                    <Form.Row>
                        <FieldArray
                            name="neighbourhood_locations"
                            component={Repeater}
                            RepeatableItem={NeighborhoodLoactionsRepeaterItem}
                            canAddMultiple
                            canSortNewItems
                            canSave={false}
                            addNewLabel="Add new item"
                            uniqueIdentifier="customIdentifier"
                        />
                    </Form.Row>
                    <LocationMap
                        coordinates={coordinates}
                        onChange={handleChangeCoordinate}
                        onActivateMarker={handleActiveMarker}
                        activeMarker={activeMarker}
                    />
                </>
            )}
            {useHardcodedPins && (
                <Form.Row>
                    <Field
                        name="neighborhood_map_configuration_id"
                        fieldProps={{
                            label: 'Neighborhood locations',
                            clearable: true,
                            helperText: 'Predefined set of neighborhood locations (MLA Blue)',
                            options: neighborhoodMapConfigurationOptions
                        }}
                        fieldAttr={{ id: 'isActive' }}
                        component={Select}
                    />
                </Form.Row>
            )}
            <br />
            <br />
            <br />
            <Form.Row>
                <Button submit disabled={pristine} loading={submitting} label={submitLabel} />
            </Form.Row>
        </Form>
    );
};

ProjectMLABlueForm.defaultProps = {
    onSubmit: () => {},
    change: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    isSlugRequired: false,
    form: null,
    coordinates: [],
    useHardcodedPins: true
};

ProjectMLABlueForm.propTypes = {
    touch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    change: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isSlugRequired: PropTypes.bool,
    form: PropTypes.string,
    coordinates: PropTypes.oneOfType([PropTypes.array]),
    useHardcodedPins: PropTypes.bool
};

export default reduxForm({
    form: 'ProjectMLABlueForm',
    enableReinitialize: true
})(ProjectMLABlueForm);
