import { createSelector } from 'reselect';

export const getSalesGuidelinesFetching = state =>
    state.sessionSettings?.salesGuidelines?.salesGuidelinesFetching || false;

export const getSalesGuidelinesData = state =>
    state.sessionSettings?.salesGuidelines?.salesGuidelinesData || [];

export const getProspectProfileQuestionsFetching = state =>
    state.sessionSettings?.prospectProfileQuestions?.listingFetching || false;

export const getProspectProfileQuestionsData = state =>
    state.sessionSettings?.prospectProfileQuestions?.listingResponse || [];

export default createSelector([
    getSalesGuidelinesFetching,
    getSalesGuidelinesData,
    getProspectProfileQuestionsData,
    getProspectProfileQuestionsFetching
]);
