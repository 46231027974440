import { createSelector } from 'reselect';

export const getProspectData = (state, iri) => state.prospects?.[iri];

export const getSingleProspectFetching = state => state.prospects?.singleFetching;

export const getProspectSessionFetching = state => state.prospects?.prospectSessionFetching;

export const getProspectQA = (state, iri) => state.prospects?.prospectQA?.[iri];

export const getProspectQAFetching = state => state.prospects?.prospectQAFetching;

export const getProspectQuestionCount = state => state.prospects?.prospectQuestionCount;

export default createSelector([
    getProspectData,
    getSingleProspectFetching,
    getProspectSessionFetching,
    getProspectQA,
    getProspectQAFetching,
    getProspectQuestionCount
]);
