import React from 'react';
import PropTypes from 'prop-types';
import Form, { NumberRange, Autocomplete } from 'erpcore/components/Form';
import { Field } from 'redux-form';
import { useRouteMatch } from 'react-router-dom';

const FloorSegmentsRepeatableItem = ({ member }) => {
    const match = useRouteMatch();
    const id = match?.params?.id;

    return (
        <div>
            <Form.Columns>
                <Form.GridColumn size={7}>
                    <Field
                        name={`${member}.level`}
                        fieldProps={{
                            label: 'Level range',
                            clearable: false
                        }}
                        fieldAttr={{ id: `${member}.level`, required: true }}
                        component={NumberRange}
                    />
                </Form.GridColumn>

                <Form.GridColumn size={4}>
                    <Field
                        name={`${member}.key_plan`}
                        fieldProps={{
                            label: 'Key plan',
                            clearable: true,
                            options: {
                                endpoint: `api/project/key-plans?filters[project.id][equals]=${id}`,
                                mapData: {
                                    label: 'name',
                                    value: 'iri'
                                }
                            }
                        }}
                        fieldAttr={{ id: `${member}.key_plan`, required: true }}
                        component={Autocomplete}
                    />
                </Form.GridColumn>
            </Form.Columns>
        </div>
    );
};

FloorSegmentsRepeatableItem.defaultProps = {
    member: null
};

FloorSegmentsRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default FloorSegmentsRepeatableItem;
