/* eslint-disable camelcase */
import { diff } from 'deep-object-diff';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import {
    actions as projectActions,
    actions as projectsActions
} from 'erpcore/screens/Projects/Projects.reducer';
import {
    getIsUpdating,
    getProjectData,
    getProjectFetching
} from 'erpcore/screens/Projects/Projects.selectors';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import ProjectKeyPlans from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectKeyPlans';
import ProjectUnitTypes from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectUnitTypes';
import UnitSettingsBuildings from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsSettingsBuildings';
import UnitsSettingsFloorPlansToggle from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsSettingsFloorPlansToggle';
import { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionError } from 'redux-form';
import './ProjectEditMultiEffectUnitsSettings.scss';

const ProjectEditMultiEffectUnitsSettings = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectDataOriginal = useSelector(state => getProjectData(state, projectIri)) || {};
    const isUpdating = useSelector(getIsUpdating);
    const projectData = dtoForm(projectDataOriginal) || {};
    const fetching = useSelector(getProjectFetching);
    const {
        key_plans: keyPlans = [],
        buildings = [],
        unit_types: unitTypes = [],
        use_unit_type_floorplans: useUnitTypeFloorplans = false
    } = {
        ...projectDataOriginal
    };
    const nonDeletedKeyPlans = keyPlans.filter(keyplan => !keyplan?.deleted);
    const nonDeletedUnitTypes = unitTypes.filter(unitType => !unitType.deleted);

    const buildingInitialValues = {
        buildings: buildings?.map(building => {
            const nonDeletedLevels = building?.levels?.filter(level => !level?.deleted);

            return {
                ...building,
                levels: nonDeletedLevels?.map(level => {
                    const { level_from: levelsFrom, level_to: levelsTo, key_plan: keyPlan } = {
                        ...level
                    };

                    return {
                        ...level,
                        level: {
                            range_start: levelsFrom,
                            range_end: levelsTo
                        },
                        key_plan: keyPlan?.iri
                    };
                })
            };
        })
    };

    const projectKeyplansInitialValues = {
        key_plans: nonDeletedKeyPlans?.map(keyPlan => {
            const { key_plan: keyPlanData } = { ...keyPlan };

            return {
                ...keyPlan,
                key_plan: keyPlanData?.iri
            };
        })
    };

    const projectUnitTypesInitialValues = {
        unit_types: nonDeletedUnitTypes.map(unit => {
            const {
                floor_plan: floorPlan,
                unit_type: unitType,
                exterior_area: exteriorArea,
                interior_area: interiorArea,
                nr_of_bathrooms: nrOfBathrooms,
                nr_of_bedrooms: nrOfBedrooms,
                square_footage: squareFootage,
                image_gallery: imageGallery,
                unit_positions: unitPositions,
                price_description: priceDescription,
                amount_description_public_on_blue: amountDescriptionPublicOnBlue
            } = { ...unit };
            return {
                iri: unit?.iri,
                exterior_area: exteriorArea,
                interior_area: interiorArea,
                name: unit?.name,
                nr_of_bathrooms: nrOfBathrooms,
                nr_of_bedrooms: nrOfBedrooms,
                square_footage: squareFootage,
                status: unit?.status || '',
                image_gallery: imageGallery?.length ? unit.image_gallery : [],
                floor_plan: floorPlan?.iri,
                unit_type: unitType?.iri,
                unit_positions: unitPositions?.iri,
                price_description: priceDescription,
                amount_description_public_on_blue: amountDescriptionPublicOnBlue
            };
        })
    };

    /**
     * Fetch unit settings data
     * @return {Promise}
     */
    const fetchUnitsSettings = () => {
        const params = {
            include:
                'keyPlans,keyPlans.keyPlan,unitTypes,unitTypes.floorPlan,unitTypes.unitType,unitTypes.unitPositions'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_FETCHING_UNIT_SETTINGS,
                promise: { resolve, reject },
                iri: projectIri,
                projectID: match?.params?.id,
                params
            });
        });
    };

    /**
     * Update project data
     * @params formData {Object}
     * @return {Promise}
     */
    const onToggleChanged = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT_TOGGLE_FLOORPLANS,
                iri: projectIri,
                formData
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    /**
     * Create building
     * @params formData {Object}
     * @return {Promise}
     */
    const onCreateBuilding = formData => {
        formData = {
            ...formData,
            project: projectIri
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectsActions.START_CREATE_PROJECT_BUILDING,
                formData,
                promise: { resolve, reject }
            });
        }).catch(error => {
            return error;
        });
    };

    /**
     * Updates building
     * @params formData {Object}
     * @return {Promise}
     */
    const onUpdateBuilding = data => {
        const buildingInitialValue = projectDataOriginal?.buildings?.find(
            building => building?.iri === data.iri
        );
        const formData = { ...data };

        if (buildingInitialValue) {
            const formDiff = diff(buildingInitialValue, formData);

            if (formDiff?.levels) {
                delete formDiff.levels;
            }

            if (Object.keys(formDiff)?.length > 0) {
                return new Promise((resolve, reject) => {
                    dispatch({
                        type: projectsActions.START_UPDATE_PROJECT_BUILDING,
                        iri: data?.iri,
                        formData: { ...formDiff },
                        promise: { resolve, reject }
                    });
                }).catch(error => {
                    return error;
                });
            }
        }

        return false;
    };

    /**
     * Removes building
     * @params formData {Object}
     * @return {Promise}
     */
    const onRemoveBuilding = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectsActions.START_DELETE_PROJECT_BUILDING,
                iri: formData?.iri,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchUnitsSettings());
    };

    /**
     * Creates Project keyplan
     * @params formData {Object}
     * @return {Promise}
     */
    const onCreateProjectKeyplan = formData => {
        const { key_plan: keyPlan } = { ...formData };
        formData = {
            ...formData,
            project: projectIri,
            key_plan: keyPlan
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_CREATE_PROJECT_KEY_PLAN,
                promise: { resolve, reject },
                formData
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchUnitsSettings());
    };

    /**
     * Removes project keyplan
     * @params formData {Object}
     * @return {Promise}
     */
    const onRemoveProjectKeyplan = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_DELETE_PROJECT_KEY_PLAN,
                iri: formData?.iri,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchUnitsSettings());
    };

    /**
     * Updates project keyplan
     * @params formData {Object}
     * @return {Promise}
     */
    const onUpdateProjectKeyplan = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_UPDATE_PROJECT_KEY_PLAN,
                iri: formData?.iri,
                formData,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchUnitsSettings());
    };

    /**
     * Creates project unit type
     * @params formData {Object}
     * @return {Promise}
     */
    const onCreateProjectUnitType = formData => {
        const {
            square_footage: squareFootage,
            interior_area: InteriorArea,
            exterior_area: exteriorArea
        } = { ...formData };
        formData = {
            ...formData,
            square_footage: parseInt(squareFootage, 10),
            interior_area: parseInt(InteriorArea, 10),
            exterior_area: parseInt(exteriorArea, 10),
            project: projectIri
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_CREATE_PROJECT_UNIT_TYPE,
                formData,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchUnitsSettings());
    };

    /**
     * Updates project unit type
     * @params formData {Object}
     * @return {Promise}
     */
    const onUpdateProjectUnitType = formData => {
        const {
            square_footage: squareFootage,
            interior_area: InteriorArea,
            exterior_area: exteriorArea
        } = { ...formData };
        formData = {
            ...formData,
            square_footage: parseInt(squareFootage, 10),
            interior_area: parseInt(InteriorArea, 10),
            exterior_area: parseInt(exteriorArea, 10)
        };

        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_UPDATE_PROJECT_UNIT_TYPE,
                iri: formData?.iri,
                formData,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchUnitsSettings());
    };

    /**
     * Removes project unit type
     * @params formData {Object}
     * @return {Promise}
     */
    const onRemoveProjectUnitType = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_DELETE_PROJECT_UNIT_TYPE,
                iri: formData?.iri,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => fetchUnitsSettings());
    };

    useEffect(() => {
        fetchUnitsSettings();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            {(fetching || isUpdating) && <PageLoader />}
            <ProjectEditPageHeader data={projectData} />
            <PageContent className="units-settings">
                <UnitsSettingsFloorPlansToggle
                    onToggleChanged={onToggleChanged}
                    initialValues={{ use_unit_type_floorplans: useUnitTypeFloorplans }}
                />

                <h3 className="units-settings__helper-title">
                    Define buildings, levels and key plans
                </h3>

                <UnitSettingsBuildings
                    initialValues={buildingInitialValues}
                    onCreate={onCreateBuilding}
                    onRemove={onRemoveBuilding}
                    onUpdate={onUpdateBuilding}
                    onFinish={fetchUnitsSettings}
                />

                <br />

                <h3 className="units-settings__helper-title">Key plans</h3>
                <ProjectKeyPlans
                    onCreate={onCreateProjectKeyplan}
                    onUpdate={onUpdateProjectKeyplan}
                    onRemove={onRemoveProjectKeyplan}
                    onFinish={fetchUnitsSettings}
                    initialValues={projectKeyplansInitialValues}
                />

                <br />

                <h3 className="units-settings__helper-title">Project unit types</h3>
                <ProjectUnitTypes
                    onCreate={onCreateProjectUnitType}
                    onRemove={onRemoveProjectUnitType}
                    onUpdate={onUpdateProjectUnitType}
                    onFinish={fetchUnitsSettings}
                    initialValues={projectUnitTypesInitialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMultiEffectUnitsSettings.defaultProps = {
    match: {}
};

ProjectEditMultiEffectUnitsSettings.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditMultiEffectUnitsSettings;
