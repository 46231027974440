import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { withRouter } from 'react-router-dom';
import { dtoForm } from 'erpcore/utils/dto';
import { diff } from 'deep-object-diff';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import UserEditForm from 'erpcore/screens/Users/components/UserEditForm';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { getUserData, getSingleUserFetching } from 'erpcore/screens/Users/Users.selectors';
import UserEditTabs from 'erpcore/screens/Users/components/UserEditTabs';
import UserEditPageHeader from 'erpcore/screens/Users/components/UserEditPageHeader';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';
import { dtoIgnoreTimezone } from 'erpcore/utils/utils';
import { productAccess } from 'erpcore/screens/Users/data/users';

const UserEdit = ({ match }) => {
    const dispatch = useDispatch();
    const userIri = `/api/users/${match?.params?.id}`;
    const userData = useSelector(state => dtoForm(getUserData(state, userIri))) || {};
    const fetching = useSelector(state => getSingleUserFetching(state));
    const initialValues = {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone_number: userData.phone_number,
        is_active: userData.is_active,
        birthdate: dtoIgnoreTimezone(userData.birthdate),
        location: {
            full_address: userData.full_address,
            street: userData.street,
            country: userData.country,
            state: userData.state,
            city: userData.city,
            zip: userData.zip,
            latitude: userData.latitude,
            longitude: userData.longitude
        },
        t_shirt_size: userData.t_shirt_size,
        image: userData.image,
        gender: userData.gender,
        type: userData._type,
        product_access: productAccess.reduce((accumulator, current) => {
            if (userData[current.value]) {
                accumulator.push(current.value);
            }
            return accumulator;
        }, []),
        lastLoggedIn: userData.last_activity,
        projects: userData?.projects || []
    };

    const pageTitle = () => {
        const { first_name: firstName, last_name: lastName } = { ...userData };
        if (firstName || lastName) {
            return `Edit user - ${firstName} ${lastName}`;
        }

        return `Edit user`;
    };

    const fetchUserData = () => {
        const params = {
            include: 'organizations,country,state,city,image,projects,roles,prospect,realtor'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_FETCHING_SINGLE_USER,
                iri: userIri,
                params
            });
        }).catch(error => ({ error }));
    };

    const onSubmit = formData => {
        // send only changed data
        let formDataDiff = { ...diff(initialValues, formData) };
        // Handle Location data
        if (formDataDiff.location) {
            formDataDiff = setLocationData('location', formDataDiff);
        }

        if ('projects' in formDataDiff) {
            formDataDiff.projects = formData.projects || []; // don't send diff array, send the whole array
        }

        if (formData?.product_access?.length) {
            delete formDataDiff.product_access;

            productAccess.forEach(product => {
                formDataDiff[product.value] = !!formData.product_access.includes(product.value);
            });
        }

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_UPDATE_SINGLE_USER,
                iri: userIri,
                formData: formDataDiff
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    const onAvesdoEmailApply = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_UPDATE_AVESDO_EMAIL,
                iri: userIri,
                prospectIri: userData?.prospect,
                realtorIri: userData?.realtor,
                avesdoIri: formData?.iri,
                email: formData?.email
            })
        ).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <UserEditPageHeader pageTitle={pageTitle()} />
            <UserEditTabs userData={userData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <UserEditForm
                    onSubmit={onSubmit}
                    form="UserEditForm"
                    initialValues={initialValues}
                    submitLabel="Update"
                    userId={match?.params?.id}
                    userData={userData}
                    onAvesdoEmailApply={onAvesdoEmailApply}
                />
            </PageContent>
        </LayoutManager>
    );
};

UserEdit.defaultProps = {
    match: {}
};

UserEdit.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(UserEdit);
