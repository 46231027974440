import React from 'react';
import { Field, formValueSelector, getFormInitialValues } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import FloorSegments from 'erpcore/screens/Projects/screens/ProjectEdit/components/FloorSegments';
import { useSelector } from 'react-redux';

const BuildingsRepeatableItem = ({ member, data: { onFinish } }) => {
    const selector = formValueSelector('UnitsSettingsBuildings');
    const repeaterItemData = useSelector(state => selector(state, member));
    const initialFormValues = useSelector(state =>
        getFormInitialValues('UnitsSettingsBuildings')(state)
    );
    const errors = {
        levels: []
    };

    const { isNewRepeaterItem, iri } = { ...repeaterItemData };

    const initialBuildingFormValues = initialFormValues?.buildings?.find(
        building => building?.iri === iri
    );

    /**
     * Check if any errors are present in array
     * @param arr {Array}
     * @return {Array}
     */
    const checkForErrors = arr => {
        return arr.filter(err => {
            const objectKeys = Object.keys(err) || {};

            if (objectKeys?.length) {
                const cleanObjectKeys = objectKeys.filter(key => {
                    if (!err?.[key]) {
                        return false;
                    }

                    return key;
                });

                return cleanObjectKeys.some(key => {
                    if (err?.[key]) {
                        return true;
                    }

                    return false;
                });
            }

            return false;
        });
    };

    const ifIsTrueOrZero = value => {
        return value || value === 0;
    };

    /**
     * Validate entire form
     * returns errors object or false if no errors present
     * @param formValues {Object}
     * @return {Object|false}
     */
    const validate = value => {
        errors.levels = [];
        const overlappedIndexes = [];
        const levels = value?.levels ? Array.from(value?.levels) : [];

        levels.forEach((item, index) => {
            const { level, key_plan: keyPlan } = { ...item };
            const { range_start: rangeStart, range_end: rangeEnd } = { ...level };

            if (!keyPlan) {
                errors.levels[index] = { ...errors.levels[index], key_plan: 'required' };
            } else {
                errors.levels[index] = { ...errors.levels[index], key_plan: undefined };
            }

            if (!level || !ifIsTrueOrZero(rangeStart) || rangeEnd < rangeStart) {
                if (!level || !ifIsTrueOrZero(rangeStart)) {
                    errors.levels[index] = { ...errors.levels[index], level: 'required' };
                }
                if (rangeEnd < rangeStart) {
                    errors.levels[index] = {
                        ...errors.levels[index],
                        level: 'Level end cannot be smaller then level start'
                    };
                }
            } else {
                errors.levels[index] = { ...errors.levels[index], level: undefined };
            }

            const arr = Array.from(levels?.slice(index + 1, levels?.length));

            arr.forEach((otherItems, otherItemsIndex) => {
                const { level: otherItemsLevel } = { ...otherItems };
                const { range_start: otherItemsRangeStart, range_end: otherItemsRangeEnd } = {
                    ...otherItemsLevel
                };

                if (
                    ifIsTrueOrZero(rangeEnd) &&
                    ifIsTrueOrZero(otherItemsRangeStart) &&
                    rangeEnd >= otherItemsRangeStart
                )
                    overlappedIndexes.push(index, index + 1 - otherItemsIndex);

                if (
                    ifIsTrueOrZero(rangeStart) &&
                    ifIsTrueOrZero(otherItemsRangeEnd) &&
                    otherItemsRangeEnd <= rangeStart
                ) {
                    overlappedIndexes.push(index, index + 1 - otherItemsIndex);
                }
            });
        });

        const removeDoubleIndexes = [...new Set(overlappedIndexes)];

        removeDoubleIndexes.forEach(index => {
            errors.levels[index] = {
                ...errors.levels[index],
                level: 'Ranges are overlapping'
            };
        });

        const hasErrors = checkForErrors(errors.levels);

        if (hasErrors.length) {
            return errors;
        }

        return false;
    };

    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{
                        label: 'Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.name`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <FloorSegments
                shouldBuildingDisplaySegments={isNewRepeaterItem}
                onFinish={onFinish}
                initialValues={initialBuildingFormValues}
                buildingIri={iri}
                form={`FloorSegmentForm-${repeaterItemData?.iri}`}
                validate={value => validate(value)}
            />
        </div>
    );
};

BuildingsRepeatableItem.defaultProps = {
    member: null,
    data: {}
};

BuildingsRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object])
};

export default BuildingsRepeatableItem;
