export const hsPipelines = [
    {
        value: '15249981',
        label: 'Nest Mortgage'
    },
    {
        value: 'default',
        label: 'Sales Pipeline'
    },
    {
        value: '4894927',
        label: 'MLA Complete Pipeline'
    },
    {
        value: '10605836',
        label: 'Industry Pipeline'
    },
    {
        value: '12147991',
        label: 'Blue Prospects'
    },
    {
        value: '16180573',
        label: 'Assignment Pipeline'
    },
    {
        value: '6846317',
        label: 'Full Scope Corporate Pipeline'
    },
    {
        value: '14517622',
        label: 'Blue Corporate Pipeline'
    },
    {
        value: '7374332',
        label: 'Realtor Call Campaigns (pre-registration)'
    },
    {
        value: '12342103',
        label: 'Advisory Corporate Pipeline'
    },
    {
        value: '13817632',
        label: 'Blue Realtors'
    },
    {
        value: '16511933',
        label: 'Realtor Project Registrants'
    }
];

export const hsStages = [
    {
        value: '15249982',
        label: 'Lead (or Queue)'
    },
    {
        value: '15249983',
        label: 'Meeting Scheduled'
    },
    {
        value: '15430955',
        label: 'Application Started'
    },
    {
        value: '15249984',
        label: 'Application (Complete) Submitted By Borrower'
    },
    {
        value: '16546611',
        label: '(Deal) Submitted'
    },
    {
        value: '16546612',
        label: 'Pre-Approved (ST)'
    },
    {
        value: '16546613',
        label: 'Pre-Approved (LT)'
    },
    {
        value: '15249985',
        label: 'Approved'
    },
    {
        value: '16546629',
        label: 'Waiting to Fund'
    },
    {
        value: '15249986',
        label: 'Funded'
    },
    {
        value: '15249988',
        label: 'Collapsed'
    },
    {
        value: '3992795',
        label: 'New Registrant'
    },
    {
        value: '6373781',
        label: 'Lead Up'
    },
    {
        value: 'qualifiedtobuy',
        label: 'Connection/Qualification'
    },
    {
        value: 'presentationscheduled',
        label: 'Home Selection'
    },
    {
        value: 'decisionmakerboughtin',
        label: 'Purchase Commitment'
    },
    {
        value: '6348200',
        label: 'Purchaser Pending'
    },
    {
        value: 'closedwon',
        label: 'Purchaser Firm'
    },
    {
        value: 'closedlost',
        label: 'Closed Lost'
    },
    {
        value: '4106888',
        label: 'Site Sold Out'
    },
    {
        value: '4701431',
        label: 'Unqualified - Industry'
    },
    {
        value: '4919842',
        label: 'New Purchaser'
    },
    {
        value: '4894930',
        label: 'Lead-Up'
    },
    {
        value: '4894929',
        label: 'Connection'
    },
    {
        value: '4992478',
        label: 'Focus'
    },
    {
        value: '4894932',
        label: 'Closed won'
    },
    {
        value: '4894934',
        label: 'Closed Lost'
    },
    {
        value: '5191586',
        label: 'On Hold (DO NOT CONTACT)'
    },
    {
        value: '10605841',
        label: 'Standard Industry Contact'
    },
    {
        value: '10605842',
        label: 'Closed won'
    },
    {
        value: '10605843',
        label: 'Closed lost'
    },
    {
        value: '12147992',
        label: 'Internal Database - No Match'
    },
    {
        value: '12147993',
        label: 'Internal Database with Match'
    },
    {
        value: '12147994',
        label: 'Registrant'
    },
    {
        value: '16380017',
        label: 'Interactive Tour Completed'
    },
    {
        value: '12147995',
        label: 'Unit Requested'
    },
    {
        value: '12147996',
        label: 'SG Visit'
    },
    {
        value: '16380058',
        label: 'Offer'
    },
    {
        value: '16380059',
        label: 'Rescission'
    },
    {
        value: '12147997',
        label: 'Firm'
    },
    {
        value: '12147998',
        label: 'Closed lost'
    },
    {
        value: '16180574',
        label: 'New Request'
    },
    {
        value: '16180576',
        label: 'Documents Pending'
    },
    {
        value: '16180577',
        label: 'Assignment Agreement Creation'
    },
    {
        value: '16180578',
        label: 'Purchaser Signatures Pending'
    },
    {
        value: '16180579',
        label: 'Assignment Fee & Stat Doc Pending'
    },
    {
        value: '16181153',
        label: 'Vendor Signatures Pending'
    },
    {
        value: '16181154',
        label: 'Update CRM'
    },
    {
        value: '16180580',
        label: 'Assignment Complete'
    },
    {
        value: '16181156',
        label: 'Assignment Cancel'
    },
    {
        value: '16181157',
        label: 'Assignment on Hold'
    },
    {
        value: '6846318',
        label: 'Unvetted Deal'
    },
    {
        value: '6846319',
        label: 'Inquiry Phase - Prelim Research'
    },
    {
        value: '7257461',
        label: 'Inquiry Phase - Exploration Meeting'
    },
    {
        value: '6846320',
        label: 'Service Proposal Draft'
    },
    {
        value: '11512958',
        label: 'Service Proposal Client Review'
    },
    {
        value: '6846321',
        label: 'Service Agreement Draft'
    },
    {
        value: '12141634',
        label: 'Service Agreement Client Review'
    },
    {
        value: '6846323',
        label: 'Project Onboard'
    },
    {
        value: '6846322',
        label: 'Project on Hold'
    },
    {
        value: '6846324',
        label: 'Pass for Now'
    },
    {
        value: '7257462',
        label: 'Pass Forever'
    },
    {
        value: '14517623',
        label: 'Unvetted Deal'
    },
    {
        value: '14517624',
        label: 'Inquiry Phase'
    },
    {
        value: '4990374',
        label: 'Pitch Phase'
    },
    {
        value: '14517625',
        label: 'Service Proposal Phase'
    },
    {
        value: '14517628',
        label: 'Project Onboard'
    },
    {
        value: '14517629',
        label: 'Closed lost'
    },
    {
        value: '7374333',
        label: 'Standard'
    },
    {
        value: '7374334',
        label: 'VIP - Realtor Call Campaigns'
    },
    {
        value: '7374335',
        label: 'Whale - Realtor Call Campaigns'
    },
    {
        value: '12393655',
        label: 'Unvetted Deal'
    },
    {
        value: '12342104',
        label: 'Inquiry Phase'
    },
    {
        value: '12342105',
        label: 'Service Proposal Phase'
    },
    {
        value: '12342109',
        label: 'Project Onboard'
    },
    {
        value: '12342107',
        label: 'Converted to ASM'
    },
    {
        value: '7386135',
        label: 'On Hold'
    },
    {
        value: '12342110',
        label: 'Closed lost'
    },
    {
        value: '13817633',
        label: 'Unvetted'
    },
    {
        value: '13817634',
        label: 'Attended Realtor Event'
    },
    {
        value: '13817635',
        label: 'Has Interested Clients'
    },
    {
        value: '13817636',
        label: 'Signed Agreement'
    },
    {
        value: '13817637',
        label: 'Client Purchased'
    },
    {
        value: '13817639',
        label: 'Closed lost'
    },
    {
        value: '16511934',
        label: 'Standard'
    },
    {
        value: '16511935',
        label: 'VIP - Realtor Project Registrants'
    },
    {
        value: '16511936',
        label: 'Whale - Realtor Project Registrants'
    },
    {
        value: '3662873',
        label: 'MLA Internal'
    }
];

export const NestMortgageHsPipelines = [
    {
        value: 'Lead (or Queue)',
        label: 'Lead (or Queue)'
    },
    {
        value: 'Meeting Scheduled',
        label: 'Meeting Scheduled'
    },
    {
        value: 'Application Started',
        label: 'Application Started'
    },
    {
        value: 'Application (Complete) Submitted By Borrower',
        label: 'Application (Complete) Submitted By Borrower'
    },
    {
        value: '(Deal) Submitted',
        label: '(Deal) Submitted'
    },
    {
        value: 'Pre-Approved (ST)',
        label: 'Pre-Approved (ST)'
    },
    {
        value: 'Pre-Approved (LT)',
        label: 'Pre-Approved (LT)'
    },
    {
        value: 'Approved',
        label: 'Approved'
    },
    {
        value: 'Waiting to Fund',
        label: 'Waiting to Fund'
    },
    {
        value: 'Funded',
        label: 'Funded'
    },
    {
        value: 'Collapsed',
        label: 'Collapsed'
    }
];
export const SalesPipelineHsPipelines = [
    {
        value: 'New Registran',
        label: 'New Registrant'
    },
    {
        value: 'Lead Up',
        label: 'Lead Up'
    },
    {
        value: 'Connection/Qualification',
        label: 'Connection/Qualification'
    },
    {
        value: 'Home Selection',
        label: 'Home Selection'
    },
    {
        value: 'Purchase Commitment',
        label: 'Purchase Commitment'
    },
    {
        value: 'Purchaser Pending',
        label: 'Purchaser Pending'
    },
    {
        value: 'Purchaser Firm',
        label: 'Purchaser Firm'
    },
    {
        value: 'Closed Lost',
        label: 'Closed Lost'
    },
    {
        value: 'Site Sold Out',
        label: 'Site Sold Out'
    },
    {
        value: 'Unqualified - Industry',
        label: 'Unqualified - Industry'
    }
];
export const MLACompletePipelineHsPipelines = [
    {
        value: 'New Purchaser',
        label: 'New Purchaser'
    },
    {
        value: 'Lead-Up',
        label: 'Lead-Up'
    },
    {
        value: 'Connection',
        label: 'Connection'
    },
    {
        value: 'Focus',
        label: 'Focus'
    },
    {
        value: 'Closed won',
        label: 'Closed won'
    },
    {
        value: 'Closed Lost',
        label: 'Closed Lost'
    },
    {
        value: 'On Hold (DO NOT CONTACT)',
        label: 'On Hold (DO NOT CONTACT)'
    }
];
export const IndustryPipelineHsPipelines = [
    {
        value: 'Standard Industry Contact',
        label: 'Standard Industry Contact'
    },
    {
        value: 'Closed won',
        label: 'Closed won'
    },
    {
        value: 'Closed lost',
        label: 'Closed lost'
    }
];
export const BlueProspectsHsPipelines = [
    {
        value: 'Internal Database - No Match',
        label: 'Internal Database - No Match'
    },
    {
        value: 'Internal Database with Match',
        label: 'Internal Database with Match'
    },
    {
        value: 'Registrant',
        label: 'Registrant'
    },
    {
        value: 'Interactive Tour Completed',
        label: 'Interactive Tour Completed'
    },
    {
        value: 'Unit Requested',
        label: 'Unit Requested'
    },
    {
        value: 'SG Visit',
        label: 'SG Visit'
    },
    {
        value: 'Offer',
        label: 'Offer'
    },
    {
        value: 'Rescission',
        label: 'Rescission'
    },
    {
        value: 'Firm',
        label: 'Firm'
    },
    {
        value: 'Closed lost',
        label: 'Closed lost'
    }
];
export const AssignmentPipelineHsPipelines = [
    {
        value: 'New Request',
        label: 'New Request'
    },
    {
        value: 'Documents Pending',
        label: 'Documents Pending'
    },
    {
        value: 'Assignment Agreement Creation',
        label: 'Assignment Agreement Creation'
    },
    {
        value: 'Purchaser Signatures Pending',
        label: 'Purchaser Signatures Pending'
    },
    {
        value: 'Assignment Fee & Stat Doc Pending',
        label: 'Assignment Fee & Stat Doc Pending'
    },
    {
        value: 'Vendor Signatures Pending',
        label: 'Vendor Signatures Pending'
    },
    {
        value: 'Update CRM',
        label: 'Update CRM'
    },
    {
        value: 'Assignment Complete',
        label: 'Assignment Complete'
    },
    {
        value: 'Assignment Cancel',
        label: 'Assignment Cancel'
    },
    {
        value: 'Assignment on Hold',
        label: 'Assignment on Hold'
    }
];
export const FullScopeCorporatePipelineHsPipelines = [
    {
        value: 'Unvetted Deal',
        label: 'Unvetted Deal'
    },
    {
        value: 'Inquiry Phase - Prelim Research',
        label: 'Inquiry Phase - Prelim Research'
    },
    {
        value: 'Inquiry Phase - Exploration Meeting',
        label: 'Inquiry Phase - Exploration Meeting'
    },
    {
        value: 'Service Proposal Draft',
        label: 'Service Proposal Draft'
    },
    {
        value: 'Service Proposal Client Review',
        label: 'Service Proposal Client Review'
    },
    {
        value: 'Service Agreement Draft',
        label: 'Service Agreement Draft'
    },
    {
        value: 'Service Agreement Client Review',
        label: 'Service Agreement Client Review'
    },
    {
        value: 'Project Onboard',
        label: 'Project Onboard'
    },
    {
        value: 'Project on Hold',
        label: 'Project on Hold'
    },
    {
        value: 'Pass for Now',
        label: 'Pass for Now'
    },
    {
        value: 'Pass Forever',
        label: 'Pass Forever'
    }
];
export const BlueCorporatePipelineHsPipelines = [
    {
        value: 'Unvetted Deal',
        label: 'Unvetted Deal'
    },
    {
        value: 'Inquiry Phase',
        label: 'Inquiry Phase'
    },
    {
        value: 'Pitch Phase',
        label: 'Pitch Phase'
    },
    {
        value: 'Service Proposal Phase',
        label: 'Service Proposal Phase'
    },
    {
        value: 'Project Onboard',
        label: 'Project Onboard'
    },
    {
        value: 'Closed lost',
        label: 'Closed lost'
    }
];
export const RealtorCallCampaignsHsPipelines = [
    {
        value: 'Standard',
        label: 'Standard'
    },
    {
        value: 'VIP',
        label: 'VIP'
    },
    {
        value: 'Whale',
        label: 'Whale'
    }
];
export const AdvisoryCorporatePipelineHsPipelines = [
    {
        value: 'Unvetted Deal',
        label: 'Unvetted Deal'
    },
    {
        value: 'Inquiry Phase',
        label: 'Inquiry Phase'
    },
    {
        value: 'Service Proposal Phase',
        label: 'Service Proposal Phase'
    },
    {
        value: 'Project Onboard',
        label: 'Project Onboard'
    },
    {
        value: 'Converted to ASM',
        label: 'Converted to ASM'
    },
    {
        value: 'On Hold',
        label: 'On Hold'
    },
    {
        value: 'Closed lost',
        label: 'Closed lost'
    }
];
export const BlueRealtorsHsPipelines = [
    {
        value: 'Unvetted',
        label: 'Unvetted'
    },
    {
        value: 'Attended Realtor Event',
        label: 'Attended Realtor Event'
    },
    {
        value: 'Has Interested Clients',
        label: 'Has Interested Clients'
    },
    {
        value: 'Signed Agreement',
        label: 'Signed Agreement'
    },
    {
        value: 'Client Purchased',
        label: 'Client Purchased'
    },
    {
        value: 'Closed lost',
        label: 'Closed lost'
    }
];
export const RealtorProjectRegistrantsHsPipelines = [
    {
        value: 'Standard',
        label: 'Standard'
    },
    {
        value: 'VIP',
        label: 'VIP'
    },
    {
        value: 'Whale',
        label: 'Whale'
    },
    {
        value: 'MLA Internal',
        label: 'MLA Internal'
    }
];
