import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import HomeTourForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/HomeTourForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { dtoForm } from 'erpcore/utils/dto';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const ProjectEditMLALiveHomeTour = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectDataOriginal = useSelector(state => getProjectData(state, projectIri)) || {};
    const projectData = dtoForm(projectDataOriginal) || {};
    const fetching = useSelector(getProjectFetching);

    const initialValues = {
        home_tour_show_home_video_tour: projectData.home_tour_show_home_video_tour,
        home_tour_sales_centre_video_tour: projectData.home_tour_sales_centre_video_tour,
        // eslint-disable-next-line camelcase
        home_tour_sales_centre3d_tour: (Array.isArray(projectData?.home_tour_sales_centre3d_tour)
            ? projectData.home_tour_sales_centre3d_tour
            : []
        ).map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        home_tour_panoramas: projectData.home_tour_panoramas?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        }),
        home_tour_gallery: projectDataOriginal.home_tour_gallery
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        // send only changed data (except main_gallery)
        const formDataDiff = diff(initialValues, formData);
        // Handle data
        const {
            home_tour_panoramas: homeTourPanoramas,
            home_tour_sales_centre3d_tour: homeTourSalesCentre3dTour,
            home_tour_gallery: homeTourGallery
        } = {
            ...formDataDiff
        };
        if (homeTourPanoramas) {
            formDataDiff.home_tour_panoramas = cleanRepeaterOutput(formData.home_tour_panoramas, [
                'customIdentifier'
            ]);
        }
        if (homeTourSalesCentre3dTour) {
            formDataDiff.home_tour_sales_centre3d_tour = cleanRepeaterOutput(
                formData.home_tour_sales_centre3d_tour,
                ['customIdentifier']
            );
        }
        if (homeTourGallery) {
            formDataDiff.home_tour_gallery = formData.home_tour_gallery;
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <HomeTourForm
                    onSubmit={onSubmit}
                    form={`HomeTourForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMLALiveHomeTour.defaultProps = {
    match: {}
};

ProjectEditMLALiveHomeTour.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProjectEditMLALiveHomeTour);
