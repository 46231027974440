import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import './TableActions.scss';

const TableActions = ({ children }) => {
    return (
        <div className="table-actions">
            <span className="table-actions__dots">
                <Svg icon="action" />
            </span>
            <ul className="table-actions__items">{children}</ul>
        </div>
    );
};

TableActions.defaultProps = {
    children: null
};

TableActions.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

/**
 * Listing Table Action
 */
TableActions.Action = function Action({ children }) {
    return <li className="table-actions__item">{children}</li>;
};

TableActions.Action.defaultProps = {
    children: null
};

TableActions.Action.propTypes = {
    children: PropTypes.node
};

export default TableActions;
