import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './OIAccessProject.scss';
import Image from 'erpcore/components/Image';
import { NavLink } from 'react-router-dom';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

const OIAccessProject = ({ project }) => {
    const [projectData, setProjectData] = useState(null);
    const { featured_image: featuredImage } = projectData || {};

    useEffect(() => {
        restClient.get(project?.iri).then(response => setProjectData(dto(response.data).data));
    }, [project]);

    return (
        <NavLink to={`dashboard/project/${project.id}`} className="projects__item" key={project.id}>
            {featuredImage?.iri ? (
                <Image
                    isContainer
                    version="large"
                    iri={featuredImage?.iri}
                    className="projects__item-image"
                    fallback={<div className="projects__item-image" />}
                />
            ) : (
                <div className="projects__item-image" />
            )}
            <div className="projects__item-content">
                <span className="projects__item-location">
                    {project?.city?.name}
                    {project?.state?.name ? `/ ${project?.state?.name}` : ''}
                </span>
                <span className="projects__item-name">{project.name}</span>
                <span className="projects__item-type">{project?.street}</span>
            </div>
            <div className="projects__item-action">View project</div>
        </NavLink>
    );
};

OIAccessProject.propTypes = {
    project: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default OIAccessProject;
