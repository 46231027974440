import React from 'react';
import { withRouter } from 'react-router-dom';
import Tabs from 'erpcore/components/Tabs';

const IntegrationTabs = () => {
    const links = [];

    links.push(
        {
            link: `/settings/general/integration/avesdo`,
            label: 'Avesdo'
        },
        {
            link: `/settings/general/integration/hubspot`,
            label: 'Hubspot',
            subLinks: [
                {
                    link: `/settings/general/integration/hubspot/settings`,
                    label: 'Settings'
                },
                {
                    link: `/settings/general/integration/hubspot/connect`,
                    label: 'Connect'
                }
            ]
        }
    );

    return <Tabs links={links} />;
};

export default withRouter(IntegrationTabs);
