import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import './Accordion.scss';

const Accordion = ({ variation, title, children, activeItemIndex }) => {
    const [accordionActiveIndex, setAccordionActiveIndex] = useState(activeItemIndex);

    const handleAccordionState = activeIndex => {
        if (activeIndex === accordionActiveIndex) {
            setAccordionActiveIndex(null);
        } else {
            setAccordionActiveIndex(activeIndex);
        }
    };

    // render children and add them additional props
    const renderChildren = () => {
        return React.Children.map(children, (child, index) => {
            return React.cloneElement(child, {
                onClick: handleAccordionState,
                index,
                active: accordionActiveIndex === index
            });
        });
    };

    return (
        <div className={`accordion${variation ? ` accordion--${variation}` : ''}`}>
            {title && (
                <div className="accordion__header">
                    <h3 className="accordion__header-title">{title}</h3>
                </div>
            )}
            {children && <ul className="accordion__content">{renderChildren()}</ul>}
        </div>
    );
};

Accordion.defaultProps = {
    title: null,
    children: null,
    variation: null,
    activeItemIndex: null
};

Accordion.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf([PropTypes.node])
    ]),
    variation: PropTypes.oneOf(['grey']),
    activeItemIndex: PropTypes.number
};

/**
 * Accordion Item Component
 */
Accordion.Item = function Item({
    variation,
    title,
    headerActions,
    content,
    onClick,
    index,
    active
}) {
    const onItemClick = () => {
        return onClick(index);
    };

    return (
        <li
            className={`accordion__item${active ? ' accordion__item--active' : ''}${
                variation ? ` accordion__item--${variation}` : ''
            }${headerActions ? ` accordion__item--header-actions` : ''}`}
        >
            <button type="button" onClick={onItemClick} className="accordion__item-header">
                {typeof title === 'string' && <h3 className="accordion__item-title">{title}</h3>}
                {typeof title !== 'string' && <div className="accordion__item-title">{title}</div>}
                <div className="accordion__item-action">
                    <Svg className="accordion__item-action-icon" icon="arrowDown" />
                </div>
            </button>
            {headerActions && <div className="accordion__item-header-actions">{headerActions}</div>}
            {content && (
                <div className="accordion__item-content">
                    <div className="accordion__item-content-inner">{content}</div>
                </div>
            )}
        </li>
    );
};

Accordion.Item.defaultProps = {
    title: null,
    headerActions: null,
    content: null,
    onClick: () => {},
    index: 0,
    active: false,
    variation: null
};

Accordion.Item.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf([PropTypes.node])
    ]),
    headerActions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf([PropTypes.node])
    ]),
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf([PropTypes.node])
    ]),
    onClick: PropTypes.func,
    index: PropTypes.number,
    active: PropTypes.bool,
    variation: PropTypes.oneOf(['thin'])
};

export default Accordion;
