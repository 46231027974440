import React, { Fragment, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import restClient from 'erpcore/api/restClient';

import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';

import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';
import { getUserData } from 'erpcore/screens/Users/Users.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';
import ElementLoader from 'erpcore/components/ElementLoader';

const UserEditPageHeader = ({ pageTitle }) => {
    const [impersonating, setImpersonating] = useState(false);
    const meData = useSelector(getMeData) || {};
    const params = useParams();
    const history = useHistory();
    const userIri = `/api/users/${params?.id}`;
    const userData = useSelector(state => getUserData(state, userIri)) || {};
    const portalIframe = useRef(null);

    const impersonateUser = useCallback(
        type => {
            setImpersonating(true);
            restClient.get(`${userData?.iri}/impersonate`).then(response => {
                portalIframe.current.contentWindow.postMessage(
                    { impersonateToken: response?.data?.token },
                    '*'
                );
                const redirect = () => {
                    setImpersonating(false);
                    window
                        .open(
                            `${
                                type === 'reporting'
                                    ? enviromentVariables?.REPORTING_APP_URL
                                    : enviromentVariables?.PORTAL_APP_URL
                            }`,
                            '_blank'
                        )
                        .focus();
                };
                setTimeout(redirect, 2000);
            });
        },
        [userData, setImpersonating]
    );

    const { is_active: isActive } = { ...userData };

    const dropdownActions = [
        isActive
            ? {
                  id: 'impersonate-portal',
                  label: 'Impersonate on Portal',
                  onClick: () => impersonateUser('portal')
              }
            : null,
        isActive
            ? {
                  id: 'impersonate-reporting',
                  label: 'Impersonate on Dashboard Feature',
                  onClick: () => impersonateUser('reporting')
              }
            : null,
        {
            id: 'create-new-user',
            label: 'Create New User',
            onClick: () => history.push('/users/create')
        }
    ];

    return (
        <Fragment>
            <HeadMeta title={pageTitle} />
            <PageHeader title={pageTitle}>
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <>
                        {impersonating && <ElementLoader overlay />}
                        <ButtonDropdown options={dropdownActions.filter(n => n)} />
                        <iframe
                            style={{ display: 'none' }}
                            title="portal"
                            src={enviromentVariables?.PORTAL_APP_URL}
                            ref={portalIframe}
                        />
                    </>
                )}
            </PageHeader>
        </Fragment>
    );
};

UserEditPageHeader.defaultProps = {
    pageTitle: null
};

UserEditPageHeader.propTypes = {
    pageTitle: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

export default UserEditPageHeader;
