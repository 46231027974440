import React from 'react';
import { Field } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const TitleUrlRepeaterItem = ({ member, data }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.url`}
                    fieldProps={{
                        label: data?.urlLabel || 'Url',
                        clearable: true,
                        helperText: data?.urlHelperText
                    }}
                    fieldAttr={{ id: `${member}.url`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

TitleUrlRepeaterItem.defaultProps = {
    member: null,
    data: null
};

TitleUrlRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object])
};

export default TitleUrlRepeaterItem;
