import { createSelector } from 'reselect';

export const getSessionsData = (state, iri) => state.sessions?.[iri];

export const getSingleSessionFetching = state => state.sessions?.singleFetching;

export const getSessionProspects = (state, iri) => state.sessions?.[iri]?.['session_prospects'];

export const getSingleSessionProspectsFetching = state =>
    state.sessions?.singleSessionProspectsFetching;

export const getSessionProspectQA = (state, iri) => state.sessions?.sessionProspectQA?.[iri];

export const getSessionProspectQAFetching = state => state.sessions?.sessionProspectQAFetching;

export const getSessionProspectQuestionCount = state =>
    state.sessions?.sessionProspectQuestionCount;

export const getSessionChat = (state, iri) => state.sessions?.sessionChat?.[iri];

export default createSelector([
    getSessionsData,
    getSingleSessionFetching,
    getSessionProspects,
    getSingleSessionProspectsFetching,
    getSessionProspectQA,
    getSessionProspectQAFetching,
    getSessionProspectQuestionCount,
    getSessionChat
]);
