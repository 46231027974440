import React from 'react';
import PropTypes from 'prop-types';
import TooltipPlugin from 'react-tooltip-lite';
import './Tooltip.scss';

const Tooltip = ({ content, children, tipContentHover, direction, zIndex, className, styles }) => (
    <TooltipPlugin
        className={`tooltip ${className || false}`}
        tagName="span"
        padding="8px"
        useDefaultStyles={false}
        content={content}
        direction={direction}
        tipContentHover={tipContentHover}
        tipContentClassName="tooltip-content"
        zIndex={zIndex}
        styles={styles}
    >
        {children}
    </TooltipPlugin>
);

Tooltip.defaultProps = {
    content: '',
    children: null,
    tipContentHover: false,
    direction: 'down',
    zIndex: 10000,
    className: null,
    styles: {}
};

Tooltip.propTypes = {
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.string
    ]),
    tipContentHover: PropTypes.bool,
    direction: PropTypes.string,
    zIndex: PropTypes.number,
    className: PropTypes.string,
    styles: PropTypes.oneOfType([PropTypes.object])
};

export default Tooltip;
