import React, { useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import SessionTabs from 'erpcore/screens/Settings/Session/components/SessionTabs';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import SalesGuidelinesForm from 'erpcore/screens/Settings/Session/components/SalesGuidelinesForm';
import { useDispatch, useSelector } from 'react-redux';
import { actions as sessionActions } from 'erpcore/screens/Settings/Session/SessionSettings.reducer';
import {
    getSalesGuidelinesData,
    getSalesGuidelinesFetching
} from 'erpcore/screens/Settings/Session/SessionSettings.selectors';
import PageLoader from 'erpcore/components/PageLoader';

const OrganizationGeneral = () => {
    const title = 'Session - Sales guidelines';
    const dispatch = useDispatch();
    const salesGuidelinesData = useSelector(state => getSalesGuidelinesData(state));
    const salesGuidelinesFetching = useSelector(state => getSalesGuidelinesFetching(state));

    const initialValues = {
        sales_guidelines: salesGuidelinesData
    };

    const onCreate = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionActions.START_CREATE_SALES_GUIDELINES,
                promise: { resolve, reject },
                formData
            });
        }).catch(error => {
            return error;
        });
    };

    const onRemove = iri => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionActions.START_DELETE_SALES_GUIDELINE,
                iri,
                promise: { resolve, reject }
            });
        }).catch(error => {
            return error;
        });
    };

    const onUpdate = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionActions.START_UPDATE_SALES_GUIDELINE,
                iri: formData?.iri,
                formData,
                promise: { resolve, reject }
            });
        }).catch(error => {
            return error;
        });
    };

    const fetchSalesGuidelines = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionActions.START_FETCHING_SALES_GUIDELINES,
                promise: { resolve, reject }
            });
        });
    };

    useEffect(() => {
        fetchSalesGuidelines();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            {salesGuidelinesFetching && <PageLoader />}
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <SessionTabs />
                <SalesGuidelinesForm
                    onCreate={onCreate}
                    onRemove={onRemove}
                    onUpdate={onUpdate}
                    onFinish={fetchSalesGuidelines}
                    initialValues={initialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default OrganizationGeneral;
