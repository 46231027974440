import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as sessionActions } from 'erpcore/screens/Settings/Session/SessionSettings.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getFormValues } from 'redux-form';

const actionName = 'PROFILE_QUESTIONS';
const reducerName = 'sessionSettings';
const defaultParams = 'order_by[position]=asc&pagination=false';

/**
 * Fetch Sales Guidelines
 * @param  {Object} promise
 */
export function* fetchSalesGuidelines({ promise }) {
    try {
        const fetchSalesGuidelinesAPI = yield restClient.get('api/sales-guidelines');
        yield put({
            type: sessionActions.FETCHING_SALES_GUIDELINES_SUCCESSFUL,
            response: dto(fetchSalesGuidelinesAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.FETCHING_SALES_GUIDELINES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create ProfileQuestion
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProfileQuestion({ promise, formData }) {
    try {
        const createProjectAPI = yield restClient.post('api/prospect-profile-questions', formData);
        yield put({
            type: sessionActions.CREATE_PROFILE_QUESTION_SUCCESFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProjectAPI?.data
        });
        const state = yield select();
        const formValues = getFormValues('ProspectProfileQuestionsFilters')(state);

        const params = {
            'filters[section][equals]': formValues?.section
        };
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: actionName,
            params,
            name: reducerName,
            endpoint: `api/prospect-profile-questions?${defaultParams}`
        });
        yield call(promise.resolve, createProjectAPI?.data);
    } catch (error) {
        yield put({
            type: sessionActions.CREATE_PROFILE_QUESTION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Sales Guidelines
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createSalesGuidelines({ promise, formData }) {
    try {
        const createSalesGuidelinesAPI = yield restClient.post('api/sales-guidelines', formData);
        yield put({
            type: sessionActions.CREATE_SALES_GUIDELINES_SUCCESSFUL
        });
        yield put({
            type: sessionActions.START_FETCHING_SALES_GUIDELINES,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createSalesGuidelinesAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.CREATE_SALES_GUIDELINES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update positions
 * @param  {Array} items
 * @return {Object} Response from API
 */
export function* updateQuestionPositions({ promise, data }) {
    try {
        const orderedItems = data.map(item => {
            return {
                id: item?.id,
                position: item?.position
            };
        });
        const updateQuestionPositionsAPI = yield restClient.patch(
            'api/prospect-profile-questions/position',
            orderedItems
        );
        yield put({
            type: sessionActions.UPDATE_POSITIONS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateQuestionPositionsAPI?.data
        });
        const state = yield select();
        const formValues = getFormValues('ProspectProfileQuestionsFilters')(state);

        const params = {
            'filters[section][equals]': formValues?.section
        };
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: actionName,
            params,
            name: reducerName,
            endpoint: `api/prospect-profile-questions?${defaultParams}`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.UPDATE_POSITIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Profile Question
 * @param  {Object} iri of profile question
 * @return {Object} Response from API
 */
export function* updateProfileQuestion({ promise, iri, formData }) {
    try {
        const updateProfileQuestionAPI = yield restClient.patch(iri, formData);
        yield put({
            type: sessionActions.UPDATE_PROFILE_QUESTION_SUCCESSFUL
        });
        const state = yield select();
        const formValues = getFormValues('ProspectProfileQuestionsFilters')(state);

        const params = {
            'filters[section][equals]': formValues?.section
        };
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: actionName,
            name: reducerName,
            params,
            endpoint: `api/prospect-profile-questions?${defaultParams}`
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProfileQuestionAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.UPDATE_PROFILE_QUESTION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Sales Guideline
 * @param  {Object} iri of sale guideline
 * @return {Object} Response from API
 */
export function* updateSalesGuideline({ promise, iri, formData }) {
    try {
        const updateSalesGuidelineAPI = yield restClient.patch(iri, formData);
        yield put({
            type: sessionActions.UPDATE_SALES_GUIDELINE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSalesGuidelineAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.UPDATE_SALES_GUIDELINE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Profile Prospect Question
 * @param  {Object} profile prospect question iri
 * @return {Object} Response from API
 */
export function* deleteProfileQuestion({ promise, iri }) {
    try {
        const deleteProfileQuestionAPI = yield restClient.delete(iri);
        yield put({
            type: sessionActions.DELETE_PROFILE_QUESTION_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProfileQuestionAPI?.data
        });
        const state = yield select();
        const formValues = getFormValues('ProspectProfileQuestionsFilters')(state);

        const params = {
            'filters[section][equals]': formValues?.section
        };
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            entity: actionName,
            name: reducerName,
            params,
            endpoint: `api/prospect-profile-questions?${defaultParams}`
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.DELETE_PROFILE_QUESTION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Profile Prospect Question
 * @param  {Object} profile prospect question iri
 * @return {Object} Response from API
 */
export function* deleteSalesGuidelines({ promise, iri }) {
    try {
        const deleteSalesGuidelinesAPI = yield restClient.delete(iri);
        yield put({
            type: sessionActions.DELETE_SALES_GUIDELINES_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSalesGuidelinesAPI?.data
        });
        yield put({
            type: sessionActions.START_FETCHING_SALES_GUIDELINES,
            promise
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionActions.DELETE_SALES_GUIDELINES_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const sessionSettingsSaga = [
    takeLatest(sessionActions.START_CREATE_PROFILE_QUESTION, createProfileQuestion),
    takeLatest(sessionActions.START_DELETE_PROFILE_QUESTION, deleteProfileQuestion),
    takeLatest(sessionActions.START_UPDATE_PROFILE_QUESTION, updateProfileQuestion),
    takeLatest(sessionActions.START_CREATE_SALES_GUIDELINES, createSalesGuidelines),
    takeLatest(sessionActions.START_FETCHING_SALES_GUIDELINES, fetchSalesGuidelines),
    takeLatest(sessionActions.START_DELETE_SALES_GUIDELINE, deleteSalesGuidelines),
    takeLatest(sessionActions.START_UPDATE_SALES_GUIDELINE, updateSalesGuideline),
    takeLatest(sessionActions.START_UPDATE_POSITIONS, updateQuestionPositions)
];

export default sessionSettingsSaga;
