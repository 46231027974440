import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';

import Button from 'erpcore/components/Button';
import ClientCreateEditForm from 'erpcore/screens/Clients/components/ClientCreateEditForm';
import ElementLoader from 'erpcore/components/ElementLoader';
import Modal from 'erpcore/components/Modal';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

import { actions as clientsActions } from 'erpcore/screens/Clients/Clients.reducer';
import { getSingleClientFetching, getClientData } from 'erpcore/screens/Clients/Clients.selectors';
import { dtoForm } from 'erpcore/utils/dto';

const ClientEditActionModal = ({ value: iri }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const clientData = dtoForm(useSelector(state => getClientData(state, iri)));
    const fetching = useSelector(getSingleClientFetching);
    const params = {
        include: 'city,country,state,accountManager'
    };
    const {
        name,
        email,
        phone,
        website,
        account_manager: accountManager,
        full_address: address,
        street,
        country,
        state,
        city,
        zip,
        latitude,
        longitude
    } = { ...clientData };

    const initialValues = {
        name,
        email,
        phone,
        website,
        account_manager: accountManager,
        location: {
            full_address: address,
            street,
            country,
            state,
            city,
            zip,
            latitude,
            longitude
        }
    };

    const fetchClientData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: clientsActions.START_FETCHING_CLIENT,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        // fetch data only on modal open
        if (opened) {
            fetchClientData();
        }
    }, [opened]);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        formData = diff(clientData, formData);

        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: clientsActions.START_UPDATE_CLIENT,
                promise: { resolve, reject },
                iri,
                formData
            });
        })
            .then(() => {
                handleModal();
            })
            .catch(error => {
                return error;
            });
    };

    const modalTitle = () => {
        if (clientData?.name) {
            return `Edit client - ${clientData.name}`;
        }

        return `Edit client`;
    };

    return (
        <Fragment>
            <Button
                onClick={() => handleModal()}
                variation="action"
                iconName="edit"
                label="Update"
                labelOnlyAria
            />
            <Modal
                variation="medium"
                opened={opened}
                onClose={() => handleModal()}
                title={modalTitle()}
            >
                {fetching === true && <ElementLoader overlay />}
                <ClientCreateEditForm
                    initialValues={initialValues}
                    form="ClientEditForm"
                    onSubmit={onSubmit}
                    submitLabel="Update"
                    onCancel={() => handleModal()}
                />
            </Modal>
        </Fragment>
    );
};

ClientEditActionModal.defaultProps = {
    value: null
};

ClientEditActionModal.propTypes = {
    value: PropTypes.string
};

export default ClientEditActionModal;
