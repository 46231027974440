import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Form, { Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';

const ProductTypesEditCreateForm = ({ handleSubmit, onSubmit, invalid, pristine, submitLabel }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    fieldProps={{
                        label: 'Product Type'
                    }}
                    fieldAttr={{
                        required: true,
                        id: 'product-type'
                    }}
                    component={Text}
                    validation={valueValidation([{ validator: 'required' }])}
                />
                <Button disabled={invalid || pristine} submit label={submitLabel} />
            </Form.Row>
        </Form>
    );
};

ProductTypesEditCreateForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitLabel: 'Add new'
};

ProductTypesEditCreateForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitLabel: PropTypes.string
};

export default reduxForm({
    form: 'ProductTypesEditCreateForm',
    enableReinitialize: true
})(ProductTypesEditCreateForm);
