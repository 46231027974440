import React from 'react';
import { reduxForm, Field } from 'redux-form';
import Form, { Autocomplete, DateRange } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

const SessionReportFilters = ({ onFilterChange, handleSubmit }) => {
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    return (
        <Form onSubmit={handleSubmit(onFilterChange)}>
            <Form.Columns>
                {hasPermissionsAccessForEntities.users && (
                    <Form.Column>
                        <Field
                            name="users"
                            fieldProps={{
                                label: 'Filter by User',
                                options: {
                                    endpoint: `api/users`,
                                    mapData: {
                                        value: 'iri',
                                        label: '{first_name} {last_name}'
                                    }
                                }
                            }}
                            fieldAttr={{
                                id: 'sales_agent'
                            }}
                            component={Autocomplete}
                        />
                    </Form.Column>
                )}
                {hasPermissionsAccessForEntities.projects && (
                    <Form.Column>
                        <Field
                            name="project"
                            fieldProps={{
                                label: 'Filter by Project',
                                options: {
                                    endpoint: `api/projects`,
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                }
                            }}
                            fieldAttr={{
                                id: 'project'
                            }}
                            component={Autocomplete}
                        />
                    </Form.Column>
                )}
                <Form.Column>
                    <Field
                        name="date_range"
                        fieldProps={{
                            label: 'Filter by Date Range'
                        }}
                        fieldAttr={{
                            id: 'date-range'
                        }}
                        component={DateRange}
                    />
                </Form.Column>
                <Button submit label="Filter Report" />
            </Form.Columns>
        </Form>
    );
};

SessionReportFilters.defaultProps = {
    onFilterChange: () => {},
    handleSubmit: () => {}
};

SessionReportFilters.propTypes = {
    onFilterChange: PropTypes.func,
    handleSubmit: PropTypes.func
};

export default reduxForm({
    form: 'SessionReportFilters',
    enableReinitialize: true
})(SessionReportFilters);
