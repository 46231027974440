import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PortalChecklistItemsForm from 'erpcore/screens/Settings/Portal/components/PortalChecklistItemsForm';
import PortalTabs from 'erpcore/screens/Settings/Portal/components/PortalTabs';
import PageContent from 'erpcore/components/Layout/PageContent';
import Modal from 'erpcore/components/Modal';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import { getOrganizationSetting } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import Listing from 'erpcore/components/Listing';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';
import Svg from 'erpcore/components/Svg';
import Button from 'erpcore/components/Button';
import './PortalCheclistItems.scss';

const PortalChecklistItems = () => {
    const title = 'Homeowner Portal App - Checklist Items';
    const [fetching, setFetching] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const dispatch = useDispatch();
    const settingName = 'PortalChecklistItems';
    const settings = useSelector(state => getOrganizationSetting(state, settingName)) || [];
    const data = settings?.data;

    const fetchPortalBanners = params => {
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName,
                params
            });
        })
            .then(() => setFetching(false))
            .catch(error => ({ error }));
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                type: organizationActions.START_UPDATE_ORGANIZATION_SETTING,
                settingName,
                portal: true,
                formData,
                iri: formData?.iri,
                promise: { resolve, reject }
            })
        )
            .then(() => fetchPortalBanners())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    const onDelete = iri => {
        return new Promise((resolve, reject) =>
            dispatch({
                type: organizationActions.START_DELETE_ORGANIZATION_SETTING,
                settingName,
                iri,
                promise: { resolve, reject }
            })
        )
            .then(() => {
                fetchPortalBanners();
                setDeleteModal(false);
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchPortalBanners();
    }, []);

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'Items',
                field: 'items'
            },
            {
                title: 'Offers',
                field: 'offers'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        // Table Data
        if (data?.length) {
            orderBy(data, item => !item?.settings?.required, ['asc']).map(row => {
                const { id, iri } = {
                    ...row
                };
                const { item, offers, required } = {
                    ...row?.settings
                };

                const offersRender = offers?.map(offer => {
                    const { company_name: companyName } = offer;
                    return (
                        <>
                            <p>
                                {companyName} <br /> {offer?.offer}
                            </p>
                        </>
                    );
                });

                return table.data.push({
                    id,
                    iri,
                    items: `${item} ${required ? ' (required)' : ''}`,
                    offers: offersRender,
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <Tooltip content="Edit Checklist Item">
                                    <button
                                        type="button"
                                        onClick={() => setEditModal({ iri, ...row?.settings })}
                                    >
                                        <Svg icon="edit" />
                                    </button>
                                </Tooltip>
                            </TableActions.Action>
                            <TableActions.Action>
                                <Tooltip content="Delete Checklist Item">
                                    <button type="button" onClick={() => setDeleteModal(iri)}>
                                        <Svg icon="remove" />
                                    </button>
                                </Tooltip>
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <div className="portal-checklist-items">
                    <PortalTabs />
                    <Modal
                        opened={!!createModal}
                        onClose={() => setCreateModal(false)}
                        title="Add New Checklist Item"
                    >
                        <PortalChecklistItemsForm
                            onSubmit={onSubmit}
                            form="PortalChecklistItemsCreateForm"
                            submitLabel="Create"
                            onSubmitSuccess={() => setCreateModal(false)}
                        />
                    </Modal>
                    <Modal
                        opened={!!editModal}
                        onClose={() => setEditModal(false)}
                        title="Edit Checklist Item"
                    >
                        <PortalChecklistItemsForm
                            onSubmit={onSubmit}
                            form="PortalChecklistItemsForm"
                            submitLabel="Update"
                            initialValues={editModal}
                            onSubmitSuccess={() => setEditModal(false)}
                        />
                    </Modal>
                    <Modal
                        opened={!!deleteModal}
                        onClose={() => setDeleteModal(false)}
                        title="Delete item"
                        subtitle="Are you sure you want to delete this item?"
                        variation="small"
                    >
                        <Button onClick={() => onDelete(deleteModal)} label="Delete" />
                        <Button
                            onClick={() => setDeleteModal(false)}
                            label="Cancel"
                            variation="secondary"
                        />
                    </Modal>
                    <Listing
                        hideSearch
                        name="portalFAQ"
                        reducerName="portalFAQ"
                        meta={settings?.meta}
                        table={tableData()}
                        loading={fetching}
                        onListingConfigUpdate={params => fetchPortalBanners(params)}
                    >
                        <Button
                            onClick={() => setCreateModal(true)}
                            label="Add New Item"
                            variation="secondary"
                        />
                    </Listing>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

export default PortalChecklistItems;
