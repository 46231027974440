import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import PageLoader from 'erpcore/components/PageLoader';
import Favicon from 'erpcore/components/Favicon';
import { hexToRGB, isOiAccessSubdomain } from 'erpcore/utils/utils';

import { actions as organizationActions } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.reducer';
import {
    getOrganizationSettings,
    getFetchingOrganizationSettings
} from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import enviromentVariables from '../enviromentVariables';

const OrganizationSettings = ({ children }) => {
    const dispatch = useDispatch();
    const organizationSettings = useSelector(state => getOrganizationSettings(state)) || {};
    const fetchingOrganizationSettings =
        useSelector(state => getFetchingOrganizationSettings(state)) || false;

    const fetchOrganizationSettings = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCHING_PUBLIC_ORGANIZATION_SETTINGS
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchOrganizationSettings();
    }, []);

    const { primaryColor, sidebarColor, buttonColor, textColor, favicon, titleFont, bodyFont } = {
        ...organizationSettings.settings
    };

    return (
        <>
            {!!favicon && <Favicon iri={favicon} />}
            <Helmet>
                {enviromentVariables.REACT_APP_ENV === 'production' && isOiAccessSubdomain() && (
                    <script
                        async
                        src="https://www.googletagmanager.com/gtag/js?id=G-M8R2W8W27Z"
                    ></script>
                )}
                {enviromentVariables.REACT_APP_ENV === 'production' && isOiAccessSubdomain() && (
                    <script>
                        {`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-M8R2W8W27Z');`}
                    </script>
                )}

                <style type="text/css">
                    {`
                        :root {
                            ${titleFont ? `--titleFont: '${titleFont}';` : ''}
                            ${bodyFont ? `--bodyFont: '${bodyFont}';` : ''}
                            ${
                                primaryColor
                                    ? `--primaryColor: ${hexToRGB(primaryColor, false, true)};`
                                    : ''
                            }
                            ${
                                sidebarColor
                                    ? `--sidebarColor: ${hexToRGB(sidebarColor, false, true)};`
                                    : ''
                            }
                            ${
                                buttonColor
                                    ? `--buttonColor: ${hexToRGB(buttonColor, false, true)};`
                                    : ''
                            }
                            ${textColor ? `--textColor: ${hexToRGB(textColor, false, true)};` : ''}
                        }
                    `}
                </style>
            </Helmet>
            {fetchingOrganizationSettings ? <PageLoader /> : children}
        </>
    );
};

OrganizationSettings.defaultProps = {
    children: null
};

OrganizationSettings.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node])
};

export default OrganizationSettings;
