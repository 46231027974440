import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import Listing from 'erpcore/components/Listing';
import ModalChangePassword from 'erpcore/screens/Users/components/ModalChangePassword';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import StatusBadge from 'erpcore/components/StatusBadge';
import Svg from 'erpcore/components/Svg';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Tooltip from 'erpcore/components/Tooltip';
import UserDelete from 'erpcore/screens/Users/components/UsersDelete';
import UserListingName from 'erpcore/screens/Users/components/UserListingName';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getImageSrcFromMediaObject } from 'erpcore/components/ImageManager';
import { hasPermission } from 'erpcore/utils/RolesManager';
import { userActiveStatus, statusBadges, userType } from 'erpcore/screens/Users/data/users';
import { getOptionByValue } from 'erpcore/components/Form/Form.utils';
import StatusCircle from 'erpcore/components/StatusCircle';

const UsersListing = () => {
    const reducerName = 'users';
    const actionName = 'USERS';

    const dispatch = useDispatch();
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const meData = useSelector(getMeData) || {};
    const title = 'Users';

    const fetchUsers = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/users?include=image,image.versions,office'
            });
        }).catch(error => {
            return error;
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        table.filters = [
            {
                name: 'user_projects',
                label: 'Projects related to user deals',
                defaultOperator: 'in',
                filterFields: {
                    value: {
                        component: 'multiautocomplete',
                        fieldProps: {
                            label: 'Projects',
                            options: {
                                endpoint: `/api/projects`,
                                mapData: {
                                    value: 'iri',
                                    label: 'name'
                                }
                            },
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'is_active',
                label: 'Status',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Status',
                            options: userActiveStatus,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'type',
                label: 'Type',
                defaultOperator: 'equals',
                filterFields: {
                    value: {
                        component: 'select',
                        fieldProps: {
                            label: 'Type',
                            options: userType,
                            clearable: false
                        },
                        fieldValidation: [{ validator: 'required' }]
                    }
                }
            },
            {
                name: 'first_name',
                label: 'Is user unnamed',
                filterTagLabel: 'Is user unnamed',
                defaultOperator: 'not_exists',
                filterFields: {
                    defaultValue: '1'
                }
            },
            {
                name: 'prospect',
                label: 'Is Purchaser',
                filterTagLabel: 'Is Purchaser',
                defaultOperator: 'exists',
                filterFields: {
                    defaultValue: '1',
                    additionalFilter: { fieldName: 'type', value: 'prospect' }
                }
            },
            {
                name: 'is_test_portal_user',
                label: 'Is Test Portal User',
                filterTagLabel: 'Is Test Portal User',
                defaultOperator: 'equals',
                filterFields: {
                    defaultValue: true
                }
            }
        ];

        table.schema = [
            {
                title: 'User name',
                field: 'name',
                sortable: 'lastName',
                mobile: 'title'
            },
            {
                title: 'Projects related to user deals',
                field: 'projects'
            },
            {
                title: 'Email',
                field: 'email'
            },
            {
                title: 'Status',
                field: 'status',
                mobile: 'status'
            },
            {
                title: 'User Type',
                field: 'userType'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'ASC',
            sortable: 'lastName'
        };

        const { data: listingData } = { ...listing };
        if (listingData) {
            listingData.map(row => {
                const {
                    id,
                    iri,
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    office,
                    image,
                    is_active: isActive,
                    user_projects: userProjects
                } = row;
                const userName = `${firstName || ''} ${lastName || ''}`.trim();

                const contentUrl = getImageSrcFromMediaObject(image, 'small');

                let status = { type: '', text: '' };
                if (isActive) {
                    status = 'active';
                } else {
                    status = 'inactive';
                }

                let editLink = `/users/${id}/edit/details`;
                if (
                    !hasPermission('CAN_MANAGE_COMMON_USER', meData) &&
                    hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData)
                ) {
                    editLink = `/users/${id}/edit/employment-records`;
                }

                const userTypeRender = () => {
                    if (row?._type === 'prospect' && row?.prospect) {
                        return 'Prospect/Purchaser';
                    }

                    return getOptionByValue(row?._type, userType)?.label || row?._type;
                };

                const userProjectsRender = userProjects?.length
                    ? userProjects.map(project => {
                          const {
                              portal_enabled: portalEnabled,
                              test_portal_enabled: testPortalEnabled
                          } = project;
                          let portalState = 'grey';
                          let portalTooltip = 'Portal is not active';
                          if (testPortalEnabled) {
                              portalState = 'sky';
                              portalTooltip = 'Test Portal is active';
                          }
                          if (portalEnabled) {
                              portalState = 'apple';
                              portalTooltip = 'Portal is active';
                          }
                          return (
                              <div style={{ minWidth: '200px' }}>
                                  <Tooltip content={portalTooltip}>
                                      <StatusCircle type={portalState} />
                                  </Tooltip>{' '}
                                  <a href={`/projects/${project?.id}/edit/general-info`}>
                                      {project?.name}
                                  </a>
                                  <br />
                              </div>
                          );
                      })
                    : 'No project';

                return table.data.push({
                    id,
                    iri,
                    name: (
                        <UserListingName
                            name={userName || 'Unnamed user'}
                            imageUrl={contentUrl}
                            office={office}
                        />
                    ),
                    projects: userProjectsRender,
                    email,
                    status: (
                        <StatusBadge
                            type={statusBadges[status]?.type || status}
                            text={statusBadges[status]?.label || status}
                        />
                    ),
                    userType: userTypeRender(),
                    actions: (
                        <TableActions>
                            {(hasPermission('CAN_MANAGE_COMMON_USER', meData) ||
                                hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData)) && (
                                <TableActions.Action>
                                    <Tooltip content="Edit user">
                                        <NavLink to={editLink}>
                                            <Svg icon="edit" />
                                        </NavLink>
                                    </Tooltip>
                                </TableActions.Action>
                            )}

                            {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                                <TableActions.Action>
                                    <ModalChangePassword
                                        id={id}
                                        name={userName}
                                        key={`ChangePassword_${id}`}
                                    />
                                </TableActions.Action>
                            )}

                            {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                                <TableActions.Action>
                                    <UserDelete iri={iri} key={`UserDelete ${iri}`} />
                                </TableActions.Action>
                            )}
                        </TableActions>
                    )
                });
            });
        }
        return table;
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                    <Button href="/users/create" label="Create user" />
                )}
            </PageHeader>
            <Listing
                name={reducerName}
                reducerName={reducerName}
                loading={listingFetching}
                meta={listing?.meta}
                table={tableData()}
                onListingConfigUpdate={params => fetchUsers(params)}
            />
        </LayoutManager>
    );
};

export default UsersListing;
