export const actions = {
    START_FETCHING_TOTAL_SESSIONS: 'START_FETCHING_TOTAL_SESSIONS',
    FETCH_TOTAL_SESSIONS_SUCCESSFUL: 'FETCH_TOTAL_SESSIONS_SUCCESSFUL',
    FETCH_TOTAL_SESSIONS_FAILED: 'FETCH_TOTAL_SESSIONS_FAILED',

    START_FETCHING_POST_DEMO_VIEWS: 'START_FETCHING_POST_DEMO_VIEWS',
    FETCH_POST_DEMO_VIEWS_SUCCESSFUL: 'FETCH_POST_DEMO_VIEWS_SUCCESSFUL',
    FETCH_POST_DEMO_VIEWS_FAILED: 'FETCH_TOTAL_SESSIONS_FAILED'
};

export const initialState = {
    fetchingReportSessionData: false,
    totalSessions: null,
    postDemoViews: null
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_TOTAL_SESSIONS:
            return {
                ...state,
                fetchingReportSessionData: true
            };
        case actions.FETCH_TOTAL_SESSIONS_SUCCESSFUL:
            return {
                ...state,
                fetchingReportSessionData: false,
                totalSessions: response?.data?.total || null
            };
        case actions.FETCH_TOTAL_SESSIONS_FAILED:
            return {
                ...state,
                fetchingReportSessionData: false
            };
        case actions.START_FETCHING_POST_DEMO_VIEWS:
            return {
                ...state,
                fetchingReportSessionData: true
            };
        case actions.FETCH_POST_DEMO_VIEWS_SUCCESSFUL:
            return {
                ...state,
                fetchingReportSessionData: false,
                postDemoViews: response?.data?.postDemoViews || null
            };
        case actions.FETCH_POST_DEMO_VIEWS_FAILED:
            return {
                ...state,
                fetchingReportSessionData: false
            };
        default:
            return state;
    }
};
