import { createSelector } from 'reselect';

export const getDealData = (state, iri) => state.deals?.[iri];

export const getDealFetching = state => state.deals?.singleFetching;

export const getDealQA = (state, iri) => state.deals?.dealQA?.[iri];

export const getDealsQAFetching = state => state.deals?.dealQAFetching;

export const getDealQuestionCount = state => state.deals?.dealQuestionCount;

export default createSelector([
    getDealData,
    getDealFetching,
    getDealQA,
    getDealsQAFetching,
    getDealQuestionCount
]);
