import { hexToRGB } from 'erpcore/utils/utils';

// Creates canvas gradient using ctx.CreateLinearGradient with color stops tool //
const setGradientColor = (canvas, color) => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 20, 0, ctx.canvas.clientHeight * 2);
    // Gradient olorstops can only accept RGB colors, hexToRGB creates RGBA from hex, with custom alpha values //
    gradient.addColorStop(0, hexToRGB(color, 0.25));
    gradient.addColorStop(1, 'rgba(255,255,255,0)');

    return gradient;
};

// eslint-disable-next-line import/prefer-default-export
export { setGradientColor };
