import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Divisions Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const ProspectNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'prospect.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Prospect successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'prospect.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Prospect successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'prospect.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Prospect successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'prospects.imported':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Prospect successfully imported!"
                    type="success"
                    expire="3500"
                />
            );
        case 'prospects.importedWithErrors':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={
                        <>
                            {detail?.map(errorDetail => (
                                <p>{errorDetail}</p>
                            ))}
                        </>
                    }
                    type="error"
                />
            );
        case 'deal.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Deal successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'deal.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Deal successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

ProspectNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

ProspectNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default ProspectNotifications;
