import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';

import Button from 'erpcore/components/Button';
import ElementLoader from 'erpcore/components/ElementLoader';
import Modal from 'erpcore/components/Modal';

import { actions as prospectActions } from 'erpcore/screens/Prospects/Prospects.reducer';
import {
    getSingleProspectFetching,
    getProspectData
} from 'erpcore/screens/Prospects/Prospects.selectors';
import { dtoForm } from 'erpcore/utils/dto';
import ProspectForm from 'erpcore/screens/Prospects/components/ProspectForm';
import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

const ProspectEditActionModal = ({ value: iri }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);
    const clientData = dtoForm(useSelector(state => getProspectData(state, iri)));
    const fetching = useSelector(getSingleProspectFetching);
    const params = {
        include: 'city,country,state'
    };
    const {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        full_address: address,
        street,
        country,
        state,
        city,
        zip,
        latitude,
        longitude
    } = { ...clientData };

    const initialValues = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        location: {
            full_address: address,
            street,
            country,
            state,
            city,
            zip,
            latitude,
            longitude
        }
    };

    const fetchProspectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: prospectActions.START_FETCHING_SINGLE_PROSPECT,
                iri,
                promise: { resolve, reject },
                params
            });
        });
    };

    useEffect(() => {
        // fetch data only on modal open
        if (opened) {
            fetchProspectData();
        }
    }, [opened]);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        formData = diff(clientData, formData);

        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: prospectActions.START_UPDATE_SINGLE_PROSPECT,
                promise: { resolve, reject },
                iri,
                formData
            });
        })
            .then(() => {
                handleModal();
            })
            .catch(error => {
                return error;
            });
    };

    const modalTitle = () => {
        if (clientData?.name) {
            return `Edit prospect - ${clientData.name}`;
        }

        return `Edit prospect`;
    };

    return (
        <Fragment>
            <Button
                onClick={() => handleModal()}
                variation="action"
                iconName="edit"
                label="Update"
                labelOnlyAria
            />
            <Modal
                variation="medium"
                opened={opened}
                onClose={() => handleModal()}
                title={modalTitle()}
            >
                {fetching === true && <ElementLoader overlay />}
                <ProspectForm
                    initialValues={initialValues}
                    form="ProspectEditForm"
                    onSubmit={onSubmit}
                    submitLabel="Update"
                    onCancel={() => handleModal()}
                />
            </Modal>
        </Fragment>
    );
};

ProspectEditActionModal.defaultProps = {
    value: null
};

ProspectEditActionModal.propTypes = {
    value: PropTypes.string
};

export default ProspectEditActionModal;
