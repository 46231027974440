import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import UserEditPageHeader from 'erpcore/screens/Users/components/UserEditPageHeader';
import UserEditTabs from 'erpcore/screens/Users/components/UserEditTabs';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { SubmissionError } from 'redux-form';

import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';
import { getSingleUserFetching, getUserData } from 'erpcore/screens/Users/Users.selectors';

import { getUniqueListBy } from 'erpcore/utils/utils';
import UserReportingDashboardForm from '../../components/UserReportingDashboardForm';
import './UserReportingDashboard.scss';

const UserReportingDashboard = ({ match }) => {
    const dispatch = useDispatch();
    const userIri = `/api/users/${match?.params?.id}`;
    const userData = useSelector(state => getUserData(state, userIri)) || {};
    const fetching = useSelector(getSingleUserFetching);

    const { client_available_projects: clientAvailableProjects } = userData;

    const initialValues = {
        client_available_projects: clientAvailableProjects?.map(project => project?.iri)
    };

    const pageTitle = () => {
        const { first_name: firstName, last_name: lastName } = { ...userData };
        if (firstName || lastName) {
            return `Edit user - ${firstName} ${lastName}`;
        }

        return `Edit user`;
    };

    // Fetch User Data
    const fetchUserData = () => {
        // Getting included data from API by setting params
        const params = {
            include: 'projects,clientAvailableProjects,clientExcludedProjects'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_FETCHING_SINGLE_USER,
                iri: userIri,
                params
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const projects = useMemo(() => {
        const projectsChecks = [];

        // eslint-disable-next-line camelcase
        // if (!(userData?.client_available_projects && userData?.client_excluded_projects)) {
        // eslint-disable-next-line no-unused-expressions,camelcase
        userData?.projects?.forEach(project => {
            projectsChecks.push({
                value: project?.iri,
                label: project?.name,
                id: project?.iri
            });
        });
        // }

        // eslint-disable-next-line no-unused-expressions,camelcase
        userData?.client_available_projects?.forEach(project =>
            projectsChecks.push({
                value: project?.iri,
                label: project?.name,
                id: project?.iri
            })
        );
        // eslint-disable-next-line no-unused-expressions,camelcase
        userData?.client_excluded_projects?.forEach(project =>
            projectsChecks.push({
                value: project?.iri,
                label: project?.name,
                id: project?.iri
            })
        );
        const uniqueProjectsChecks = getUniqueListBy(projectsChecks, 'value');
        return orderBy(uniqueProjectsChecks, 'value');
    }, [userData]);

    const onSubmitUserReportingDashboardForm = formData => {
        if (!formData) {
            return false;
        }

        // eslint-disable-next-line camelcase
        if (!formData?.client_available_projects) formData.client_available_projects = [];

        const excluded = projects.filter(
            project =>
                // eslint-disable-next-line camelcase
                !formData?.client_available_projects?.includes(project.value)
        );

        formData.client_excluded_projects = excluded.map(project => project?.value);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_UPDATE_SINGLE_USER,
                iri: userIri,
                formData
            })
        )
            .then(() => fetchUserData())
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <UserEditPageHeader pageTitle={pageTitle()} />
            <UserEditTabs userData={userData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <UserReportingDashboardForm
                    onSubmit={onSubmitUserReportingDashboardForm}
                    form="UserReportingDashboardForm"
                    initialValues={initialValues}
                    projects={projects}
                />
            </PageContent>
        </LayoutManager>
    );
};

UserReportingDashboard.displayName = 'UserReportingDashboard';

UserReportingDashboard.defaultProps = {};

UserReportingDashboard.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default withRouter(UserReportingDashboard);
