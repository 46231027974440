import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PortalTabs from 'erpcore/screens/Settings/Portal/components/PortalTabs';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import FaqWrapper from 'erpcore/screens/Settings/Portal/components/FaqWrapper';

const FAQ = () => {
    const title = 'Homeowner Portal App - FAQ';

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <PortalTabs />
                <FaqWrapper formName="PortalGlobalFAQForm" />
            </PageContent>
        </LayoutManager>
    );
};

export default FAQ;
