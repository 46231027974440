export const actions = {
    START_UPDATE_USER: 'START_UPDATE_USER',
    UPDATE_USER_SUCCESSFUL: 'UPDATE_USER_SUCCESSFUL',
    UPDATE_USER_FAILED: 'UPDATE_USER_FAILED',
    START_CHANGE_PASSWORD: 'START_CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESFUL: 'CHANGE_PASSWORD_SUCCESFUL',
    CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED'
};

export const initialState = {};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.UPDATE_USER_FAILED: {
            return {
                ...state,
                error: response
            };
        }
        case actions.CHANGE_PASSWORD_FAILED: {
            return {
                ...state,
                error: response
            };
        }
        default:
            return state;
    }
};
