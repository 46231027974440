import { takeLatest, put, call, select, all, takeEvery } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as prospectsActions } from './Prospects.reducer';

const dealIncludes =
    ',project,sessions,sessions.salesAgent,sessions.session.sessionProspects.prospect,notes.note,unit,unitType,prospects';
const defaultIncludes = `sessions,deals,deals.project,deals.sessions,deals.unit,deals.unitType,city,state,country,projects.project${dealIncludes}`;

/**
 * Create Prospect
 * @param  {Object} promise
 * @param  {Object} formData
 * @return {Object} response from API
 */

export function* createProspect({ promise, formData }) {
    try {
        const { projects } = formData;
        delete formData.projects;
        const createProspectAPI = yield restClient.post(`/api/prospects`, {
            ...formData,
            ...{ include: defaultIncludes }
        });
        if (Object.keys(projects || {}).length > 0)
            yield all(
                Object.keys(projects).map(projectIndex => {
                    return restClient.post(`/api/prospect-projects`, {
                        project: projects[projectIndex],
                        prospect: createProspectAPI?.data?.data?.id
                    });
                })
            );
        yield put({
            type: prospectsActions.CREATE_PROSPECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProspectAPI?.data
        });
        yield call(promise.resolve, createProspectAPI?.data);
    } catch (error) {
        yield put({
            type: prospectsActions.CREATE_PROSPECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Prospect
 * @param {Object} promise
 * @param {String} Prospect Iri
 * @return {Object} response from API
 */
export function* fetchProspect({ promise, iri, params }) {
    try {
        const fetchProspectAPI = yield restClient.get(iri, {
            params: {
                ...params,
                ...{ include: defaultIncludes }
            }
        });
        yield put({
            type: prospectsActions.FETCH_SINGLE_PROSPECT_SUCCESSFUL
        });
        yield put({
            type: prospectsActions.STORE_SINGLE_PROSPECT_DATA,
            iri,
            response: dto(fetchProspectAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: prospectsActions.FETCH_SINGLE_PROSPECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Prospect single data
 * @param  {Object} Customeer Iri
 * @return {Object} Response from API
 */
export function* updateSingleProspect({ promise, formData, iri }) {
    try {
        const { projects, projectsProspects } = formData;
        delete formData.projects;
        const updateSingleProspectAPI = yield restClient.put(`${iri}`, formData);
        const deletedProjects = projectsProspects.filter(
            initialProject => !projects.includes(initialProject?.project?.iri)
        );

        const existingProjects = projectsProspects.filter(initialProject =>
            projects.includes(initialProject?.project?.iri)
        );

        const newProjects = projects.filter(project => {
            return (
                existingProjects.filter(
                    existingProject => existingProject?.project?.iri === project
                ).length === 0
            );
        });

        yield all(
            deletedProjects?.map(deletedProject => {
                return restClient.delete(deletedProject?.iri);
            })
        );

        yield all(
            newProjects?.map(newProject => {
                return restClient.post(`/api/prospect-projects`, {
                    project: newProject,
                    prospect: iri
                });
            })
        );

        yield put({
            type: prospectsActions.UPDATE_SINGLE_PROSPECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleProspectAPI?.data
        });

        yield call(promise.resolve, updateSingleProspectAPI?.data);
    } catch (error) {
        yield put({
            type: prospectsActions.UPDATE_SINGLE_PROSPECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Single Prospect
 * @param  {Object} Iri of Prospect
 * @return {Object} Response from API
 */
export function* deleteSingleProspect({ promise, iri }) {
    try {
        const deleteSingleProspectAPI = yield restClient.delete(iri);
        yield put({
            type: prospectsActions.DELETE_SINGLE_PROSPECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteSingleProspectAPI?.data
        });
        const params = yield select(getQueryParams, { name: 'prospects' });
        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PROSPECTS',
            name: 'prospects',
            endpoint: `api/prospects?${defaultIncludes}`
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: prospectsActions.DELETE_SINGLE_PROSPECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Single Prospect Sessions
 * @param  {Object} Iri of Prospect
 * @return {Object} Response from API
 */
export function* fetchProspectSessionData({ promise, iri, dealIris }) {
    try {
        const fetchProspectSessionDataAPI = yield restClient.get(`/api/sessions`, {
            params: {
                include: 'project,salesAgent,deal,deal.project',
                'filters[deal][equals]': dealIris
            }
        });
        yield put({
            type: prospectsActions.FETCHING_SINGLE_PROSPECT_SESSION_SUCCESSFUL
        });
        yield put({
            type: prospectsActions.STORE_SINGLE_PROSPECT_DATA,
            iri,
            response: { data: { sessions: dto(fetchProspectSessionDataAPI?.data)?.data } }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: prospectsActions.FETCHING_SINGLE_PROSPECT_SESSION_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Session Prospects
 * @param {Object} promise
 * @param {String} Prospect Iri
 * @return {Object} response from API
 */
export function* fetchProspectQA({ promise, prospectIri, dealIri }) {
    try {
        const params = {};
        if (prospectIri) {
            params['filters[session_prospect.prospect][equals]'] = prospectIri;
        }
        if (dealIri) {
            params['filters[deal][equals]'] = dealIri;
        }
        const fetchAPI = yield restClient.get(
            `/api/profile-answers?pagination=false&include=question,sessionProspect,sessionProspect.prospect&order_by[question.position]=asc`,
            {
                params
            }
        );
        const fetchAllQuestionsAPI = yield restClient.get(
            `/api/prospect-profile-questions?pagination=false`
        );
        const dtoFetchAPI = dto(fetchAPI?.data);
        yield put({
            type: prospectsActions.FETCH_PROSPECT_QA_SUCCESSFUL,
            response: dtoFetchAPI.data,
            iri: dealIri || prospectIri
        });
        yield put({
            type: prospectsActions.SET_PROSPECT_QUESTION_COUNT,
            response: fetchAllQuestionsAPI?.data?.data?.length || 0
        });
        yield call(promise.resolve, dtoFetchAPI);
    } catch (error) {
        yield put({
            type: prospectsActions.FETCH_PROSPECT_QA_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Prospect
 * @param  {Object} promise
 * @param  {Object} formData
 * @return {Object} response from API
 */

export function* createProspectDeal({ promise, formData }) {
    try {
        const createProspectDealAPI = yield restClient.post(`/api/deals`, formData);
        yield put({
            type: prospectsActions.CREATE_PROSPECT_DEAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProspectDealAPI?.data
        });
        yield call(promise.resolve, createProspectDealAPI?.data);
    } catch (error) {
        yield put({
            type: prospectsActions.CREATE_PROSPECT_DEAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Prospect single data
 * @param  {Object} Customeer Iri
 * @return {Object} Response from API
 */
export function* updateProspectDeal({ promise, formData, iri }) {
    try {
        const updateSingleProspectAPI = yield restClient.put(`${iri}`, formData);
        yield put({
            type: prospectsActions.UPDATE_PROSPECT_DEAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSingleProspectAPI?.data
        });

        yield call(promise.resolve, updateSingleProspectAPI?.data);
    } catch (error) {
        yield put({
            type: prospectsActions.UPDATE_PROSPECT_DEAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const prospectsSaga = [
    takeLatest(prospectsActions.START_CREATE_PROSPECT, createProspect),
    takeEvery(prospectsActions.START_FETCHING_SINGLE_PROSPECT, fetchProspect),
    takeLatest(prospectsActions.START_UPDATE_SINGLE_PROSPECT, updateSingleProspect),
    takeLatest(prospectsActions.START_DELETE_SINGLE_PROSPECT, deleteSingleProspect),
    takeEvery(prospectsActions.START_FETCHING_SINGLE_PROSPECT_SESSION, fetchProspectSessionData),
    takeLatest(prospectsActions.START_FETCHING_PROSPECT_QA, fetchProspectQA),
    takeLatest(prospectsActions.START_CREATE_PROSPECT_DEAL, createProspectDeal),
    takeLatest(prospectsActions.START_UPDATE_PROSPECT_DEAL, updateProspectDeal)
];
