import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

//  Importing Reducers
// General Reducers
import { reducer as form } from 'redux-form';
import authManager from 'erpcore/utils/AuthManager/AuthManager.reducer';
import routerManager from 'erpcore/utils/RouterManager/RouterManager.reducer';
import layoutManager from 'erpcore/utils/LayoutManager/LayoutManager.reducer';
import notificationManager from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import forgotPassword from 'erpcore/screens/ForgotPassword/ForgotPassword.reducer';
import imageManager from 'erpcore/components/ImageManager/ImageManager.reducer';
import myAccount from 'erpcore/screens/MyAccount/MyAccount.reducer';
import activityLog from 'erpcore/components/ActivityLog/ActivityLog.reducer';
import comments from 'erpcore/components/Comments/Comments.reducer';
import listing from 'erpcore/components/Listing/Listing.reducer';
// Project Reducers - REMEMBER TO ORDER THEM ALPHABETICALLY //
import clients from 'erpcore/screens/Clients/Clients.reducer';
// import companyContacts from 'erpcore/screens/Companies/screens/CompanyContacts/CompanyContacts.reducer';
import contacts from 'erpcore/screens/Contacts/Contacts.reducer';
import {
    options,
    organization,
    permissions,
    roles,
    sessionSettings,
    vonageVideoAPI,
    unitTypes,
    neighbourhoodLocationSections,
    hubSpotIntegration,
    productTypes,
    blogCategories,
    avesdoEmails,
    hubspotLogs
} from 'erpcore/screens/Settings/Settings.reducer';
import blogs from 'erpcore/screens/Settings/Blue/screens/Blog/Blog.reducer';
// Settings
import employmentRecords from 'erpcore/screens/Users/screens/UserEmploymentRecords/UserEmploymentRecords.reducer';
import projects from 'erpcore/screens/Projects/Projects.reducer';
import prospects from 'erpcore/screens/Prospects/Prospects.reducer';
import sessions from 'erpcore/screens/Sessions/Sessions.reducer';
import { sessionReport } from 'erpcore/screens/Reports/Reports.reducer'; // Reports
import users from 'erpcore/screens/Users/Users.reducer';
import organizationSettings from 'erpcore/utils/OrganizationSettings/OrganizationSettings.reducer';
import dashboard from 'erpcore/screens/Dashboard/Dashboard.reducer';
import oiAccessDashboard from 'erpcore/screens/OiAccessDashboards/OiAccessDashboard.reducer';
import deals from 'erpcore/screens/Deals/Deals.reducer';

//  Combining all existing reducers
const appReducer = combineReducers({
    authManager,
    routerManager,
    layoutManager,
    notificationManager,
    activityLog,
    comments,
    listing,
    form,
    forgotPassword,
    imageManager,
    myAccount, // system reducers end
    clients,
    // companyContacts,
    contacts,
    employmentRecords,
    options,
    organization,
    organizationSettings,
    permissions,
    projects,
    prospects,
    roles,
    sessions,
    sessionSettings,
    sessionReport,
    users,
    dashboard,
    oiAccessDashboard,
    vonageVideoAPI,
    unitTypes,
    neighbourhoodLocationSections,
    hubSpotIntegration,
    deals,
    productTypes,
    avesdoEmails,
    hubspotLogs,
    blogs,
    blogCategories
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGN_OUT_SUCCESSFUL') {
        storage.removeItem('persist:root');
        const organizationSettingsCache = state?.organizationSettings?.organizationSettings;
        const imageManagerCollectionCache = state?.imageManager?.collection;
        //  clearing out state
        //  filling out state with cached data
        state = undefined;
        state = {
            organizationSettings: { organizationSettings: organizationSettingsCache },
            imageManager: {
                collection: imageManagerCollectionCache
            }
        };
    }

    return appReducer(state, action);
};

export default rootReducer;
