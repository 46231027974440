import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Autocomplete, DateTime } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ClientAddProjectForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    disabled
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="project"
                    id="project"
                    fieldProps={{
                        label: 'Project',
                        options: {
                            endpoint: '/api/projects?filters[portal_enabled][equals]=true',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ required: true, disabled }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="valid_until"
                    id="valid_until"
                    fieldProps={{
                        label: 'Valid Until'
                    }}
                    fieldAttr={{ required: true }}
                    component={DateTime}
                />
            </Form.Row>
            <Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Add" />
            </Form.Row>
        </Form>
    );
};

ClientAddProjectForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    disabled: false
};

ClientAddProjectForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool
};

export default reduxForm({
    form: 'ClientAddProjectForm',
    enableReinitialize: true
})(ClientAddProjectForm);
