import React from 'react';
import PropTypes from 'prop-types';
import './Card.scss';

const Card = ({ children }) => <div className="card">{children}</div>;

Card.LineBreak = () => {
    return <div className="card__break" />;
};

Card.defaultProps = {
    children: null
};

Card.propTypes = {
    children: PropTypes.node
};

Card.Title = ({ children }) => <div className="card__title">{children}</div>;

Card.Title.defaultProps = {
    children: null
};

Card.Title.propTypes = {
    children: PropTypes.node
};

export default Card;
