import React from 'react';
import { withRouter } from 'react-router-dom';

import Tabs from 'erpcore/components/Tabs';

const OIAccessTabs = () => {
    const links = [];

    links.push({
        link: `/settings/oiaccess/general`,
        label: 'General'
    });

    links.push({
        link: `/settings/oiaccess/help`,
        label: 'Help section'
    });

    return <Tabs links={links} />;
};

export default withRouter(OIAccessTabs);
