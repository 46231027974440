import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form, { GeneratePassword } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';

class ModalChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { id, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_CHANGE_PASSWORD_SINGLE_USER,
                formData,
                id
            })
        )
            .then(() => {
                this.setState({ opened: false });
            })
            .catch(error => {
                this.setState({ opened: false });
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting, name } = this.props;
        const { opened } = this.state;

        return (
            <Fragment>
                <Tooltip content="Change password">
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="lock" />
                    </button>
                </Tooltip>
                <Modal
                    variation="medium"
                    opened={opened}
                    onClose={() => this.handleModal()}
                    title="Change password"
                    subtitle={
                        <Fragment>
                            Password update for <em>{name}</em>
                        </Fragment>
                    }
                >
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Form.Row>
                            <GeneratePassword name="new_password" isRequired />
                        </Form.Row>
                        <Button submit loading={submitting} label="Update" />
                        <Button
                            variation="secondary"
                            label="Cancel"
                            onClick={() => this.handleModal()}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

ModalChangePassword.defaultProps = {
    submitting: false,
    id: null,
    name: null,
    dispatch: () => {}
};

ModalChangePassword.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    dispatch: PropTypes.func
};

export default reduxForm({
    form: 'ChangePasswordForm',
    enableReinitialize: true
})(ModalChangePassword);
