import React from 'react';
import { Field } from 'redux-form';
import Form, { Text, Textarea, Switch } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const ReportsRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{
                        label: 'Dashboard name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.id`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.subtitle`}
                    fieldProps={{
                        label: 'Dashboard subtitle',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.id` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.navigation_overlay`}
                    fieldProps={{
                        label: 'Dashboard navigation overlay',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.navigation_overlay` }}
                    component={Switch}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.iframe`}
                    fieldProps={{ label: 'Power Bi Report IFrame' }}
                    fieldAttr={{ id: `${member}.id`, required: true }}
                    component={Textarea}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

ReportsRepeaterItem.defaultProps = {
    member: null
};

ReportsRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ReportsRepeaterItem;
