import unionWith from 'lodash/unionWith';

/**
 * get id From iri
 * @param {string} iri
 * @returns {string}
 */
const getIdFromIri = iri => {
    if (!iri) {
        return null;
    }
    // Assuming the id value is the last part after the /
    const n = iri.lastIndexOf('/');
    const id = iri.substring(n + 1);

    return id;
};

/**
 * Build Item Object with data
 * @param {object} item
 * @returns {object}
 */
const buildItemObject = item => {
    if (!item) {
        return null;
    }
    const itemObject = Object.assign({}, item.attributes);
    itemObject.type = item.type;
    itemObject.iri = item.id;
    itemObject.id = getIdFromIri(item.id);

    return itemObject;
};

/**
 * Handling relationships data
 * @param {object} relationships
 * @returns {object}
 */
const getRelationshipsFlattened = relationships => {
    if (!relationships) {
        throw new Error('Incorrect data passed!');
    }

    const keys = Object.keys(relationships);
    const relationshipsData = {};

    keys.map(key => {
        // If relationships data is Array
        // else is Object
        if (
            relationships[key] &&
            relationships[key].data &&
            relationships[key].data.constructor === Array
        ) {
            relationshipsData[key] = [];
            relationships[key].data.map(item => {
                const relationshipItem = buildItemObject(item);
                return relationshipsData[key].push(relationshipItem);
            });
        } else {
            relationshipsData[key] = buildItemObject(relationships[key].data);
        }
        return relationshipsData;
    });

    return relationshipsData;
};

/**
 * Handling included data
 * @param relationships {object} Object with relationships as properties. Each property is object with data property, which can be Object containing id and type, or Array with Objects containing id and type.
 * @param included {array} Included data
 * @param excluded {array} Array of relationships to exclude from processing, passed on each recursive call by the function itself, to avoid infinite loops in cases when included items relate to each other.
 * @returns {object} containing attributes
 */
const getIncludedDataByRelationship = (relationships, included, excluded = []) => {
    if (!relationships || !included) {
        throw new Error('Incorrect data passed!');
    }

    const keys = Object.keys(relationships);
    const includedData = {};

    keys.map(key => {
        // if relationship is array
        //      loop array
        //          formatt data
        // else relationship is object
        //      if included data merge

        if (
            relationships[key] &&
            relationships[key].data &&
            relationships[key].data.constructor === Array
        ) {
            includedData[key] = [];
            relationships[key].data.map(relationshipItem => {
                included.map(item => {
                    if (item.id === relationshipItem.id && item.type === relationshipItem.type) {
                        //  If relationship in relationship exists merge included relations to attributes
                        if (
                            item.relationships &&
                            // avoiding infinite loop
                            !excluded.find(
                                excludedItem =>
                                    item.id === excludedItem.id && item.type === excludedItem.type
                            )
                        ) {
                            const excludedNew = [...excluded, item]; // avoiding infinite loop
                            item.attributes = {
                                ...item.attributes,
                                ...getIncludedDataByRelationship(
                                    item.relationships,
                                    included,
                                    excludedNew
                                )
                            };
                        }
                        includedData[key].push(buildItemObject(item));
                    }
                    return includedData;
                });
                return includedData;
            });
        } else {
            return included.map(item => {
                // If has included data - format them
                // else flatten relationships
                if (
                    item.id === relationships[key].data.id &&
                    item.type === relationships[key].data.type &&
                    // BUG: causes memory overflow with tickets
                    !(
                        item.type === 'ReservationTicket' ||
                        item.type === 'ReservationTicketzoneOrder'
                    ) &&
                    // avoiding infinite loop
                    !excluded.find(
                        excludedItem =>
                            item.id === excludedItem.id && item.type === excludedItem.type
                    )
                ) {
                    let deepIncludedData = {};
                    if (item.relationships) {
                        const excludedNew = [...excluded, item]; // avoiding infinite loop
                        deepIncludedData = getIncludedDataByRelationship(
                            item.relationships,
                            included,
                            [...excluded, item, excludedNew]
                        );
                    }
                    includedData[key] = Object.assign({}, buildItemObject(item), deepIncludedData);
                }
                return includedData;
            });
        }
        return includedData;
    });
    return includedData;
};

/**
 * Get included data in formFormated DTO
 * @param {object} iri
 * @param {object} includedData
 * @returns {object}
 */
export const getIncludedDataByIri = (iri, includedData) => {
    if (includedData?.included && iri) {
        return includedData?.included?.find(include => include?.iri === iri) || {};
    }

    return {};
};

/**
 * Prepare data for included from select
 * @param {object} data
 * @param {object} included
 * @returns {object}
 */
export const formatSelectDataForIncluded = (data, included) => {
    const includedData = [];
    data.map(item => {
        const includedDataObject = {};
        includedDataObject.iri = item.value;
        includedDataObject.label = item.label;
        includedData.push(includedDataObject);
        return true;
    });

    const mergedIncludes = unionWith(included, includedData, (a, b) => a.iri === b.iri);

    return mergedIncludes;
};

/**
 * Transfering data from the API to Frontend Form friendly format
 * @param {object} inputData
 * @returns {object}
 */
const dtoForm = inputData => {
    if (!inputData) {
        return null;
    }

    let data = null;

    data = Object.assign({}, inputData);
    data.included = [];

    // Loop through the data
    Object.keys(data).forEach(key => {
        // check if property is array
        if (key !== 'included' && data[key] && Array.isArray(data[key])) {
            // create new array rewriting array objects with iri values
            data[key] = data[key].map(item => {
                // check if property is object
                if (item && item.constructor === Object) {
                    // check if property has iri value
                    if (item.iri) {
                        data.included.push(item);
                        // rewrite property value to match iri
                        return item.iri;
                    }
                    return item;
                }
                return item;
            });
        }
        // check if property is object
        if (data[key] && data[key].constructor === Object) {
            // check if property has iri value
            if (data[key].iri) {
                data.included.push(inputData[key]);
                // rewrite property value to match iri
                data[key] = data[key].iri;
                return data;
            }
            return data;
        }
        return data;
    });

    return data;
};

/**
 * Transforming data from the API to Frontend (and Form) friendly format for Listing API endpoints
 * @param {object} inputData
 * @returns {object}
 */
const dtoListing = inputData => {
    if (!inputData) {
        throw new Error('Incorrect data passed!');
    }

    if (!inputData.data) {
        return null;
    }

    // creating new duplicate object
    const data = Object.assign({}, inputData);

    const listingData = [];

    inputData.data.map(inputItem => {
        // creating new duplicate object
        let item = Object.assign({}, inputItem);

        let includedData = {};

        const defaultItemData = {
            iri: item.id,
            id: getIdFromIri(item.id)
        };

        // Handling included data
        // If no included data, flatten relationships
        if (item.relationships && inputData.included) {
            includedData = getIncludedDataByRelationship(item.relationships, inputData.included);
        }
        if (item.relationships && !inputData.included) {
            includedData = getRelationshipsFlattened(item.relationships);
        }

        // handling the data
        item = Object.assign(item, item.attributes, includedData, defaultItemData);

        // cleanup
        delete item.attributes;
        if (item.relationships) {
            delete item.relationships;
        }

        return listingData.push(item);
    });

    // Replacing data
    delete data.data;
    data.data = listingData;

    // cleanup
    if (data.included) {
        delete data.included;
    }

    return data;
};

/**
 * Transforming data from the API to Frontend (and Form) friendly format for Single API endpoints
 * @param {object} inputData
 * @returns {object}
 */
const dtoSingle = inputData => {
    if (!inputData) {
        throw new Error('Incorrect data passed!');
    }

    if (!inputData.data) {
        return null;
    }

    let data = null;
    let includedData = {};

    // setting iri and id values
    const defaultData = {
        iri: inputData.data.id,
        id: getIdFromIri(inputData.data.id)
    };

    // creating new duplicate object
    data = Object.assign({}, inputData);

    // Handling included data
    // If no included data, flatten relationships
    if (data.data.relationships && inputData.included) {
        includedData = getIncludedDataByRelationship(data.data.relationships, inputData.included);
    }
    if (data.data.relationships && !inputData.included) {
        includedData = getRelationshipsFlattened(data.data.relationships);
    }

    // handling the data
    data.data = Object.assign(data.data, inputData.data.attributes, includedData, defaultData);

    // cleanup
    if (data.included) {
        delete data.included;
    }
    delete data.data.attributes;
    if (data.data.relationships) {
        delete data.data.relationships;
    }
    return data;
};

/**
 * Transfering data from the API to Frontend (and Form) friendly format
 * @param {object} inputData
 * @returns {object}
 */
const dto = inputData => {
    if (!inputData) {
        return null;
    }

    let data = inputData;

    // Listing is Array
    if (inputData.data && inputData.data.constructor === Array) {
        data = dtoListing(inputData);
    }
    // Single is Object
    if (inputData.data && inputData.data.constructor === Object) {
        data = dtoSingle(inputData);
    }

    return data;
};

export { dtoForm, getIdFromIri, getIncludedDataByRelationship, getRelationshipsFlattened };
export default dto;
