import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';

import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';

class ProjectDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { dispatch, projectData } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_DELETE_PROJECT,
                iri: projectData?.iri
            })
        )
            .then(() => {
                this.setState({ opened: false });
            })
            .catch(error => {
                this.setState({ opened: false });
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { projectData, handleSubmit, submitting } = this.props;
        const { opened } = this.state;

        return (
            <Fragment>
                <Tooltip content="Delete project">
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="remove" />
                    </button>
                </Tooltip>
                <Modal
                    variation="medium"
                    opened={opened}
                    onClose={() => this.handleModal()}
                    title="Delete project"
                    subtitle={
                        <>
                            Are you sure you want to delete project{' '}
                            <strong>{projectData?.name}</strong>?
                            <br />
                            Project will be archived, you will be able to access it in the future
                            along with all related records.
                        </>
                    }
                >
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Button submit loading={submitting} label="Delete" />
                        <Button
                            variation="secondary"
                            label="Cancel"
                            onClick={() => this.handleModal()}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

ProjectDelete.defaultProps = {
    submitting: false,
    projectData: {}
};

ProjectDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    projectData: PropTypes.oneOfType([PropTypes.object]),
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { projectData } = ownProps;

    return {
        form: `ProjectDeleteForm-${projectData.iri}`,
        initialValues: { name: projectData.name }
    };
};

ProjectDelete = reduxForm({
    enableReinitialize: false
})(ProjectDelete);

export default connect(mapStateToProps)(ProjectDelete);
