import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions as blogsActions } from 'erpcore/screens/Settings/Blue/screens/Blog/Blog.reducer';
import Modal from 'erpcore/components/Modal';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import BlogsEditCreateForm from 'erpcore/screens/Settings/Blue/screens/Blog/components/BlogEditCreateForm';

const BlogsEdit = ({ data }) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);
    const { title, excerpt, content, image, category, publish_date: publishDate } = { ...data };

    const initialValues = {
        title,
        excerpt,
        content,
        image: image?.iri || null,
        category: category?.iri,
        publish_date: publishDate
    };

    const handleModal = () => {
        setModalOpened(!modalOpened);
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: blogsActions.START_UPDATE_BLOG,
                promise: { resolve, reject },
                formData,
                iri: data?.iri
            });
        }).finally(() => {
            handleModal();
        });
    };

    return (
        <Fragment>
            <Tooltip content="Edit FAQ Post">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="edit" />
                </button>
            </Tooltip>
            <Modal
                variation="large"
                opened={modalOpened}
                onClose={() => handleModal()}
                title="Edit FAQ Post"
            >
                <BlogsEditCreateForm
                    onSubmit={onSubmit}
                    onCancel={() => handleModal()}
                    form={`BlogsEditForm-${data?.id}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </Modal>
        </Fragment>
    );
};

BlogsEdit.defaultProps = {
    data: {}
};

BlogsEdit.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object])
};

export default BlogsEdit;
