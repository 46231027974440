const colors = [
    '#831b45',
    '#1700b8',
    '#323232',
    '#1700b8',
    '#bf87e1',
    '#ed767a',
    '#56a8c2',
    '#646464',
    '#8dc875',
    '#1700b8',
    '#b3b3b3',
    '#f0c356'
];

export default colors;
