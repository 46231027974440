import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Departments Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const TimeLogs = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'timelog.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log entry successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timelog.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log entry successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timelog.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log entry successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'timelog.bulkActions.lockEntriesSuccessful':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Time log entries successfully locked!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

TimeLogs.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

TimeLogs.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default TimeLogs;
