import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Form, { TextEditor, Text } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const BlueSettingsContactForm = ({ handleSubmit, onSubmit, submitting, pristine, invalid }) => {
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="homepage_video_url"
                        fieldProps={{
                            label: 'Homepage Video URL',
                            clearable: true
                        }}
                        fieldAttr={{ id: 'homepage_video_url' }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="contact_footer"
                        fieldProps={{
                            label: 'Footer Contact',
                            clearable: true
                        }}
                        fieldAttr={{ id: `footer_contact` }}
                        component={TextEditor}
                    />
                    <Field
                        name="contact_page"
                        fieldProps={{
                            label: 'Contact Page Text',
                            clearable: true
                        }}
                        fieldAttr={{ id: `footer_contact` }}
                        component={TextEditor}
                    />
                </Form.Row>
                <Form.SectionTitle>Blue Score Default Percentages</Form.SectionTitle>
                <Form.Row>
                    <Field
                        name="location"
                        fieldProps={{
                            label: 'Location',
                            clearable: true
                        }}
                        fieldAttr={{ id: `location`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="value"
                        fieldProps={{
                            label: 'Value',
                            clearable: true
                        }}
                        fieldAttr={{ id: `value`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="developer"
                        fieldProps={{
                            label: 'Developer',
                            clearable: true
                        }}
                        fieldAttr={{ id: `developer`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="appreciation"
                        fieldProps={{
                            label: 'Appreciation',
                            clearable: true
                        }}
                        fieldAttr={{ id: `appreciation`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                    <Field
                        name="amenities"
                        fieldProps={{
                            label: 'Amenities',
                            clearable: true
                        }}
                        fieldAttr={{ id: `amenities`, type: 'number', min: 0, max: 100 }}
                        component={Text}
                        validation={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Button submit disabled={pristine || invalid} loading={submitting} label="Save" />
            </Form>
        </>
    );
};

BlueSettingsContactForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false
};

BlueSettingsContactForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'BlueSettingsContactForm',
    enableReinitialize: true
})(BlueSettingsContactForm);
