import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import MyAccountTabs from 'erpcore/screens/MyAccount/components/MyAccountTabs';
import LinkedAccountsForm from 'erpcore/screens/MyAccount/components/LinkedAccountsForm';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

const MyAccountLinkedAccounts = () => {
    const title = 'My account';

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <MyAccountTabs />
                <LinkedAccountsForm />
            </PageContent>
        </LayoutManager>
    );
};

export default MyAccountLinkedAccounts;
