import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Svg from 'erpcore/components/Svg';
import './Tabs.scss';

const Tabs = ({ links }) => {
    if (!links || !links?.length) {
        return false;
    }

    return (
        <nav className="tabs">
            <ul className="tabs__list">
                {links.map(item => {
                    const { link, label, subLinks } = item;
                    if (subLinks) {
                        return (
                            <div key={`tab_index_${link}`} className="tabs__dropdown">
                                <span className="tabs__dropdown-label">
                                    {label}{' '}
                                    <Svg className="tabs__dropdown-label-icon" icon="arrowDown" />
                                </span>
                                <ul className="tabs__dropdown-list">
                                    {subLinks.map(subItem => {
                                        const { link: subLink, label: subLabel } = subItem;
                                        return (
                                            <li key={subLink} className="tabs__dropdown-list-item">
                                                <NavLink
                                                    to={subLink}
                                                    className="tabs__dropdown-link"
                                                    activeClassName="tabs__dropdown-link--active"
                                                    exact
                                                >
                                                    {subLabel}
                                                </NavLink>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    }
                    return (
                        <li key={link} className="tabs__list-item">
                            <NavLink
                                to={link}
                                className="tabs__link"
                                activeClassName="tabs__link--active"
                                exact
                            >
                                {label}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

Tabs.defaultProps = {
    links: null
};

Tabs.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        })
    )
};

export default Tabs;
