import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageContent from 'erpcore/components/Layout/PageContent';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import LayoutManager from 'erpcore/utils/LayoutManager';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import { getOrganizationSetting } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import mlaOiAcceesLogoBlack from 'erpcore/assets/images/mla-oi-access-logo-black.png';
import Accordion from 'erpcore/components/Accordion';
import { actions as oiAccessDashboardActions } from '../../OiAccessDashboard.reducer';
import './OiAccessHelp.scss';

const params = {
    'filters[reporting][exists]': 1
};

const OiAccessHelp = () => {
    const dispatch = useDispatch();
    const meData = useSelector(getMeData);
    const title = 'Oi Access Dashboard';
    const filterUserRoles = meData?.roles?.map(userRole => userRole.attribute);
    const hasSuperAdminRole = filterUserRoles.indexOf('CAN_ROLE_SUPER_ADMIN') !== -1;
    const currentPage = 1;

    const settings = useSelector(state => getOrganizationSetting(state, 'oiAccessHelp')) || [];
    const settingData = settings?.data?.length ? settings?.data[0] : {};
    const { help } = {
        ...settingData?.settings
    };

    const fetchUserProjects = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: oiAccessDashboardActions.START_FETCHING_SUPER_ADMIN_PROJECTS,
                params: hasSuperAdminRole ? { page: currentPage, ...params } : params,
                endpoint: hasSuperAdminRole
                    ? 'api/projects'
                    : `api/users/${meData?.id}/?include=projects,clientAvailableProjects,clientExcludedProjects,projects.featuredImage,clientAvailableProjects.featuredImage`
            });
        }).catch(error => ({ error }));
    };

    const fetchSettings = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName: 'oiAccessHelp'
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchUserProjects();
        fetchSettings();
    }, []);

    console.error(help);

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <header className="page-header-centered">
                <img draggable={false} src={mlaOiAcceesLogoBlack} alt="Mla Oi Access Logo" />
            </header>
            <PageContent>
                <div className="oi-access-help">
                    <h3 className="oi-access-help__title">Help</h3>
                    <Accordion activeItemIndex={0}>
                        {help?.map(item => (
                            <Accordion.Item
                                title={item?.title}
                                content={
                                    <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                                }
                            />
                        ))}
                    </Accordion>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

export default OiAccessHelp;
