import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

import enviromentVariables from 'erpcore/utils/enviromentVariables';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import ConnectForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/ConnectForm';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import { dtoForm } from 'erpcore/utils/dto';

const ProjectEditMultiEffectConnect = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const projectDataForm = dtoForm(projectData) || {};
    const fetching = useSelector(getProjectFetching);

    const generatedCode = `<script id="am2_mla_connect_widget" async defer src="${window.location.origin}/plugins/mlaConnectWidget/am2_mlaConnectWidget.js?site_id=${projectData?.id}&api_url=${enviromentVariables?.REST_API}"></script>`;
    const initialValues = { ...projectDataForm.connect, generatedCode };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        const derivatedFormData = Object.assign({}, formData);
        delete derivatedFormData.generatedCode;
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: {
                    connect: derivatedFormData
                }
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <ConnectForm
                    onSubmit={onSubmit}
                    form={`ConnectProjectEditForm-${projectIri}`}
                    initialValues={initialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMultiEffectConnect.defaultProps = {
    match: {}
};

ProjectEditMultiEffectConnect.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default withRouter(ProjectEditMultiEffectConnect);
