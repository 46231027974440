import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import Form, { Text, Email, Phone, Location } from 'erpcore/components/Form';

const ProspectForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    onCancel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="first_name"
                    fieldProps={{
                        label: 'First Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'first_name', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="last_name"
                    fieldProps={{
                        label: 'Last Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'last_name', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'email', required: true }}
                    component={Email}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="phone"
                    fieldProps={{
                        label: 'Phone',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'phone', required: true }}
                    component={Phone}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="location"
                    id="location"
                    component={Location}
                    fieldProps={{
                        locationManualTogglelabel: 'Input address manually'
                    }}
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
                {onCancel && (
                    <Button variation="secondary" label="Cancel" onClick={() => onCancel()} />
                )}
            </Form.Row>
        </Form>
    );
};

ProspectForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    onCancel: null
};

ProspectForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onCancel: PropTypes.func
};

export default reduxForm({
    form: 'ProspectForm',
    enableReinitialize: true
})(ProspectForm);
