import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import { actions as NeighbourhoodLocationSectionsActions } from 'erpcore/screens/Settings/NeighbourhoodLocationSections/NeighbourhoodLocationSections.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import { reset } from 'redux-form';
import NeighbourhoodLocationSectionsEditCreateForm from '../../components/NeighbourhoodLocationSectionsEditCreateForm';
import NeighbourhoodLocationSectionsEdit from '../../components/NeighbourhoodLocationSectionsEdit';
import NeighbourhoodLocationSectionsDelete from '../../components/NeighbourhoodLocationSectionsDelete';

const NeighbourhoodLocationSections = () => {
    const title = 'Neighbourhood Location Sections';
    const reducerName = 'neighbourhoodLocationSections';
    const actionName = 'NEIGHBOURHOOD_LOCATION_SECTIONS';
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const dispatch = useDispatch();

    const fetchUnitTypes = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/neighbourhood-location-sections'
            });
        }).catch(error => {
            return error;
        });
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type:
                    NeighbourhoodLocationSectionsActions.START_CREATE_NEIGHBOURHOOD_LOCATION_SECTION,
                promise: { resolve, reject },
                formData
            });
        }).then(() => {
            dispatch(reset('NeighborhoodLocationSectionsEditCreateForm'));
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'Section',
                field: 'name',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'id'
        };

        if (listing && listing.data) {
            listing.data.map(row => {
                const { name } = { ...row };

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    name: name || '',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <NeighbourhoodLocationSectionsEdit data={row} />
                            </TableActions.Action>
                            <TableActions.Action>
                                <NeighbourhoodLocationSectionsDelete
                                    iri={row?.iri}
                                    form={`NeighbourhoodLocationSectionDelete-${row?.id}`}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <NeighbourhoodLocationSectionsEditCreateForm onSubmit={onSubmit} />
                <Listing
                    name={reducerName}
                    hideHeader
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchUnitTypes(params)}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default NeighbourhoodLocationSections;
