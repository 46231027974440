import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import ProductTypesEditCreateForm from 'erpcore/screens/Settings/ProductTypes/components/ProductTypesEditCreateForm';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import ProductTypesDelete from 'erpcore/screens/Settings/ProductTypes/components/ProductTypesDelete';
import ProductTypesEdit from 'erpcore/screens/Settings/ProductTypes/components/ProductTypesEdit';
import { actions as productTypesActions } from 'erpcore/screens/Settings/ProductTypes/ProductTypes.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import { reset } from 'redux-form';

const ProductTypes = () => {
    const title = 'Product Types';
    const reducerName = 'productTypes';
    const actionName = 'PRODUCT_TYPES';
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const dispatch = useDispatch();

    const fetchProductTypes = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/product-types'
            });
        }).catch(error => {
            return error;
        });
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: productTypesActions.START_CREATE_PRODUCT_TYPE,
                promise: { resolve, reject },
                formData
            });
        }).then(() => {
            dispatch(reset('ProductTypesEditCreateForm'));
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'Product Types',
                field: 'product_types',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'id'
        };

        if (listing && listing.data) {
            listing.data.map(row => {
                const { name } = { ...row };

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    product_types: name || '',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <ProductTypesEdit data={row} />
                            </TableActions.Action>
                            <TableActions.Action>
                                <ProductTypesDelete
                                    iri={row?.iri}
                                    form={`ProductTypesDelete-${row?.id}`}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <ProductTypesEditCreateForm onSubmit={onSubmit} />
                <Listing
                    name={reducerName}
                    hideHeader
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchProductTypes(params)}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ProductTypes;
