const renderBackgroundColors = (items, colorIntensity) => {
    const colors = [];

    items.map((item, i) => {
        return colors.push(`rgba(0, 102, 138, ${(1 / items?.length) * (i + colorIntensity)}`);
    });

    return colors;
};

export default renderBackgroundColors;
