import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as sessionReportActions } from 'erpcore/screens/Reports/Session/SessionReports.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

/**
 * Fetch Total Sessions
 * @param  {Object} promise
 * @return {string} response from API
 */
export function* fetchTotalSessions({ promise, params }) {
    try {
        const fetchTotalSessionsAPI = yield restClient.get('api/reports/sessions', {
            headers: {
                Accept: 'application/json'
            },
            params
        });
        yield put({
            type: sessionReportActions.FETCH_TOTAL_SESSIONS_SUCCESSFUL,
            response: fetchTotalSessionsAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionReportActions.FETCH_TOTAL_SESSIONS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Total Post Demo Views
 * @param  {Object} promise
 * @return {string} response from API
 */
export function* fetchTotalPostDemoViews({ promise, params }) {
    try {
        const fetchTotalSessionsAPI = yield restClient.get('api/reports/sessions', {
            headers: {
                Accept: 'application/json'
            },
            params
        });
        yield put({
            type: sessionReportActions.FETCH_POST_DEMO_VIEWS_SUCCESSFUL,
            response: fetchTotalSessionsAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: sessionReportActions.FETCH_POST_DEMO_VIEWS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const sessionReportSaga = [
    takeLatest(sessionReportActions.START_FETCHING_TOTAL_SESSIONS, fetchTotalSessions),
    takeLatest(sessionReportActions.START_FETCHING_POST_DEMO_VIEWS, fetchTotalPostDemoViews)
];
