import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { Link } from 'react-router-dom';
import Button from 'erpcore/components/Button';
import Form, { Email, Password } from 'erpcore/components/Form';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const SignInForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="email"
                    id="email"
                    fieldProps={{
                        label: 'Email',
                        clearable: true
                    }}
                    component={Email}
                    validate={valueValidation([{ validator: 'email' }, { validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="password"
                    id="password"
                    fieldProps={{
                        label: 'Password'
                    }}
                    component={Password}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Link to="/forgot-password">Forgot password?</Link>
            </Form.Row>
            <Form.Row verticalCenter>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label="Sign In"
                />
            </Form.Row>
        </Form>
    );
};

SignInForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false
};

SignInForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'SignInForm',
    enableReinitialize: true
})(SignInForm);
