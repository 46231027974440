import React, { useState, useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import SessionReportFilters from 'erpcore/screens/Reports/Session/components/SessionReportFilters';
import SessionReportPerSalesAgent from 'erpcore/screens/Reports/Session/components/SessionReportPerSalesAgent';
import SessionReportPerRealtor from 'erpcore/screens/Reports/Session/components/SessionReportPerRealtor';
import SessionsOverTime from 'erpcore/screens/Reports/Session/components/SessionsOverTime';
import PostDemoPreviewUsageOverTime from 'erpcore/screens/Reports/Session/components/PostDemoPreviewUsageOverTime';
import Widget from 'erpcore/components/Widget';
import { useDispatch, useSelector } from 'react-redux';
import './SessionReports.scss';
import { getFormValues } from 'redux-form';
import { actions as sessionReportActions } from 'erpcore/screens/Reports/Session/SessionReports.reducer';
import {
    getSessionDataFetching,
    getTotalsSessionData,
    getPostDemoViewSessionData
} from 'erpcore/screens/Reports/Session/SessionReports.selectors';
import ElementLoader from 'erpcore/components/ElementLoader';
import moment from 'moment-timezone';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';

const SessionReports = () => {
    const title = 'Sessions Report';
    const dispatch = useDispatch();
    const sessionDataFetching = useSelector(getSessionDataFetching);
    const totalSessions = useSelector(getTotalsSessionData);
    const totalDemoViews = useSelector(getPostDemoViewSessionData);
    const meData = useSelector(getMeData) || {};
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);
    const globalFilters = useSelector(state => getFormValues('SessionReportFilters')(state));
    const [hideFields, setHideFields] = useState(false);

    const fetchTotalSessions = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionReportActions.START_FETCHING_TOTAL_SESSIONS,
                params,
                promise: { resolve, reject }
            });
        }).catch(err => err);
    };

    const fetchTotalPostDemoViews = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: sessionReportActions.START_FETCHING_POST_DEMO_VIEWS,
                params,
                promise: { resolve, reject }
            });
        }).catch(err => err);
    };

    useEffect(() => {
        fetchTotalPostDemoViews({ display_by: 'postDemoViews' });
        fetchTotalSessions({ display_by: 'total' });
    }, []);

    const onFilterChange = formData => {
        const { users, project, date_range: dateRange } = { ...formData };
        let { start, end } = { ...dateRange };

        if (start) {
            start = moment(start).format('YYYY-MM-DD');
        }

        if (end) {
            end = moment(end).format('YYYY-MM-DD');
        }

        if (users) {
            setHideFields(true);
        } else {
            setHideFields(false);
        }

        const postDemoViewsParams = {
            display_by: 'postDemoViews',
            'filters[project]': project,
            'filters[user]': users,
            'filters[start_date]': start,
            'filters[end_date]': end
        };
        const totalSessionParams = {
            display_by: 'total',
            'filters[project]': project,
            'filters[user]': users,
            'filters[start_date]': start,
            'filters[end_date]': end
        };

        fetchTotalPostDemoViews(postDemoViewsParams);
        fetchTotalSessions(totalSessionParams);
    };

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />

            <PageContent>
                <SessionReportFilters onFilterChange={onFilterChange} />

                <PageContent.Row>
                    <Widget className="session-reports-item">
                        <Widget.Item iconName="sessions" iconColor="denim" title="Total Sessions">
                            <span className="font-2">
                                {sessionDataFetching ? <ElementLoader /> : totalSessions}
                            </span>
                        </Widget.Item>
                    </Widget>
                    <Widget className="session-reports-item">
                        <Widget.Item
                            iconName="postDemo"
                            iconColor="denim"
                            title="Total Post-demo Views"
                        >
                            <span className="font-2">
                                {sessionDataFetching ? <ElementLoader /> : totalDemoViews}
                            </span>
                        </Widget.Item>
                    </Widget>
                </PageContent.Row>

                {!hideFields && hasPermissionsAccessForEntities.users && (
                    <PageContent.Row>
                        <SessionReportPerSalesAgent globalFilters={globalFilters} />
                        <SessionReportPerRealtor globalFilters={globalFilters} />
                    </PageContent.Row>
                )}

                <PageContent.Row>
                    <SessionsOverTime />
                </PageContent.Row>

                <PageContent.Row>
                    <PostDemoPreviewUsageOverTime />
                </PageContent.Row>
            </PageContent>
        </LayoutManager>
    );
};

export default SessionReports;
