import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import dto from 'erpcore/utils/dto';
import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

/**
 * Create Project
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProject({ promise, formData }) {
    try {
        const createProjectAPI = yield restClient.post('api/projects', formData);
        yield put({
            type: projectsActions.CREATE_PROJECT_SUCCESFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProjectAPI?.data
        });
        yield call(promise.resolve, createProjectAPI?.data);
    } catch (error) {
        yield put({
            type: projectsActions.CREATE_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Project
 * @param  {Object} promise
 * @return {string} id Project id
 */
export function* fetchProject({ promise, iri, params }) {
    try {
        const fetchProjectAPI = yield restClient.get(iri, { params });
        yield put({
            type: projectsActions.FETCHING_PROJECT_SUCCESSFUL
        });

        yield put({
            type: projectsActions.STORE_PROJECT_DATA,
            iri,
            response: dto(fetchProjectAPI?.data)
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.FETCHING_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project data
 * @param  {Object} iri id of Project
 * @return {Object} Response from API
 */
export function* updateProject({ promise, iri, formData }) {
    try {
        const updateProjectAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_SUCCESSFUL
        });
        yield put({
            type: projectsActions.STORE_PROJECT_DATA,
            iri,
            response: dto(updateProjectAPI?.data)
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Project
 * @param  {Object} projectIri iri of Permission
 * @return {Object} Response from API
 */
export function* deleteProject({ promise, iri }) {
    try {
        const deleteProjectAPI = yield restClient.delete(iri);
        yield put({
            type: projectsActions.DELETE_PROJECT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProjectAPI?.data
        });

        const params = yield select(getQueryParams, { name: 'projects' });

        yield put({
            promise,
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: 'PROJECTS',
            name: 'projects',
            endpoint: 'api/projects?include=client'
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.DELETE_PROJECT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Project Key plan
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProjectKeyPlan({ promise, formData }) {
    try {
        const createProjectKeyPlanAPI = yield restClient.post('api/project/key-plans', formData);
        yield put({
            type: projectsActions.CREATE_PROJECT_KEY_PLAN_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProjectKeyPlanAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.CREATE_PROJECT_KEY_PLAN_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Project Building
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProjectBuilding({ promise, formData }) {
    try {
        const createProjectBuildingAPI = yield restClient.post('api/project/buildings', formData);
        yield put({
            type: projectsActions.CREATE_PROJECT_BUILDING_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProjectBuildingAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.CREATE_PROJECT_BUILDING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project Building
 * @param  {Object} iri of building level
 * @return {Object} Response from API
 */
export function* updateProjectBuilding({ promise, iri, formData }) {
    try {
        const updateProjectBuildingAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_BUILDING_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectBuildingAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_BUILDING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Building level
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createBuildingLevel({ promise, formData }) {
    try {
        const createBuildingLevelAPI = yield restClient.post(
            'api/project/building-levels',
            formData
        );
        yield put({
            type: projectsActions.CREATE_BUILDING_LEVEL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBuildingLevelAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.CREATE_BUILDING_LEVEL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Project unit settings
 * @param  {Object} promise
 * @param  {String} iri
 * @return {string} id Project id
 */
export function* fetchUnitSettings({ promise, iri, params, projectID }) {
    try {
        const projectData = yield restClient.get(iri, { params });

        const buildingsData = yield restClient.get('api/project/buildings', {
            params: {
                include: 'levels,levels.keyPlan,levels.keyPlan.keyPlan',
                'filters[project.id][equals]': projectID
            }
        });

        const projectDataDTO = dto(projectData?.data);
        const buildingsDataDTO = dto(buildingsData?.data);

        const response = {
            ...projectDataDTO?.data,
            buildings: buildingsDataDTO?.data
        };

        yield put({
            type: projectsActions.FETCH_UNIT_SETTINGS_SUCCESSFUL,
            response,
            iri
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.FETCH_UNIT_SETTINGS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Project
 * @param  {Object} building iri
 * @return {Object} Response from API
 */
export function* deleteProjectBuilding({ promise, iri }) {
    try {
        const deleteProjectBuildingAPI = yield restClient.delete(iri);
        yield put({
            type: projectsActions.DELETE_PROJECT_BUILDING_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProjectBuildingAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.DELETE_PROJECT_BUILDING_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Project Unit Type
 * @param  {Object} building iri
 * @return {Object} Response from API
 */
export function* deleteProjectUnitType({ promise, iri }) {
    try {
        const deleteProjectUnitTypeAPI = yield restClient.delete(iri);
        yield put({
            type: projectsActions.DELETE_PROJECT_UNIT_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProjectUnitTypeAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.DELETE_PROJECT_UNIT_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Project building level
 * @param  {Object} building iri
 * @return {Object} Response from API
 */
export function* deleteProjectBuildingLevel({ promise, iri }) {
    try {
        const deleteProjectBuildingLevelAPI = yield restClient.delete(iri);
        yield put({
            type: projectsActions.DELETE_PROJECT_BUILDING_LEVEL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProjectBuildingLevelAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.DELETE_PROJECT_BUILDING_LEVEL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Project key plan
 * @param  {Object} key plan iri
 * @return {Object} Response from API
 */
export function* deleteProjectKeyPlan({ promise, iri }) {
    try {
        const deleteProjectKeyPlanAPI = yield restClient.delete(iri);
        yield put({
            type: projectsActions.DELETE_PROJECT_KEY_PLAN_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProjectKeyPlanAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.DELETE_PROJECT_KEY_PLAN_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project Building level
 * @param  {String} iri of building level
 * @return {Object} Response from API
 */
export function* updateProjectBuildingLevel({ promise, iri, formData }) {
    try {
        const updateProjectBuildingLevelAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_BUILDING_LEVEL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectBuildingLevelAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_BUILDING_LEVEL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project Building level
 * @param  {String} iri of building level
 * @return {Object} Response from API
 */
export function* updateProjectKeyPlan({ promise, iri, formData }) {
    try {
        const updateProjectKeyPlanAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_KEY_PLAN_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectKeyPlanAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_KEY_PLAN_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project Unit Type
 * @param  {String} iri of building level
 * @return {Object} Response from API
 */
export function* updateProjectUnitType({ promise, iri, formData }) {
    try {
        const updateProjectUnitTypeAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_UNIT_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectUnitTypeAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_UNIT_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Project Unit Type
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProjectUnitType({ promise, formData }) {
    try {
        const createBuildingLevelAPI = yield restClient.post('/api/project/unit-types', formData);
        yield put({
            type: projectsActions.CREATE_PROJECT_UNIT_TYPE_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBuildingLevelAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.CREATE_PROJECT_UNIT_TYPE_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Project Unit
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createProjectUnit({ promise, formData }) {
    try {
        const createProjectUnitAPI = yield restClient.post('/api/project/units', formData);
        yield put({
            type: projectsActions.CREATE_PROJECT_UNIT_SUCCESSFULL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createProjectUnitAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.CREATE_PROJECT_UNIT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project Unit
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* updateProjectUnit({ promise, formData, iri }) {
    try {
        const updateProjectUnitAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_UNIT_SUCCESSFULL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectUnitAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_UNIT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Delete Project Unit
 * @param  {String} Project unit iri
 * @return {Object} Response from API
 */
export function* deleteProjectUnit({ promise, iri }) {
    try {
        const deleteProjectUnitAPI = yield restClient.delete(iri);
        yield put({
            type: projectsActions.DELETE_PROJECT_UNIT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteProjectUnitAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.DELETE_PROJECT_UNIT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 *  * Delete Project Unit
 * @param  {String} Project unit iri
 * @return {Object} Response from API
 */
export function* startImportUnits({ promise, id, formData }) {
    try {
        const startImportUnitsAPI = yield restClient.post(
            `api/projects/${id}/units/import`,
            {
                ...formData
            },
            {
                headers: {
                    'Content-type': 'text/csv'
                }
            }
        );
        yield put({
            type: projectsActions.IMPORT_UNITS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: startImportUnitsAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.IMPORT_UNITS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Project toggle Data
 * @param  {Object} iri id of Project
 * @return {Object} Response from API
 */
export function* updateProjectToggle({ promise, iri, formData }) {
    try {
        const updateProjectAPI = yield restClient.patch(iri, formData);
        yield put({
            type: projectsActions.UPDATE_PROJECT_TOGGLE_FLOORPLANS_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateProjectAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.UPDATE_PROJECT_TOGGLE_FLOORPLANS_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* syncHubSpot({ promise, iri }) {
    try {
        const syncAPI = yield restClient.post(`/api/integration/sync/hubspot?project=${iri}`);
        yield put({
            type: projectsActions.PROJECT_SYNC_HUBSPOT_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: syncAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.PROJECT_SYNC_HUBSPOT_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* syncAvesdo({ promise, iri }) {
    try {
        yield restClient.post(`${iri}/sync-avesdo`, {});
        yield put({
            type: projectsActions.PROJECT_SYNC_AVESDO_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                code: 'integration.avesdo.sync.ok'
            }
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: projectsActions.PROJECT_SYNC_AVESDO_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: {
                code: 'integration.avesdo.sync.error'
            }
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const projectsSaga = [
    takeLatest(projectsActions.START_CREATE_PROJECT, createProject),
    takeLatest(projectsActions.START_FETCHING_PROJECT, fetchProject),
    takeLatest(projectsActions.START_UPDATE_PROJECT, updateProject),
    takeLatest(projectsActions.START_DELETE_PROJECT, deleteProject),
    takeLatest(projectsActions.START_PROJECT_SYNC_HUBSPOT, syncHubSpot),
    takeLatest(projectsActions.START_PROJECT_SYNC_AVESDO, syncAvesdo),
    takeLatest(projectsActions.START_DELETE_PROJECT, deleteProject),
    takeLatest(projectsActions.START_CREATE_PROJECT_BUILDING, createProjectBuilding),
    takeLatest(projectsActions.START_CREATE_PROJECT_KEY_PLAN, createProjectKeyPlan),
    takeLatest(projectsActions.START_CREATE_PROJECT_BUILDING_LEVEL, createBuildingLevel),
    takeLatest(projectsActions.START_FETCHING_UNIT_SETTINGS, fetchUnitSettings),
    takeLatest(projectsActions.START_DELETE_PROJECT_BUILDING, deleteProjectBuilding),
    takeLatest(projectsActions.START_DELETE_PROJECT_BUILDING_LEVEL, deleteProjectBuildingLevel),
    takeLatest(projectsActions.START_DELETE_PROJECT_KEY_PLAN, deleteProjectKeyPlan),
    takeLatest(projectsActions.START_UPDATE_PROJECT_BUILDING_LEVEL, updateProjectBuildingLevel),
    takeLatest(projectsActions.START_UPDATE_PROJECT_KEY_PLAN, updateProjectKeyPlan),
    takeLatest(projectsActions.START_CREATE_PROJECT_UNIT_TYPE, createProjectUnitType),
    takeLatest(projectsActions.START_DELETE_PROJECT_UNIT_TYPE, deleteProjectUnitType),
    takeLatest(projectsActions.START_UPDATE_PROJECT_UNIT_TYPE, updateProjectUnitType),
    takeLatest(projectsActions.START_UPDATE_PROJECT_BUILDING, updateProjectBuilding),
    takeLatest(projectsActions.START_CREATE_PROJECT_UNIT, createProjectUnit),
    takeLatest(projectsActions.START_UPDATE_PROJECT_UNIT, updateProjectUnit),
    takeLatest(projectsActions.START_DELETE_PROJECT_UNIT, deleteProjectUnit),
    takeLatest(projectsActions.START_IMPORT_UNITS, startImportUnits),
    takeLatest(projectsActions.START_UPDATE_PROJECT_TOGGLE_FLOORPLANS, updateProjectToggle)
];

export default projectsSaga;
