import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form, { GeneratePassword } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';

const UserChangePasswordForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <GeneratePassword name="new_password" isRequired />
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

UserChangePasswordForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    pristine: false,
    invalid: false
};

UserChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'UserChangePasswordForm',
    enableReinitialize: true
})(UserChangePasswordForm);
