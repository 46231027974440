import { createSelector } from 'reselect';

export const getVonageVideoFetching = state => state.vonageVideoAPI?.fetching || false;

export const getVonageVideoAPIKey = state => state.vonageVideoAPI?.vonageVideoAPIKey || null;

export const getVonageVideoSecretKey = state => state.vonageVideoAPI?.vonageVideoSecretKey || null;

export default createSelector([
    getVonageVideoFetching,
    getVonageVideoAPIKey,
    getVonageVideoSecretKey
]);
