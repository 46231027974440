import PropTypes from 'prop-types';
import Form, { Text } from 'erpcore/components/Form';
import { Field } from 'redux-form';
import React from 'react';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const DepositStructureDescriptionRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: false
                    }}
                    fieldAttr={{ id: `${member}.level`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.price`}
                    fieldProps={{
                        label: 'Price/percentage',
                        clearable: false
                    }}
                    fieldAttr={{ id: `${member}.price`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.dueDate`}
                    fieldProps={{
                        label: 'Due date',
                        clearable: false
                    }}
                    fieldAttr={{ id: `${member}.dueDate`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

DepositStructureDescriptionRepeaterItem.defaultProps = {
    member: null
};

DepositStructureDescriptionRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DepositStructureDescriptionRepeaterItem;
