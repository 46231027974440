export const stringOperations = [
    { label: 'Equals', value: 'equals' },
    { label: 'Contains', value: 'contains' }
];

export const arrayOperations = [
    { label: 'In', value: 'in' },
    { label: 'Not in', value: 'not_in' },
    { label: 'Between', value: 'between' },
    { label: 'Not Between', value: 'not_between' }
];

export const intOperations = [
    { label: 'Smaller Than', value: 'smaller_than' },
    { label: 'Smaller Than Or Equal', value: 'smaller_than_or_equal' },
    { label: 'Larger Than', value: 'larger_than' },
    { label: 'Larger Than Or Equal', value: 'larger_than_or_equal' }
];

export const operations = [...stringOperations, ...arrayOperations, ...intOperations];
