import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ElementLoader from 'erpcore/components/ElementLoader';
import LayoutManager from 'erpcore/utils/LayoutManager';
import BlueSettingsFeaturedProjectsForm from 'erpcore/screens/Settings/Blue/components/BlueSettingsFeaturedProjectsForm';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import { getOrganizationSetting } from 'erpcore/screens/Settings/Organization/Organization.selectors';

import orderBy from 'lodash/orderBy';
import BlueSettingsGeneralForm from 'erpcore/screens/Settings/Blue/components/BlueSettingsGeneralForm';
import BlueTabs from 'erpcore/screens/Settings/Blue/components/BlueTabs';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

const PortalBanners = () => {
    const title = 'MLA Blue App - General';
    const [fetching, setFetching] = useState(false);
    const dispatch = useDispatch();
    const featuredProjectsData = useSelector(state =>
        getOrganizationSetting(state, 'MLABlueSettingsFeaturedProjects')
    );
    const generalData = useSelector(state => getOrganizationSetting(state, 'MLABlueSettings'));

    const featuredProjectsInitialValues = {
        featured_projects:
            orderBy(featuredProjectsData?.data, item => item?.settings?.position)?.map(
                featuredProject => ({
                    iri: featuredProject?.iri,
                    ...featuredProject?.settings
                })
            ) || []
    };

    const generalDataSettings = useMemo(() => generalData?.data[0]?.settings, [generalData]);

    const generalInitialValues = {
        ...generalDataSettings
    };

    const fetchSettings = settingName => {
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName,
                params: {
                    limit: 999
                }
            });
        })
            .then(() => setFetching(false))
            .catch(error => ({ error }));
    };

    const onCreate = (formData, settingName) => {
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                type: organizationActions.START_CREATE_ORGANIZATION_SETTING,
                settingName,
                portal: true,
                formData,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => {
                setFetching(false);
                fetchSettings(settingName);
            });
    };

    const onUpdate = (formData, settingName) => {
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                type: organizationActions.START_UPDATE_ORGANIZATION_SETTING,
                iri: formData?.iri,
                settingName,
                portal: true,
                formData,
                promise: { resolve, reject },
                updateRedux: false
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => {
                setFetching(false);
                fetchSettings(settingName);
            });
    };

    const onDelete = (formData, settingName) => {
        setFetching(true);
        return new Promise((resolve, reject) => {
            dispatch({
                type: organizationActions.START_DELETE_ORGANIZATION_SETTING,
                settingName,
                iri: formData?.iri,
                promise: { resolve, reject }
            });
        })
            .catch(error => {
                return error;
            })
            .finally(() => {
                setFetching(false);
                fetchSettings(settingName);
            });
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchSettings('MLABlueSettingsFeaturedProjects');
        fetchSettings('MLABlueSettings');
    }, []);

    const onGeneralFormSubmit = useCallback(
        formData => {
            onUpdate({ ...formData, iri: generalData?.data[0]?.iri || null }, 'MLABlueSettings');
        },
        [generalDataSettings]
    );

    const dropdownActions = [
        {
            id: 'send-rets-email',
            label: 'Export RETS data in CSV format',
            onClick: () => {
                restClient
                    .get('/api/integration/export/rets')
                    .then(() => {
                        dispatch({
                            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                            response: { code: 'export.rets.success' }
                        });
                    })
                    .catch(() => {
                        dispatch({
                            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                            response: { code: 'export.rets.error' }
                        });
                    });
            }
        }
    ];

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <ButtonDropdown options={dropdownActions} />
            </PageHeader>
            <BlueTabs />
            <PageContent>
                {fetching && <ElementLoader overlay />}
                <h2>General Information</h2>
                <BlueSettingsGeneralForm
                    onSubmit={onGeneralFormSubmit}
                    initialValues={generalInitialValues}
                />
                <hr />
                <h2>Featured Projects</h2>
                <BlueSettingsFeaturedProjectsForm
                    submitLabel="Save"
                    onCreate={formData => onCreate(formData, 'MLABlueSettingsFeaturedProjects')}
                    onUpdate={formData => onUpdate(formData, 'MLABlueSettingsFeaturedProjects')}
                    onRemove={formData => onDelete(formData, 'MLABlueSettingsFeaturedProjects')}
                    initialValues={featuredProjectsInitialValues}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default PortalBanners;
