import React, { useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import './Search.scss';
import Svg from 'erpcore/components/Svg';

const Search = ({ onChangeSearch, queryParams }) => {
    const searchInput = useRef(null);
    const delayedSearch = debounce(q => onChangeSearch(q), 350);
    const isActive = queryParams?.q || false;

    useEffect(() => {
        searchInput.current.value = queryParams?.q || '';
    }, [queryParams]);

    return (
        <div className={`search${isActive !== false ? ' search--active' : ''}`}>
            <Svg icon="search" className="search__icon" />
            <input
                type="text"
                className="search__input"
                defaultValue={queryParams.q}
                placeholder="Search"
                onChange={e => delayedSearch({ q: e?.target?.value, page: 1 })}
                ref={searchInput}
            />
            <button
                type="button"
                className="search__close"
                onClick={() => onChangeSearch({ q: '' })}
            >
                <Svg icon="close" />
            </button>
        </div>
    );
};
Search.defaultProps = {
    onChangeSearch: () => {},
    queryParams: {}
};

Search.propTypes = {
    onChangeSearch: PropTypes.func,
    queryParams: PropTypes.oneOfType([PropTypes.object])
};

export default Search;
