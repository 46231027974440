import React, { useState } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import Listing from 'erpcore/components/Listing';
import { useDispatch, useSelector } from 'react-redux';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import moment from 'moment-timezone';
import Button from 'erpcore/components/Button';
import restClient from 'erpcore/api/restClient';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

const HubspotLogs = () => {
    const title = 'Hubspot Log';
    const reducerName = 'hubspotLogs';
    const actionName = 'HUBSPOT_LOGS';
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const dispatch = useDispatch();
    const [exportingCSV, setExportingCSV] = useState(false);

    const fetchHubspotLogs = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/hubspot-webhook-logs'
            });
        }).catch(error => {
            return error;
        });
    };

    const exportHubspotLog = () => {
        setExportingCSV(true);

        restClient
            .get(`api/hubspot-webhook-logs/export`, {
                params: {},
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'text/csv'
                }
            })
            .then(response => {
                setExportingCSV(false);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `hubspot-log.csv`);
                link.click();
            })
            .catch(() => {
                setExportingCSV(false);
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'error500' }
                });
            });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'type',
                field: 'type',
                mobile: 'title'
            },
            {
                title: 'Message',
                field: 'message',
                mobile: 'subtitle'
            },
            {
                title: 'Created At',
                field: 'created_at',
                sortable: 'created_at'
            },
            {
                title: 'Updated At',
                field: 'updated_at',
                sortable: 'updated_at'
            }
        ];

        if (listing && listing.data) {
            listing.data.map(row => {
                const { message, _type, created_at: createdAt, updated_at: updatedAt } = { ...row };

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    message: message || '-',
                    type: <strong>{_type || '-'}</strong>,
                    created_at: moment(createdAt).format('MMM Do, YYYY, h:mm a'),
                    updated_at: moment(updatedAt).format('MMM Do, YYYY, h:mm a')
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button label="Export Log" loading={exportingCSV} onClick={exportHubspotLog} />
            </PageHeader>
            <PageContent>
                <Listing
                    name={reducerName}
                    hideHeader
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchHubspotLogs(params)}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default HubspotLogs;
