import React from 'react';
import Form, { Select } from 'erpcore/components/Form';
import { reduxForm, Field } from 'redux-form';
import { projectSections } from 'erpcore/screens/Projects/data/projectsData';

const ProspectProfileQuestionsFilters = () => {
    return (
        <Form.Columns>
            <Form.GridColumn size={5}>
                <Form.Row>
                    <Field
                        name="section"
                        fieldProps={{ options: projectSections, clearable: false }}
                        fieldAttr={{ id: 'section' }}
                        component={Select}
                    />
                </Form.Row>
            </Form.GridColumn>
        </Form.Columns>
    );
};

export default reduxForm({
    form: 'ProspectProfileQuestionsFilters'
})(ProspectProfileQuestionsFilters);
