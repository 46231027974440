import React from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';

import './Input.scss';

function Input({ input, fieldProps, fieldAttr, field, meta, children, className }) {
    let hasValue =
        (input?.value && (input?.value?.length > 0 || input?.value)) ||
        input.value === 0 ||
        input.value === false;

    if (Array.isArray(input?.value) && input?.value?.length === 0) {
        hasValue = false;
    }

    const labelActive =
        meta.active || meta.autofilled || hasValue || fieldProps.forceLabelActive === true;

    return (
        <div
            className={`input${labelActive !== false ? ' input--active' : ''}${
                fieldAttr.required === true ? ' input--required' : ''
            }${fieldProps.icon ? ` input--has-icon` : ''}${
                fieldProps.iconPosition
                    ? ` input--icon-${fieldProps.iconPosition}`
                    : `${fieldProps.icon ? ' input--icon-left' : ''}`
            }${meta.active ? ` input--focused` : ''}${
                fieldAttr.disabled ? ` input--disabled` : ''
            }${meta.touched && meta.error ? ` input--error` : ''} ${className || ''}`}
        >
            {children}
            {fieldProps.icon && (
                <div
                    className={`input__icon${
                        fieldProps.iconPosition === 'right'
                            ? ` input__icon--right`
                            : ` input__icon--left`
                    }`}
                >
                    <Svg icon={fieldProps.icon} />
                </div>
            )}
            {fieldProps.clearable &&
                !fieldAttr.disabled &&
                hasValue &&
                field.component !== 'select' && (
                    <span
                        role="button"
                        aria-label={`Clear ${input.name}`}
                        tabIndex="-1"
                        className="input__action input__action--clear"
                        onKeyPress={() => input.onChange('')}
                        onClick={() => input.onChange('')}
                    >
                        <Svg icon="close" />
                    </span>
                )}
            {meta.touched && meta.error && (
                <span className="input__helper-text input__helper-text--error">
                    {meta.error.message ? meta.error.message : meta.error}
                </span>
            )}
            {fieldProps.helperText && (
                <span className="input__helper-text">{fieldProps.helperText}</span>
            )}
            {fieldProps.label && (
                <label
                    htmlFor={input.name}
                    className={`input__label${
                        labelActive !== false ? ' input__label--active' : ''
                    }`}
                >
                    {fieldProps.label}
                </label>
            )}
        </div>
    );
}

Input.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    children: null,
    className: ''
};

Input.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]).isRequired,
    meta: PropTypes.oneOfType([PropTypes.object]).isRequired,
    children: PropTypes.node,
    className: PropTypes.string
};

export default Input;
