import React, { useState } from 'react';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import UnitsCreateEditForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/UnitsCreateEditForm';
import { useDispatch } from 'react-redux';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';
import PropTypes from 'prop-types';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useRouteMatch } from 'react-router-dom';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const UnitsCreate = ({ onFinish }) => {
    const match = useRouteMatch();
    const id = match?.params?.id;
    const projectIri = `/api/projects/${id}`;
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        formData = {
            ...formData,
            project: projectIri,
            price: {
                amount: formData?.amount || 0,
                currency: formData?.currency || 'CAD'
            }
        };

        // eslint-disable-next-line camelcase
        if (formData?.unit_attributes) {
            formData.unit_attributes = cleanRepeaterOutput(formData.unit_attributes, [
                'customIdentifier'
            ]);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_CREATE_PROJECT_UNIT,
                promise: { resolve, reject },
                formData
            });
        })
            .then(() => {
                handleModal();
                onFinish();
            })
            .catch(err => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: {
                        code: 'projectUnit.nonExistingLevel',
                        detail: err?.errors
                    }
                });
            });
    };

    return (
        <>
            <Button label="Add New" size="small" onClick={handleModal} />
            <Modal opened={opened} title="Create unit" variation="medium" onClose={handleModal}>
                <UnitsCreateEditForm onSubmit={onSubmit} submitLabel="Create" />
            </Modal>
        </>
    );
};

UnitsCreate.defaultProps = {
    onFinish: () => {}
};

UnitsCreate.propTypes = {
    onFinish: PropTypes.func
};

export default UnitsCreate;
