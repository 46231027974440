import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Projects Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Projects = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'project.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/building.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project building successfully create!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/building.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project building successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/building.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project building successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/keyplan.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project key plan successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/keyplan.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project key plan successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/keyplan.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project key plan successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/buildinglevel.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Building level successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/buildinglevel.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Building level successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/buildinglevel.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Building level successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/unittype.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project unit type successfully create!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/unittype.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project unit type successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/unittype.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project unit type successfully removed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/unit.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project unit successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/unit.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project unit successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project/unit.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Project unit successfully removed!"
                    type="success"
                    expire="3500"
                />
            );
        case 'projectUnit.noBuildingLevels':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={detail}
                    type="error"
                    expire="3500"
                />
            );
        case 'projectUnit.invalidLevel':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={detail}
                    type="error"
                    expire="3500"
                />
            );
        case 'projectUnit.nonExistingLevel':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={detail?.map(el => (
                        <p>{el?.message}</p>
                    ))}
                    type="error"
                />
            );

        case 'project.mlaConnectWidgetCodeCopySuccessfull':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Code has been successfully copied!"
                    type="success"
                    expire="3500"
                />
            );
        case 'project.slugNotUnique':
            return (
                <Notification identifier={identifier} title="Error" text={detail} type="error" />
            );
        default:
            return null;
    }
};

Projects.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Projects.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Projects;
