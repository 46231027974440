export const actions = {
    START_FETCHING_PROJECTS: 'START_FETCHING_PROJECTS',
    FETCHING_SUCCESSFUL_PROJECTS: 'FETCHING_SUCCESSFUL_PROJECTS',
    FETCHING_FAILED_PROJECTS: 'FETCHING_FAILED_PROJECTS',

    START_FETCHING_PROJECT_UNITS: 'START_FETCHING_PROJECT_UNITS',
    FETCHING_SUCCESSFUL_PROJECT_UNITS: 'FETCHING_SUCCESSFUL_PROJECT_UNITS',
    FETCHING_FAILED_PROJECT_UNITS: 'FETCHING_FAILED_PROJECT_UNITS',

    START_CREATE_PROJECT: 'START_CREATE_PROJECT',
    CREATE_PROJECT_SUCCESFUL: 'CREATE_PROJECT_SUCCESFUL',
    CREATE_PROJECT_FAILED: 'CREATE_PROJECT_FAILED',
    START_UPDATE_PROJECT: 'START_UPDATE_PROJECT',
    UPDATE_PROJECT_SUCCESSFUL: 'UPDATE_PROJECT_SUCCESSFUL',
    UPDATE_PROJECT_FAILED: 'UPDATE_PROJECT_FAILED',
    START_FETCHING_PROJECT: 'START_FETCHING_PROJECT',
    FETCHING_PROJECT_SUCCESSFUL: 'FETCHING_PROJECT_SUCCESSFUL',
    FETCHING_PROJECT_FAILED: 'FETCHING_PROJECT_FAILED',
    STORE_PROJECT_DATA: 'STORE_PROJECT_DATA',
    START_DELETE_PROJECT: 'START_DELETE_PROJECT',
    DELETE_PROJECT_SUCCESSFUL: 'DELETE_PROJECT_SUCCESSFUL',
    DELETE_PROJECT_FAILED: 'DELETE_PROJECT_FAILED',

    START_PROJECT_SYNC_HUBSPOT: 'START_PROJECT_SYNC_HUBSPOT',
    PROJECT_SYNC_HUBSPOT_SUCCESSFUL: 'PROJECT_SYNC_HUBSPOT_SUCCESSFUL',
    PROJECT_SYNC_HUBSPOT_FAILED: 'PROJECT_SYNC_HUBSPOT_FAILED',

    START_PROJECT_SYNC_AVESDO: 'START_PROJECT_SYNC_AVESDO',
    PROJECT_SYNC_AVESDO_SUCCESSFUL: 'PROJECT_SYNC_AVESDO_SUCCESSFUL',
    PROJECT_SYNC_AVESDO_FAILED: 'PROJECT_SYNC_AVESDO_FAILED',

    START_CREATE_PROJECT_BUILDING: 'START_CREATE_PROJECT_BUILDING',
    CREATE_PROJECT_BUILDING_SUCCESSFUL: 'CREATE_PROJECT_BUILDING_SUCCESSFUL',
    CREATE_PROJECT_BUILDING_FAILED: 'CREATE_PROJECT_BUILDING_FAILED',

    START_CREATE_PROJECT_KEY_PLAN: 'START_CREATE_PROJECT_KEY_PLAN',
    CREATE_PROJECT_KEY_PLAN_SUCCESSFUL: 'CREATE_PROJECT_KEY_PLAN_SUCCESSFUL',
    CREATE_PROJECT_KEY_PLAN_FAILED: 'CREATE_PROJECT_KEY_PLAN_FAILED',

    START_UPDATE_PROJECT_KEY_PLAN: ' START_UPDATE_PROJECT_KEY_PLAN',
    UPDATE_PROJECT_KEY_PLAN_SUCCESSFUL: 'UPDATE_PROJECT_KEY_PLAN_SUCCESSFUL',
    UPDATE_PROJECT_KEY_PLAN_FAILED: 'UPDATE_PROJECT_KEY_PLAN_FAILED',

    START_DELETE_PROJECT_KEY_PLAN: ' START_DELETE_PROJECT_KEY_PLAN',
    DELETE_PROJECT_KEY_PLAN_SUCCESSFUL: 'DELETE_PROJECT_KEY_PLAN_SUCCESSFUL',
    DELETE_PROJECT_KEY_PLAN_FAILED: 'DELETE_PROJECT_KEY_PLAN_FAILED',

    START_CREATE_PROJECT_BUILDING_LEVEL: 'START_CREATE_PROJECT_BUILDING_LEVEL',
    CREATE_BUILDING_LEVEL_SUCCESSFUL: 'CREATE_BUILDING_LEVEL_SUCCESSFUL',
    CREATE_BUILDING_LEVEL_FAILED: 'CREATE_BUILDING_LEVEL_FAILED',

    START_UPDATE_PROJECT_BUILDING_LEVEL: 'START_UPDATE_PROJECT_BUILDING_LEVEL',
    UPDATE_PROJECT_BUILDING_LEVEL_SUCCESSFUL: 'UPDATE_PROJECT_BUILDING_LEVEL_SUCCESSFUL',
    UPDATE_PROJECT_BUILDING_LEVEL_FAILED: 'UPDATE_PROJECT_BUILDING_LEVEL_FAILED',

    START_UPDATE_PROJECT_UNIT_TYPE: 'START_UPDATE_PROJECT_UNIT_TYPE',
    UPDATE_PROJECT_UNIT_TYPE_SUCCESSFUL: 'UPDATE_PROJECT_UNIT_TYPE_SUCCESSFUL',
    UPDATE_PROJECT_UNIT_TYPE_FAILED: 'UPDATE_PROJECT_UNIT_TYPE_FAILED',

    START_UPDATE_PROJECT_BUILDING: 'START_UPDATE_PROJECT_BUILDING',
    UPDATE_PROJECT_BUILDING_SUCCESSFUL: 'UPDATE_PROJECT_BUILDING_SUCCESSFUL',
    UPDATE_PROJECT_BUILDING_FAILED: 'UPDATE_PROJECT_BUILDING_FAILED',

    START_DELETE_PROJECT_BUILDING_LEVEL: 'START_DELETE_PROJECT_BUILDING_LEVEL',
    DELETE_PROJECT_BUILDING_LEVEL_SUCCESSFUL: 'DELETE_PROJECT_BUILDING_LEVEL_SUCCESSFUL',
    DELETE_PROJECT_BUILDING_LEVEL_FAILED: 'DELETE_PROJECT_BUILDING_LEVEL_FAILED',

    START_FETCHING_UNIT_SETTINGS: 'START_FETCHING_UNIT_SETTINGS',
    FETCH_UNIT_SETTINGS_SUCCESSFUL: 'FETCH_UNIT_SETTINGS_SUCCESSFUL',
    FETCH_UNIT_SETTINGS_FAILED: 'FETCH_UNIT_SETTINGS_FAILED',

    START_DELETE_PROJECT_BUILDING: 'START_DELETE_PROJECT_BUILDING',
    DELETE_PROJECT_BUILDING_SUCCESSFUL: 'DELETE_PROJECT_BUILDING_SUCCESSFUL',
    DELETE_PROJECT_BUILDING_FAILED: 'DELETE_PROJECT_BUILDING_FAILED',

    START_DELETE_PROJECT_UNIT_TYPE: 'START_DELETE_PROJECT_UNIT_TYPE',
    DELETE_PROJECT_UNIT_TYPE_SUCCESSFUL: 'DELETE_PROJECT_UNIT_TYPE_SUCCESSFUL',
    DELETE_PROJECT_UNIT_TYPE_FAILED: 'DELETE_PROJECT_UNIT_TYPE_FAILED',

    START_CREATE_PROJECT_UNIT_TYPE: 'START_CREATE_PROJECT_UNIT_TYPE',
    CREATE_PROJECT_UNIT_TYPE_SUCCESSFUL: 'CREATE_PROJECT_UNIT_TYPE_SUCCESSFUL',
    CREATE_PROJECT_UNIT_TYPE_FAILED: 'CREATE_PROJECT_UNIT_TYPE_FAILED',

    START_CREATE_PROJECT_UNIT: 'START_CREATE_PROJECT_UNIT',
    CREATE_PROJECT_UNIT_SUCCESSFULL: 'CREATE_PROJECT_UNIT_SUCCESSFUL',
    CREATE_PROJECT_UNIT_FAILED: 'CREATE_PROJECT_UNIT_FAILED',

    START_UPDATE_PROJECT_UNIT: 'START_UPDATE_PROJECT_UNIT',
    UPDATE_PROJECT_UNIT_SUCCESSFULL: 'UPDATE_PROJECT_UNIT_SUCCESSFUL',
    UPDATE_PROJECT_UNIT_FAILED: 'UPDATE_PROJECT_UNIT_FAILED',

    START_DELETE_PROJECT_UNIT: 'START_DELETE_PROJECT_UNIT',
    DELETE_PROJECT_UNIT_SUCCESSFUL: 'DELETE_PROJECT_UNIT_SUCCESSFUL',
    DELETE_PROJECT_UNIT_FAILED: 'DELETE_PROJECT_UNIT_FAILED',

    START_IMPORT_UNITS: 'START_IMPORT_UNITS',
    IMPORT_UNITS_SUCCESSFUL: 'IMPORT_UNITS_SUCCESSFUL',
    IMPORT_UNITS_FAILED: 'IMPORT_UNITS_FAILED',

    START_UPDATE_PROJECT_TOGGLE_FLOORPLANS: 'START_UPDATE_PROJECT_TOGGLE_FLOORPLANS',
    UPDATE_PROJECT_TOGGLE_FLOORPLANS_SUCCESSFUL: 'UPDATE_PROJECT_TOGGLE_FLOORPLANS_SUCCESSFUL',
    UPDATE_PROJECT_TOGGLE_FLOORPLANS_FAILED: 'UPDATE_PROJECT_TOGGLE_FLOORPLANS_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    updating: false,
    units: {
        unitsData: [],
        unitsFetching: false
    }
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_UPDATE_PROJECT_TOGGLE_FLOORPLANS: {
            return {
                ...state,
                updating: true
            };
        }
        case actions.UPDATE_PROJECT_TOGGLE_FLOORPLANS_SUCCESSFUL: {
            return {
                ...state,
                updating: false
            };
        }
        case actions.UPDATE_PROJECT_TOGGLE_FLOORPLANS_FAILED: {
            return {
                ...state,
                updating: false
            };
        }
        case actions.START_FETCHING_PROJECT_UNITS: {
            return {
                ...state,
                units: {
                    ...state.units,
                    unitsFetching: true
                }
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECT_UNITS: {
            return {
                ...state,
                units: {
                    ...state.units,
                    unitsFetching: false,
                    unitsData: response?.data
                }
            };
        }
        case actions.FETCHING_FAILED_PROJECT_UNITS: {
            return {
                ...state,
                units: {
                    ...state.units,
                    unitsFetching: false
                }
            };
        }
        case actions.START_FETCHING_PROJECTS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_PROJECTS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_PROJECTS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_PROJECT: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.START_FETCHING_UNIT_SETTINGS: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_UNIT_SETTINGS_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                [iri]: response
            };
        }
        case actions.FETCH_UNIT_SETTINGS_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_PROJECT_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_PROJECT_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_PROJECT_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        default:
            return state;
    }
};
