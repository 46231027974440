import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ProgressBar.scss';

const ProgressBar = ({ percentage }) => {
    const [initializedValue, setInitializedValue] = useState(0);
    useEffect(() => {
        setInitializedValue(percentage);
    }, [percentage]);
    return (
        <div className="progress-bar">
            <div className="progress-bar__line" style={{ width: `${initializedValue}%` }}></div>
        </div>
    );
};

ProgressBar.defaultProps = {
    percentage: ''
};

ProgressBar.propTypes = {
    percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ProgressBar;
