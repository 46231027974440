import React, { useEffect } from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectData, getProjectFetching } from 'erpcore/screens/Projects/Projects.selectors';
import PropTypes from 'prop-types';
import RealtorPortalForm from 'erpcore/screens/Projects/screens/ProjectEdit/components/RealtorPortalForm';
import { actions as projectsActions } from 'erpcore/screens/Projects/Projects.reducer';
import { diff } from 'deep-object-diff';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

const ProjectEditMultiEffectRealtorPortal = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectData = useSelector(state => getProjectData(state, projectIri)) || {};
    const fetching = useSelector(getProjectFetching);
    const { realtor_deposit_structure: realtorDepositStructureValues } = {
        ...projectData
    };

    const initialValues = {
        realtor_deposit_structure: realtorDepositStructureValues?.map((table, i) => {
            const output = { ...table, customIdentifier: `customIdentifier-${i}` };
            if (output?.items?.length) {
                output.items = output.items.map((item, j) => {
                    const newItem = { ...item };
                    delete newItem.isNewRepeaterItem;
                    return { ...newItem, customInnerIdentifier: `customIdentifier-${j}` };
                });
            }
            return output;
        })
    };

    const fetchProjectData = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_FETCHING_PROJECT,
                iri: projectIri
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectData();
    }, []);

    const onSubmit = formData => {
        const formDataDiff = diff(initialValues, formData);
        const { realtor_deposit_structure: realtorDepositStructureDiff } = {
            ...formDataDiff
        };

        if (realtorDepositStructureDiff) {
            formDataDiff.realtor_deposit_structure = cleanRepeaterOutput(
                formData.realtor_deposit_structure,
                ['customIdentifier']
            );
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectsActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <ProjectEditPageHeader data={projectData} />
            <PageContent>
                {fetching === true && <PageLoader content />}
                <RealtorPortalForm
                    initialValues={initialValues}
                    submitLabel="Update"
                    onSubmit={onSubmit}
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMultiEffectRealtorPortal.defaultProps = {
    match: {}
};

ProjectEditMultiEffectRealtorPortal.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditMultiEffectRealtorPortal;
