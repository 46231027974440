import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Image from 'erpcore/components/Image';
import { getOrganizationSettings } from 'erpcore/utils/OrganizationSettings/OrganizationSettings.selectors';
import mlaErpLogoWhite from 'erpcore/assets/images/mla-erp-logo-white.png';
import mlaOiAccessLogoWhite from 'erpcore/assets/images/mla-oi-access-logo-white.png';
import { isOiAccessSubdomain } from 'erpcore/utils/utils';
import styles from './Hero.module.scss';

const Hero = ({ className }) => {
    const organizationSettings = useSelector(getOrganizationSettings);
    return (
        <Image
            iri={organizationSettings?.settings?.loginBackground}
            className={`${styles.hero} ${isOiAccessSubdomain() &&
                styles.hero__oiaccess} ${className || ''}`}
            isContainer
            fallback={<span className={`${styles.hero} ${className || ''}`}></span>}
        >
            <div className={styles.hero__content}>
                <img
                    src={!isOiAccessSubdomain() ? mlaErpLogoWhite : mlaOiAccessLogoWhite}
                    alt={!isOiAccessSubdomain() ? 'MLA ERP' : 'MLA OI ACCESS'}
                />
            </div>
        </Image>
    );
};

Hero.defaultProps = {
    className: ''
};

Hero.propTypes = {
    className: PropTypes.string
};

export default Hero;
