import React from 'react';
import { Field } from 'redux-form';
import Form, { Autocomplete, Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';

const NeghborhoodLocationsRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{
                        label: 'Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.name` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.latitude`}
                    fieldProps={{ label: 'Latitude' }}
                    fieldAttr={{ id: `${member}.latitude` }}
                    editorProps={{
                        toolbar: {
                            options: ['inline', 'list'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic', 'underline']
                            }
                        }
                    }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.longitude`}
                    fieldProps={{ label: 'Longitude' }}
                    fieldAttr={{ id: `${member}.longitude` }}
                    editorProps={{
                        toolbar: {
                            options: ['inline', 'list'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic', 'underline']
                            }
                        }
                    }}
                    component={Text}
                />
            </Form.Row>
            <Field
                name={`${member}.neighbourhood_location_section`}
                fieldProps={{
                    label: 'Neighbourhood location sections',
                    clearable: true,
                    options: {
                        endpoint: '/api/neighbourhood-location-sections',
                        mapData: {
                            value: 'iri',
                            label: 'name'
                        }
                    }
                }}
                component={Autocomplete}
            />
        </div>
    );
};

NeghborhoodLocationsRepeaterItem.defaultProps = {
    member: null
};

NeghborhoodLocationsRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default NeghborhoodLocationsRepeaterItem;
