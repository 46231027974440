import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - UnitTypes Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const UnitTypes = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'unittype.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Unit type successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'unittype.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Unit type successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'unittype.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Unit type successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

UnitTypes.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

UnitTypes.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default UnitTypes;
