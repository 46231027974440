import React, { useEffect, useState } from 'react';
import Widget from 'erpcore/components/Widget';
import { BarChart } from 'erpcore/components/Charts';
import renderBackgroundColors from 'erpcore/screens/Reports/Session/SessionReports.utils';
import ProgressBar from 'erpcore/components/ProgressBar';
import restClient from 'erpcore/api/restClient';
import ElementLoader from 'erpcore/components/ElementLoader';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import './SessionReportPerRealtor.scss';
import { getSessionDataFetching } from 'erpcore/screens/Reports/Session/SessionReports.selectors';
import { getFormValues } from 'redux-form';

const SessionReportPerRealtor = () => {
    const dispatch = useDispatch();
    const [chartData, setChartData] = useState({});
    const globalFiltersChanged = useSelector(getSessionDataFetching);
    const globalFilters = useSelector(state => getFormValues('SessionReportFilters')(state));
    const [fetchingChartData, setFetchingChartData] = useState(false);
    const { meta, data } = { ...chartData };
    const { totals } = { ...meta };

    const { perRealtor = [] } = { ...data };

    const formatChartData = () => {
        const defaultValues = {
            labels: perRealtor.map(realtor => realtor?.name),
            datasets: []
        };

        defaultValues.datasets.push({
            label: 'Sessions',
            backgroundColor: renderBackgroundColors(defaultValues.labels, 0.8),
            hoverBackgroundColor: renderBackgroundColors(defaultValues.labels, 2.5),
            data: perRealtor.map(realtor => realtor?.count)
        });

        return { labels: defaultValues.labels, datasets: defaultValues.datasets };
    };

    const options = {
        scales: {
            yAxes: [
                {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    labels: {
                        show: true
                    },
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        }
    };

    const fetchChartData = async formValues => {
        const { date_range: dateRange, project } = { ...formValues };
        const { start: startDate, end: endDate } = { ...dateRange };

        setFetchingChartData(true);
        const params = {
            pagination: false,
            display_by: 'perRealtor',
            'filters[start_date]': startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
            'filters[end_date]': endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
            'filters[project]': project
        };

        await restClient
            .get('api/reports/sessions', {
                headers: { Accept: 'application/json' },
                params
            })
            .then(response => {
                setChartData(response?.data);
            })
            .catch(error => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: error?.response?.data || error
                });
            })
            .finally(() => {
                setFetchingChartData(false);
            });
    };

    useEffect(() => {
        fetchChartData(globalFilters);
    }, [globalFiltersChanged]);

    return (
        <Widget className="session-report-per-realtor" title="Sessions per Realtor">
            {fetchingChartData && <ElementLoader overlay />}

            <div className="session-report-per-realtor">
                <span className="session-report-per-realtor--big">
                    {totals?.averageNumberOfSessions || 0}{' '}
                </span>
                <span>avg. sessions per Realtor</span>
            </div>

            <div className="session-report-per-realtor__chart">
                <div className="session-report-per-realtor__chart-responsive">
                    <BarChart data={formatChartData()} options={options} />
                </div>
            </div>

            <div className="session-report-per-realtor__line">
                {!!perRealtor?.length &&
                    perRealtor.map(realtor => {
                        return (
                            <div className="session-report-per-realtor__line-single">
                                <p>
                                    {realtor.name} <span>{realtor.count}</span>
                                </p>
                                <ProgressBar percentage={realtor.count} />
                            </div>
                        );
                    })}
            </div>
        </Widget>
    );
};

export default SessionReportPerRealtor;
