import React from 'react';
import { Field } from 'redux-form';
import Form, { Text, TextEditor } from 'erpcore/components/Form';
import PropTypes from 'prop-types';

const TitleDescriptionRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.description`}
                    fieldProps={{ label: 'Description' }}
                    fieldAttr={{ id: `${member}.description` }}
                    editorProps={{
                        toolbar: {
                            options: ['inline', 'list'],
                            inline: {
                                inDropdown: false,
                                options: ['bold', 'italic', 'underline']
                            }
                        }
                    }}
                    component={TextEditor}
                />
            </Form.Row>
        </div>
    );
};

TitleDescriptionRepeaterItem.defaultProps = {
    member: null
};

TitleDescriptionRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default TitleDescriptionRepeaterItem;
