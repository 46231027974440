import React from 'react';
import { withRouter } from 'react-router-dom';

import Tabs from 'erpcore/components/Tabs';

const PortalTabs = () => {
    const links = [];

    links.push({
        link: `/settings/portal/general`,
        label: 'General'
    });
    links.push({
        link: `/settings/portal/checklist-items`,
        label: 'Checklist Items'
    });
    links.push({
        link: `/settings/portal/faq`,
        label: 'FAQ'
    });
    links.push({
        link: `/settings/portal/banners`,
        label: 'Banners'
    });

    return <Tabs links={links} />;
};

export default withRouter(PortalTabs);
