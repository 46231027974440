import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import Button from 'erpcore/components/Button';
import ClientCreateEditForm from 'erpcore/screens/Clients/components/ClientCreateEditForm';
import Modal from 'erpcore/components/Modal';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';

import { actions as clientsActions } from 'erpcore/screens/Clients/Clients.reducer';

const ClientCreateActionModal = ({ formName }) => {
    const dispatch = useDispatch();
    const [opened, setOpened] = useState(false);

    const handleModal = () => {
        setOpened(!opened);
    };

    const onSubmit = formData => {
        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: clientsActions.START_CREATE_CLIENT,
                promise: { resolve, reject },
                formData
            });
        })
            .then(response => {
                handleModal();
                return dispatch(change(formName, 'client', response?.data?.id));
            })
            .catch(error => {
                return error;
            });
    };

    return (
        <Fragment>
            <Button
                onClick={() => handleModal()}
                variation="action"
                iconName="plus"
                label="Create"
                labelOnlyAria
            />
            <Modal
                variation="medium"
                opened={opened}
                onClose={() => handleModal()}
                title="Add new client"
            >
                <ClientCreateEditForm
                    form="ClientCreateForm"
                    onSubmit={onSubmit}
                    submitLabel="Create"
                    onCancel={() => handleModal()}
                />
            </Modal>
        </Fragment>
    );
};

ClientCreateActionModal.defaultProps = {
    formName: null
};

ClientCreateActionModal.propTypes = {
    formName: PropTypes.string
};

export default ClientCreateActionModal;
