import React from 'react';
import Form, { Autocomplete, Media, SwitchCard, Text } from 'erpcore/components/Form';
import { Field, FieldArray, getFormValues } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import style from './ProjectUnitTypesRepeaterItem.module.scss';

const ProjectUnitTypesRepeaterItem = ({ member }) => {
    const currentUnitType = member.substring(11, member.length - 1);
    const formValues = useSelector(getFormValues('ProjectUnitTypes'));
    const { unit_types: unitTypes } = { ...formValues };
    const { price_description: priceDescription } = { ...unitTypes?.[currentUnitType] };

    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{
                        label: 'Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.name`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <Field
                    name={`${member}.unit_type`}
                    fieldProps={{
                        label: 'Unit type',
                        clearable: true,
                        options: {
                            endpoint: 'api/unit-types',
                            mapData: {
                                label: 'name',
                                value: 'iri'
                            }
                        }
                    }}
                    fieldAttr={{ id: `${member}.unit_type` }}
                    component={Autocomplete}
                />
                <Field
                    name={`${member}.status`}
                    fieldProps={{
                        label: 'Status',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.status` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.square_footage`}
                    fieldProps={{
                        label: 'Total SqFt',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.square_footage`, required: true }}
                    component={Text}
                    validate={valueValidation([
                        { validator: 'required' },
                        { validator: 'numericality' }
                    ])}
                />
                <Field
                    name={`${member}.interior_area`}
                    fieldProps={{
                        label: 'Interior Area SqFt',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.interior_area`, type: 'number' }}
                    component={Text}
                />
                <Field
                    name={`${member}.exterior_area`}
                    fieldProps={{
                        label: 'Exterior Area SqFt',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.exterior_area`, type: 'number' }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row className={priceDescription ? style.amount : ''}>
                <Field
                    name={`${member}.price_description`}
                    fieldProps={{
                        label: 'Amount description',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.price_description` }}
                    component={Text}
                />
                {!!priceDescription && (
                    <Field
                        name={`${member}.amount_description_public_on_blue`}
                        fieldProps={{
                            label: 'Amount description publicly visible on MLA Blue'
                        }}
                        fieldAttr={{ id: `${member}.amount_description_public_on_blue` }}
                        component={SwitchCard}
                    />
                )}
            </Form.Row>

            <Form.SectionTitleSmall>Floor plan *</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name={`${member}.floor_plan`}
                    id={`${member}.floor_plan`}
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['png', 'jpg', 'jpeg']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>

            <Form.SectionTitleSmall>Image gallery</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name={`${member}.image_gallery`}
                    id={`${member}.image_gallery`}
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['png', 'jpg', 'jpeg']}
                />
            </Form.Row>

            <Form.SectionTitleSmall>Unit positions</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name={`${member}.unit_positions`}
                    id={`${member}.unit_positions`}
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['png', 'jpg', 'jpeg']}
                />
            </Form.Row>
        </div>
    );
};

ProjectUnitTypesRepeaterItem.defaultProps = {
    member: null
};

ProjectUnitTypesRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectUnitTypesRepeaterItem;
