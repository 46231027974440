import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';

import Form, {
    Text,
    Email,
    Phone,
    DateTime,
    RadioGroup,
    Location,
    Media,
    Switch
} from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Modal from 'erpcore/components/Modal';
import ChangePasswordForm from 'erpcore/screens/MyAccount/components/ChangePasswordForm';

import { valueValidation } from 'erpcore/components/Form/Form.utils';
import { gender } from 'erpcore/screens/Users/data/users';
import { actions as myAccountActions } from 'erpcore/screens/MyAccount/MyAccount.reducer';

const UpdateProfileForm = ({
    canChangePassword,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    const dispatch = useDispatch();
    const [modalOpened, setModalOpened] = useState(false);

    const handleModal = (newOpenState = null) => {
        if (newOpenState === null) {
            setModalOpened(!modalOpened);
        } else {
            setModalOpened(newOpenState);
        }
    };

    const onSubmitChangePassword = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: myAccountActions.START_CHANGE_PASSWORD,
                formData
            });
        })
            .then(() => {
                handleModal(false);
            })
            .catch(() => {
                dispatch(reset('MyAccountChangePasswordForm'));
            });
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.SectionTitle>Personal details</Form.SectionTitle>
                <Form.Columns>
                    <Form.Column>
                        <Form.Row>
                            <Field
                                name="first_name"
                                id="first_name"
                                fieldProps={{
                                    label: 'First name',
                                    clearable: true
                                }}
                                fieldAttr={{ required: true }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="last_name"
                                id="last_name"
                                fieldProps={{
                                    label: 'Last name',
                                    clearable: true
                                }}
                                fieldAttr={{ required: true }}
                                component={Text}
                                validate={valueValidation([{ validator: 'required' }])}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="email"
                                id="email"
                                fieldProps={{
                                    label: 'Email'
                                }}
                                fieldAttr={{ disabled: true }}
                                component={Email}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="phone_number"
                                id="phone_number"
                                fieldProps={{
                                    label: 'Phone'
                                }}
                                component={Phone}
                            />
                        </Form.Row>
                        <Form.Row>
                            <Field
                                name="birthdate"
                                id="birthdate"
                                fieldProps={{
                                    label: 'Birth date',
                                    showTimeSelect: false,
                                    clearable: true,
                                    icon: 'date',
                                    iconPosition: 'right'
                                }}
                                component={DateTime}
                            />
                        </Form.Row>
                        <Field
                            name="is_birthdate_private"
                            fieldProps={{
                                type: 'single',
                                label: 'Keep my birth date private',
                                on: {
                                    value: true,
                                    id: 'true'
                                },
                                off: {
                                    value: false,
                                    id: 'false'
                                }
                            }}
                            fieldAttr={{
                                id: 'is_birthdate_private',
                                name: 'is_birthdate_private'
                            }}
                            component={Switch}
                        />
                    </Form.Column>
                    <Form.Column>
                        <Form.SectionTitleSmall>Profile photo</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="image"
                                id="image"
                                component={Media}
                                useLightbox
                                useImageManager
                                versionName="small"
                                allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                            />
                        </Form.Row>

                        <Form.SectionTitleSmall>Gender</Form.SectionTitleSmall>
                        <Form.Row>
                            <Field
                                name="gender"
                                id="gender"
                                fieldProps={{
                                    options: gender
                                }}
                                component={RadioGroup}
                            />
                        </Form.Row>
                    </Form.Column>
                </Form.Columns>
                <Form.Section>
                    <Form.Row>
                        <Field
                            name="location"
                            id="location"
                            fieldProps={{
                                locationManualTogglelabel: 'Input address manually' // ,
                                // showLocationDetails: true
                            }}
                            // fieldAttr={{ disabled: true }}
                            component={Location}
                        />
                    </Form.Row>
                    <Button
                        submit
                        disabled={pristine || invalid}
                        loading={submitting}
                        label={submitLabel}
                    />
                    {!!canChangePassword && (
                        <Button
                            onClick={() => handleModal()}
                            variation="secondary"
                            label="Change password"
                        />
                    )}
                </Form.Section>
            </Form>
            {!!canChangePassword && (
                <Modal
                    variation="medium"
                    opened={modalOpened}
                    onClose={() => handleModal()}
                    title="Change password"
                >
                    <ChangePasswordForm
                        form="MyAccountChangePasswordForm"
                        onSubmit={onSubmitChangePassword}
                    />
                </Modal>
            )}
        </>
    );
};

UpdateProfileForm.defaultProps = {
    canChangePassword: false,
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Update',
    pristine: false,
    invalid: false
};

UpdateProfileForm.propTypes = {
    canChangePassword: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'UpdateProfileForm',
    enableReinitialize: true
})(UpdateProfileForm);
