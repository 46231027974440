import ElementLoader from 'erpcore/components/ElementLoader';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageContent from 'erpcore/components/Layout/PageContent';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import LayoutManager from 'erpcore/utils/LayoutManager';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { actions as organizationActions } from 'erpcore/screens/Settings/Organization/Organization.reducer';
import { getOrganizationSetting } from 'erpcore/screens/Settings/Organization/Organization.selectors';
import mlaOiAccessHero from 'erpcore/assets/images/mla-oi-hero-dashboard.jpg';
import mlaOiAccessSubscribe from 'erpcore/assets/images/oi-access-subscribe.png';
import Svg from 'erpcore/components/Svg';
import Modal from 'erpcore/components/Modal';
import Pagination from '../../../../components/Listing/components/Pagination';
import Projects from '../../components/Projects/Projects';
import { actions as oiAccessDashboardActions } from '../../OiAccessDashboard.reducer';
import { getDashboardData, getDashboardFetching } from '../../OiAccessDashboard.selectors';
import './OiAccessDashboard.scss';

const params = {
    'filters[reporting][exists]': 1
};

const OiAccessDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const meData = useSelector(getMeData);
    const title = 'Oi Access Dashboard';
    const filterUserRoles = meData?.roles?.map(userRole => userRole.attribute);
    const hasSuperAdminRole = filterUserRoles.indexOf('CAN_ROLE_SUPER_ADMIN') !== -1;
    const { data: projectsData, meta } = useSelector(state => getDashboardData(state));
    const isFetchingOiAccessProjects = useSelector(state => getDashboardFetching(state));
    const getAllQueryParams = new URLSearchParams(window.location.search);
    const usersPageByQueryParams = +getAllQueryParams.get('page');
    const [currentPage, setCurrentPage] = useState(usersPageByQueryParams || 1);
    const memoizedProjectsList = useMemo(() => projectsData, [projectsData]);

    const [videoModal, setVideoModal] = useState(false);

    const settings = useSelector(state => getOrganizationSetting(state, 'oiAccessGeneral')) || [];
    const settingData = settings?.data?.length ? settings?.data[0] : {};
    const { pbi_message: pbiMessage } = {
        ...settingData?.settings
    };

    const fetchUserProjects = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: oiAccessDashboardActions.START_FETCHING_SUPER_ADMIN_PROJECTS,
                params: hasSuperAdminRole ? { page: currentPage, ...params } : params,
                endpoint: hasSuperAdminRole
                    ? 'api/projects'
                    : `api/users/${meData?.id}/?include=projects,clientAvailableProjects,clientExcludedProjects,projects.featuredImage,clientAvailableProjects.featuredImage`
            });
        }).catch(error => ({ error }));
    };

    const fetchSettings = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: organizationActions.START_FETCH_ORGANIZATION_SETTING,
                settingName: 'oiAccessGeneral'
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchUserProjects();
        fetchSettings();
    }, [currentPage]);

    const handleOnChangePaginator = ({ page }) => {
        setCurrentPage(page);
        history.push({
            search: `?page=${page}`
        });
    };

    const filterProjects = projectsList =>
        Array.isArray(projectsList) &&
        projectsList?.filter(project => project?.reporting && project?.reporting?.length);

    const { client_available_projects: clientAvailableProjects } = memoizedProjectsList;

    const projectsList = hasSuperAdminRole
        ? filterProjects(memoizedProjectsList)
        : (clientAvailableProjects?.length && clientAvailableProjects) || [];

    return (
        <LayoutManager slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageContent>
                <div className="oi-access-dashboard__section">
                    <img src={mlaOiAccessHero} alt="MLA OI" />
                    <h1>MLA Oi DASHBOARD</h1>
                    {pbiMessage && (
                        <div
                            className="oi-access-dashboard__columns"
                            dangerouslySetInnerHTML={{ __html: pbiMessage }}
                        />
                    )}
                </div>
                <div className="oi-access-dashboard__title" id="projects">
                    PROJECTS
                </div>
                <div className="oi-access-dashboard__section">
                    <div className="projects">
                        <div className="projects__item-list">
                            {!!isFetchingOiAccessProjects && <ElementLoader overlay />}
                            <Projects projects={projectsList?.length ? projectsList : []} />
                            {!isFetchingOiAccessProjects && projectsList?.length === 0
                                ? 'No project assigned to you!'
                                : ''}
                        </div>
                    </div>
                </div>
                {(filterProjects(memoizedProjectsList)?.length || 0) >= 10 && (
                    <div className="oi-access-dashboard-footer">
                        <Pagination
                            meta={{ ...meta, currentPage }}
                            onChangePagination={handleOnChangePaginator}
                        />
                    </div>
                )}
                <div className="oi-access-dashboard__title">MLA VISION</div>
                <div className="oi-access-dashboard__section">
                    <div className="oi-access-dashboard__video">
                        <button
                            type="button"
                            className="oi-access-dashboard__video-play"
                            onClick={() => setVideoModal(true)}
                        >
                            <Svg icon="play" />
                        </button>
                        <iframe
                            className="oi-access-dashboard__iframe"
                            src="https://player.vimeo.com/video/728255680?background=1&amp;loop=1&amp;api=1&amp;player_id=vvvvimeoVideo-6938435"
                            frameBorder="0"
                            webkitallowfullscreen=""
                            mozallowfullscreen=""
                            allowFullScreen=""
                            title="MLA Vision"
                        />
                    </div>
                    <h1>Together, we RISE above.</h1>
                    <h2>
                        Our company&apos;s foundation is built on the four pillars of Relationships,
                        Insights, Sales, and Experience. We believe in guiding the inches of
                        innovation through thoughtful leadership to best serve our development
                        partners, realtors, and homebuyers while achieving the vision of creating
                        meaningful real estate experiences.
                    </h2>
                </div>
                <div className="oi-access-dashboard__title">MLA OI DASHBOARD FAQs</div>
                <div className="oi-access-dashboard__section">
                    <span className="oi-access-dashboard__learn-more">
                        Learn more about MLA Oi Dashboard <NavLink to="/help">here</NavLink>
                    </span>
                    <a
                        href="https://realestateinsider.mlacanada.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="oi-access-dashboard__subscribe"
                        title="Subscribe"
                    >
                        <img src={mlaOiAccessSubscribe} alt="MLA Subscribe" />
                    </a>
                </div>
                <div className="oi-access-dashboard__footer">
                    <a href="#projects" className="oi-access-dashboard__footer-link">
                        Projects
                    </a>
                    <a
                        className="oi-access-dashboard__footer-link"
                        href="https://mlacanada.com/mla-vision"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        MLA Vision
                    </a>
                    <a
                        className="oi-access-dashboard__footer-link"
                        href="https://mlacanada.com/newsfeed"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Newsfeed
                    </a>
                    <NavLink className="oi-access-dashboard__footer-link" to="/help">
                        FAQs
                    </NavLink>
                    <a
                        href="mailto:tech@mlacanada.com"
                        className="oi-access-dashboard__footer-contact"
                    >
                        CONTACT US
                    </a>
                </div>
                <Modal variation="cinema" opened={videoModal} onClose={() => setVideoModal(false)}>
                    <iframe
                        title="MLA Vision"
                        width="1920"
                        height="1080"
                        frameBorder="0"
                        allowFullScreen="allowfullscreen"
                        src="https://player.vimeo.com/video/728284133?autoplay=1"
                    />
                </Modal>
            </PageContent>
        </LayoutManager>
    );
};

export default OiAccessDashboard;
