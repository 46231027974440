import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ElementLoader from 'erpcore/components/ElementLoader';
import restClient from 'erpcore/api/restClient';
import React, { useEffect, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import dto from 'erpcore/utils/dto';

import { actions as oiAccessDashboardActions } from '../../OiAccessDashboard.reducer';
import {
    getDashboardSingleProjectData,
    getDashboardFetching
} from '../../OiAccessDashboard.selectors';
import style from './OiAccessProject.module.scss';

const params = {
    'filters[reporting][exists]': 1,
    limit: 99
};

const OiAccessProject = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const projectId = location?.pathname?.split('/')[3];
    const meData = useSelector(getMeData);
    const filterUserRoles = meData?.roles?.map(userRole => userRole.attribute);
    const hasSuperAdminRole = filterUserRoles.indexOf('CAN_ROLE_SUPER_ADMIN') !== -1;
    const title = 'Oi Access Project';
    const { data } = useSelector(state => getDashboardSingleProjectData(state));
    const isFetchingOiAccessProjects = useSelector(state => getDashboardFetching(state));
    const reportingList = useMemo(() => {
        return data?.reporting;
    }, [data?.reporting]);

    const fetchUserProjects = () => {
        if (hasSuperAdminRole) {
            return new Promise((resolve, reject) => {
                dispatch({
                    promise: { resolve, reject },
                    type: oiAccessDashboardActions.START_FETCHING_SUPER_ADMIN_PROJECTS,
                    params: hasSuperAdminRole ? { ...params } : {},
                    endpoint: hasSuperAdminRole
                        ? 'api/projects'
                        : `api/users/${meData?.id}/?include=projects,clientAvailableProjects,clientExcludedProjects`
                });
            }).catch(error => ({ error }));
        }

        return restClient
            .get('api/me', {
                params: {
                    include: 'clientAvailableProjects,clientExcludedProjects'
                }
            })
            .then(response => {
                const responseDTO = dto(response.data);
                if (
                    // eslint-disable-next-line camelcase
                    !responseDTO?.data?.client_available_projects ||
                    // eslint-disable-next-line camelcase
                    responseDTO?.data?.client_available_projects?.filter(
                        item => item.id === projectId
                    )?.length === 0
                ) {
                    history.push('/dashboard');
                    return false;
                }
                return new Promise((resolve, reject) => {
                    dispatch({
                        promise: { resolve, reject },
                        type: oiAccessDashboardActions.START_FETCHING_SUPER_ADMIN_PROJECTS,
                        params: hasSuperAdminRole ? { params } : {},
                        endpoint: hasSuperAdminRole
                            ? 'api/projects'
                            : `api/users/${meData?.id}/?include=projects,clientAvailableProjects,clientExcludedProjects`
                    });
                }).catch(error => ({ error }));
            });
    };

    useEffect(() => {
        fetchUserProjects();
    }, []);

    const fetchProjectDashboards = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: oiAccessDashboardActions.START_FETCHING_SINGLE_PROJECT,
                endpoint: `/api/projects/${projectId}`
            });
        }).catch(error => ({ error }));
    };

    useEffect(() => {
        fetchProjectDashboards();
    }, [location]);

    return (
        <LayoutManager slot="main" layoutType="merge" className="main--oi-access-dashboard">
            <HeadMeta title={title} />
            {!isFetchingOiAccessProjects && (!reportingList || !reportingList?.length) && (
                <p className={style['oi-access-project__message']}>No Dashboards</p>
            )}
            <div className={style['oi-access-project']}>
                {reportingList &&
                    reportingList?.length &&
                    reportingList?.map((reportingListItem, index) => {
                        return (
                            <Fragment key={index}>
                                {!!isFetchingOiAccessProjects && <ElementLoader overlay />}
                                <div className={`${style['oi-access-project__iframe-wrapper']}`}>
                                    <div
                                        className={`${
                                            style['oi-access-project__iframe']
                                        } ${reportingListItem.navigation_overlay &&
                                            style['oi-access-project__iframe--overlay']}`}
                                        dangerouslySetInnerHTML={{
                                            __html: reportingListItem?.iframe
                                        }}
                                    ></div>
                                    {reportingListItem.navigation_overlay && (
                                        <div
                                            className={style['oi-access-project__iframe-overlays']}
                                        ></div>
                                    )}
                                </div>
                            </Fragment>
                        );
                    })}
            </div>
        </LayoutManager>
    );
};

export default OiAccessProject;
