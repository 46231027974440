import { getSidebarShowDeck } from 'erpcore/utils/LayoutManager/LayoutManager.selectors';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import './TabsNavigation.scss';

const TabsNavigation = ({ tabs }) => {
    const location = useLocation();
    const subLinksRef = useRef(null);
    const showDeck = useSelector(getSidebarShowDeck);
    const subLinksClass = showDeck
        ? 'tabs-navigation__sublinks--layout-with-deck'
        : 'tabs-navigation__sublinks--layout-without-deck';

    if (!tabs || !Object.keys(tabs)?.length) {
        return false;
    }

    const hasTabActivated = slug =>
        location?.pathname.indexOf(slug) !== -1 ? 'tabs-navigation__link--active' : '';
    const hasSubTabActivated = subTabLink =>
        location?.pathname === subTabLink ? 'tabs-navigation__link--active' : '';
    const tabKey = location.pathname.split('/')[4];
    const hasTabsNavigationGap = !!(
        location.pathname.includes(tabKey) && tabs[tabKey]?.subTabs?.length
    );

    const subTabs = hasTabsNavigationGap && tabs[tabKey]?.subTabs;

    return (
        <nav className="tabs-navigation">
            <ul className="tabs-navigation__links">
                {Object.values(tabs)?.map(({ tabId, label, link, slug }) => {
                    return (
                        <React.Fragment key={tabId}>
                            <li className="tabs-navigation__item">
                                <Link
                                    to={link}
                                    className={`tabs-navigation__link ${hasTabActivated(slug)}`}
                                    exact
                                >
                                    {label}
                                </Link>
                            </li>
                        </React.Fragment>
                    );
                })}
            </ul>
            {subTabs?.length ? (
                <ul ref={subLinksRef} className={`tabs-navigation__sublinks ${subLinksClass}`}>
                    {subTabs?.map(({ subTabId, link: subTabLink, label: subTabLabel }) => {
                        return (
                            <li key={subTabId} className="tabs-navigation__subitem">
                                <Link
                                    to={subTabLink}
                                    className={`tabs-navigation__sublink ${hasSubTabActivated(
                                        subTabLink
                                    )}`}
                                    exact
                                >
                                    {subTabLabel}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </nav>
    );
};

TabsNavigation.displayName = 'TabsNavigation';

TabsNavigation.defaultProps = {
    tabs: null
};

TabsNavigation.propTypes = {
    tabs: PropTypes.objectOf(
        PropTypes.shape({
            tabId: PropTypes.number,
            link: PropTypes.string,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            subTabs: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        })
    )
};

export default TabsNavigation;
