import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as sessionActions } from 'erpcore/screens/Sessions/Sessions.reducer';
import { useDispatch } from 'react-redux';

const SessionDelete = ({ iri, handleSubmit, submitting }) => {
    const [opened, setOpened] = useState(false);
    const dispatch = useDispatch();

    const onSubmit = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_DELETE_SINGLE_SESSION,
                iri
            })
        )
            .then(() => {
                setOpened(false);
            })
            .catch(error => {
                setOpened(false);
                return error;
            });
    };

    const handleModal = () => {
        setOpened(!opened);
    };

    return (
        <Fragment>
            <Tooltip content="Delete session">
                <button type="button" onClick={() => handleModal()}>
                    <Svg icon="remove" />
                </button>
            </Tooltip>
            <Modal
                variation="small"
                opened={opened}
                onClose={() => handleModal()}
                title="Delete session"
                subtitle="Are you sure you want to delete this Session?"
            >
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Button submit loading={submitting} label="Delete" />
                    <Button variation="secondary" label="Cancel" onClick={() => handleModal()} />
                </Form>
            </Modal>
        </Fragment>
    );
};

SessionDelete.defaultProps = {
    submitting: false,
    iri: null
};

SessionDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    iri: PropTypes.string
};

export default reduxForm({
    form: 'SessionDeleteForm',
    enableReinitialize: true
})(SessionDelete);
