import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm, getFormValues } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import slugify from 'react-slugify';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import Button from 'erpcore/components/Button';
import ClientCreateActionModal from 'erpcore/screens/Clients/components/ClientCreateActionModal';
import ClientEditActionModal from 'erpcore/screens/Clients/components/ClientEditActionModal';
import Form, {
    Text,
    Textarea,
    Autocomplete,
    Select,
    Location,
    Media,
    Email,
    TextEditor,
    DateTime
} from 'erpcore/components/Form';
import { projectIsActive } from 'erpcore/screens/Projects/data/projectsData';
import AppStatuses from 'erpcore/components/AppStatuses';
import copy from 'copy-to-clipboard';
import Svg from 'erpcore/components/Svg';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import styles from './ProjectForm.module.scss';

const oiAppUrl = 'https://projects.mlacanada.com' || enviromentVariables.OI_APP_URL;
const blueAppUrl = 'https://mlablue.com' || enviromentVariables.BLUE_APP_URL;
const portalAppUrl = 'https://portal.mlacanada.com' || enviromentVariables.PORTAL_APP_URL;

const CopyButton = ({ copyText }) => {
    const dispatch = useDispatch();
    return (
        <button
            title="Click to copy to clipboard."
            className={styles['copy-link']}
            type="button"
            onClick={() => {
                copy(copyText);
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: {
                        code: 'copy.successful'
                    }
                });
            }}
        >
            <Svg icon="link" /> {copyText}
        </button>
    );
};

CopyButton.propTypes = {
    copyText: PropTypes.string.isRequired
};

const ConstructionStatusRepeatableItem = member => {
    const selectedStage = member?.data?.stage;
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member?.member}.stage`}
                    fieldProps={{
                        label: 'Stage',
                        clearable: true,
                        options: [
                            {
                                value: 'demolition_and_foundation',
                                label: 'Demolition & Foundation'
                            },
                            { value: 'framing_and_roofing', label: 'Framing & Roofing' },
                            { value: 'rough-ins_and_drywall', label: 'Rough-ins & Drywall' },
                            { value: 'interior_finishes', label: 'Interior Finishes' },
                            { value: 'landscaping', label: 'Landscaping' },
                            { value: 'final_detailing', label: 'Final Detailing' },
                            { value: 'custom_stage', label: 'Custom Stage' }
                        ]
                    }}
                    fieldAttr={{ id: `${member?.member}.stage`, required: true }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                {selectedStage === 'custom_stage' && (
                    <Field
                        name={`${member?.member}.custom_stage`}
                        fieldProps={{
                            label: 'Custom Stage',
                            clearable: true
                        }}
                        fieldAttr={{ id: `${member?.member}.custom_stage`, required: true }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                )}
                <Field
                    name={`${member?.member}.status`}
                    fieldProps={{
                        label: 'Status',
                        clearable: true,
                        options: [
                            { value: 'completed', label: 'Completed' },
                            { value: 'in_progress', label: 'In Progress' },
                            { value: 'not_started', label: 'Not Started' }
                        ]
                    }}
                    fieldAttr={{ id: `${member?.member}.status`, required: true }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member?.member}.date`}
                    fieldProps={{
                        label: 'Date',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member?.member}.date` }}
                    component={DateTime}
                />
            </Form.Row>
        </div>
    );
};

ConstructionStatusRepeatableItem.defaultProps = {};

ConstructionStatusRepeatableItem.propTypes = {};

const KeyContactsRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.name`}
                    fieldProps={{
                        label: 'Contact Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.url`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.email`}
                    fieldProps={{
                        label: 'Contact Email',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.email`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Column>
                <Field
                    name={`${member}.content`}
                    fieldProps={{
                        label: 'Contact Content',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.content`, required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <AppStatuses mlaBlue mlaLive />
            </Form.Column>
        </div>
    );
};

KeyContactsRepeatableItem.defaultProps = {
    member: null
};

KeyContactsRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const ProjectHeroRepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Form.SectionTitleSmall>Hero Image *</Form.SectionTitleSmall>
                <FieldArray
                    name={`${member}.image`}
                    id={`${member}.image`}
                    component={Media}
                    useLightbox
                    maxImageCount={1}
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
        </div>
    );
};

ProjectHeroRepeatableItem.defaultProps = {
    member: null
};

ProjectHeroRepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const ProjectForm = ({
    touch,
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    isSlugRequired,
    form
}) => {
    const formData = useSelector(state => getFormValues(form)(state));

    useEffect(() => {
        if (!isSlugRequired) {
            touch('slug');
        }
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Column>
                <Field
                    name="name"
                    fieldProps={{
                        label: 'Project name',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'name', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <AppStatuses mlaLive homestore mlaBlue portal />
            </Form.Column>
            <Form.Row>
                <Field
                    name="slug"
                    fieldProps={{
                        label: 'Slug',
                        clearable: false,
                        helperText: (
                            <p>
                                <CopyButton
                                    copyText={`${blueAppUrl}/project/${formData?.slug || '[slug]'}`}
                                />
                                <CopyButton
                                    copyText={`${portalAppUrl}/${formData?.slug || '[slug]'}`}
                                />
                                <CopyButton
                                    copyText={`${oiAppUrl}/project/${formData?.slug || '[slug]'}`}
                                />
                                <br />
                            </p>
                        )
                    }}
                    fieldAttr={{ id: 'slug', required: true }}
                    component={Text}
                    parse={value => {
                        return slugify(value.replaceAll(' - ', '-').replaceAll(' ', '-'));
                    }}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Column>
                <Field
                    name="client"
                    fieldProps={{
                        label: 'Client',
                        options: {
                            endpoint: '/api/clients',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        },
                        clearable: true,
                        actionButton: {
                            edit: {
                                component: <ClientEditActionModal formName={form} />
                            },
                            create: {
                                component: <ClientCreateActionModal formName={form} />
                            }
                        }
                    }}
                    fieldAttr={{ id: 'client', required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <AppStatuses mlaLive mlaBlue />
            </Form.Column>
            <Form.Row>
                <Field
                    name="contact_email"
                    fieldProps={{
                        label: 'Email address',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'contact_email', required: true }}
                    component={Email}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Column>
                <Field
                    name="is_active"
                    fieldProps={{
                        label: 'Is active',
                        clearable: true,
                        options: projectIsActive
                    }}
                    fieldAttr={{ id: 'isActive' }}
                    component={Select}
                />
                <AppStatuses mlaLive homestore mlaBlue />
            </Form.Column>
            <Form.Column>
                <Field
                    name="headline"
                    fieldProps={{
                        label: 'Headline',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'headline' }}
                    component={Text}
                />
                <AppStatuses homestore mlaBlue />
            </Form.Column>
            <Form.Column>
                <Field
                    name="description"
                    fieldProps={{
                        label: 'Short description',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'description' }}
                    component={Textarea}
                />
                <AppStatuses mlaLive homestore mlaBlue portal />
            </Form.Column>
            <Form.SectionTitleSmall>Development project logo</Form.SectionTitleSmall>
            <Form.Column>
                <Field
                    name="development_project_logo"
                    id="development_project_logo"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
                <AppStatuses mlaLive mlaBlue portal />
            </Form.Column>
            <Form.SectionTitleSmall>Featured image</Form.SectionTitleSmall>
            <Form.Column>
                <Field
                    name="featured_image"
                    id="featured_image"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
                <AppStatuses mlaLive homestore portal />
            </Form.Column>
            <Form.SectionTitleSmall>Main gallery</Form.SectionTitleSmall>
            <Form.Column>
                <FieldArray
                    name="main_gallery"
                    id="main_gallery"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
                <AppStatuses mlaLive portal />
            </Form.Column>
            <Form.SectionTitleSmall>Site plan image</Form.SectionTitleSmall>
            <Form.Column>
                <Field
                    name="site_plan_image"
                    id="site_plan_image"
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
                <AppStatuses mlaLive mlaBlue />
            </Form.Column>
            <Form.Row>
                <Field
                    name="location"
                    fieldProps={{
                        locationManualTogglelabel: 'Input address manually'
                    }}
                    fieldAttr={{ id: 'location' }}
                    component={Location}
                />
            </Form.Row>
            <AppStatuses mlaLive portal homestore mlaBlue />
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

ProjectForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create',
    isSlugRequired: false,
    form: null
};

ProjectForm.propTypes = {
    touch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isSlugRequired: PropTypes.bool,
    form: PropTypes.string
};

export default reduxForm({
    form: 'ProjectForm',
    enableReinitialize: true
})(ProjectForm);
