import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FieldArray, getFormValues, reduxForm } from 'redux-form';

import Form, { Repeater } from 'erpcore/components/Form';
import SessionProspectRepeatableItem from 'erpcore/screens/Sessions/components/SessionProspectsRepeatableItem';
import { getSingleSessionProspectsFetching } from 'erpcore/screens/Sessions/Sessions.selectors';

const SessionEditProspectsForm = ({
    handleSubmit,
    onSubmit,
    form,
    sessionFormName,
    onDeleteProspectSession,
    onUpdateProspectSession,
    onCreateProspectSession,
    onFinish
}) => {
    const fetchingSessionProspects = useSelector(state => getSingleSessionProspectsFetching(state));

    // this form values
    const formValues = useSelector(getFormValues(form));
    const { session_prospects: sessionProspects = [] } = { ...formValues };

    // other form values
    const sessionFormValues = useSelector(getFormValues(sessionFormName));
    const { connection_type: connectionType } = { ...sessionFormValues };

    const addUpdateItems = items => {
        const promises = [];

        if (Object.keys(items?.oldItemsDiff)?.length) {
            Object.keys(items?.oldItemsDiff).map(index => {
                const obj = { ...items?.oldItems[index], ...items?.oldItemsDiff?.[index] };
                return promises.push(onUpdateProspectSession(obj));
            });
        }

        if (items?.newItems?.length) {
            items.newItems.map(item => promises.push(onCreateProspectSession(item?.iri)));
        }

        return Promise.all(promises);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitle>
                {connectionType === 'one-to-many' ? 'Prospects' : 'Prospect'}
            </Form.SectionTitle>
            <Form.Row>
                <FieldArray
                    className="repeater--session-prospects"
                    name="session_prospects"
                    component={Repeater}
                    RepeatableItem={SessionProspectRepeatableItem}
                    canSave
                    data={{
                        form,
                        sessionProspectIris: sessionProspects.map(item => item.prospectIri)
                    }}
                    loading={fetchingSessionProspects}
                    onRemoveItem={item =>
                        onDeleteProspectSession(item?.itemData?.sessionProspectIri)
                    }
                    onSaveAll={items =>
                        addUpdateItems(items)
                            .catch(err => err)
                            .finally(() => {
                                onFinish();
                            })
                    }
                    saveButtonIntent="all"
                    canSort={false}
                    addNewLabel="Add prospect"
                    saveLabel="Save prospects to session"
                    deleteModalTitle="Remove Prospect"
                    deleteModalSubTitle="Are you sure you want to remove this prospect from session?"
                    uniqueIdentifier="sessionProspectIri"
                />
            </Form.Row>
        </Form>
    );
};

SessionEditProspectsForm.defaultProps = {
    onSubmit: () => {},
    form: null,
    sessionFormName: null,
    onDeleteProspectSession: () => {},
    onUpdateProspectSession: () => {},
    onCreateProspectSession: () => {},
    onFinish: () => {}
};

SessionEditProspectsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    form: PropTypes.string,
    sessionFormName: PropTypes.string,
    onDeleteProspectSession: PropTypes.func,
    onUpdateProspectSession: PropTypes.func,
    onCreateProspectSession: PropTypes.func,
    onFinish: () => {}
};

export default reduxForm({
    form: 'SessionEditProspectsForm',
    sessionFormName: 'SessionEditProspectsForm',
    enableReinitialize: true
})(SessionEditProspectsForm);
