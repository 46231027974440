import React from 'react';
import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import BlogCategoriesEditCreateForm from 'erpcore/screens/Settings/Blue/screens/BlogCategories/components/BlogCategoriesEditCreateForm';
import TableActions from 'erpcore/components/Listing/components/TableActions';
import Listing from 'erpcore/components/Listing';
import BlogCategoriesDelete from 'erpcore/screens/Settings/Blue/screens/BlogCategories/components/BlogCategoriesDelete';
import BlogCategoriesEdit from 'erpcore/screens/Settings/Blue/screens/BlogCategories/components/BlogCategoriesEdit';
import { actions as blogCategoriesActions } from 'erpcore/screens/Settings/Blue/screens/BlogCategories/BlogCategories.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import {
    getListingFetching,
    getListingResponse
} from 'erpcore/components/Listing/Listing.selectors';
import { reset } from 'redux-form';
import BlueTabs from 'erpcore/screens/Settings/Blue/components/BlueTabs';

const BlogCategories = () => {
    const title = 'MLA Blue App - FAQ Categories';
    const reducerName = 'blogCategories';
    const actionName = 'BLOG_CATEGORIES';
    const listing = useSelector(state => getListingResponse(state, reducerName));
    const listingFetching = useSelector(state => getListingFetching(state, reducerName));
    const dispatch = useDispatch();

    const fetchBlogCategories = params => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: listingActions.START_FETCHING_LISTING,
                params,
                entity: actionName,
                name: reducerName,
                endpoint: 'api/blog-categories'
            });
        }).catch(error => {
            return error;
        });
    };

    const onSubmit = formData => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: blogCategoriesActions.START_CREATE_BLOG_CATEGORIE,
                promise: { resolve, reject },
                formData
            });
        }).then(() => {
            dispatch(reset('BlogCategoriesEditCreateForm'));
        });
    };

    const tableData = () => {
        const table = {};
        table.data = [];

        // Table Schema
        table.schema = [
            {
                title: 'FAQ Categories',
                field: 'name',
                sortable: false,
                mobile: 'title'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        table.defaultSort = {
            order: 'DESC',
            sortable: 'id'
        };

        if (listing && listing.data) {
            listing.data.map(row => {
                const { name } = { ...row };

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    name: name || '',
                    actions: (
                        <TableActions>
                            <TableActions.Action>
                                <BlogCategoriesEdit data={row} />
                            </TableActions.Action>
                            <TableActions.Action>
                                <BlogCategoriesDelete
                                    iri={row?.iri}
                                    form={`BlogCategoriesDelete-${row?.id}`}
                                />
                            </TableActions.Action>
                        </TableActions>
                    )
                });
            });
        }

        return table;
    };

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <BlueTabs />
            <PageContent>
                <BlogCategoriesEditCreateForm onSubmit={onSubmit} />
                <Listing
                    name={reducerName}
                    hideHeader
                    reducerName={reducerName}
                    loading={listingFetching}
                    meta={listing?.meta}
                    table={tableData()}
                    onListingConfigUpdate={params => fetchBlogCategories(params)}
                />
            </PageContent>
        </LayoutManager>
    );
};

export default BlogCategories;
