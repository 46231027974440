import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';

import Form, {
    Media,
    Repeater,
    Text,
    TextEditor,
    DateTime,
    Textarea,
    Switch
} from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const RepeatableItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.company_name`}
                    fieldProps={{
                        label: 'Company Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.company_name`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.offer`}
                    fieldProps={{
                        label: 'Offer',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.offer`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.short_description`}
                    fieldProps={{
                        label: 'Short Description',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.short_description`, required: true }}
                    component={Textarea}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.long_description`}
                    fieldProps={{
                        label: 'Long Description',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.long_description`, required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.SectionTitle>Logo Image</Form.SectionTitle>
            <Form.Row>
                <FieldArray
                    name={`${member}.logo`}
                    id={`${member}.logo`}
                    component={Media}
                    useLightbox
                    maxImageCount={1}
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
        </div>
    );
};

RepeatableItem.defaultProps = {
    member: null
};

RepeatableItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const RepeatableItemSteps = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.step`}
                    fieldProps={{
                        label: 'Step',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.step`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

RepeatableItemSteps.defaultProps = {
    member: null
};

RepeatableItemSteps.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const PortalChecklistItemsForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="item"
                        id="item"
                        fieldProps={{
                            label: 'Item',
                            clearable: true
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="required"
                        fieldProps={{
                            label: 'Required',
                            clearable: true,
                            on: {
                                value: true
                            },
                            off: {
                                value: false
                            }
                        }}
                        fieldAttr={{ id: `required` }}
                        component={Switch}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="subtitle"
                        id="subtitle"
                        fieldProps={{
                            label: 'Subtitle',
                            clearable: true
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="date"
                        id="date"
                        fieldProps={{
                            label: 'Date',
                            clearable: true
                        }}
                        component={DateTime}
                    />
                </Form.Row>
                <Form.SectionTitle>Additional Checklist item steps</Form.SectionTitle>
                <p>First step will be checked when offer is requested.</p>
                <Form.Row>
                    <FieldArray
                        name="steps"
                        component={Repeater}
                        RepeatableItem={RepeatableItemSteps}
                        canSort={false}
                        canSortNewItems={false}
                        saveButtonIntent="all"
                        canSave={false}
                        uniqueIdentifier="iri"
                        addNewLabel="Add New Step"
                        canAddMultiple
                    />
                </Form.Row>
                <Form.SectionTitle>Offers</Form.SectionTitle>
                <Form.Row>
                    <FieldArray
                        name="offers"
                        component={Repeater}
                        RepeatableItem={RepeatableItem}
                        canSort={false}
                        canSortNewItems={false}
                        saveButtonIntent="all"
                        canSave={false}
                        uniqueIdentifier="iri"
                        addNewLabel="Add New Offer"
                        canAddMultiple
                    />
                </Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form>
        </>
    );
};

PortalChecklistItemsForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    pristine: false,
    invalid: false
};

PortalChecklistItemsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'PortalChecklistItemsForm',
    enableReinitialize: true
})(PortalChecklistItemsForm);
