import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'erpcore/screens/Users/Users.selectors';
import { useRouteMatch } from 'react-router-dom';
import ElementLoader from 'erpcore/components/ElementLoader';
import PageContent from 'erpcore/components/Layout/PageContent';
import Card from 'erpcore/components/Layout/Card';
import ClientAddProjectForm from 'erpcore/screens/Users/components/Client/components/ClientAddProjectForm';
import { reset } from 'redux-form';
import Tooltip from 'erpcore/components/Tooltip';
import { formatDate } from 'erpcore/utils/utils';
import Button from 'erpcore/components/Button';
import ClientAddUnitForm from 'erpcore/screens/Users/components/Client/components/ClientAddUnitForm';
import ClientEditValidUntilForm from 'erpcore/screens/Users/components/Client/components/ClientEditValidUntilForm';
import Modal from 'erpcore/components/Modal';

const dateInPast = (firstDate, secondDate) => {
    if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
        return true;
    }
    return false;
};

function dateWithMonthsDelay(months) {
    const date = new Date();
    date.setMonth(date.getMonth() + months);

    return date;
}

const Client = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const userIri = `/api/users/${match?.params?.id}`;
    const userData = useSelector(state => getUserData(state, userIri)) || {};
    const [clientProjects, setClientProjects] = useState({});
    const [clientUnits, setClientUnits] = useState({});
    const [fetchingClient, setFetchingClient] = useState(false);
    const [editValidUntil, setEditValidUntil] = useState(false);
    const isClient = useMemo(() => userData?._type === 'client', [userData]);

    const fetchClientData = useCallback(() => {
        setFetchingClient(true);
        axios
            .all([
                restClient
                    .get(
                        `/api/client-assigned-projects?filters[client][equals]=${userIri}&include=project`,
                        {
                            headers: {
                                Accept: ' application/vnd.api+json'
                            }
                        }
                    )
                    .then(response => {
                        setClientProjects(dto(response?.data));
                    }),
                restClient
                    .get(
                        `/api/client-assigned-units?filters[client][equals]=${userIri}&include=unit,unit.project`,
                        {
                            headers: {
                                Accept: ' application/vnd.api+json'
                            }
                        }
                    )
                    .then(response => {
                        setClientUnits(dto(response?.data));
                    })
            ])
            .then(() => {
                setFetchingClient(false);
            });
    }, [setClientUnits, setClientProjects]);

    useEffect(() => {
        if (isClient) {
            fetchClientData();
        }
    }, [isClient]);

    const removeClientAssignment = useCallback(iri => {
        setFetchingClient(true);
        restClient.delete(iri).then(() => {
            fetchClientData();
        });
    }, []);

    const addClientAssignment = useCallback(
        (url, data) => {
            setFetchingClient(true);
            data.client = userIri;
            restClient.post(url, data).then(() => {
                fetchClientData();
            });
        },
        [userIri]
    );

    const informClient = useCallback(() => {
        restClient
            .post(
                `${userIri}/client-inform`,
                {},
                {
                    headers: {
                        'content-type': 'application/json'
                    }
                }
            )
            .then(() => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'client.mail.success' }
                });
            })
            .catch(() => {
                dispatch({
                    type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                    response: { code: 'client.mail.error' }
                });
            });
    }, [userIri]);

    const updateValidUntil = useCallback(
        formData =>
            new Promise(() => {
                const { valid_until: validUntil, iri } = { ...formData };
                return restClient
                    .put(iri, {
                        valid_until: validUntil
                    })
                    .then(() => {
                        setEditValidUntil();
                        fetchClientData();
                    });
            }),
        []
    );

    return (
        <div className="user-permissions__client">
            {fetchingClient === true && <ElementLoader overlay />}
            <PageContent.Section>
                <PageContent.Title>Client settings</PageContent.Title>
                <div className="user-permissions__client-column">
                    <Card>
                        <Card.Title>Project assignment</Card.Title>
                        <br />
                        <ClientAddProjectForm
                            initialValues={{
                                valid_until: dateWithMonthsDelay(1)
                            }}
                            disabled={fetchingClient}
                            onSubmit={formData => {
                                if (
                                    clientProjects?.data?.filter(
                                        clientAssignment =>
                                            clientAssignment?.project?.iri === formData?.project
                                    ).length > 0
                                ) {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: { code: 'client.already-assigned' }
                                    });
                                } else {
                                    addClientAssignment('/api/client-assigned-projects', formData);
                                    dispatch(reset('ClientAddProjectForm'));
                                }
                            }}
                        />
                        {clientProjects?.data?.map(assignment => {
                            const { valid_until: validUntil, iri } = { ...assignment };
                            return (
                                <div
                                    className="user-permissions__client-assignment"
                                    key={assignment?.iri}
                                >
                                    {assignment?.project?.name}
                                    <div>
                                        <Tooltip
                                            content={`Valid until ${formatDate(validUntil)}`}
                                            className={
                                                dateInPast(new Date(validUntil), new Date())
                                                    ? 'user-permissions__red'
                                                    : ''
                                            }
                                        >
                                            <Button
                                                variation="link"
                                                className="user-permissions__valid-until"
                                                label={formatDate(validUntil)}
                                                onClick={() =>
                                                    setEditValidUntil({
                                                        iri,
                                                        valid_until: validUntil
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                        <Button
                                            size="small"
                                            variation="link"
                                            iconName="remove"
                                            labelOnlyAria
                                            onClick={() => removeClientAssignment(assignment?.iri)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <Button
                            variation="link"
                            size="small"
                            label="Inform client"
                            onClick={() => informClient()}
                        />
                    </Card>
                </div>
                <div className="user-permissions__client-column">
                    <Card>
                        <Card.Title>Unit assignment</Card.Title>
                        <br />
                        <ClientAddUnitForm
                            initialValues={{
                                valid_until: dateWithMonthsDelay(1)
                            }}
                            disabled={fetchingClient}
                            onSubmit={formData => {
                                if (
                                    clientUnits?.data?.filter(
                                        clientAssignment =>
                                            clientAssignment?.unit?.iri === formData?.unit
                                    ).length > 0
                                ) {
                                    dispatch({
                                        type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
                                        response: { code: 'client.already-assigned' }
                                    });
                                } else {
                                    addClientAssignment('/api/client-assigned-units', formData);
                                    dispatch(reset('ClientAddUnitsForm'));
                                }
                            }}
                        />
                        {clientUnits?.data?.map(assignment => {
                            const { valid_until: validUntil, iri } = { ...assignment };
                            return (
                                <div
                                    key={assignment?.iri}
                                    className="user-permissions__client-assignment"
                                >
                                    {assignment?.unit?.name} - {assignment?.unit?.project?.name}
                                    <div>
                                        <Tooltip
                                            content={`Valid until ${formatDate(validUntil)}`}
                                            className={
                                                dateInPast(new Date(validUntil), new Date())
                                                    ? 'user-permissions__red'
                                                    : ''
                                            }
                                        >
                                            <Button
                                                variation="link"
                                                className="user-permissions__valid-until"
                                                label={formatDate(validUntil)}
                                                onClick={() =>
                                                    setEditValidUntil({
                                                        iri,
                                                        valid_until: validUntil
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                        <Button
                                            size="small"
                                            variation="link"
                                            iconName="remove"
                                            labelOnlyAria
                                            onClick={() => removeClientAssignment(assignment?.iri)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <Button
                            variation="link"
                            size="small"
                            label="Inform client"
                            onClick={() => informClient()}
                        />
                    </Card>
                </div>
            </PageContent.Section>
            <Modal
                title="Valid Until"
                variation="small"
                onClose={() => setEditValidUntil(false)}
                opened={editValidUntil}
            >
                <ClientEditValidUntilForm
                    onSubmit={updateValidUntil}
                    initialValues={editValidUntil}
                />
            </Modal>
        </div>
    );
};

Client.defaultProps = {};

Client.propTypes = {};

export default Client;
