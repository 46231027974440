export const actions = {
    START_FETCHING_DEALS: 'START_FETCHING_DEALS',
    FETCHING_SUCCESSFUL_DEALS: 'FETCHING_SUCCESSFUL_DEALS',
    FETCHING_FAILED_DEALS: 'FETCHING_FAILED_DEALS',

    START_CREATE_DEAL: 'START_CREATE_DEAL',
    CREATE_DEAL_SUCCESFUL: 'CREATE_DEAL_SUCCESFUL',
    CREATE_DEAL_FAILED: 'CREATE_DEAL_FAILED',

    START_UPDATE_DEAL: 'START_UPDATE_DEAL',
    UPDATE_DEAL_SUCCESSFUL: 'UPDATE_DEAL_SUCCESSFUL',
    UPDATE_DEAL_FAILED: 'UPDATE_DEAL_FAILED',

    START_FETCHING_DEAL: 'START_FETCHING_DEAL',
    FETCHING_DEAL_SUCCESSFUL: 'FETCHING_DEAL_SUCCESSFUL',
    FETCHING_DEAL_FAILED: 'FETCHING_DEAL_FAILED',

    STORE_DEAL_DATA: 'STORE_DEAL_DATA',

    START_DELETE_DEAL: 'START_DELETE_DEAL',
    DELETE_DEAL_SUCCESSFUL: 'DELETE_DEAL_SUCCESSFUL',
    DELETE_DEAL_FAILED: 'DELETE_DEAL_FAILED',

    START_FETCHING_DEAL_QA: 'START_FETCHING_DEAL_QA',
    FETCH_DEAL_QA_SUCCESSFUL: 'FETCH_DEAL_QA_SUCCESSFUL',
    FETCH_DEAL_QA_FAILED: 'FETCH_DEAL_QA_FAILED',

    SET_DEAL_QUESTION_COUNT: 'SET_DEAL_QUESTION_COUNT',

    START_FETCHING_DEAL_SESSION: 'START_FETCHING_DEAL_SESSION',
    FETCHING_DEAL_SESSION_SUCCESSFUL: 'FETCHING_DEAL_SESSION_SUCCESSFUL',
    FETCHING_DEAL_SESSION_FAILED: 'FETCHING_DEAL_SESSION_FAILED',

    START_SUBMIT_MESSAGE: 'START_SUBMIT_MESSAGE',
    SUBMIT_MESSAGE_SUCCESS: 'SUBMIT_MESSAGE_SUCCESS',
    SUBMIT_MESSAGE_FAILED: 'SUBMIT_MESSAGE_FAILED'
};

export const initialState = {
    listingFetching: false,
    listingResponse: {
        data: [],
        meta: {},
        included: []
    },
    listingError: [],
    singleFetching: false,
    dealQAFetching: false,
    dealQA: null,
    dealQuestionCount: 0
};

export default (state = initialState, { type, response, iri }) => {
    switch (type) {
        case actions.START_FETCHING_DEALS: {
            return {
                ...state,
                listingFetching: true
            };
        }
        case actions.FETCHING_SUCCESSFUL_DEALS: {
            return {
                ...state,
                listingFetching: false,
                listingResponse: response
            };
        }
        case actions.FETCHING_FAILED_DEALS: {
            return {
                ...state,
                listingFetching: false,
                listingError: response
            };
        }
        case actions.START_FETCHING_DEAL: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.START_FETCHING_UNIT_SETTINGS: {
            return {
                ...state,
                singleFetching: true
            };
        }
        case actions.FETCH_UNIT_SETTINGS_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false,
                [iri]: response
            };
        }
        case actions.FETCH_UNIT_SETTINGS_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_DEAL_SUCCESSFUL: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.FETCHING_DEAL_FAILED: {
            return {
                ...state,
                singleFetching: false
            };
        }
        case actions.STORE_DEAL_DATA: {
            return {
                ...state,
                [iri]: response.data
            };
        }
        case actions.START_FETCHING_PROSPECT_QA: {
            return {
                ...state,
                dealQAFetching: true
            };
        }
        case actions.FETCH_DEAL_QA_SUCCESSFUL: {
            return {
                ...state,
                dealQAFetching: false,
                dealQA: { ...state?.dealQA, [iri]: response }
            };
        }
        case actions.FETCH_DEAL_QA_FAILED: {
            return {
                ...state,
                dealQAFetching: false
            };
        }
        case actions.SET_DEAL_QUESTION_COUNT: {
            return {
                ...state,
                dealQuestionCount: response
            };
        }
        case actions.START_FETCHING_DEAL_SESSION: {
            return {
                ...state,
                dealSessionFetching: true
            };
        }
        case actions.FETCHING_DEAL_SESSION_SUCCESSFUL: {
            return {
                ...state,
                dealSessionFetching: false
            };
        }
        case actions.FETCHING_DEAL_SESSION_FAILED: {
            return {
                ...state,
                dealSessionFetching: false
            };
        }
        default:
            return state;
    }
};
