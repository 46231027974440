import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { reset, SubmissionError } from 'redux-form';

import LayoutManager from 'erpcore/utils/LayoutManager';
import PageNotifications from 'erpcore/components/Layout/PageNotifications';
import ClientCreateEditForm from 'erpcore/screens/Clients/components/ClientCreateEditForm';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import { actions as clientsActions } from 'erpcore/screens/Clients/Clients.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';

import { setLocationData } from 'erpcore/components/Form/components/Location/dto';
import { getIdFromIri } from 'erpcore/utils/dto';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';

const ClientCreate = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const title = 'Add new client';

    const onSubmitCreateClientForm = formData => {
        const { location } = { ...formData };

        if (location) {
            formData = setLocationData('location', formData);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                type: clientsActions.START_CREATE_CLIENT,
                promise: { resolve, reject },
                formData
            });
        })
            .then(response => {
                if (response?.data?.id) {
                    history.push(`/clients/${getIdFromIri(response?.data?.id)}/edit/general-info`);
                } else {
                    dispatch(reset('ClientCreateForm'));
                }
            })
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    return (
        <LayoutManager className="main--narrow" slot="main" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageNotifications />
            <PageContent>
                <ClientCreateEditForm
                    form="ClientCreateForm"
                    onSubmit={onSubmitCreateClientForm}
                    submitLabel="Create"
                />
            </PageContent>
        </LayoutManager>
    );
};

export default ClientCreate;
