export const actions = {
    START_SIGN_IN: 'START_SIGN_IN',
    START_SIGN_IN_WITH_TOKEN: 'START_SIGN_IN_WITH_TOKEN',
    SIGN_IN_SUCCESSFUL: 'SIGN_IN_SUCCESSFUL',
    SIGN_IN_FAILED: 'SIGN_IN_FAILED',
    START_SIGN_OUT: 'START_SIGN_OUT',
    SIGN_OUT_SUCCESSFUL: 'SIGN_OUT_SUCCESSFUL',
    STORE_USER_DATA: 'STORE_USER_DATA',
    UPDATE_USER_DATA: 'UPDATE_USER_DATA',
    START_FETCHING_ME: 'START_FETCHING_ME',
    FETCHING_ME_SUCCESSFULL: 'FETCHING_ME_SUCCESSFULL',
    FETCHING_ME_FAILED: 'FETCHING_ME_FAILED',
    START_FETCHING_ME_PERMISSIONS: 'START_FETCHING_ME_PERMISSIONS',
    FETCHING_ME_PERMISSIONS_SUCCESSFULL: 'FETCHING_ME_PERMISSIONS_SUCCESSFULL',
    FETCHING_ME_PERMISSIONS_FAILED: 'FETCHING_ME_PERMISSIONS_FAILED'
};

export const initialState = {
    token: '',
    user: null,
    isSignedIn: false,
    fetchingMe: false
};

export default (state = initialState, { type, response }) => {
    switch (type) {
        case actions.START_FETCHING_ME: {
            return {
                ...state,
                fetchingMe: true
            };
        }
        case actions.FETCHING_ME_SUCCESSFULL:
        case actions.FETCHING_ME_FAILED: {
            return {
                ...state,
                fetchingMe: false
            };
        }
        case actions.START_SIGN_IN: {
            return {
                ...state
            };
        }
        case actions.SIGN_IN_SUCCESSFUL: {
            return {
                ...state,
                token: response
            };
        }
        case actions.SIGN_IN_FAILED: {
            return {
                ...state,
                token: '',
                isSignedIn: false
            };
        }
        case actions.STORE_USER_DATA: {
            return {
                ...state,
                user: response?.data,
                userOrganization: response?.data?.organization,
                isSignedIn: true
            };
        }
        case actions.UPDATE_USER_DATA: {
            const { data: responseData } = { ...response };
            // If response.user doesn't contain country/city/state properties, create them and set them to null so that they can overwrite old state properties.
            // We do this because we are merging old user and new user state.
            // We don't do this on other places because on other places we overwrite state instead of merging.
            // Merging is done here because of permissions...
            if (!('country' in responseData)) {
                responseData.country = null;
            }
            if (!('city' in responseData)) {
                responseData.city = null;
            }
            if (!('state' in responseData)) {
                responseData.state = null;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    ...responseData
                }
            };
        }
        default:
            return state;
    }
};
