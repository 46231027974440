import React from 'react';
import { Field } from 'redux-form';
import Form, { TextEditor, Text } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

const OIAccessHelpRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title`, required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.content`}
                    fieldProps={{ label: 'Content' }}
                    fieldAttr={{ id: `${member}.content`, required: true }}
                    component={TextEditor}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
        </div>
    );
};

OIAccessHelpRepeaterItem.defaultProps = {
    member: null
};

OIAccessHelpRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default OIAccessHelpRepeaterItem;
