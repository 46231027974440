import { createSelector } from 'reselect';

export const getSessionDataFetching = state =>
    state.sessionReport?.fetchingReportSessionData || false;

export const getTotalsSessionData = state => state.sessionReport?.totalSessions || 0;

export const getPostDemoViewSessionData = state => state.sessionReport?.postDemoViews || 0;

export default createSelector([
    getSessionDataFetching,
    getTotalsSessionData,
    getPostDemoViewSessionData
]);
