import React from 'react';
import { withRouter } from 'react-router-dom';
import Tabs from 'erpcore/components/Tabs';
import { useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { hasPermission } from 'erpcore/utils/RolesManager';

const SessionTabs = () => {
    const meData = useSelector(getMeData) || {};
    const links = [];

    if (hasPermission('CAN_MANAGE_COMMON_SESSION', meData)) {
        links.push(
            {
                link: `/settings/mla-live/session/sales-guidelines`,
                label: 'Sales guidelines'
            },
            {
                link: `/settings/mla-live/session/prospect-profile-questions`,
                label: 'Prospect profile questions'
            }
        );
    }

    return <Tabs links={links} />;
};

export default withRouter(SessionTabs);
