import React, { useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import copy from 'copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import moment from 'moment-timezone';
import LayoutManager from 'erpcore/utils/LayoutManager';
import ElementLoader from 'erpcore/components/ElementLoader';
import Svg from 'erpcore/components/Svg';
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import ButtonDropdown from 'erpcore/components/ButtonDropdown';
import Card from 'erpcore/components/Layout/Card';
import Button from 'erpcore/components/Button';
import ProgressBar from 'erpcore/components/ProgressBar';
import Collapse from 'erpcore/components/Collapse';
import Tooltip from 'erpcore/components/Tooltip';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import { actions as sessionActions } from 'erpcore/screens/Sessions/Sessions.reducer';
import {
    getSessionsData,
    getSingleSessionFetching,
    getSessionProspects,
    getSessionProspectQA,
    getSessionProspectQuestionCount,
    getSessionChat
} from 'erpcore/screens/Sessions/Sessions.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import './SessionView.scss';

const SessionEdit = () => {
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const demoUrl = enviromentVariables.DEMO_APP_URL;
    const sessionIri = `/api/sessions/${match?.params?.id}`;
    const sessionData = useSelector(state => getSessionsData(state, sessionIri)) || {};
    const fetching = useSelector(state => getSingleSessionFetching(state));
    const sessionProspects = useSelector(state => getSessionProspects(state, sessionIri)) || [];
    const sessionProspectQA = uniqBy(
        useSelector(state => getSessionProspectQA(state, sessionIri)) || [],
        e => {
            return e.question?.question;
        }
    );
    const sessionChat = useSelector(state => getSessionChat(state, sessionIri)) || [];
    const sessionProspectQuestionCount =
        useSelector(state => getSessionProspectQuestionCount(state)) || 0;
    const [loading, setLoading] = useState([]);
    const title = 'Session';
    const sessionFinished = sessionData?.duration;
    let sessionHasNonAttendees = false;

    const {
        timezone,
        date,
        sales_agent: salesAgent = {},
        guest_participants_enabled: guestParticipantsEnabled,
        guest_hash: guestHash,
        spectators_enabled: spectatorsEnabled,
        spectator_hash: spectatorHash,
        deal = {}
    } = {
        ...sessionData
    };
    const { unit_type: unitType, notes = [], prospects } = { ...deal };

    const prospectData = sessionProspects?.map(sessionProspect => {
        const {
            id,
            prospect,
            has_attended: hasAttended,
            invitation_sent: invitationSent,
            recap_sent: recapSent,
            documents,
            nr_of_views: numberOfViews,
            recap_expired: recapExpired,
            recap_expires_at: recapExpiresAt,
            non_attendance_sent: nonAttendanceSent
        } = sessionProspect;
        const { first_name: firstName, last_name: lastName } = prospect || {};

        if (!hasAttended) sessionHasNonAttendees = true;

        return {
            id,
            prospectIri: sessionProspect?.prospect?.iri,
            sessionProspectIri: sessionProspect?.iri,
            name: `${firstName || ''} ${lastName || ''}`,
            hash: sessionProspect?.hash,
            hasAttended,
            invitationSent,
            recapSent,
            documents,
            numberOfViews,
            recapExpired,
            recapExpiresAt,
            nonAttendanceSent
        };
    });
    const attendedProspects = prospectData.filter(prospect => prospect.hasAttended);

    const clearedGuests = [];
    //  Filtering out duplicates
    if (sessionData?.guests?.length > 0) {
        sessionData.guests.forEach(item => {
            const existing = clearedGuests.filter(v => {
                return v.email === item.email;
            });
            if (!existing.length) {
                if (typeof item.value === 'string') item.value = [item.value];
                clearedGuests.push(item);
            }
        });
    }

    const guests = clearedGuests?.filter(prospect => prospect._type === 'guest');
    const audience = clearedGuests?.filter(prospect => prospect._type === 'audience');

    const fetchSessionProspectQAData = prospectIri => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_FETCHING_SESSION_PROSPECT_QA,
                prospectIri,
                sessionIri
            });
        }).catch(error => ({ error }));
    };

    const fetchSessionChat = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_FETCHING_SESSION_CHAT,
                sessionIri
            });
        }).catch(error => ({ error }));
    };

    const fetchSessionData = () => {
        const params = {
            include:
                'sessionProspects,salesAgent,projects,sessionProspects.prospect,sessionProspects.notes,guests,deal,deal.project,deal.unit,deal.unitType,deal.prospect,timezone'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_FETCHING_SINGLE_SESSION,
                iri: sessionIri,
                params
            });
        })
            .then(response => {
                const { session_prospects: sessionProspect } = { ...response.data };
                const { iri } = { ...sessionProspect[0].prospect };
                if (sessionProspect?.length === 1) {
                    fetchSessionProspectQAData(iri);
                }
            })
            .catch(error => ({ error }));
    };

    /*
     * Effects
     */
    useEffect(() => {
        fetchSessionData();
        fetchSessionChat();
    }, []);

    const dropdownActions = [];

    if (sessionFinished) {
        dropdownActions.push({
            id: 'send-post-demo-email-to-all',
            label: 'Send post-demo email',
            onClick: () =>
                new Promise((resolve, reject) => {
                    setLoading([...loading, 'dropdown-action']);
                    dispatch({
                        promise: { resolve, reject },
                        type: sessionActions.START_RESEND_SESSION_POST_DEMO_MAIL,
                        iri: sessionIri,
                        all: true
                    });
                })
                    .then(() => fetchSessionData())
                    .catch(error => ({ error }))
                    .finally(() => setLoading([]))
        });
        if (sessionHasNonAttendees) {
            dropdownActions.push({
                id: 'send-non-attendee-email-to-all',
                label: 'Send non-attendee email',
                onClick: () =>
                    new Promise((resolve, reject) => {
                        setLoading([...loading, 'dropdown-action']);
                        dispatch({
                            promise: { resolve, reject },
                            type: sessionActions.START_RESEND_SESSION_NON_ATTENDEE_MAIL,
                            iri: sessionIri,
                            all: true
                        });
                    })
                        .then(() => fetchSessionData())
                        .catch(error => ({ error }))
                        .finally(() => setLoading([]))
            });
        }
    } else {
        dropdownActions.push({
            id: 'send-invitation-to-all',
            label: 'Send invitation',
            onClick: () =>
                new Promise((resolve, reject) => {
                    setLoading([...loading, 'dropdown-action']);
                    dispatch({
                        promise: { resolve, reject },
                        type: sessionActions.START_RESEND_SESSION_INVITATION,
                        iri: sessionIri,
                        all: true
                    });
                })
                    .then(() => fetchSessionData())
                    .catch(error => ({ error }))
                    .finally(() => setLoading([]))
        });
    }

    const getProjectByIri = projectIri =>
        sessionData?.projects?.filter(project => project.iri === projectIri)[0] || {};

    const renderInfoBox = () => (
        <Card>
            <div className="session-view__info-box">
                <div className="session-view__data">
                    <span className="session-view__data-header">Session Date</span>
                    <span className="session-view__data-body">
                        {moment(date).format('MMM Do, YYYY, h:mm a ') || ''}
                        {timezone && (
                            <Tooltip content={timezone.title}>
                                {moment()
                                    .tz(timezone.name)
                                    .format('z')}
                            </Tooltip>
                        )}
                    </span>
                </div>
                <div className="session-view__data">
                    <span className="session-view__data-header">Sales Agent</span>
                    <span className="session-view__data-body">
                        {salesAgent.first_name} {salesAgent.last_name}
                    </span>
                </div>
            </div>
        </Card>
    );

    const adminPanelUrl = `${demoUrl}/session/admin/${match?.params?.id}`;
    const adminPresentationUrl = `${demoUrl}/session/presentation/${match?.params?.id}`;
    const guestsUrl = `${demoUrl}/session/guest/${guestHash}`;
    const audienceUrl = `${demoUrl}/session/spectator/${spectatorHash}`;

    const resendInvitation = prospect =>
        new Promise((resolve, reject) => {
            setLoading([...loading, prospect.sessionProspectIri]);
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_RESEND_SESSION_INVITATION,
                iri: prospect.sessionProspectIri
            });
        })
            .then(() => fetchSessionData())
            .catch(error => ({ error }))
            .finally(() => setLoading([]));

    const resendPostDemoMail = prospect =>
        new Promise((resolve, reject) => {
            setLoading([...loading, prospect.sessionProspectIri]);
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_RESEND_SESSION_POST_DEMO_MAIL,
                iri: prospect.sessionProspectIri
            });
        })
            .then(() => fetchSessionData())
            .catch(error => ({ error }))
            .finally(() => setLoading([]));

    const copyLiveDemoLink = prospect => {
        const demoUrlLive = `${demoUrl}/session/prospect/${prospect?.hash}`;
        copy(demoUrlLive);
        dispatch({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'session.linkSuccessCopied' }
        });
    };

    const copyPostDemoLink = prospect => {
        const demoUrlPostPresentation = `${demoUrl}/presentation/${prospect?.hash}`;
        copy(demoUrlPostPresentation);
        dispatch({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: { code: 'session.linkSuccessCopied' }
        });
    };

    const resendNonAttendeeEmail = prospect =>
        new Promise((resolve, reject) => {
            setLoading([...loading, prospect.sessionProspectIri]);
            dispatch({
                promise: { resolve, reject },
                type: sessionActions.START_RESEND_SESSION_NON_ATTENDEE_MAIL,
                iri: prospect.sessionProspectIri
            });
        })
            .then(() => fetchSessionData())
            .catch(error => ({ error }))
            .finally(() => setLoading([]));

    const renderSessionProspectInfo = () => (
        <Card>
            <Card.Title>Session Info</Card.Title>
            <div className="session-view__items">
                <span className="session-view__label">Admin panel</span>
                <span className="session-view__value">
                    <a href={adminPanelUrl} target="_blank" rel="noopener noreferrer">
                        {adminPanelUrl}
                    </a>
                </span>
                <span className="session-view__label">Admin Presentation</span>
                <span className="session-view__value">
                    <a href={adminPresentationUrl} target="_blank" rel="noopener noreferrer">
                        {adminPresentationUrl}
                    </a>
                </span>

                {!!guestParticipantsEnabled && (
                    <>
                        <span className="session-view__label">Guests URL</span>
                        <span className="session-view__value">
                            <a href={guestsUrl} target="_blank" rel="noopener noreferrer">
                                {guestsUrl}
                            </a>
                        </span>
                    </>
                )}
                {!!spectatorsEnabled && (
                    <>
                        <span className="session-view__label">Stream URL</span>
                        <span className="session-view__value">
                            <a href={audienceUrl} target="_blank" rel="noopener noreferrer">
                                {audienceUrl}
                            </a>
                        </span>
                    </>
                )}
            </div>
            {guests?.length > 0 && (
                <div className="session-view__guest-list">
                    <Collapse initiallyExpanded>
                        <Collapse.Visible>
                            <Card.Title>Guests</Card.Title>
                        </Collapse.Visible>
                        <Collapse.Expandable>
                            {guests?.map(guest => (
                                <p key={`guest-${guest.email}`} className="session-view__guest">
                                    <strong>
                                        {guest.first_name || ''} {guest.last_name || ''}
                                    </strong>
                                    <span className="session-view__guest-email">{guest.email}</span>
                                </p>
                            ))}
                        </Collapse.Expandable>
                    </Collapse>
                </div>
            )}
            {audience?.length > 0 && (
                <div className="session-view__guest-list">
                    <Collapse initiallyExpanded>
                        <Collapse.Visible>
                            <Card.Title>Audience</Card.Title>
                        </Collapse.Visible>
                        <Collapse.Expandable>
                            {audience?.map(guest => (
                                <p key={`audience-${guest.email}`} className="session-view__guest">
                                    <strong>
                                        {guest.first_name || ''} {guest.last_name || ''}
                                    </strong>
                                    <span className="session-view__guest-email">{guest.email}</span>
                                </p>
                            ))}
                        </Collapse.Expandable>
                    </Collapse>
                </div>
            )}
            {prospects?.length > 0 && (
                <>
                    <Card.Title>Prospects</Card.Title>
                    {orderBy(prospectData, ['id']).map((prospect, index) => (
                        <div
                            className="session-view__prospect"
                            key={`prospect-${prospect.sessionProspectIri}`}
                        >
                            {loading.includes(prospect.sessionProspectIri) === true && (
                                <ElementLoader overlay />
                            )}
                            <Collapse initiallyExpanded={index === 0}>
                                <Collapse.Visible>
                                    <Card.Title>
                                        {prospect.name}
                                        {!!sessionFinished && (
                                            <>
                                                {prospect.hasAttended === true ? (
                                                    <span className="session-view__icon session-view__icon--apple session-view__prospect-attended">
                                                        <Tooltip content="Attended">
                                                            <Svg icon="checkmarkRound" />
                                                        </Tooltip>
                                                    </span>
                                                ) : (
                                                    <span className="session-view__icon session-view__icon--tomato session-view__prospect-attended">
                                                        <Tooltip content="Has Not Attended">
                                                            <Svg icon="deniedRound" />
                                                        </Tooltip>
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </Card.Title>
                                </Collapse.Visible>
                                <Collapse.Expandable>
                                    {!sessionFinished && (
                                        <div className="session-view__prospect-line">
                                            <div className="session-view__prospect-line-label">
                                                Live Demo{' '}
                                                <Svg
                                                    icon="mailLine"
                                                    className={`session-view__icon ${
                                                        prospect.invitationSent
                                                            ? ''
                                                            : 'session-view__icon--tomato'
                                                    }`}
                                                />
                                            </div>
                                            <div className="session-view__prospect-line-links">
                                                <Button
                                                    onClick={() => resendInvitation(prospect)}
                                                    variation="link"
                                                    label={
                                                        prospect.invitationSent
                                                            ? 'Resend Invitation'
                                                            : 'Send Invitation'
                                                    }
                                                />
                                                <Button
                                                    onClick={() => copyLiveDemoLink(prospect)}
                                                    variation="link"
                                                    label="Copy Link"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {sessionFinished && prospect.hasAttended === false && (
                                        <div className="session-view__prospect-line">
                                            <div className="session-view__prospect-line-label">
                                                Live Demo
                                            </div>
                                            <div className="session-view__prospect-line-links">
                                                <Button
                                                    onClick={() => resendNonAttendeeEmail(prospect)}
                                                    variation="link"
                                                    label={
                                                        prospect.nonAttendanceSent
                                                            ? 'Resend Non-Attendee email'
                                                            : 'Send Non-Attendee email'
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {!!sessionFinished && (
                                        <div className="session-view__prospect-line">
                                            {prospect.recapExpired ? (
                                                <>
                                                    <div className="session-view__prospect-line-label">
                                                        Post-Demo preview expired at:
                                                    </div>
                                                    <div className="session-view__prospect-line-links">
                                                        <strong>
                                                            {moment(prospect.recapExpiresAt).format(
                                                                'MMM Do, YYYY, h:mm a'
                                                            ) || ''}
                                                        </strong>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="session-view__prospect-line-label">
                                                        Post-Demo Preview{' '}
                                                        <Svg
                                                            icon="presentation"
                                                            className={`session-view__icon ${
                                                                prospect.recapSent
                                                                    ? ''
                                                                    : 'session-view__icon--tomato'
                                                            }`}
                                                        />
                                                    </div>
                                                    <div className="session-view__prospect-line-links">
                                                        <Button
                                                            onClick={() =>
                                                                resendPostDemoMail(prospect)
                                                            }
                                                            variation="link"
                                                            label={
                                                                prospect.recapSent
                                                                    ? 'Resend post-demo email'
                                                                    : 'Send post-demo email'
                                                            }
                                                        />
                                                        <Button
                                                            onClick={() =>
                                                                copyPostDemoLink(prospect)
                                                            }
                                                            variation="link"
                                                            label="Copy Link"
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div className="session-view__prospect-line-stats">
                                                <Svg
                                                    icon="passShow"
                                                    className="session-view__icon session-view__icon--denim"
                                                />{' '}
                                                {prospect.numberOfViews || 0}
                                            </div>
                                        </div>
                                    )}
                                </Collapse.Expandable>
                            </Collapse>
                        </div>
                    ))}
                </>
            )}
        </Card>
    );

    const renderSidebar = () => (
        <Card>
            <Collapse initiallyExpanded>
                <Collapse.Visible>
                    <Card.Title>Session Stats</Card.Title>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <div className="session-view__items">
                        <div className="session-view__columns">
                            <div className="session-view__column">
                                <span className="session-view__label">Prospect Attendance</span>
                                <span className="session-view__value">
                                    {attendedProspects.length}/{prospectData.length}
                                </span>
                            </div>
                            {guests.length > 0 && (
                                <div className="session-view__column">
                                    <span className="session-view__label">Guests</span>
                                    <span className="session-view__value">{guests.length}</span>
                                </div>
                            )}
                            {audience.length > 0 && (
                                <div className="session-view__column">
                                    <span className="session-view__label">Audience</span>
                                    <span className="session-view__value">{audience.length}</span>
                                </div>
                            )}
                        </div>
                        <span className="session-view__label">Session duration</span>
                        <span className="session-view__value">
                            {sessionData.duration > 0
                                ? moment
                                      .utc(sessionData.duration * 1000)
                                      .format('HH[h] mm[m] ss[s]')
                                : 'This session has not yet taken place'}
                        </span>
                    </div>
                </Collapse.Expandable>
            </Collapse>
            <Collapse initiallyExpanded>
                <Collapse.Visible>
                    <Card.Title>Deal</Card.Title>
                </Collapse.Visible>
                <Collapse.Expandable>
                    <div className="prospect-view__deals">
                        {Object.keys(deal).length === 0 && 'There are no deals currently'}
                        {Object.keys(deal).length > 0 && (
                            <div className="prospect-view__deal">
                                <div className="prospect-view__deal-header">
                                    <span className="prospect-view__deal-title">
                                        {deal?.name || ''}
                                    </span>
                                    <span className="prospect-view__deal-link">
                                        <Button
                                            size="small"
                                            href={`/deals/${deal?.id}/view`}
                                            variation="link"
                                            label="View"
                                        />
                                    </span>
                                </div>

                                <div className="prospect-view__deal-info">
                                    <span>{deal?.project?.name || ''}</span>
                                    <span>{deal?.unit?.name || ''}</span>
                                    <span>{unitType?.name || ''}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </Collapse.Expandable>
            </Collapse>
            {sessionProspects?.length === 1 && (
                <>
                    {sessionProspectQA.length > 1 && (
                        <Collapse initiallyExpanded>
                            <Collapse.Visible>
                                <Card.Title>Prospect Questions</Card.Title>
                            </Collapse.Visible>
                            <Collapse.Expandable>
                                <div className="session-view__prospect-questions">
                                    <span className="session-view__label">
                                        Prospect Questions Answered
                                    </span>
                                    <span className="prospect-view__value">
                                        <strong>
                                            {sessionProspectQA?.length}/
                                            {sessionProspectQuestionCount}
                                        </strong>
                                    </span>
                                    <ProgressBar
                                        percentage={
                                            (100 * sessionProspectQA?.length) /
                                            sessionProspectQuestionCount
                                        }
                                    ></ProgressBar>
                                    {sessionProspectQA.map(qa => (
                                        <React.Fragment key={`qa-${qa.iri}`}>
                                            <span className="session-view__label">
                                                {qa?.question?.question}
                                            </span>
                                            <div className="session-view__value">
                                                {qa?.answers?.map((answer, index) => (
                                                    <span
                                                        key={`answer-${index}`}
                                                        className="session-view__value-chip"
                                                    >
                                                        {answer}
                                                    </span>
                                                ))}
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </Collapse.Expandable>
                        </Collapse>
                    )}
                </>
            )}
            {notes.length > 0 && (
                <Collapse initiallyExpanded>
                    <Collapse.Visible>
                        <Card.Title>Notes</Card.Title>
                    </Collapse.Visible>
                    <Collapse.Expandable>
                        <div className="session-view__notes">
                            {notes?.map(note => (
                                <div
                                    key={`note-${note.date || date}`}
                                    className="session-view__note"
                                >
                                    <div className="session-view__note-info">
                                        <span>
                                            {note.date
                                                ? moment(note.date).format('MMM Do, YYYY, h:mm a')
                                                : moment(date).format('MMM Do, YYYY, h:mm a')}
                                        </span>
                                        {!!note.project && (
                                            <span> @ {getProjectByIri(note.project).name}</span>
                                        )}
                                    </div>
                                    <div
                                        className="session-view__note-comment"
                                        dangerouslySetInnerHTML={{ __html: note.comment }}
                                    />
                                </div>
                            ))}
                        </div>
                    </Collapse.Expandable>
                </Collapse>
            )}
            {!!sessionChat.length > 0 && (
                <Collapse initiallyExpanded>
                    <Collapse.Visible>
                        <Card.Title>Chat History</Card.Title>
                    </Collapse.Visible>
                    <Collapse.Expandable>
                        <div className="session-view__notes">
                            {orderBy(sessionChat, ['created_at'], ['asc']).map(message => (
                                <div
                                    key={`note-${message.created_at}`}
                                    className="session-view__note"
                                >
                                    <div className="session-view__note-info">
                                        <span>{message.created_by}</span>
                                        <span>
                                            {' '}
                                            @{' '}
                                            {moment(message.created_at).format(
                                                'MMM Do, YYYY, h:mm a'
                                            )}
                                        </span>
                                    </div>
                                    <div className="session-view__note-comment">
                                        {message.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Collapse.Expandable>
                </Collapse>
            )}
        </Card>
    );

    return (
        <LayoutManager slot="main" className="main--medium" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title}>
                <Button
                    href={`/sessions/${match?.params?.id}/edit`}
                    variation="tertiary"
                    label="Edit Session"
                />
                <ButtonDropdown placeholder="Actions" options={dropdownActions} />
            </PageHeader>
            <PageContent>
                {(fetching === true || loading.includes('dropdown-action') === true) && (
                    <PageLoader content />
                )}
                <div className="session-view">
                    <div className="session-view__header">{renderInfoBox()}</div>
                    <div className="session-view__content">
                        <div className="session-view__main">{renderSessionProspectInfo()}</div>
                        <div className="session-view__sidebar">{renderSidebar()}</div>
                    </div>
                </div>
            </PageContent>
        </LayoutManager>
    );
};

SessionEdit.defaultProps = {};

SessionEdit.propTypes = {};

export default SessionEdit;
