import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';
import HubSpotIntegrationClientKeysForm from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/components/HubSpotIntegrationClientKeysForm';

import { useDispatch, useSelector } from 'react-redux';
import { actions as hubspotIntegrationActions } from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/HubSpotIntegration.reducer';
import {
    isHubSpotFetching,
    getHubSpotIntegrationData
} from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/HubSpotIntegration.selectors';
import PageLoader from 'erpcore/components/PageLoader';
import { SubmissionError } from 'redux-form';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';

const clientKeysSettingsName = 'hubspot-integration';
const tokensSettingsName = 'hubspot-integration-tokens';

const HubSpotConnection = () => {
    const history = useHistory();
    const title = 'HubSpot integration';
    const dispatch = useDispatch();
    const isFetching = useSelector(isHubSpotFetching);

    const clientKeys = useSelector(state =>
        getHubSpotIntegrationData(state, clientKeysSettingsName)
    );
    const tokens = useSelector(state => getHubSpotIntegrationData(state, tokensSettingsName));

    const clientKeysInitialValues = {
        // eslint-disable-next-line camelcase
        client_id: clientKeys?.settings?.client_id,
        // eslint-disable-next-line camelcase
        client_secret: clientKeys?.settings?.client_secret
    };

    // eslint-disable-next-line camelcase
    const integrationHasSecret = // eslint-disable-next-line camelcase
        clientKeysInitialValues?.client_id && clientKeysInitialValues?.client_secret;
    // eslint-disable-next-line camelcase
    const integrationIsConnected = tokens?.settings?.access_token;

    const validateCode = () => {
        const { search, pathname } = history.location;
        const params = qs.parse(search, { ignoreQueryPrefix: true });
        if (params.code) {
            history.push(`${pathname}`);
            return new Promise((resolve, reject) => {
                dispatch({
                    type: hubspotIntegrationActions.START_VALIDATE_HUBSPOT,
                    promise: { resolve, reject },
                    code: params.code
                });
            }).catch(err => err);
        }
        return true;
    };

    const fetchClientKeys = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: hubspotIntegrationActions.START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS,
                promise: { resolve, reject },
                settingsName: clientKeysSettingsName
            });
        }).catch(err => err);
    };

    const fetchTokens = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: hubspotIntegrationActions.START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS,
                promise: { resolve, reject },
                settingsName: tokensSettingsName
            });
        }).catch(err => err);
    };

    const fetchAll = () =>
        fetchClientKeys().finally(() => {
            fetchTokens();
        });

    const connectToHubSpot = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: hubspotIntegrationActions.START_CONNECT_TO_HUBSPOT
            })
        )
            .then(fetchAll)
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    const onSubmitClientKeys = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                ...(clientKeys?.iri ? { iri: clientKeys.iri } : null), // if iri then update, else create
                type: hubspotIntegrationActions.START_UPDATE_HUBSPOT_INTEGRATION_SETTINGS,
                settingsName: clientKeysSettingsName,
                formData
            })
        )
            .then(() => fetchAll().then(connectToHubSpot))
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    const syncHubSpot = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: hubspotIntegrationActions.START_SYNC_HUBSPOT
            })
        )
            .then(fetchAll)
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    const disconnectHubSpot = () => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: hubspotIntegrationActions.START_DISCONNECT_HUBSPOT,
                clientKeys,
                tokens
            })
        )
            .then(fetchAll)
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    useEffect(() => {
        fetchAll();
        validateCode();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            {isFetching && <PageLoader />}
            <PageContent>
                <PageContent.Title>Connection</PageContent.Title>
                <HubSpotIntegrationClientKeysForm
                    disabled={!!integrationIsConnected}
                    initialValues={clientKeysInitialValues}
                    onSubmit={onSubmitClientKeys}
                    hideConnect={!!integrationIsConnected && integrationHasSecret}
                    onConnect={connectToHubSpot}
                />
                {!!integrationIsConnected && (
                    <>
                        <Button
                            label="Disconnect"
                            onClick={disconnectHubSpot}
                            variation="primary"
                        />
                        <PageContent.Title>Sync</PageContent.Title>
                        <p>
                            Sync will start background process of syncing ERP data and Hubspot data
                            which includes
                            <br /> Prospects, Deals, Sessions, Questions&Answers
                        </p>
                        <Button label="Sync now" variation="secondary" onClick={syncHubSpot} />
                    </>
                )}
            </PageContent>
        </LayoutManager>
    );
};

export default HubSpotConnection;
