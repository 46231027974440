import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'erpcore/components/Button';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import { hasPermission } from 'erpcore/utils/RolesManager';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import ProjectEditTabsNavigation from '../ProjectEditTabsNavigation';

const ProjectEditPageHeader = ({ data }) => {
    const meData = useSelector(getMeData) || {};
    const { id, name } = { ...data };

    const pageTitle = () => {
        if (name) {
            return `Project - ${name}`;
        }

        return `Project`;
    };

    return (
        <>
            <HeadMeta title={pageTitle()} />
            <PageHeader title={pageTitle()}>
                {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                    <Button href="/projects/create" label="Create new" />
                )}
            </PageHeader>
            <ProjectEditTabsNavigation id={id} />
        </>
    );
};

ProjectEditPageHeader.defaultProps = {
    data: {}
};

ProjectEditPageHeader.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditPageHeader;
