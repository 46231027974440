import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';
import Svg from 'erpcore/components/Svg';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import { actions as usersActions } from 'erpcore/screens/Users/Users.reducer';

class UsersDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

        this.handleModal = this.handleModal.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit() {
        const { iri, dispatch } = this.props;

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: usersActions.START_DELETE_SINGLE_USER,
                iri
            })
        )
            .then(() => {
                this.setState({ opened: false });
            })
            .catch(error => {
                this.setState({ opened: false });
                return error;
            });
    }

    handleModal() {
        const { opened: isOpen } = this.state;
        this.setState({ opened: !isOpen });
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        const { opened } = this.state;

        return (
            <Fragment>
                <Tooltip content="Delete user">
                    <button type="button" onClick={() => this.handleModal()}>
                        <Svg icon="remove" />
                    </button>
                </Tooltip>
                <Modal
                    variation="small"
                    opened={opened}
                    onClose={() => this.handleModal()}
                    title="Delete user"
                    subtitle="Are you sure you want to delete this User?"
                >
                    <Form onSubmit={handleSubmit(this.onSubmit)}>
                        <Button submit loading={submitting} label="Delete" />
                        <Button
                            variation="secondary"
                            label="Cancel"
                            onClick={() => this.handleModal()}
                        />
                    </Form>
                </Modal>
            </Fragment>
        );
    }
}

UsersDelete.defaultProps = {
    submitting: false,
    iri: null
};

UsersDelete.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    iri: PropTypes.string,
    dispatch: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'UsersDeleteForm',
    enableReinitialize: true
})(UsersDelete);
