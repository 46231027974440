import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Form, { Autocomplete } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const OrganizationGeneralForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel,
    isCurrencyDisabled
}) => {
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="currency"
                        id="currency"
                        fieldProps={{
                            label: 'Currency',
                            options: {
                                endpoint: '/api/currencies?pagination=false',
                                mapData: {
                                    value: 'iri',
                                    label: 'code'
                                }
                            },
                            clearable: true,
                            helperText: isCurrencyDisabled
                                ? 'Currency can not be changed for the organization, all accounting items are converted into organization currency for reporting purposes.'
                                : 'It will not be possible to change the currency for the organization once it is set.'
                        }}
                        fieldAttr={{
                            required: true,
                            disabled: isCurrencyDisabled
                        }}
                        component={Autocomplete}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>

                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form>
        </>
    );
};

OrganizationGeneralForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    pristine: false,
    invalid: false,
    isCurrencyDisabled: false
};

OrganizationGeneralForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    isCurrencyDisabled: PropTypes.bool
};

export default reduxForm({
    form: 'OrganizationGeneralForm',
    enableReinitialize: true
})(OrganizationGeneralForm);
