import React from 'react';
import Form, { Text } from 'erpcore/components/Form';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';

const AvesdoIntegrationForm = ({ handleSubmit, onSubmit, invalid, pristine, submitting }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="token"
                    fieldProps={{ label: 'API token' }}
                    fieldAttr={{ id: 'token' }}
                    component={Text}
                />
            </Form.Row>
            <Button loading={submitting} submit disabled={invalid || pristine} label="Update" />
        </Form>
    );
};

AvesdoIntegrationForm.defaultProps = {
    onSubmit: () => {},
    handleSubmit: () => {},
    invalid: false,
    pristine: false,
    submitting: false
};

AvesdoIntegrationForm.propTypes = {
    onSubmit: PropTypes.func,
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool
};

export default reduxForm({
    form: 'AvesdoIntegrationForm',
    enableReinitialize: true
})(AvesdoIntegrationForm);
