import React from 'react';
import { Field, FieldArray } from 'redux-form';
import Form, { Text, Media } from 'erpcore/components/Form';
import PropTypes from 'prop-types';
// import { valueValidation } from 'erpcore/components/Form/Form.utils';

const TitleGalleryRepeaterItem = ({ member, data }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                {!!data?.mediaTitle && (
                    <Form.SectionTitleSmall>{data?.mediaTitle}</Form.SectionTitleSmall>
                )}
                <FieldArray
                    name={`${member}.gallery`}
                    id={`${member}.gallery`}
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['jpg', 'jpeg', 'png', 'gif']}
                />
            </Form.Row>
        </div>
    );
};

TitleGalleryRepeaterItem.defaultProps = {
    member: null,
    data: null
};

TitleGalleryRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    data: PropTypes.oneOfType([PropTypes.object])
};

export default TitleGalleryRepeaterItem;
