import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import styles from './Checkbox.module.scss';

const Checkbox = ({ input, meta, fieldProps, fieldAttr, isGroup }) => {
    return (
        <Fragment>
            <div className={`${styles['input-checkbox']}`}>
                <input
                    type="checkbox"
                    aria-label={`${fieldProps.label}`}
                    name={input.name}
                    {...fieldAttr}
                    id={fieldProps.id ? fieldProps.id : input.name + fieldProps.value}
                    value={fieldProps.value}
                    checked={isGroup ? input.value.indexOf(fieldProps.value) !== -1 : input.value}
                    onChange={event => {
                        input.onBlur();
                        if (isGroup) {
                            const newValue = [...input.value];
                            if (event.target.checked) {
                                newValue.push(fieldProps.value);
                            } else {
                                newValue.splice(newValue.indexOf(fieldProps.value), 1);
                            }

                            return input.onChange(newValue.length ? newValue : null);
                        }
                        if (event.target.checked) {
                            return input.onChange(fieldProps.value);
                        }
                        return input.onChange('');
                    }}
                />
                <label
                    className={`${styles['input-checkbox__label']}`}
                    htmlFor={fieldProps.id ? fieldProps.id : input.name + fieldProps.value}
                >
                    <span className={`${styles['input-checkbox__icon']}`}>
                        <Svg icon="checkmark" />
                    </span>
                    <span>{fieldProps.label}</span>
                </label>
            </div>
            {meta.touched && meta.error && !isGroup && (
                <span className={`${styles['input-checkbox__error']}`}>
                    {meta.error.message ? meta.error.message : meta.error}
                </span>
            )}
        </Fragment>
    );
};
Checkbox.defaultProps = {
    fieldProps: {
        label: '',
        options: []
    },
    fieldAttr: {},
    input: {},
    meta: {},
    isGroup: false
};
Checkbox.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    isGroup: PropTypes.bool
};
export default Checkbox;
