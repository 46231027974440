import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';
import Form, { Repeater } from 'erpcore/components/Form';
import SalesGuidelinesRepeatableItem from 'erpcore/screens/Settings/Session/components/SalesGuidelinesRepeatableItem';

const SalesGuidelinesForm = ({ onCreate, onRemove, onUpdate, onFinish }) => {
    const addUpdateItems = (oldItems, oldItemsDiff, newItems) => {
        const promises = [];

        if (Object.keys(oldItemsDiff)?.length > 0) {
            oldItems.map(item => promises.push(onUpdate(item)));
        }

        if (newItems?.length > 0) {
            newItems.map(item => promises.push(onCreate(item)));
        }

        return Promise.all([promises]);
    };

    return (
        <Form>
            <Form.Row>
                <FieldArray
                    name="sales_guidelines"
                    component={Repeater}
                    RepeatableItem={SalesGuidelinesRepeatableItem}
                    canAddMultiple
                    canSort={false}
                    canSortNewItems={false}
                    canSave
                    saveButtonIntent="all"
                    onSaveAll={({ oldItems, oldItemsDiff, newItems }) => {
                        addUpdateItems(oldItems, oldItemsDiff, newItems).then(() => {
                            onFinish();
                        });
                    }}
                    onRemoveItem={({ itemData }) => onRemove(itemData?.iri)}
                    addNewLabel="Add new item"
                    uniqueIdentifier="iri"
                />
            </Form.Row>
        </Form>
    );
};

SalesGuidelinesForm.defaultProps = {
    onCreate: () => {},
    onRemove: () => {},
    onUpdate: () => {},
    onFinish: () => {}
};

SalesGuidelinesForm.propTypes = {
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func,
    onFinish: () => {}
};

export default reduxForm({
    form: 'SalesGuidelinesForm',
    enableReinitialize: true
})(SalesGuidelinesForm);
