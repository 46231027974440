import React from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';

const Text = ({ input, meta, fieldProps, fieldAttr, field }) => (
    <Input fieldProps={fieldProps} fieldAttr={fieldAttr} field={field} input={input} meta={meta}>
        <input
            id={input.name}
            {...input}
            aria-label={`${input.name}`}
            type="text"
            {...fieldAttr}
            className="input__field"
        />
    </Input>
);

Text.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

Text.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default Text;
