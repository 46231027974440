import Form, { Repeater } from 'erpcore/components/Form';
import { FieldArray, reduxForm } from 'redux-form';
import React from 'react';
import Button from 'erpcore/components/Button';
import PropTypes from 'prop-types';
import DepositStructureRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/DepositStructureRepeaterItem';
import AppStatuses from 'erpcore/components/AppStatuses';

const RealtorPortalForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitleSmall>Deposit Structure</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="realtor_deposit_structure"
                    component={Repeater}
                    RepeatableItem={DepositStructureRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new deposit structure"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <AppStatuses homestore mlaBlue />
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

RealtorPortalForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

RealtorPortalForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'RealtorPortalForm',
    enableReinitialize: true
})(RealtorPortalForm);
