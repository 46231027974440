/* eslint-disable camelcase */
import PageContent from 'erpcore/components/Layout/PageContent';
import PageLoader from 'erpcore/components/PageLoader';
import { actions as projectActions } from 'erpcore/screens/Projects/Projects.reducer';
import {
    getIsUpdating,
    getProjectData,
    getProjectFetching
} from 'erpcore/screens/Projects/Projects.selectors';
import ProjectEditPageHeader from 'erpcore/screens/Projects/screens/ProjectEdit/components/ProjectEditPageHeader';
import { dtoForm } from 'erpcore/utils/dto';
import LayoutManager from 'erpcore/utils/LayoutManager';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { SubmissionError } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { diff } from 'deep-object-diff';
import ProjectEditMultiEffectFeaturesListForm from 'erpcore/screens/Projects/components/ProjectEditMultiEffectFeaturesListForm';
import { cleanRepeaterOutput } from 'erpcore/components/Form/components/Repeater';

const ProjectEditMultiEffectFeaturesList = ({ match }) => {
    const dispatch = useDispatch();
    const projectIri = `/api/projects/${match?.params?.id}`;
    const projectDataOriginal = useSelector(state => getProjectData(state, projectIri)) || {};
    const isUpdating = useSelector(getIsUpdating);
    const projectData = dtoForm(projectDataOriginal) || {};
    const fetching = useSelector(getProjectFetching);

    const initialValues = {
        features_list: projectData.features_list?.map((item, i) => {
            return { ...item, customIdentifier: `customIdentifier-${i}` };
        })
    };

    const onSubmit = formData => {
        // send only changed data
        const formDataDiff = diff(initialValues, formData);
        // Handle data
        const { features_list: featuresList } = {
            ...formDataDiff
        };

        if (featuresList) {
            formDataDiff.features_list = cleanRepeaterOutput(formData.features_list, [
                'customIdentifier'
            ]);
        }

        return new Promise((resolve, reject) => {
            dispatch({
                promise: { resolve, reject },
                type: projectActions.START_UPDATE_PROJECT,
                iri: projectIri,
                formData: formDataDiff
            });
        }).catch(error => {
            throw new SubmissionError(reduxFormErrorMapper(error));
        });
    };

    /**
     * Fetch unit settings data
     * @return {Promise}
     */
    const fetchUnitsSettings = () => {
        const params = {
            include:
                'keyPlans,keyPlans.keyPlan,unitTypes,unitTypes.floorPlan,unitTypes.unitType,unitTypes.unitPositions'
        };
        return new Promise((resolve, reject) => {
            dispatch({
                type: projectActions.START_FETCHING_UNIT_SETTINGS,
                promise: { resolve, reject },
                iri: projectIri,
                projectID: match?.params?.id,
                params
            });
        });
    };

    useEffect(() => {
        fetchUnitsSettings();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            {(fetching || isUpdating) && <PageLoader />}
            <ProjectEditPageHeader data={projectData} />
            <PageContent className="units-settings">
                <ProjectEditMultiEffectFeaturesListForm
                    onSubmit={onSubmit}
                    form={`ProjectEditMultiEffectFeaturesListForm-${projectIri}`}
                    initialValues={initialValues}
                    submitLabel="Update"
                />
            </PageContent>
        </LayoutManager>
    );
};

ProjectEditMultiEffectFeaturesList.defaultProps = {
    match: {}
};

ProjectEditMultiEffectFeaturesList.propTypes = {
    match: PropTypes.oneOfType([PropTypes.object])
};

export default ProjectEditMultiEffectFeaturesList;
