import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { getUserCurrentOrganization } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import enviromentVariables from 'erpcore/utils/enviromentVariables';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as portalActions } from './Portal.reducer';

const portalParams = {
    include: 'currency'
};

/**
 * Fetch Portal
 * @param  {Object} promise
 * @param {string} iri Portal iri
 */
export function* fetchPortal({ promise, iri }) {
    try {
        const fetchPortalAPI = yield restClient.get(iri, { params: portalParams });
        yield put({
            type: portalActions.FETCH_SINGLE_PORTAL_SUCCESSFUL
        });
        yield put({
            type: portalActions.STORE_SINGLE_PORTAL_DATA,
            iri,
            response: dto(fetchPortalAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: portalActions.FETCH_SINGLE_PORTAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Mine Portal
 * @param  {Object} promise
 */
export function* fetchMinePortal({ promise }) {
    const currentUserPortal = yield select(getUserCurrentOrganization);
    const { iri } = yield { ...currentUserPortal };
    try {
        const fetchPortalAPI = yield restClient.get(iri, { params: portalParams });
        yield put({
            type: portalActions.FETCH_SINGLE_PORTAL_SUCCESSFUL
        });
        yield put({
            type: portalActions.STORE_SINGLE_PORTAL_DATA,
            iri,
            response: dto(fetchPortalAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: portalActions.FETCH_SINGLE_PORTAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Portal single data
 * @param  {Object} promise
 * @param {Object} formData
 * @param {string} iri Portal iri
 * @return {Object} Response from API
 */
export function* updateSinglePortal({ promise, formData, iri }) {
    try {
        const updateSinglePortalAPI = yield restClient.put(iri, formData, {
            params: portalParams
        });
        const dtoUpdateSinglePortalAPI = yield dto(updateSinglePortalAPI?.data);
        yield put({
            type: portalActions.UPDATE_SINGLE_PORTAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSinglePortalAPI?.data
        });
        yield put({
            type: portalActions.STORE_SINGLE_PORTAL_DATA,
            iri,
            response: dtoUpdateSinglePortalAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: portalActions.UPDATE_SINGLE_PORTAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update mine Portal data
 * @param  {Object} promise
 * @param {Object} formData
 * @return {Object} Response from API
 */
export function* updateMinePortal({ promise, formData }) {
    const currentUserPortal = yield select(getUserCurrentOrganization);
    const { iri } = yield { ...currentUserPortal };
    try {
        const updateSinglePortalAPI = yield restClient.put(iri, formData, {
            params: portalParams
        });
        const dtoUpdateSinglePortalAPI = yield dto(updateSinglePortalAPI?.data);
        yield put({
            type: portalActions.UPDATE_SINGLE_PORTAL_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateSinglePortalAPI?.data
        });
        yield put({
            type: portalActions.STORE_SINGLE_PORTAL_DATA,
            iri,
            response: dtoUpdateSinglePortalAPI
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: portalActions.UPDATE_SINGLE_PORTAL_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* fetchPortalSettingsPublic({ promise }) {
    try {
        const fetchPortalSettingsAPI = yield restClient.get('/api/organization-settings', {
            params: { 'filters[name][equals]': enviromentVariables.REACT_APP_SLUG }
        });
        yield put({
            type: portalActions.FETCHING_SUCCESSFUL_PORTAL_SETTINGS_PUBLIC,
            response: dto(fetchPortalSettingsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: portalActions.FETCHING_FAILED_PORTAL_SETTINGS_PUBLIC
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* fetchPortalSettings({ promise }) {
    try {
        const fetchPortalSettingsAPI = yield restClient.get('/api/organization-settings', {
            params: { 'filters[name][equals]': enviromentVariables.REACT_APP_SLUG }
        });
        yield put({
            type: portalActions.FETCHING_SUCCESSFUL_PORTAL_SETTINGS,
            response: dto(fetchPortalSettingsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: portalActions.FETCHING_FAILED_PORTAL_SETTINGS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

export function* updatePortalSettings({ promise, iri, formData }) {
    try {
        let PortalSettingsAPI = null;
        //  If exists patch it if not post
        if (iri) {
            PortalSettingsAPI = yield restClient.patch(iri, {
                name: enviromentVariables.REACT_APP_SLUG,
                settings: { ...formData }
            });
        } else {
            PortalSettingsAPI = yield restClient.post('/api/organization-settings', {
                name: enviromentVariables.REACT_APP_SLUG,
                settings: { ...formData }
            });
        }
        yield put({
            type: portalActions.UPDATE_SUCCESSFUL_PORTAL_SETTINGS,
            response: dto(PortalSettingsAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: portalActions.UPDATE_FAILED_PORTAL_SETTINGS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export default [
    takeLatest(portalActions.START_FETCHING_SINGLE_PORTAL, fetchPortal),
    takeLatest(portalActions.START_FETCHING_MINE_PORTAL, fetchMinePortal),
    takeLatest(portalActions.START_UPDATE_SINGLE_PORTAL, updateSinglePortal),
    takeLatest(portalActions.START_UPDATE_MINE_PORTAL, updateMinePortal),
    takeLatest(portalActions.START_FETCHING_PORTAL_SETTINGS, fetchPortalSettings),
    takeLatest(portalActions.START_UPDATE_PORTAL_SETTINGS, updatePortalSettings)
];
