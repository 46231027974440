import { takeLatest, put, call } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';

import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import { actions as dashboardActions } from './OiAccessDashboard.reducer';

/**
 * Fetch User Projects
 * @param {Object} promise
 * @return {Object} response from API
 */
export function* fetchUserProjects({ promise, params, endpoint }) {
    try {
        const fetchProjectssAPI = yield restClient.get(endpoint, { params });
        yield put({
            type: dashboardActions.FETCHING_SUCCESSFUL_SUPER_ADMIN_PROJECTS,
            response: dto(fetchProjectssAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardActions.FETCHING_FAILED_SUPER_ADMIN_PROJECTS
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Fetch Single Project
 * @param {Object} promise
 * @return {Object} response from API
 */
export function* fetchSingleProject({ promise, params, endpoint }) {
    try {
        const fetchProjectssAPI = yield restClient.get(endpoint, { params });
        yield put({
            type: dashboardActions.FETCHING_SUCCESSFUL_SINGLE_PROJECT,
            response: dto(fetchProjectssAPI?.data)
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: dashboardActions.FETCHING_FAILED_SINGLE_PROJECT
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
export const oiAccessDashboardSaga = [
    takeLatest(dashboardActions.START_FETCHING_SUPER_ADMIN_PROJECTS, fetchUserProjects),
    takeLatest(dashboardActions.START_FETCHING_SINGLE_PROJECT, fetchSingleProject)
];
