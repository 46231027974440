const answerTypes = [
    {
        label: 'Single choice',
        id: 'single-choice',
        value: 'single'
    },
    {
        label: 'Multiple choice',
        id: 'multiple-choice',
        value: 'multiple'
    }
];

export default answerTypes;
