import React from 'react';
import { Field } from 'redux-form';
import Form, { Text, Media } from 'erpcore/components/Form';
import PropTypes from 'prop-types';

const DisclosureFormRepeaterItem = ({ member }) => {
    return (
        <div>
            <Form.Row>
                <Field
                    name={`${member}.title`}
                    fieldProps={{
                        label: 'Title',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.title` }}
                    component={Text}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name={`${member}.link`}
                    fieldProps={{
                        label: 'Link',
                        clearable: true
                    }}
                    fieldAttr={{ id: `${member}.link` }}
                    component={Text}
                />
            </Form.Row>
            <Form.SectionTitleSmall>PDF</Form.SectionTitleSmall>
            <Form.Row>
                <Field
                    name={`${member}.file`}
                    fieldAttr={{ id: `${member}.file` }}
                    component={Media}
                    useLightbox
                    useImageManager
                    allowedFileTypes={['pdf']}
                />
            </Form.Row>
        </div>
    );
};

DisclosureFormRepeaterItem.defaultProps = {
    member: null
};

DisclosureFormRepeaterItem.propTypes = {
    member: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default DisclosureFormRepeaterItem;
