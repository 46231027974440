import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionError } from 'redux-form';

import LayoutManager from 'erpcore/utils/LayoutManager';
import HeadMeta from 'erpcore/components/Layout/HeadMeta';
import PageHeader from 'erpcore/components/Layout/PageHeader';
import PageContent from 'erpcore/components/Layout/PageContent';

import { actions as hubspotIntegrationActions } from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/HubSpotIntegration.reducer';
import AvesdoIntegrationForm from 'erpcore/screens/Settings/Integration/screens/AvesdoIntegration/components/AvesdoIntegrationForm';
import { reduxFormErrorMapper } from 'erpcore/components/Form/Form.utils';
import { getHubSpotIntegrationData } from 'erpcore/screens/Settings/Integration/screens/HubSpotIntegration/HubSpotIntegration.selectors';

const clientKeysSettingsName = 'avesdo-integration-settings';

const AvesdoIntegration = () => {
    const dispatch = useDispatch();
    const title = 'Avesdo Integration';

    const avesdoSettings = useSelector(state =>
        getHubSpotIntegrationData(state, clientKeysSettingsName)
    );

    const initialValues = {
        ...avesdoSettings?.settings
    };

    //  Using hubspot saga and reducer to store token to organization settings
    const fetchSettings = () => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: hubspotIntegrationActions.START_FETCHING_HUBSPOT_INTEGRATION_SETTINGS,
                promise: { resolve, reject },
                settingsName: clientKeysSettingsName
            });
        }).catch(err => err);
    };

    const fetchAll = () => {
        fetchSettings();
    };

    const onSubmitForm = formData => {
        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: hubspotIntegrationActions.START_UPDATE_HUBSPOT_INTEGRATION_SETTINGS,
                settingsName: clientKeysSettingsName,
                iri: avesdoSettings?.iri || false,
                formData
            })
        )
            .then(fetchAll)
            .catch(error => {
                throw new SubmissionError(reduxFormErrorMapper(error));
            });
    };

    useEffect(() => {
        fetchAll();
    }, []);

    return (
        <LayoutManager slot="main" className="main--narrow" layoutType="merge">
            <HeadMeta title={title} />
            <PageHeader title={title} />
            <PageContent>
                <AvesdoIntegrationForm initialValues={initialValues} onSubmit={onSubmitForm} />
            </PageContent>
        </LayoutManager>
    );
};

export default AvesdoIntegration;
