import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';

import Button from 'erpcore/components/Button';
import Form, { Text, Autocomplete, MultiAutocomplete } from 'erpcore/components/Form';

const DealForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, submitLabel, form }) => {
    const formData = useSelector(state => getFormValues(form)(state));

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Row>
                <Field
                    name="name"
                    fieldProps={{
                        label: 'Deal Name',
                        clearable: true
                    }}
                    fieldAttr={{ id: 'name', required: true }}
                    component={Text}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="prospects"
                    fieldProps={{
                        label: 'Prospects',
                        clearable: true,
                        options: {
                            endpoint: '/api/prospects',
                            mapData: {
                                value: 'iri',
                                label: '{first_name} {last_name}'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'prospects', required: true }}
                    component={MultiAutocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            <Form.Row>
                <Field
                    name="project"
                    fieldProps={{
                        label: 'Project',
                        clearable: true,
                        options: {
                            endpoint: '/api/projects?pagination=false',
                            mapData: {
                                value: 'iri',
                                label: 'name'
                            }
                        }
                    }}
                    fieldAttr={{ id: 'projects', required: true }}
                    component={Autocomplete}
                    validate={valueValidation([{ validator: 'required' }])}
                />
            </Form.Row>
            {formData?.project && (
                <>
                    <Form.Row>
                        <Field
                            name="unit"
                            fieldProps={{
                                label: 'Unit',
                                clearable: true,
                                options: {
                                    endpoint: `/api/project/units?filters[unitType.project][equals]=${formData?.project}`,
                                    mapData: {
                                        value: 'iri',
                                        label: 'name'
                                    }
                                }
                            }}
                            fieldAttr={{ id: 'unit' }}
                            component={Autocomplete}
                        />
                    </Form.Row>
                </>
            )}
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
            {submitLabel === 'Update' ? (
                <>
                    <br />
                    <Form.Row>
                        <Field
                            name="hubspot_pipeline_label"
                            fieldProps={{
                                label: 'Hubspot Pipeline'
                            }}
                            fieldAttr={{ disabled: true }}
                            component={Text}
                        />
                    </Form.Row>
                    <Form.Row>
                        <Field
                            name="hubspot_stage_label"
                            fieldProps={{
                                label: 'Hubspot Stage'
                            }}
                            fieldAttr={{ disabled: true }}
                            component={Text}
                        />
                    </Form.Row>
                </>
            ) : (
                ''
            )}
        </Form>
    );
};

DealForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

DealForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    form: PropTypes.string.isRequired
};

export default reduxForm({
    enableReinitialize: true,
    destroyOnUnmount: true
})(DealForm);
