import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input';
import './NumberRange.scss';

const NumberRange = ({ input, meta, fieldProps, fieldAttr, field }) => {
    const [rangeStart, setRangeStart] = useState('');
    const [rangeEnd, setRangeEnd] = useState('');
    const { value } = { ...input };
    const { range_start: inputRangeStart, range_end: inputRangeEnd } = { ...value };
    const inputRangeStartRef = useRef(undefined);
    const inputRangeEndRef = useRef(undefined);
    const {
        rangeStartPlaceholder = 'Enter range start',
        rangeEndPlaceholder = 'Enter range end'
    } = { ...fieldAttr };

    useEffect(() => {
        if (inputRangeStart !== inputRangeStartRef?.current) {
            inputRangeStartRef.current = inputRangeStart;
            setRangeStart(inputRangeStart);
        }
        if (inputRangeEnd !== inputRangeEndRef?.current) {
            inputRangeEndRef.current = inputRangeEnd;
            setRangeEnd(inputRangeEnd);
        }
    }, [value]);

    const onBlur = () => {
        input.onBlur();
        input.onChange({
            range_start: rangeStart,
            range_end: rangeEnd
        });
    };

    const getInteger = inputValue => {
        // if (inputValue === '-') return inputValue; // allow negative numbers

        const integer = parseInt(inputValue, 10);
        if (integer === 0 || (integer && integer >= 0)) {
            return integer;
        }

        return '';
    };

    const onChange = e => {
        if (e.target.id === 'range_start') {
            if (e.target.value !== '') {
                input.onChange({
                    range_start: getInteger(e?.target?.value),
                    range_end: rangeEnd
                });
            } else {
                input.onChange({
                    range_start: e?.target?.value,
                    range_end: rangeEnd
                });
            }
        }

        if (e.target.id === 'range_end') {
            if (e.target.value !== '') {
                input.onChange({
                    range_start: rangeStart,
                    range_end: getInteger(e?.target?.value)
                });
            } else {
                input.onChange({
                    range_start: rangeStart,
                    range_end: e?.target?.value
                });
            }
        }
    };

    return (
        <Input
            fieldProps={fieldProps}
            fieldAttr={fieldAttr}
            field={field}
            input={input}
            meta={meta}
            className="number-range input--active"
        >
            <div className="input__field">
                <input
                    onBlur={onBlur}
                    id="range_start"
                    aria-label="range_start"
                    type="text"
                    onChange={onChange}
                    placeholder={rangeStartPlaceholder}
                    value={rangeStart}
                    autoComplete="off"
                />

                <div>-</div>

                <input
                    onBlur={onBlur}
                    id="range_end"
                    aria-label="range_end"
                    type="text"
                    onChange={onChange}
                    placeholder={rangeEndPlaceholder}
                    value={rangeEnd}
                    autoComplete="off"
                />
            </div>
        </Input>
    );
};

NumberRange.defaultProps = {
    fieldProps: {},
    fieldAttr: {},
    field: {},
    input: {},
    meta: {}
};

NumberRange.propTypes = {
    fieldProps: PropTypes.oneOfType([PropTypes.object]),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    field: PropTypes.oneOfType([PropTypes.object]),
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object])
};

export default NumberRange;
