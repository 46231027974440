import { takeLatest, put, call, select } from 'redux-saga/effects';
import restClient from 'erpcore/api/restClient';

import { actions as blogsActions } from 'erpcore/screens/Settings/Blue/screens/Blog/Blog.reducer';
import { actions as listingActions } from 'erpcore/components/Listing/Listing.reducer';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';

import { getQueryParams } from 'erpcore/components/Listing/Listing.selectors';

const actionName = 'BLOGS';
const reducerName = 'blogs';

/**
 * Delete Product Type
 * @param  {Object} product type iri
 * @return {Object} Response from API
 */
export function* deleteBlog({ promise, iri }) {
    try {
        const deleteBlogAPI = yield restClient.delete(iri);
        yield put({
            type: blogsActions.DELETE_BLOG_SUCCESSFUL
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: deleteBlogAPI?.data
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/blogs`,
            promise
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: blogsActions.DELETE_BLOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Create Blog
 * @param  {Object} formData
 * @return {Object} Response from API
 */
export function* createBlog({ promise, formData }) {
    try {
        const createBlogAPI = yield restClient.post('api/blogs', formData);
        yield put({
            type: blogsActions.CREATE_BLOG_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/blogs`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: createBlogAPI?.data
        });

        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: blogsActions.CREATE_BLOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Update Product Type
 * @param  {Object} iri of product type
 * @return {Object} Response from API
 */
export function* updateBlog({ promise, iri, formData }) {
    try {
        const updateBlogAPI = yield restClient.patch(iri, formData);
        yield put({
            type: blogsActions.UPDATE_BLOG_SUCCESSFUL
        });

        const params = yield select(getQueryParams, { name: reducerName });

        yield put({
            type: listingActions.START_FETCHING_LISTING,
            params,
            entity: actionName,
            name: reducerName,
            endpoint: `api/blogs`,
            promise
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: updateBlogAPI?.data
        });
        yield call(promise.resolve);
    } catch (error) {
        yield put({
            type: blogsActions.UPDATE_BLOG_FAILED
        });
        yield put({
            type: notificationManagerActions.ADD_FLOATING_NOTIFICATION,
            response: error?.response?.data || error
        });
        yield call(promise.reject, error?.response?.data || error);
    }
}

/**
 * Register action to watcher
 */
const blogsSaga = [
    takeLatest(blogsActions.START_DELETE_BLOG, deleteBlog),
    takeLatest(blogsActions.START_CREATE_BLOG, createBlog),
    takeLatest(blogsActions.START_UPDATE_BLOG, updateBlog)
];

export default blogsSaga;
