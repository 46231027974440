import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from 'erpcore/components/Svg';
import { actions as notificationManagerActions } from 'erpcore/utils/NotificationManager/NotificationManager.reducer';
import './Notification.scss';

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = { expired: false, hovered: false };
    }

    componentDidMount() {
        this.setTimer();
    }

    componentDidUpdate(prevProps, prevState) {
        const { expired, hovered } = this.state;
        const { prevExpired, prevHovered } = prevState;

        if (prevExpired !== expired || prevHovered !== hovered) {
            if (expired && !hovered) {
                this.removeNotification();
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    setHovered(state = false) {
        this.setState({ hovered: state });
    }

    setTimer() {
        const { expire } = this.props;
        // clear any existing timer
        if (this.timer != null) {
            clearTimeout(this.timer);
        }

        // hide after `expire` milliseconds
        if (expire !== null) {
            this.timer = setTimeout(() => {
                this.timer = null;
                this.setState({ expired: true });
            }, expire);
        }
    }

    removeNotification() {
        const { dispatch, identifier } = this.props;

        dispatch({
            type: notificationManagerActions.REMOVE_NOTIFICATION,
            identifier
        });
    }

    render() {
        const { identifier, type, title, text, icon, children } = this.props;

        return (
            <div
                className={`notification${type !== '' ? ` notification--${type}` : ''}`}
                onMouseEnter={() => this.setHovered(true)}
                onMouseLeave={() => this.setHovered(false)}
            >
                {icon === true &&
                    (type === 'success' || type === 'warning' || type === 'error') && (
                        <Svg
                            className="notification__icon"
                            icon={`notification${type[0].toUpperCase()}${type.substring(1)}`}
                        />
                    )}
                <div className="notification__content">
                    {title !== '' && <p className="notification__title">{title}</p>}
                    {text !== '' && <p className="notification__text">{text}</p>}
                    {children}
                </div>
                {identifier && (
                    <button
                        type="button"
                        aria-label="Close"
                        className="notification__close"
                        onClick={() => this.removeNotification()}
                    >
                        <Svg className="notification__close-icon" icon="close" />
                    </button>
                )}
            </div>
        );
    }
}

Notification.defaultProps = {
    dispatch: () => {},
    identifier: null,
    type: 'warning',
    title: '',
    text: '',
    icon: false,
    children: null,
    expire: null
};
Notification.propTypes = {
    dispatch: PropTypes.func,
    identifier: PropTypes.string,
    type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    icon: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    expire: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default connect()(Notification);
