import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dtoForm } from 'erpcore/utils/dto';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import { Field, reduxForm } from 'redux-form';
import Form, { Text } from 'erpcore/components/Form';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import { actions as myAccountActions } from '../../MyAccount.reducer';

class LinkedAccountsForm extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(formData) {
        const { dispatch, initialValues } = this.props;
        // send only changed data
        formData = diff(initialValues, formData);

        return new Promise((resolve, reject) =>
            dispatch({
                promise: { resolve, reject },
                type: myAccountActions.START_UPDATE_USER,
                formData
            })
        ).catch(error => {
            return error;
        });
    }

    render() {
        const { handleSubmit } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.onSubmit)}>
                <Form.SectionTitle>Linked accounts</Form.SectionTitle>
                <Form.Row>
                    <Field
                        name="asana_account_id"
                        id="asana_account_id"
                        fieldProps={{
                            label: 'Asana account ID',
                            clearable: true
                        }}
                        component={Text}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="jira_account_id"
                        id="jira_account_id"
                        fieldProps={{
                            label: 'Jira account ID',
                            clearable: true
                        }}
                        component={Text}
                    />
                </Form.Row>
            </Form>
        );
    }
}

LinkedAccountsForm.defaultProps = {
    initialValues: {}
};

LinkedAccountsForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    initialValues: PropTypes.oneOfType([PropTypes.object])
};

// Order of this steps is mandatory to have initialValues working
LinkedAccountsForm = reduxForm({
    form: 'LinkedAccountsForm',
    enableReinitialize: true
})(LinkedAccountsForm);

//  Getting initial value populated in the form from the store
const mapStateToProps = state => ({
    initialValues: dtoForm(getMeData(state))
});

export default withRouter(connect(mapStateToProps)(LinkedAccountsForm));
