import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form, { Select } from 'erpcore/components/Form';
import { valueValidation } from 'erpcore/components/Form/Form.utils';
import Button from 'erpcore/components/Button';
import ElementLoader from 'erpcore/components/ElementLoader';

const UserCreateDealRestrictionForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    deals,
    fetchingDeals
}) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {fetchingDeals && <ElementLoader overlay />}
            <Form.Row>
                <Field
                    name="deal"
                    id="deal"
                    fieldProps={{
                        label: 'Deal',
                        options:
                            deals?.data?.map(item => {
                                const { strata_lot: strataLot = '-' } = item?.unit;
                                const { unitNumber = '-' } = item?.unit?.meta?.avesdo;

                                return {
                                    value: item?.iri,
                                    label: `sl: ${strataLot} un: ${unitNumber} ${item?.name}`
                                };
                            }) || [],
                        clearable: true
                    }}
                    fieldAttr={{ required: true }}
                    component={Select}
                    validate={valueValidation([{ validator: 'required' }])}
                />
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label="Create Restriction"
                />
            </Form.Row>
        </Form>
    );
};

UserCreateDealRestrictionForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    deals: {},
    fetchingDeals: false
};

UserCreateDealRestrictionForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    deals: PropTypes.oneOfType([PropTypes.object]),
    fetchingDeals: PropTypes.bool
};

export default reduxForm({
    form: 'UserCreateDealRestrictionForm',
    enableReinitialize: true
})(UserCreateDealRestrictionForm);
