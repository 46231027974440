import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Default System Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const DefaultNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'error404':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="404 (Not Found). Please try to refresh the page."
                    type="error"
                />
            );
        case 'error405':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="405 (Method Not Allowed). Please try to refresh the page."
                    type="error"
                />
            );
        case 'error500':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="500 (Internal Server Error). Please try to refresh the page."
                    type="error"
                />
            );
        case 'generalError':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text={
                        <Fragment>
                            <strong>{code}</strong>
                            {!!detail && (
                                <Fragment>
                                    <br />
                                    {detail}
                                </Fragment>
                            )}
                        </Fragment>
                    }
                    type="error"
                />
            );
        case 'malformedRequest':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Submitted data was invalid!"
                    type="error"
                />
            );
        case 'copy.successful':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="successfully copied to clipboard"
                    type="success"
                    expire={3000}
                />
            );
        default:
            return null;
    }
};

DefaultNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

DefaultNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default DefaultNotifications;
