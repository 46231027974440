import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - Divisions Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const SessionsNotifications = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'session.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Session successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'session.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Session successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'session.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Session successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'session.linkSuccessCopied':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Link successfully copied!"
                    type="success"
                    expire="3500"
                />
            );
        case 'sessionprospect.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Prospect successfully added to session!"
                    type="success"
                    expire="3500"
                />
            );
        case 'sessionprospect.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Session prospect successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'sessionprospect.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Prospect successfully removed from session!"
                    type="success"
                    expire="3500"
                />
            );
        case 'sessionProspect.invitationResent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invitation successfully sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'session.invitationsResent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Invitations successfully sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'sessionProspect.recapResent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Post Demo email successfully sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'session.recapsResent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Post Demo emails successfully sent!"
                    type="success"
                    expire="3500"
                />
            );
        case 'session.nonAttendanceSent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Non-Attendance email has been sent to all non-attandees!"
                    type="success"
                    expire="3500"
                />
            );
        case 'sessionProspect.nonAttendanceSent':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Non-Attendance email has been sent to prospect!"
                    type="success"
                    expire="3500"
                />
            );
        case 'sessionProspect.linkCopiedToClipboard':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Successfully copied to clipboard"
                    type="success"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

SessionsNotifications.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

SessionsNotifications.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default SessionsNotifications;
