import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';

import Button from 'erpcore/components/Button';
import Form, { Repeater } from 'erpcore/components/Form';
import FloorPlanRepeaterItem from 'erpcore/screens/Projects/screens/ProjectEdit/components/FloorPlanRepeaterItem';

const FloorplansForm = ({ handleSubmit, submitting, pristine, invalid, onSubmit, submitLabel }) => {
    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.SectionTitleSmall>Floor plans</Form.SectionTitleSmall>
            <Form.Row>
                <FieldArray
                    name="floorplans"
                    component={Repeater}
                    RepeatableItem={FloorPlanRepeaterItem}
                    canAddMultiple
                    canSortNewItems
                    canSave={false}
                    addNewLabel="Add new item"
                    uniqueIdentifier="customIdentifier"
                />
            </Form.Row>
            <Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form.Row>
        </Form>
    );
};

FloorplansForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    pristine: false,
    invalid: false,
    submitLabel: 'Create'
};

FloorplansForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default reduxForm({
    form: 'FloorplansForm',
    enableReinitialize: true
})(FloorplansForm);
