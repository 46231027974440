import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Form, { Text, Email, Switch } from 'erpcore/components/Form';
import Button from 'erpcore/components/Button';

import { valueValidation } from 'erpcore/components/Form/Form.utils';

const PortalGeneralForm = ({
    handleSubmit,
    submitting,
    pristine,
    invalid,
    onSubmit,
    submitLabel
}) => {
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Field
                        name="avesdo_link"
                        id="avesdo_link"
                        fieldProps={{
                            label: 'Add avesdo link',
                            clearable: true
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={Text}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="contact_page_email"
                        id="contact_page_email"
                        fieldProps={{
                            label: 'Contact Page Email',
                            clearable: true
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={Email}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Form.Row>
                    <Field
                        name="2fa"
                        id="2fa"
                        fieldProps={{
                            label: '2FA',
                            clearable: true,
                            on: {
                                value: true,
                                id: 'enable',
                                label: 'enable'
                            },
                            off: {
                                value: false,
                                id: 'disable',
                                label: 'disable'
                            }
                        }}
                        fieldAttr={{
                            required: true
                        }}
                        component={Switch}
                        validate={valueValidation([{ validator: 'required' }])}
                    />
                </Form.Row>
                <Button
                    submit
                    disabled={pristine || invalid}
                    loading={submitting}
                    label={submitLabel}
                />
            </Form>
        </>
    );
};

PortalGeneralForm.defaultProps = {
    onSubmit: () => {},
    submitting: false,
    submitLabel: 'Submit',
    pristine: false,
    invalid: false
};

PortalGeneralForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    submitLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    pristine: PropTypes.bool,
    invalid: PropTypes.bool
};

export default reduxForm({
    form: 'PortalGeneralForm',
    enableReinitialize: true
})(PortalGeneralForm);
