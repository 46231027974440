import React from 'react';
import PropTypes from 'prop-types';
import Notification from 'erpcore/components/Notification';

/**
 * Notification Manager - WorkTypes Notifications
 * @return {Node} Returns Notification component
 */
// eslint-disable-next-line no-unused-vars
const Blue = ({ identifier, code, detail, notificationData }) => {
    switch (code) {
        case 'blog.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="FAQ Post successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'blog.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="FAQ Post successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'blog.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="FAQ Post successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'blogcategory.itemSuccessfulyCreated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="FAQ Category successfully created!"
                    type="success"
                    expire="3500"
                />
            );
        case 'blogcategory.itemSuccessfulyUpdated':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="FAQ Category successfully updated!"
                    type="success"
                    expire="3500"
                />
            );
        case 'blogcategory.itemSuccessfulyRemoved':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="FAQ Category successfully deleted!"
                    type="success"
                    expire="3500"
                />
            );
        case 'export.rets.success':
            return (
                <Notification
                    identifier={identifier}
                    title="Success"
                    text="Successfully exported RETS data. Please check your email."
                    type="success"
                    expire="3500"
                />
            );
        case 'export.rets.error':
            return (
                <Notification
                    identifier={identifier}
                    title="Error"
                    text="Error exporting RETS data. Please try again later."
                    type="error"
                    expire="3500"
                />
            );
        default:
            return null;
    }
};

Blue.defaultProps = {
    identifier: null,
    code: null,
    detail: null,
    notificationData: null
};

Blue.propTypes = {
    identifier: PropTypes.string,
    code: PropTypes.string,
    detail: PropTypes.string,
    notificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
};

export default Blue;
