import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NotificationManager from 'erpcore/utils/NotificationManager';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { getFloatingNotifications } from 'erpcore/utils/NotificationManager/NotificationManager.selectors';
import './FloatingNotifications.scss';

const FloatingNotifications = ({ className = '' }) => {
    const notifications = useSelector(getFloatingNotifications);

    // .floating-notifications is always present in DOM for animation purposes. It is however hidden if empty.
    return (
        <div
            className={`floating-notifications ${
                !(notifications && notifications.length) ? 'floating-notifications--empty' : ''
            } ${className || ''}`}
        >
            <ReactCSSTransitionGroup
                transitionName="notification--transition"
                transitionEnterTimeout={600}
                transitionLeaveTimeout={500}
            >
                {notifications.map(notification => {
                    return (
                        <NotificationManager
                            code={notification.code}
                            key={notification.identifier}
                            identifier={notification.identifier}
                            detail={notification.detail}
                            notificationData={notification.notificationData}
                        />
                    );
                })}
            </ReactCSSTransitionGroup>
        </div>
    );
};

FloatingNotifications.defaultProps = {
    className: ''
};

FloatingNotifications.propTypes = {
    className: PropTypes.string
};

export default FloatingNotifications;
