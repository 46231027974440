import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'erpcore/components/Button';
import Tooltip from 'erpcore/components/Tooltip';
import Modal from 'erpcore/components/Modal';
import Listing from 'erpcore/components/Listing';
import restClient from 'erpcore/api/restClient';
import dto from 'erpcore/utils/dto';
import { useSelector } from 'react-redux';
import { getUserData } from 'erpcore/screens/Users/Users.selectors';
import { useRouteMatch } from 'react-router-dom';

const UserAvesdoEmails = ({ onAvesdoEmailApply }) => {
    const match = useRouteMatch();
    const userIri = `/api/users/${match?.params?.id}`;
    const userData = useSelector(state => getUserData(state, userIri)) || {};
    const [opened, setOpened] = useState(false);
    const [prospectListing, setProspectListing] = useState({});
    const [fetchingProspectListing, setFetchingProspectListing] = useState(false);
    const [realtorListing, setRealtorListing] = useState({});
    const [fetchingRealtorListing, setFetchingRealtorListing] = useState(false);
    const [updating, setUpdating] = useState(null);

    const fetchAvesdoProspectEmails = useCallback(
        params => {
            if (userData?.prospect?.iri) {
                setFetchingProspectListing(true);
                restClient
                    .get('api/avesdo-purchasers?include=project,prospect,prospect.user', {
                        params: {
                            ...params,
                            'filters[prospect][equals]': userData?.prospect?.iri,
                            'filters[email][not_equals]': userData?.email
                        }
                    })
                    .then(response => setProspectListing(dto(response?.data)))
                    .finally(() => setFetchingProspectListing(false));
            }
        },
        [userData]
    );

    const fetchAvesdoRealtorEmails = useCallback(
        params => {
            if (userData?.realtor?.iri) {
                setFetchingRealtorListing(true);
                restClient
                    .get('api/avesdo-realtors?include=project,realtor,realtor.user', {
                        params: {
                            ...params,
                            'filters[realtor][equals]': userData?.realtor?.iri,
                            'filters[email][not_equals]': userData?.email
                        }
                    })
                    .then(response => setRealtorListing(dto(response?.data)))
                    .finally(() => setFetchingRealtorListing(false));
            }
        },
        [userData]
    );

    const tableData = (data, isRealtor) => {
        const table = {};
        table.data = [];

        const contactType = isRealtor ? 'p' : 'r';

        // Table Schema
        table.schema = [
            {
                title: 'Email',
                field: 'email',
                sortable: false,
                mobile: 'title'
            },
            {
                title: isRealtor ? 'Realtor' : 'Prospect',
                field: 'userName',
                sortable: false,
                mobile: 'subtitle'
            },
            {
                title: 'Project',
                field: 'project',
                sortable: false,
                mobile: 'project'
            },
            {
                title: 'Actions',
                field: 'actions'
            }
        ];

        if (data) {
            data.map((row, index) => {
                const { email, project, prospect, realtor } = { ...row };

                const {
                    first_name: prospectFirstName,
                    last_name: prospectLastName,
                    user: prospectUser
                } = {
                    ...prospect
                };
                const {
                    first_name: realtorFirstName,
                    last_name: realtorLastName,
                    user: realtorUser
                } = {
                    ...realtor
                };

                const userName = isRealtor
                    ? `${realtorFirstName} ${realtorLastName}`
                    : `${prospectFirstName} ${prospectLastName}`;

                return table.data.push({
                    id: row?.id,
                    iri: row?.iri,
                    email: (
                        <>
                            <span style={{ whiteSpace: 'nowrap' }}>
                                <strong>Avesdo email:</strong> {email}
                                <br />
                            </span>
                            {prospect?.email && (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <strong>Prospect email:</strong> {prospect?.email} <br />
                                </span>
                            )}
                            {realtor?.email && (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <strong>Realtor email:</strong> {realtor?.email} <br />
                                </span>
                            )}
                            {(prospectUser?.email || realtorUser?.user) && (
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    <strong>User email:</strong>{' '}
                                    {prospectUser?.email || realtorUser?.user} <br />
                                </span>
                            )}
                        </>
                    ),
                    project: project?.name || '-',
                    userName,
                    actions: (
                        <div style={{ textAlign: 'right' }}>
                            {userData?.email !== email && (
                                <Button
                                    size="small"
                                    label="Apply Avesdo Email to all related contacts"
                                    loading={`${contactType}${index}` === updating}
                                    onClick={() => {
                                        setUpdating(`${contactType}${index}`);
                                        onAvesdoEmailApply(row).finally(() => {
                                            setOpened(false);
                                            setUpdating(null);
                                        });
                                    }}
                                />
                            )}
                        </div>
                    )
                });
            });
        }

        return table;
    };

    const mainUserName = useMemo(() => {
        const { first_name: firstName, last_name: lastName } = { ...userData };
        return `${firstName} ${lastName}`;
    }, [userData]);

    useEffect(() => {
        fetchAvesdoProspectEmails();
        fetchAvesdoRealtorEmails();
    }, [userData, userIri]);

    return (
        <>
            {(userData?.prospect?.iri || userData?.realtor?.iri) && (
                <>
                    {(prospectListing?.data?.length > 0 || realtorListing?.data?.length > 0) && (
                        <Tooltip content="List of all emails used on this user (contact)">
                            <Button label="Avesdo Emails" onClick={() => setOpened(true)} />
                        </Tooltip>
                    )}
                    <Modal
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title={`Avesdo Emails for ${mainUserName}`}
                        subtitle="List of all emails used on this user (contact)"
                    >
                        {userData?.prospect?.iri && (
                            <>
                                <h3>Prospect E-mails</h3>
                                <Listing
                                    name="avesdoEmailsProspect"
                                    hideHeader
                                    reducerName="avesdoEmailsProspect"
                                    loading={fetchingProspectListing}
                                    meta={prospectListing?.meta || {}}
                                    table={tableData(prospectListing?.data || [])}
                                    onListingConfigUpdate={params =>
                                        fetchAvesdoProspectEmails(params)
                                    }
                                />
                            </>
                        )}
                        {userData?.realtor?.iri && (
                            <>
                                <h3>Realtor E-mails</h3>
                                <Listing
                                    name="avesdoEmailsRealtor"
                                    hideHeader
                                    reducerName="avesdoEmailsRealtor"
                                    loading={fetchingRealtorListing}
                                    meta={realtorListing?.meta || {}}
                                    table={tableData(realtorListing?.data || [], true)}
                                    onListingConfigUpdate={params =>
                                        fetchAvesdoRealtorEmails(params)
                                    }
                                />
                            </>
                        )}
                        <Button
                            variation="secondary"
                            label="Close"
                            onClick={() => setOpened(false)}
                        />
                    </Modal>
                </>
            )}
        </>
    );
};

UserAvesdoEmails.defaultProps = {};

UserAvesdoEmails.propTypes = {
    onAvesdoEmailApply: PropTypes.func.isRequired
};

export default UserAvesdoEmails;
