import React from 'react';
import PropTypes from 'prop-types';

import styles from './SwitchCard.module.scss';

const SwitchCard = ({ input, meta, fieldProps, fieldAttr, children }) => {
    const { name, value = false, onChange } = { ...input };
    const { label, description } = { ...fieldProps };
    let { offValue, onValue } = { ...fieldProps };
    const { id } = { ...fieldAttr };
    const { touched, error } = { ...meta };

    if (onValue === undefined) {
        onValue = true;
    }
    if (offValue === undefined) {
        offValue = false;
    }

    const nextValue = value === onValue ? offValue : onValue;

    const onSwitchClick = e => {
        if (e.target.tagName !== 'A') {
            onChange(nextValue);
        }
    };

    return (
        <>
            <div className={`${styles['switch-card']}`}>
                <button
                    type="button"
                    onClick={onSwitchClick}
                    className={`${styles['switch-card__content']}`}
                >
                    <h3 className={`${styles['switch-card__title']}`}>{label}</h3>
                    <div className={`${styles['switch-card__action']}`}>
                        <div className={`${styles['switch-card__input']}`}>
                            <span
                                role="button"
                                aria-label={`${label} switch`}
                                tabIndex={0}
                                onClick={() => onChange(nextValue)}
                                onKeyPress={() => onChange(nextValue)}
                                className={`${styles['switch-card__input-toggle']} ${
                                    value === onValue
                                        ? `${styles['switch-card__input-toggle--active']} ${styles['switch-card__input-toggle--green']}`
                                        : ''
                                }`}
                            />
                            <input
                                id={id}
                                type="checkbox"
                                className={`${styles['switch-card__input-checkbox']}`}
                                aria-label={`${name} field`}
                                defaultChecked={value}
                                name={name}
                                {...fieldAttr}
                            />
                        </div>
                    </div>
                    {description && (
                        <div className={`${styles['switch-card__description']}`}>{description}</div>
                    )}
                </button>
                {!!value && children && (
                    <div className={`${styles['switch-card__children']}`}>{children}</div>
                )}
            </div>
            {touched && error && (
                <span className={`${styles['switch-card__input-error']}`}>
                    {error.message ? error.message : error}
                </span>
            )}
        </>
    );
};

SwitchCard.defaultProps = {
    input: {},
    meta: {},
    fieldProps: {
        label: ' ',
        description: null,
        onValue: true,
        offValue: false
    },
    fieldAttr: {},
    children: null
};
SwitchCard.propTypes = {
    input: PropTypes.oneOfType([PropTypes.object]),
    meta: PropTypes.oneOfType([PropTypes.object]),
    fieldProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        onValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
        offValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    }),
    fieldAttr: PropTypes.oneOfType([PropTypes.object]),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.array
    ])
};
export default SwitchCard;
